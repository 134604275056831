<template>
  <div class="mb-3 rounded bg-white p-5 sm:p-8">
    <!-- Top -->
    <div class="flex items-center">
      <div class="mr-3 flex shrink-0 items-baseline" v-if="avatar">
        <img :src="avatar" class="h-8 w-8 rounded-full" />
      </div>
      <div class="text-sm" v-if="user_link">
        <a :href="user_link" class="blue-link font-medium">{{ name }}</a>
      </div>
      <div class="text-sm" v-else>
        {{ name }}
      </div>
      <div class="ml-3 text-sm text-bluey-grey-100">{{ amount }}</div>

      <div
        class="ml-3 flex items-center text-sm text-bluey-grey-100"
        v-if="is_monthly"
      >
        <icon name="calendar-days" class="mr-1 inline-block h-4 w-4"></icon>
        Monthly
      </div>

      <div
        class="ml-3 flex items-center text-sm text-bluey-grey-100"
        v-if="is_split"
      >
        <icon name="scale" class="mr-1 inline-block h-4 w-4"></icon>
        Split
      </div>

      <div
        title="Thanked"
        class="ml-3 flex items-center text-sm text-pink-100"
        v-if="is_thanked"
      >
        <icon name="heart" class="mr-1 inline-block h-4 w-4"></icon>
        Thanked
      </div>
    </div>

    <!-- Editing message -->
    <template v-if="!editing">
      <div class="mt-2 text-sm sm:text-base" v-if="editedMessage">
        {{ editedMessage }}
      </div>

      <button
        @click="editing = true"
        class="button button-tertiary mt-4 px-4 py-1 text-sm"
        v-if="ownMessage"
      >
        Edit your message
      </button>
    </template>

    <div class="mt-2" v-if="editing">
      <textarea
        v-model="editedMessage"
        class="beam-text-input w-full text-sm"
      ></textarea>
      <button
        @click="saveMessage"
        class="button button-tertiary px-4 py-1 text-sm"
      >
        Save
      </button>
    </div>

    <!-- Thank Button -->
    <div v-if="showThankButton" class="mt-5">
      <thank-button
        :member-id="member_id"
        :donation-id="donation_id"
        :user-id="user_id"
        :user-name="name"
        source="campaign"
      >
      </thank-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String },
    donation_id: { type: Number },
    member_id: { type: Number },
    user_id: { type: Number },
    message: { type: String },
    amount: { type: String },
    user_link: { type: String },
    avatar: { type: String },
    is_monthly: { type: Boolean },
    is_split: { type: Boolean },
    is_thanked: { type: Boolean, default: false },
  },
  data() {
    return {
      editing: false,
      editedMessage: this.message,
    }
  },
  computed: {
    showThankButton() {
      if (!gon.current_user) return false
      if (!this.member_id) return false

      return gon.current_user.member_id == this.member_id
    },
    ownMessage() {
      if (!gon.current_user) return false

      return gon.current_user.id == this.user_id
    },
  },
  methods: {
    saveMessage() {
      this.editing = false

      const data = { support_message: this.editedMessage }

      this.axios.put(`/donations/${this.donation_id}`, data)
    },
  },
}
</script>
