<script>
import MemberFormShared from "./MemberFormShared"

export default {
  mixins: [MemberFormShared],
  methods: {
    showField(id) {
      const field = this.form[id]

      if (!field.show_if) {
        return true
      }

      const func = new Function("return " + field.show_if)
      return func.bind(this)()
    },
  },
}
</script>
