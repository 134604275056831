<template>
  <vue-final-modal
    :name="name"
    v-model="open"
    :lock-scroll="false"
    @opened="opened"
    classes="flex absolute justify-center items-center h-full mx-auto w-11/12 sm:w-full"
    content-class="bg-white rounded-md relative p-8 sm:p-10 text-sm"
  >
    <modal-close-button :name="name"></modal-close-button>

    <div class="text-center text-xl font-medium">Need help with anything?</div>

    <div class="mt-4 text-sm sm:text-base">
      Send us a message and we'll get back to you as soon as possible
    </div>

    <div v-if="showNameInput" class="mt-6">
      <label for="member-name" class="font-medium">Your Name</label>
      <input
        type="text"
        name="member-name"
        id="member-name"
        class="beam-text-input mt-2 w-full"
        v-model.trim="memberName"
      />
    </div>

    <div v-if="showNameInput" class="mt-6">
      <label for="member-phone-number" class="font-medium"
        >Your Phone Number</label
      >
      <input
        type="text"
        name="member-phone-number"
        id="member-phone-number"
        class="beam-text-input mt-2 w-full"
        v-model.trim="memberPhoneNumber"
      />
    </div>

    <div class="mt-6">
      <label for="member-message" class="font-medium">Your Message</label>

      <textarea
        type="text"
        name="member-message"
        id="member-message"
        class="beam-text-input mt-2 h-40 w-full"
        v-model.trim="message"
      >
      </textarea>
    </div>

    <div class="mt-6 text-right">
      <button class="button button-secondary px-6 py-2" @click="sendMessage">
        Send
      </button>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    memberId: { type: String, required: false },
    page: { type: String, required: false },
    loggedInReferrer: { type: Boolean, default: false },
  },
  data() {
    return {
      name: "modal-member-support",
      open: false,
      message: "",
      memberName: "",
      memberPhoneNumber: "",
    }
  },
  computed: {
    showNameInput() {
      return !this.memberId && !this.loggedInReferrer
    },
  },
  methods: {
    sendMessage() {
      this.axios
        .post("/members/contact_support", {
          message: this.message,
          member_name: this.memberName,
          member_phone_number: this.memberPhoneNumber,
          member_id: this.memberId,
          page: this.page,
        })
        .then(() => {
          this.open = false
          this.message = ""
          this.$root.showFlash(
            "Thank you. One of the team will get back to you as soon as possible.",
            "notice"
          )
        })
    },
    opened() {
      if (this.showNameInput) {
        document.getElementById("member-name").focus()
      } else {
        document.getElementById("member-message").focus()
      }
    },
  },
}
</script>
