<template>
  <div class="grid grid-cols-12">
    <div class="col-span-12 mt-8 text-left md:col-span-8">
      <div class="text-md text-left font-bold">🎉 Welcome to Beam</div>

      <h1 class="beam-h1 mt-4 text-left">Let's send your first message</h1>

      <div class="mt-4 text-left">
        <p>
          One of the easiest ways you can support people on Beam is by
          <strong>sending them an encouraging message</strong>. Our members tell
          us they make all the difference.
        </p>
        <p class="mt-4">
          Get off to a flying start by sending your first message now.
        </p>
      </div>

      <campaign-comment-card
        class="mt-8 bg-white p-4"
        :campaign="campaign"
        :campaign-update-id="campaignUpdateId"
        :current-user-has-joined="true"
        :company="company"
        :show-comments="true"
        comment-source="company_onboarding"
        @message-sent="messageSent"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsMixin from "../mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    campaign: { type: Object },
    campaignUpdateId: { type: Number },
    company: { type: Object },
  },
  methods: {
    messageSent() {
      this.logEvent("campaign-message-sent", "Company Onboarding")
      this.$emit("message-sent")
    },
  },
}
</script>
