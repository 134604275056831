<template>
  <form action="/users/current.json" method="post" @submit.prevent="save">
    <label for="photo" class="mb-5 inline-block font-medium">Photo</label>

    <div class="flex items-center">
      <div v-if="user.avatar">
        <img :src="user.avatar" class="mr-4 h-20 w-20 rounded" />
      </div>

      <div class="text-center">
        <label class="button button-tertiary cursor-pointer px-8 py-2">
          <icon
            name="photo"
            :solid="true"
            class="mr-1 inline-block h-5 w-5 pt-1"
          ></icon>
          Upload photo
          <input
            type="file"
            class="hidden"
            ref="file"
            @change="handlePhotoUpload"
          />
        </label>

        <div
          @click="deletePhoto"
          class="m-4 cursor-pointer text-sm text-bluey-grey-100"
        >
          Delete your photo
        </div>
      </div>
    </div>

    <label for="first-name" class="mt-10 inline-block font-medium"
      >First name</label
    >
    <input
      type="text"
      class="beam-text-input my-5 w-full"
      v-model.trim="user.first_name"
      id="first-name"
      autocomplete="given-name"
    />

    <label for="last-name" class="mt-5 inline-block font-medium"
      >Last name</label
    >
    <input
      type="text"
      class="beam-text-input my-5 w-full"
      v-model.trim="user.last_name"
      id="last-name"
      autocomplete="family-name"
    />

    <label for="username" class="mt-5 inline-block font-medium">Username</label>
    <input
      type="text"
      class="beam-text-input my-5 w-full"
      v-model.trim="user.username"
      id="username"
    />

    <label for="email-address" class="mt-5 inline-block font-medium"
      >Email</label
    >
    <input
      type="email"
      name="email"
      class="beam-text-input my-5 w-full"
      v-model.trim="user.email"
      id="email-address"
    />

    <label for="short-bio" class="mt-5 inline-block font-medium"
      >Short bio</label
    >
    <input
      type="text"
      class="beam-text-input my-5 w-full"
      v-model.trim="user.short_bio"
      id="short-bio"
    />

    <label for="workplace" class="mt-5 inline-block font-medium"
      >Workplace</label
    >
    <input
      type="text"
      class="beam-text-input my-5 w-full"
      v-model.trim="user.workplace"
      id="workplace"
    />

    <label for="job_title" class="mt-5 inline-block font-medium"
      >Job title</label
    >
    <input
      type="text"
      class="beam-text-input my-5 w-full"
      v-model.trim="user.job_title"
      id="job_title"
    />

    <label for="website" class="mt-5 inline-block font-medium">Website</label>
    <input
      type="text"
      class="beam-text-input my-5 w-full"
      v-model.trim="user.website"
      id="website"
    />

    <label for="photo" class="my-5 inline-block font-medium"
      >Profile Privacy</label
    >
    <check-box
      checkbox-label="Make your profile private"
      :default-checked="user.is_private"
      :marginTop="0"
      ref="privateCheckbox"
    ></check-box>

    <label for="photo" class="mb-5 mt-10 inline-block font-medium"
      >Donated Amount</label
    >
    <check-box
      checkbox-label="Show the amount you have donated on your impact page"
      :default-checked="user.show_amount_donated"
      :marginTop="0"
      ref="amountCheckbox"
    ></check-box>

    <button class="button button-primary mt-8 w-full px-12 py-3 sm:w-auto">
      Save changes
    </button>
  </form>
</template>

<script>
export default {
  props: {
    initialUser: Object,
  },
  data() {
    return {
      user: {
        first_name: this.initialUser.first_name,
        last_name: this.initialUser.last_name,
        short_bio: this.initialUser.short_bio,
        workplace: this.initialUser.workplace,
        job_title: this.initialUser.job_title,
        website: this.initialUser.website,
        email: this.initialUser.email,
        username: this.initialUser.username,
        avatar: this.initialUser.thumb_avatar_url,
        is_private: this.initialUser.is_private,
        show_amount_donated: this.initialUser.show_amount_donated,
      },
    }
  },
  methods: {
    handlePhotoUpload() {
      let formData = new FormData()
      formData.append("user[avatar]", this.$refs.file.files[0])

      this.axios.patch("/users/current.json", formData).then((response) => {
        this.handleResponse(response.data.user)
      })
    },
    deletePhoto() {
      this.axios.post("/users/current/delete_avatar").then((response) => {
        this.handleResponse(response.data.user)
      })
    },
    handleResponse(user) {
      // Update the avatar on the page
      this.user.avatar = user.thumb_avatar_url
      this.user.fullName = this.fullName
      // Update the parent so photo is there when you come back
      this.$emit("user-updated", user)
    },
    save(event) {
      this.axios.patch(event.target.action, this.postData).then((response) => {
        const data = response.data

        if (data.logged_out) {
          window.location = "/"
        } else if (data.success) {
          this.$root.showFlash("Success!", "notice")
          this.$emit("user-updated", data.user)
        } else {
          this.$root.showFlash(data.error_message, "error")
        }
      })
    },
  },
  computed: {
    fullName() {
      return `${this.user.first_name} ${this.user.last_name}`
    },
    postData() {
      // Copy the data
      let data = JSON.parse(JSON.stringify(this.$data))

      const privateCheckbox = this.$refs.privateCheckbox
      data.user.is_private = privateCheckbox.checked

      const amountCheckbox = this.$refs.amountCheckbox
      data.user.show_amount_donated = amountCheckbox.checked

      // Don't send the avatar field
      delete data.user.avatar

      return data
    },
  },
}
</script>
