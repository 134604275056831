<template>
  <vue-final-modal
    :name="name"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-8 sm:p-10 max-w-md"
  >
    <modal-close-button :name="name"></modal-close-button>

    <div v-if="currentUserCanJoin" class="text-center">
      <p>
        It looks like you're logged in, so you can link your Beam account to the
        company to see the full dashboard.
      </p>
      <button
        @click="sendJoinCompanyRequest"
        class="button button-primary mt-3 w-auto w-full border-pink-100 bg-pink-100 p-4 px-8"
      >
        Link Beam account
      </button>
    </div>

    <sign-in-form
      v-else-if="showSignInForm"
      :user-source="userSource"
      :company-id="company.id"
      :validate-email="validateEmail"
      :show-name-fields="true"
      class="text-left"
    >
      <template #email-label>Your {{ company.name }} email</template>
      <template #name-label>Your full name</template>
      <template #heading>Sign in with your {{ company.name }} email</template>
      <template #success-heading>To continue, check your email!</template>
      <template #success-body="slotProps">
        <p>
          No password required, just click the link in the email we sent to
          {{ slotProps.email }}
        </p>
        <p class="mt-4">
          Please check your spam folder if you don't get the email!
        </p>
      </template>
    </sign-in-form>

    <div v-if="showEmailError" class="text-center">
      <div class="text-xl font-medium">
        Sorry, that doesn't look like a {{ company.name }} email
      </div>

      <div class="mt-4">
        If you think we've made a mistake, please email
        <a href="mailto:companies@beam.org" class="underline"
          >companies@beam.org</a
        >. Thank you!
      </div>

      <div @click="resetForm" class="mt-4">
        <button class="button button-secondary w-full px-16 py-3">
          Try again
        </button>
      </div>
    </div>

    <div v-if="!currentUserValid">
      <div class="text-xl font-medium">
        Sorry, your email address doesn't look like a {{ company.name }} address
      </div>

      <div class="mt-4">
        You're logged in with <strong>{{ currentUserEmail }}</strong
        >, but {{ company.name }} addresses end with
        <strong>@{{ this.company.email_domain }}</strong
        >. You can either change your email on your
        <a class="blue-link" href="/account" target="_blank">Beam account</a>
        then try again, or log out and start a new account with your work email.
      </div>

      <div class="mt-4">
        If you think we've made a mistake, please email
        <a href="mailto:companies@beam.org" class="underline"
          >companies@beam.org</a
        >. Thank you!
      </div>

      <div class="mt-4">
        <a
          href="/account"
          class="button button-secondary block w-full px-16 py-3"
        >
          Change email address
        </a>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import AnalyticsMixin from "../mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    company: { type: Object },
    currentUserEmail: { type: String },
    currentUserCanJoin: { type: Boolean },
    sendJoinCompanyRequest: { type: Function },
  },
  data: function () {
    return {
      name: "modal-join-company",
      userSource: null,
      open: false,
      emailValid: true,
    }
  },
  computed: {
    showSignInForm() {
      return this.emailValid && this.currentUserValid
    },
    showEmailError() {
      return this.currentUserValid && !this.emailValid
    },
    currentUserValid() {
      if (!this.currentUserEmail) {
        return true
      }

      return this.validateEmail(this.currentUserEmail)
    },
  },
  methods: {
    beforeOpen(event) {
      this.logEvent("join-company-view")

      this.userSource = event.ref.params.value.userSource
    },
    beforeClose(event) {
      // Reset the data
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    validateEmail(email) {
      if (!email.trim().endsWith(`@${this.company.email_domain}`)) {
        this.emailValid = false
        return false
      }
      return true
    },
    resetForm() {
      this.emailValid = true
    },
  },
}
</script>
