<template>
  <div
    class="mx-auto max-w-3xl rounded bg-red-600 py-8 text-center font-medium text-white"
  >
    <div>This page does not support Internet Explorer.</div>

    <div class="mt-4">
      Please use Google Chrome, Safari, Firefox, Edge, or complete this form on
      your mobile.
    </div>

    <div class="mt-4">Thank you.</div>
  </div>
</template>
