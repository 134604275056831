<template>
  <div class="col-span-12 mt-12">
    <h3 class="beam-h2 w-full text-center">
      Everyone {{ company.name }} has supported
    </h3>

    <div class="bg-white p-8 mt-8">
      <div class="text-center py-12 text-lg" v-if="campaigns.length === 0">
        <p class="mb-4">
          Once you start supporting people you’ll see their stories here
        </p>

        <a href="/company_donate" class="border-b">Get started</a>
      </div>

      <ul class="mt-5 flex flex-row flex-wrap justify-center">
        <li
          v-for="campaign in visibleCampaigns"
          v-bind:key="campaign.slug"
          class="m-2 inline-block"
        >
          <a :href="`/campaigns/${campaign.slug}`" target="_blank">
            <img
              :src="campaign.tiny_avatar_url"
              class="h-14 w-14 rounded-full"
            />
          </a>
        </li>
      </ul>
      <div
        class="mt-8 border-t border-pale-grey-100 pt-8 text-center"
        v-if="visibleCampaigns.length < campaigns.length || campaignsExpanded"
      >
        <span
          @click="campaignsExpanded = !campaignsExpanded"
          class="cursor-pointer underline"
          >{{ campaignsExpanded ? "Show fewer" : "Show more" }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    company: { type: Object },
    campaigns: { type: Array },
  },
  data() {
    return {
      campaignsExpanded: false,
    }
  },
  computed: {
    visibleCampaigns() {
      if (this.campaignsExpanded) {
        return this.campaigns
      } else {
        return this.campaigns.slice(0, 30)
      }
    },
  },
}
</script>
