<template>
  <div class="mx-auto max-w-2xl">
    <form
      ref="form"
      action="/member_leavers"
      accept-charset="UTF-8"
      method="post"
    >
      <div class="stack-mt-12">
        <member-form-field
          v-for="field in form"
          :key="field.id"
          :field="field"
          v-model="field.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberLeaverConditions from "./MemberLeaverConditions"
export default {
  mixins: [MemberFormShared, MemberLeaverConditions],
  data() {
    return {
      tableName: "member_leaver",
    }
  },
  methods: {
    setFailureReasonOptions() {
      this.form.failure_reason.checkbox_options = Object.entries(
        this.form.failure_reason.dynamic_checkbox_options
      )
        .filter(
          (option) =>
            option[1].logic.beam_services.filter((value) =>
              this.form.beam_services.value.includes(value)
            ).length > 0 &&
            option[1].logic.stage_reached.includes(
              this.form.stage_reached.value
            )
        )
        .map((option) => option[1].value)
    },
  },
  watch: {
    "form.beam_services.value": function () {
      this.setFailureReasonOptions()
    },
    "form.stage_reached.value": function () {
      this.setFailureReasonOptions()
    },
  },
}
</script>
