<template>
  <div class="flex mb-4">
    <div class="mr-4 flex-shrink-0">
      <div
        class="bg-gray-200 rounded-full p-3 flex items-center justify-center w-14 h-14"
      >
        <span class="text-lg">{{ emoji }}</span>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="text-lg font-medium">{{ title }}</div>
      <div class="">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmojiAvatarBlock",
  props: {
    emoji: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
}
</script>
