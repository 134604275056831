<template>
  <div
    class="stack-mt-5 full-screen-mobile bg-pale-grey-100 px-5 py-10 sm:rounded sm:px-10"
  >
    <div v-for="update in updates.slice(0, 1)" :key="update.id">
      <update-single :update="update"></update-single>
    </div>

    <news-notice v-if="showNotice" :notice="selectedNotice" ref="notice">
    </news-notice>

    <div v-for="update in updates.slice(1)" :key="update.id">
      <update-single :update="update"></update-single>
    </div>
  </div>
</template>

<script>
import LocalStorageMixin from "./mixins/LocalStorageMixin"

export default {
  mixins: [LocalStorageMixin],
  props: {
    campaignId: { type: Number, required: false },
    fundraiserId: { type: Number, required: false },
    firstIds: { type: Array, required: false },
    singleId: { type: Number, required: false },
    notices: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      updates: [],
      page: 0,
      finished: false,
      fetching: false,
    }
  },
  computed: {
    selectedNotice() {
      let result = this.notices.filter((notice) => {
        return !this.fetchHiddenNotices().includes(notice.id)
      })

      return result.length == 0 ? null : result[0]
    },
    showNotice() {
      return this.selectedNotice
    },
  },
  methods: {
    fetchPage() {
      if (this.finished || this.fetching) {
        return
      }

      this.fetching = true

      let url = "/campaign_updates?page=" + this.page

      if (this.campaignId) {
        url += "&campaign_id=" + this.campaignId
      }

      if (this.fundraiserId) {
        url += "&fundraiser_id=" + this.fundraiserId
      }

      if (this.firstIds) {
        this.firstIds.forEach(function (id) {
          url += "&first_ids[]=" + id
        })
      }

      if (this.singleId) {
        url += "&limit=1&first_ids[]=" + this.singleId
      }

      this.axios.get(url).then((response) => {
        const data = response.data
        this.fetching = false
        this.page += 1
        this.updates = this.updates.concat(data.updates)

        if (this.page == 1) {
          this.unhideNotice()
        }

        if (data.updates.length == 0) {
          this.finished = true
        }
      })
    },
    handleScroll() {
      if (this.campaignId || this.fundraiserId || this.singleId) {
        return
      }

      var _this = this
      window.onscroll = function () {
        var scrollPos = window.scrollY || document.documentElement.scrollTop
        if (
          scrollPos + window.innerHeight + 2000 >=
          document.documentElement.scrollHeight
        ) {
          _this.fetchPage()
        }
      }
    },
    unhideNotice() {
      if (this.$refs.notice) {
        // Prevent flicker where notice is shown top before updates loads
        this.$refs.notice.show()
      }
    },
  },
  created() {
    this.fetchPage()
  },
  mounted: function () {
    this.handleScroll()
  },
  unmounted() {
    window.onscroll = null
  },
}
</script>
