import { Controller } from "@hotwired/stimulus"
import axios from "axios"
import { apiUrl, headers } from "../../src/team-tailor"

export default class extends Controller {
  static targets = ["numberOfRoles"]

  connect() {
    axios.get(apiUrl, { headers: headers }).then((response) => {
      this.numberOfRolesTarget.innerHTML = Object.keys(
        response.data.data
      ).length
    })
  }
}
