<template>
  <div :class="{ 'border border-grey-300': showBorders }">
    <div class="p-4" :class="{ 'border-b border-grey-300': showBorders }">
      <company-form-field attribute_name="campaigns_to_support" :form="form">
        <range-slider-with-number
          id="campaigns_to_support"
          name="company[campaigns_to_support]"
          :value="
            form?.attributes?.campaigns_to_support || campaigns_to_support
          "
          min="2"
          max="20"
          class="w-full text-xl"
          @input="valueChanged"
      /></company-form-field>
    </div>
    <div
      class="p-4 text-center font-bold"
      :class="{ 'border-grey-300 border-b': showBorders }"
    >
      <div
        class="flex -space-x-2 overflow-hidden justify-center flex-wrap mb-4"
      >
        <img
          class="h-10 w-10 z-0 rounded-full ring-2 ring-white bg-yellow-500"
          v-for="campaignImageUrl in campaignImagesSubset"
          :src="campaignImageUrl"
        />
      </div>
      <slot name="support-text" :campaigns_to_support="campaigns_to_support">
        You'll be supporting {{ campaigns_to_support }} people into a job or
        home every month 🎉
      </slot>
    </div>
    <div class="p-4 flex flex-row justify-between" v-if="showCostPerMonth">
      <span>Cost per month</span
      ><span class="font-bold">£{{ campaigns_to_support * 50 }}</span>
    </div>
  </div>
</template>
<script>
const DEFAULT_NUMBER_OF_CAMPAIGNS_TO_SUPPORT = 5

export default {
  props: {
    form: { type: Object },
    campaignImages: { type: Array },
    showBorders: { type: Boolean, default: true },
    showCostPerMonth: { type: Boolean, default: true },
  },
  emits: ["update:modelValue"],
  computed: {
    campaignImagesSubset() {
      return this.campaignImages.slice(0, this.campaigns_to_support)
    },
  },
  methods: {
    valueChanged(event) {
      this.campaigns_to_support = +event.target.value
      this.$emit("update:modelValue", this.campaigns_to_support)
    },
  },
  data() {
    return {
      campaigns_to_support: DEFAULT_NUMBER_OF_CAMPAIGNS_TO_SUPPORT,
    }
  },
  mounted() {
    this.campaigns_to_support =
      this.form?.attributes?.campaigns_to_support ||
      DEFAULT_NUMBER_OF_CAMPAIGNS_TO_SUPPORT
  },
}
</script>
