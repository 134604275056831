<template>
  <form
    method="post"
    :action="action"
    class="m-auto my-8 flex w-full flex-col items-center text-left"
  >
    <div class="flex w-full flex-col md:flex-row">
      <input
        type="email"
        name="email"
        id="email"
        placeholder="Work email"
        class="mr-2 w-full flex-grow rounded-md border-2 border-navy-600 p-4 md:w-auto"
        required
      />

      <input type="hidden" name="authenticity_token" :value="csrfToken" />

      <input type="hidden" name="submission_page" :value="submissionPage" />

      <button
        class="button button-primary mt-2 w-full flex-grow border-pink-100 bg-pink-100 px-8 py-4 text-center md:mt-0 md:w-auto md:flex-shrink md:flex-grow-0"
      >
        <slot name="button-text"></slot>
      </button>
    </div>

    <!-- If this form is re-used outside of the /companies page, the message below should be parameterised somehow -->
    <p class="mt-8 text-center" v-if="showReferLink">
      Alternatively, you can
      <a href="/for-homeless-people#i-support-homeless-people" class="blue-link"
        >refer someone in need to Beam</a
      >.
    </p>
  </form>
</template>

<script>
export default {
  props: {
    csrfToken: {
      type: String,
    },
    submissionPage: {
      type: String,
    },
    action: {
      type: String,
      default: "/marketing_contacts",
    },
    showReferLink: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
