<template>
  <div class="text-left">
    <div class="text-xl font-medium">Not sure who to fund?</div>
    <div class="mt-5 text-sm sm:text-base">
      <div>We’ll send your donation to the most urgent campaign.</div>

      <div class="mt-8 text-center">
        <a class="button button-primary inline-block px-4 py-2" href="/donate"
          >Donate today</a
        >
      </div>
    </div>
  </div>
</template>
