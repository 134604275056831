<template>
  <div @click="copy">
    <slot></slot>
  </div>
</template>

<script>
import AnalyticsMixin from "./mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    text: { type: String },
    eventLabel: { type: String },
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.text)
      this.$root.showFlash("Text copied!")
      this.logEvent("copy-to-clipboard", this.eventLabel)
    },
  },
}
</script>
