export default {
  methods: {
    showField(id) {
      switch (id) {
        case "claiming_benefits":
          // Kingston
          return this.fieldEquals(this.thisForm.referral_location_id, 58)
        case "housing_officer":
          // Leicester
          return this.fieldEquals(this.thisForm.referral_location_id, 60)
        default:
          return true
      }
    },
  },
}
