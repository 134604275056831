<template>
  <section class="mb-10 mt-6 rounded bg-pale-grey-100 px-4 md:mt-10 md:px-8">
    <div class="mt-8 flex md:px-48">
      <form
        ref="form"
        :action="`/member_job_applications/${jobApplication.id}`"
        accept-charset="UTF-8"
      >
        <div class="stack-mt-8">
          <member-form-field
            v-for="field in form"
            :key="field.id"
            :field="field"
            v-model="field.value"
          />
        </div>

        <div class="mt-8">
          <div class="flex justify-center space-x-3 sm:space-x-12">
            <div class="flex justify-end">
              <button
                @click.prevent="apply"
                type="submit"
                class="button button-primary flex space-x-2 px-6 py-2"
              >
                <icon name="heart" :solid="true" class="h-6 w-6" />
                <span>{{ applyButtonText }}</span>
              </button>
            </div>

            <div class="flex justify-center">
              <button
                @click.prevent="skip"
                class="button button-tertiary px-6 py-2"
              >
                <span>{{ skipButtonText }}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberJobApplicationFormConditions from "./MemberJobApplicationFormConditions"
export default {
  mixins: [MemberFormShared, MemberJobApplicationFormConditions],
  data() {
    return {
      tableName: "member_job_application",
    }
  },
  props: {
    formType: { type: String, default: "put" }, // Override the MemberFormShared mixin default for this prop which is "post".
    jobApplication: Object,
  },
  computed: {
    applyButtonText() {
      if (this.saving) {
        return "Submitting..."
      }
      return "I want to apply!"
    },
    skipButtonText() {
      if (this.saving) {
        return "Submitting..."
      }
      return "Not for me"
    },
  },
  methods: {
    apply() {
      this.form.status.value = "interested"
      this.submit()
    },
    skip() {
      this.form.status.value = "uninterested"
      this.submit()
    },
  },
}
</script>
