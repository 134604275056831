export default {
  props: {
    url: "",
  },
  emits: ["video-ended", "video-closed"],
  methods: {
    showVideoModal() {
      this.$vfm.show("modal-video", {
        url: this.url,
        onVideoEnd: this.onVideoEnd,
        onVideoClose: this.onVideoClose,
      })
    },
    onVideoEnd() {
      this.$emit("video-ended")
    },
    onVideoClose(event) {
      this.$emit("video-closed", event)
    },
  },
  mounted() {
    if (
      document.getElementById("www-widget-api-script") || // The script that YT inserts
      document.getElementById("iframe-api-script") // The script we insert
    ) {
      return
    }
    const youtubeScriptTag = document.createElement("script")
    youtubeScriptTag.id = "iframe-api-script"
    youtubeScriptTag.src = "https://www.youtube.com/iframe_api"
    const firstScriptTag = document.getElementsByTagName("script")[0]
    firstScriptTag.parentNode.insertBefore(youtubeScriptTag, firstScriptTag)
  },
}
