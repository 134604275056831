<template>
  <div>
    <div v-if="cardDetails">
      <div>
        Saved card details: {{ cardDetails }}

        <span
          @click="deleteCard"
          class="ml-1 cursor-pointer hover:text-azure-100"
          title="Delete your card"
        >
          <icon
            name="trash"
            class="inline-block h-5 w-5 pt-1"
            :solid="true"
          ></icon>
        </span>
      </div>

      <div class="mt-8">Update your card below</div>
    </div>

    <div v-else>Add your card below</div>

    <div v-if="errorMessage" class="mt-4 font-medium text-pink-100">
      {{ errorMessage }}
    </div>

    <stripe-card-element ref="stripeElement"></stripe-card-element>

    <button
      @click="saveCardDetails"
      class="button button-secondary mt-5 w-full px-12 py-3 sm:w-auto"
    >
      <template v-if="submitting">Saving...</template>
      <template v-else>Save Card</template>
    </button>
  </div>
</template>

<script>
import * as StripeApi from "../../stripe.js"

export default {
  props: {
    initialCardDetails: { type: String },
    fullName: { type: String },
  },
  data: function () {
    return {
      errorMessage: null,
      submitting: false,
      cardDetails: this.initialCardDetails,
    }
  },
  methods: {
    updateCardOnServer(payment_method) {
      const data = { payment_method: payment_method }

      this.axios
        .put("/users/current/update_card", data)
        .then((response) => {
          if (response.data.success) {
            this.$root.showFlash("Your card details were updated!")
            if (response.data.card_details) {
              this.cardDetails = response.data.card_details
              this.$refs.stripeElement.elementCard.clear()
            }
          } else {
            this.errorMessage = response.data.error_message // Caught server error
          }
        })
        .catch((error) => {
          this.errorMessage = "Sorry, there was an error doing that."
        })
        .finally(() => {
          this.submitting = false
        })
    },
    saveCardDetails() {
      if (this.submitting) return

      this.submitting = true

      const errorCallback = (result) => {
        this.errorMessage = result.error.message
        this.submitting = false
      }

      const successCallback = (result) => {
        this.errorMessage = ""
        this.updateCardOnServer(result.setupIntent.payment_method)
      }

      StripeApi.confirmCardSetup(
        this.$refs.stripeElement.elementCard,
        { name: this.fullName },
        errorCallback,
        successCallback
      )
    },
    deleteCard() {
      if (!confirm("Are you sure you want to delete your saved card?")) {
        return
      }

      this.axios
        .post("/users/current/delete_card")
        .then((response) => {
          this.$root.showFlash("Your saved card was deleted")
          this.cardDetails = null
        })
        .catch((error) => {
          this.errorMessage = "Sorry, there was an error doing that."
        })
    },
  },
}
</script>
