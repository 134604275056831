<template>
  <section>
    <h2 class="beam-h2 my-8 w-full">💌 Send an encouraging message</h2>
    <div
      class="lg:p-unset flex flex-col items-start p-0 lg:grid lg:grid-cols-12 lg:gap-x-4"
    >
      <div class="col-span-8 mt-4">
        <!-- Center align things -->
        <div class="flex flex-col items-center">
          <div
            v-for="(campaign, key) in campaigns"
            v-bind:key="campaign.id"
            class="mb-4 w-full"
          >
            <campaign-comment-card
              class="beam-container mb-4 border border-gray-200 bg-white p-4"
              :campaign="campaign"
              :campaignUpdateId="campaign.campaign_update_id"
              :story-line-limit="2"
              :show-comments="true"
              :company="company"
              :current-user-has-joined="currentUserHasJoined"
              @message-sent="messageSent"
            ></campaign-comment-card>
            <news-notice
              v-if="notices.length == 1 && key == 0"
              :notice="notices[0]"
              ref="notice"
            >
            </news-notice>
          </div>
          <button
            v-if="hasMoreCampaigns"
            class="w-full rounded-full border border-gray-200 bg-white p-3 text-gray-600 md:w-96"
            @click="showMoreActivity"
          >
            Show more
          </button>
        </div>
      </div>
      <div
        class="sticky top-28 col-span-4 mt-8 w-full border border-gray-100 border-gray-200 bg-white p-4 md:mt-4 lg:w-auto"
      >
        <company-page-leaderboard
          :data-sets="{
            'All time': leaderboardAllTime,
            'This month': leaderboardThisMonth,
          }"
          :total-amounts="{
            'All time': leaderboardAllTimeTotal,
            'This month': leaderboardThisMonthTotal,
          }"
          :total-amount-labels="{
            'All time': 'messages sent in total',
            'This month': 'messages sent this month',
          }"
        >
          <template #title
            ><h4 class="beam-h4 mb-4 text-left">
              🏆 Leaderboard: Messages sent
            </h4></template
          >
        </company-page-leaderboard>
      </div>
    </div>
  </section>
</template>

<script>
import AnalyticsMixin from "../mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    company: { type: Object },
    notices: { type: Array, default: [] },
    currentUserHasJoined: { type: Boolean, default: false },
  },
  data() {
    return {
      campaignsPage: 0,
      campaigns: [],
      hasMoreCampaigns: false,
      leaderboardThisMonth: [],
      leaderboardThisMonthTotal: 0,
      leaderboardAllTime: [],
      leaderboardAllTimeTotal: 0,
      employeeViewRequested: false,
    }
  },
  methods: {
    showMoreActivity() {
      console.log(
        "Feed request with employee view:",
        this.employeeViewRequested
      )
      this.axios
        .get(`/company_activity_feed/${this.company.slug}`, {
          params: {
            page: this.campaignsPage,
            employee_view: this.employeeViewRequested,
          },
        })
        .then((response) => {
          const { data } = response
          if (data.success) {
            this.campaigns = this.campaigns.concat(data.campaigns)
            this.hasMoreCampaigns = data.has_more
            this.campaignsPage += 1

            this.$nextTick(() => {
              if (this.$refs.notice?.[0]) {
                // Prevent flicker where notice is shown top before updates loads
                this.$refs.notice[0].show()
              }
            })
          } else {
            this.handleError()
          }
        })
        .catch((e) => {
          this.handleError(e?.response?.data?.error_message)
        })
    },
    hasCommentCount(campaign) {
      return typeof campaign.num_colleagues_commented !== "undefined"
    },
    handleError(
      errorMessage = "Something went wrong. Please contact support@beam.org"
    ) {
      this.$root.showFlash(errorMessage, "error")
    },
    messageSent() {
      this.logEvent("campaign-message-sent", "Company Campaign Feed")
    },
    fetchLeaderboard() {
      this.axios
        .get(`/company_leaderboards/${this.company.slug}/comments`, {
          params: {
            employee_view: this.employeeViewRequested,
          },
        })
        .then((response) => {
          if (response.data.success) {
            const {
              this_calendar_month,
              all_time,
              this_calendar_month_total,
              all_time_total,
            } = response.data
            this.leaderboardThisMonth = this_calendar_month
            this.leaderboardAllTime = all_time
            this.leaderboardThisMonthTotal = this_calendar_month_total
            this.leaderboardAllTimeTotal = all_time_total
          } else {
            this.handleError()
          }
        })
        .catch((e) => {
          this.handleError(e?.response?.data?.error_message)
        })
    },
  },
  channels: {
    CompanyChannel: {
      received(data) {
        if (data.comment_leaderboard_updated) {
          this.fetchLeaderboard()
        }
      },
    },
  },
  created() {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    console.log("QS params")
    console.log(params)

    this.employeeViewRequested = params["employee_view"] === "true"

    console.log(`this.employeeViewRequested: ${this.employeeViewRequested}`)

    this.showMoreActivity()
    this.fetchLeaderboard()

    this.$cable.subscribe({
      channel: "CompanyChannel",
      company_id: this.company.id,
    })
  },
}
</script>
