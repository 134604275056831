<template>
  <a
    v-if="caseworker && caseworker.thumb_avatar_url"
    :href="`https://api.whatsapp.com/send?phone=${encodeURIComponent(
      caseworker.phone_number
    )}`"
    class="button button-tertiary mt-6 flex items-center justify-between px-4 py-2 text-sm"
  >
    <img
      class="h-8 w-8"
      :src="`/icons/whatsapp-icon.svg`"
      alt="WhatsApp Icon"
    />
    <div class="mx-auto whitespace-nowrap">WhatsApp Me</div>
  </a>
</template>

<script>
export default {
  props: {
    caseworker: { type: Object },
  },
}
</script>
