<template>
  <div
    class="transition-border-color ease-in-outrounded-md flex flex-col items-center justify-center rounded-md border-2 border-2 border-dashed bg-white p-4 align-middle duration-300"
    @drop="handlePhotoDrop"
    @dragover="handlePhotoDragOver"
    @dragleave="handlePhotoDragLeave"
    :class="{
      'border-gray-500': isDraggingOver,
      'border-gray-200': !isDraggingOver,
    }"
  >
    <img
      :src="imageUrl"
      class="h-28"
      :class="{ 'w-28 rounded-full': squareImage }"
    />
    <p class="mt-4 text-lg font-medium">
      <slot name="instruction-text"> Drag and drop an image here </slot>
    </p>
    <p class="mt-4 text-sm text-gray-500">or</p>
    <label
      class="button button-tertiary mt-4 inline-block cursor-pointer border-yellow-400 px-8 py-2"
    >
      <icon
        name="photo"
        class="mr-1 inline-block h-5 w-5 pt-1"
        :solid="true"
      ></icon>
      {{ uploading ? "Uploading..." : uploadButtonText }}
      <input
        type="file"
        class="hidden"
        ref="file"
        @change="handlePhotoUpload"
      />
    </label>
    <!-- Error message -->
    <div v-if="errorMessage" class="mt-4 text-red-500">
      {{ errorMessage }}
    </div>
    <div
      v-if="imageUrl !== '/images/missing.png'"
      class="mt-4 text-center align-middle"
    >
      <slot name="success">
        <p class="text-sm text-gray-500">Success!</p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uploadButtonText: { type: String, default: "Upload image" },
    initialImageUrl: { type: String, default: null },
    formUrl: { type: String },
    recordName: { type: String },
    attachmentAttributeName: { type: String, default: "avatar" },
    responseAttributeName: { type: String, default: "thumb_avatar_url" },
    squareImage: { type: Boolean, default: true },
  },
  data() {
    return {
      isDraggingOver: false,
      imageUrl: "/images/missing.png",
      uploading: false,
      errorMessage: null,
    }
  },
  methods: {
    uploadPhoto(file) {
      if (this.uploading) return

      let formData = new FormData()
      formData.append(
        `${this.recordName}[${this.attachmentAttributeName}]`,
        file
      )
      this.isDraggingOver = false
      this.uploading = true
      this.axios
        .patch(this.formUrl, formData)
        .then((response) => {
          const { data } = response
          if (data.success) {
            this.imageUrl = data[this.recordName][this.responseAttributeName]
            this.$emit("success", this.imageUrl)
          } else {
            this.errorMessage =
              data.error_message ||
              "Something went wrong, please skip this step or contact support@beam.org"
          }
        })
        .finally(() => {
          this.uploading = false
        })
    },
    handlePhotoUpload() {
      this.uploadPhoto(this.$refs.file.files[0])
    },
    handlePhotoDrop(e) {
      e.preventDefault()
      this.uploadPhoto(e.dataTransfer.files[0])
    },
    handlePhotoDragOver(e) {
      e.preventDefault()
      this.isDraggingOver = true
    },
    handlePhotoDragLeave(e) {
      e.preventDefault()
      this.isDraggingOver = false
    },
  },
  created() {
    if (this.initialImageUrl) {
      this.imageUrl = this.initialImageUrl
    }
  },
}
</script>
