<template>
  <div
    @click="toggleQuestion()"
    class="my-5 cursor-pointer rounded p-6 text-sm shadow sm:p-8 sm:text-lg lg:text-xl"
  >
    <div class="flex items-center justify-between">
      <div class="mr-4 font-medium">{{ question }}</div>
      <div :class="{ hidden: questionOpen, block: !questionOpen }">
        <icon name="chevron-down" class="inline-block h-5 w-5 pt-1"></icon>
      </div>
      <div :class="{ block: questionOpen, hidden: !questionOpen }">
        <icon name="chevron-up" class="inline-block h-5 w-5 pt-1"></icon>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="questionOpen" class="mt-8 text-sm sm:text-base">
        <div class="markdown-wrapper mt-4" v-html="answer"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import AnalyticsMixin from "./mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: ["question", "answer"],
  data: function () {
    return {
      questionOpen: false,
    }
  },
  methods: {
    toggleQuestion: function () {
      this.questionOpen = !this.questionOpen

      if (this.questionOpen) {
        this.logEvent("open-faq", this.question)
      }
    },
  },
}
</script>
