<template>
  <div class="beam-text-input mt-2 w-full" id="card-element"></div>
</template>

<script>
export default {
  data: function () {
    return {
      elementCard: null,
    }
  },
  mounted: function () {
    var elements = stripe.elements({
      fonts: [
        { cssSrc: "https://fonts.googleapis.com/css?family=Open Sans:400,600" },
      ],
    })

    // Don't squish card number on low widths
    var fontSize = window.innerWidth < 350 ? "15px" : "16px"

    var style = {
      base: {
        color: "#071754",
        fontFamily: "Open Sans",
        fontSmoothing: "antialiased",
        fontSize: fontSize,
        "::placeholder": {
          color: "#838ba9",
        },
      },
      invalid: {
        color: "#ff3066",
        iconColor: "#ff3066",
      },
    }

    this.elementCard = elements.create("card", {
      style: style,
      hidePostalCode: false,
      iconStyle: "default",
    })

    this.elementCard.mount("#card-element")

    this.elementCard.addEventListener("change", function (event) {
      // We could display the error message on updating the card
      if (event.error) {
        // BEAM.checkout.displayError(event.error.message);
      } else {
        // $("#card-errors")
        //   .text("")
        //   .removeClass("visible");
      }
    })
  },
}
</script>
