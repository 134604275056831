<template>
  <div class="flex items-center bg-white px-8 py-5 sm:px-12 sm:py-10">
    <img :src="photo" class="mr-10 h-16 sm:h-24" />
    <div class="sm:flex sm:items-center">
      <number-counter-group
        class="beam-h1 mr-5"
        :number="number"
      ></number-counter-group>
      <div class="beam-h2 mt-3 text-left sm:mt-0">{{ itemType }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
    },
    itemType: {
      type: String,
    },
    photo: {
      type: String,
    },
  },
}
</script>
