<template>
  <div class="items-center sm:flex sm:items-stretch">
    <div class="relative sm:w-2/3">
      <img class="w-full" :src="`/images/sign_up/housed_ian.png`" />
      <div class="absolute bottom-0 left-0 m-3 items-center sm:m-4">
        <div class="relative">
          <img
            class="relative top-11 z-40 h-16 rounded-full sm:top-14 sm:h-20"
            :class="memberBgColour"
            :src="memberImage"
            :alt="memberImageAlt"
          />
          <div
            class="relative -left-1 bottom-2 ml-14 flex items-center space-x-2 rounded-md bg-white px-4 py-3 text-sm font-bold sm:ml-16 sm:pl-6 sm:text-lg"
          >
            <icon name="check" class="h-5 w-5 sm:h-6 sm:w-6" />
            <div class="uppercase">{{ outcomeLabel }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="border-gray rounded-bl rounded-br border border-t-0 p-3 sm:flex sm:w-1/3 sm:items-center sm:rounded-none sm:rounded-br sm:rounded-tr sm:border sm:border-l-0 sm:px-4"
    >
      {{ memberStory }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgImage: { type: String },
    memberImage: { type: String },
    memberImageAlt: { type: String },
    memberBgColour: { type: String },
    memberStory: { type: String },
    outcomeLabel: { type: String },
  },
}
</script>
