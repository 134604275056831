<template>
  <div class="bg-pale-grey-100">
    <!-- Introduce what Beam is -->
    <onboarding-explainer
      class="beam-container py-8"
      v-if="step == 'explainer'"
      :company="company"
      :lives-changed-count="livesChangedCount"
      @next-cta-clicked="goToSendAMessage"
    ></onboarding-explainer>

    <!-- Send a message -->
    <onboarding-first-message
      class="beam-container py-8"
      v-if="step == 'send-a-message'"
      :campaign="campaign"
      :campaignUpdateId="campaignUpdateId"
      :company="company"
      @message-sent="finishMessageStep(true)"
    ></onboarding-first-message>

    <!-- Upload an avatar -->
    <onboarding-avatar
      class="beam-container py-8"
      v-if="step == 'upload-an-avatar'"
      :campaign="campaign"
      :company="company"
      :message-was-sent="messageWasSent"
      :currentUserAvatarUrl="currentUserAvatarUrl"
      @go-to-dashboard="goToDashboard"
    ></onboarding-avatar>

    <div class="beam-container pb-8 text-left">
      <!-- Explainer doesn't need a skip button because it does not have an action -->
      <a
        @click="skipCurrentStep"
        class="cursor-pointer underline"
        v-if="step != 'explainer'"
        >Skip for now</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaign: { type: Object },
    company: { type: Object },
    currentUserAvatarUrl: { type: String, default: null },
    campaignUpdateId: { type: Number },
    livesChangedCount: { type: Number },
  },
  data() {
    return {
      step: "explainer",
      messageWasSent: false,
    }
  },
  computed: {
    campaign_url() {
      return `/campaigns/${this.campaign.slug}`
    },
  },
  methods: {
    goToSendAMessage() {
      this.step = "send-a-message"
    },
    finishMessageStep(messageWasSent) {
      this.messageWasSent = messageWasSent || false
      if (this.currentUserAvatarUrl == "/images/missing.png") {
        this.step = "upload-an-avatar"
      } else {
        this.goToDashboard()
      }
    },
    skipCurrentStep() {
      if (this.step == "send-a-message") {
        this.finishMessageStep()
      } else if (this.step == "upload-an-avatar") {
        this.goToDashboard()
      }
    },
    goToDashboard() {
      window.location.href = `/companies/${this.company.slug}`
    },
  },
  created() {
    if (!this.campaign) {
      this.step = "upload-an-avatar"
    }
  },
}
</script>
