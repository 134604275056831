<template>
  <div
    @click="toggleChecked"
    class="flex cursor-pointer items-center justify-between rounded border-2 px-5 py-5"
    :class="wrapperClasses"
  >
    <div>
      <label :class="{ 'font-medium': checked }" class="cursor-pointer">{{
        checkboxLabel
      }}</label>
      <div
        class="mt-1 block text-xs font-normal text-bluey-grey-100"
        v-if="checkboxSubLabel"
      >
        {{ checkboxSubLabel }}
      </div>
    </div>
    <div
      class="flex h-8 w-8 items-center justify-center rounded border-2"
      :class="checkboxClasses"
    >
      <icon name="check" class="inline-block h-5 w-5 text-white"></icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkboxLabel: { type: String, required: true },
    checkboxSubLabel: { type: String, required: false },
    defaultChecked: { type: Boolean, default: false },
    marginTop: { type: Number, default: 5 },
  },
  data() {
    return {
      checked: this.defaultChecked,
    }
  },
  methods: {
    toggleChecked() {
      this.checked = !this.checked

      this.$emit("checkbox-updated", this.checked)
    },
  },
  computed: {
    checkboxClasses() {
      if (this.checked) {
        return ["bg-azure-100", "border-transparent"]
      } else {
        return ["bg-white", "border-pale-lilac-100"]
      }
    },
    wrapperClasses() {
      let result = [`mt-${this.marginTop}`]

      this.checked
        ? result.push("border-azure-100 bg-white")
        : result.push("border-pale-lilac-100 bg-white")

      return result
    },
  },
}
</script>
