<template>
  <div class="flex w-full items-center bg-white p-5">
    <img class="mr-5 block h-20 w-20 rounded-full" :src="photo" />
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    photo: {
      type: String,
      required: true,
    },
  },
}
</script>
