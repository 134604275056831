<template>
  <div class="mt-5" id="payment-request-button"></div>
</template>

<script>
export default {
  data() {
    return {
      elements: null,
      prButton: null,
      paymentRequest: null,
    }
  },
  computed: {
    label() {
      if (this.$parent.monthlyType) {
        return "Beam monthly donation"
      } else {
        return "Beam donation"
      }
    },
  },
  methods: {
    setupPaymentRequest() {
      this.paymentRequest = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        requestPayerName: true,
        requestPayerEmail: true,
        total: {
          label: this.label,
          amount: 2500,
        },
      })

      this.paymentRequest.on("paymentmethod", (paymentResponse) => {
        this.$parent.handlePaymentRequest(paymentResponse)
      })
    },
    setupButton() {
      this.prButton = this.elements.create("paymentRequestButton", {
        paymentRequest: this.paymentRequest,
        style: {
          paymentRequestButton: {
            type: "donate",
            theme: "dark",
            height: "48px",
          },
        },
      })

      this.prButton.on("click", (e) => this.handleButtonClick(e))

      this.prButton.mount("#payment-request-button")
    },
    handleButtonClick(e) {
      if (!this.$parent.validate()) {
        e.preventDefault()
        return
      }

      this.paymentRequest.update({
        total: {
          label: this.label,
          amount: this.$parent.totalAmountPence,
        },
      })
    },
  },
  created() {
    this.setupPaymentRequest()
  },
  mounted: function () {
    this.elements = stripe.elements()

    this.paymentRequest.canMakePayment().then((result) => {
      if (result) {
        this.$parent.hasPaymentRequest = true
        this.setupButton()
      } else {
        this.$parent.hasPaymentRequest = false
      }
    })
  },
}
</script>
