<template>
  <!-- Update Top -->
  <div class="rounded bg-white p-5">
    <div class="flex items-start">
      <!-- Icon -->
      <div class="mr-4 rounded-full bg-azure-15 p-4" v-if="newsItem.icon">
        <icon
          :name="newsItem.icon"
          :solid="false"
          class="h-6 w-6 text-azure-100"
        />
      </div>
      <div>
        <!-- Sentence -->
        <div class="flex items-center text-sm sm:text-base">
          <img
            class="mr-2 w-8 rounded-full"
            :src="newsItem.user_photo_url"
            v-if="newsItem.has_user_photo"
          />
          <div>
            <span v-html="newsItem.text"></span>
          </div>
        </div>

        <!-- Date -->
        <div class="mt-1 text-xs text-bluey-grey-100">
          <timeago :time="newsItem.created_at"></timeago>
        </div>

        <!-- Message -->
        <div
          class="mt-5 whitespace-pre-line text-sm sm:mt-8 sm:text-base"
          v-if="newsItem.message"
          v-html="newsItem.message"
        ></div>

        <!-- Photo -->
        <div
          class="mt-4 overflow-hidden text-center"
          style="max-height: 750px"
          v-if="newsItem.has_photo"
        >
          <img class="inline-block" :src="newsItem.item_photo_url" />
        </div>

        <!-- Thank Button -->
        <div v-if="newsItem.is_thankable" class="mt-5">
          <thank-button
            :member-id="memberId"
            :user-id="newsItem.user_id"
            :user-name="newsItem.user_first_name"
            :news-item-id="newsItem.id"
            :initial-thanked="newsItem.is_thanked"
            source="hub"
          >
          </thank-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    memberId: Number,
    newsItem: Object,
  },
}
</script>
