<template>
  <span class="gtm-video-play cursor-pointer" @click.prevent="showVideoModal">
    <slot />
  </span>
</template>

<script>
import VideoMixin from "../vue-common/mixins/VideoMixin"

export default {
  mixins: [VideoMixin],
}
</script>
