<template>
  <h2 class="beam-h2 text-left mb-4" v-show="shouldDisplay">
    {{ formSectionSchema[sectionKey] }}
  </h2>
  <fieldset class="member-form-fieldset" v-show="shouldDisplay">
    <member-form-field
      v-for="field in fields"
      :key="field.id"
      :field="field"
      v-model="field.value"
    >
      forcerefresh
    </member-form-field>
  </fieldset>
</template>

<script>
export default {
  props: {
    fields: { type: Object, default: {} },
    sectionKey: { type: String },
    formSectionSchema: { type: Object },
  },
  inject: ["shouldDisplayField"],
  computed: {
    shouldDisplay() {
      return Object.values(this.fields).some((field) =>
        this.shouldDisplayField(field)
      )
    },
  },
}
</script>
