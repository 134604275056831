<template>
  <div class="bg-white px-5 py-8 sm:p-10">
    <div class="text-left">
      <div class="mb-5 text-center text-xl font-medium sm:text-left">
        {{ title }}
      </div>

      <div
        class="flex justify-between border-t border-pale-lilac-100 py-2.5 last:border-b"
        :class="{ hidden: index > showCount }"
        v-for="(percent, key, index) in tallyData"
        :key="key"
      >
        <div class="mr-4 flex items-center">
          <div
            class="mr-3 h-3 w-3 rounded-full"
            :style="`background: ${colours[index * multiplicativeFactor]}`"
          ></div>
          <div>
            {{ key }}
          </div>
        </div>
        <div class="whitespace-nowrap">{{ percent }} %</div>
      </div>

      <div
        v-if="showCount < tallyDataLength"
        @click="showCount = tallyDataLength"
        class="mt-2 cursor-pointer text-center underline"
      >
        Show more
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    tallyData: {
      type: Object,
      required: true,
    },
    showCount: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    tallyDataLength() {
      return Object.keys(this.tallyData).length
    },
    multiplicativeFactor() {
      let dataLength = Object.keys(this.tallyData).length - 1
      let coloursLength = this.colours.length - 1
      return Math.floor(coloursLength / dataLength)
    },
  },
  data() {
    return {
      colours: [
        "#f8d557",
        "#4EAAF8",
        "#55BBF8",
        "#5CC9E6",
        "#C4DC5F",
        "#91DC7A",
        "#64DAA0",
        "#61D3C7",
        "#8395F8",
        "#BA7ADD",
        "#E05AA9",
        "#EB4669",
        "#DA3272",
        "#C72A7B",
        "#B12484",
        "#951D8B",
        "#761591",
        "#501B94",
        "#081F94",
      ],
    }
  },
}
</script>
