<template>
  <vue-final-modal
    :name="name"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-10 max-w-md"
  >
    <modal-close-button :name="name"></modal-close-button>

    <div class="mt-4 flex justify-around">
      <label v-if="!saving" class="button button-tertiary flex px-4 py-2">
        <icon
          name="photo"
          class="mr-1 h-5 w-5 self-center"
          :solid="true"
        ></icon>
        Upload
        <input
          type="file"
          class="hidden"
          ref="file"
          @change="handlePhotoUpload"
        />
      </label>

      <span class="flex" v-if="saving">
        <spinner-icon class="inline-flex h-5 w-5" />
      </span>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    fundraiserId: Number,
  },
  data: function () {
    return {
      name: "modal-fundraiser-photo",
      open: false,
      saving: false,
    }
  },
  methods: {
    handlePhotoUpload(event) {
      this.saving = true
      const reader = new FileReader()

      reader.onload = (e) => {
        const data = new FormData()

        data.append("fundraiser[photo]", e.target.result)

        this.axios
          .patch(`/fundraisers/${this.fundraiserId}.json`, data)
          .then(() => {
            location.reload()
          })
      }

      reader.readAsDataURL(event.target.files[0])
    },
  },
}
</script>
