<template>
  <section class="grid grid-cols-12 gap-5">
    <div class="col-span-12 bg-white p-8">
      <div class="mt-1 flex place-content-center items-center lg:mt-0">
        <number-counter-group
          :class="['beam-h1', { 'saturate-0': livesChangedCount == 0 }]"
          :increment-amount="3"
          :number="livesChangedCount"
        ></number-counter-group>
        <div class="beam-h1 ml-5">lives changed</div>
      </div>
    </div>

    <company-impact-number-panel :number="peopleWhoFoundJobsCount" class="">
      <template #emoji>
        <div
          :class="[
            'w-20',
            'text-4xl',
            'tracking-[-0.4em]',
            { 'saturate-0': peopleWhoFoundJobsCount == 0 },
          ]"
        >
          {{ jobsEmojis }}
        </div>
      </template>
      <template #text>people supported into work</template>
    </company-impact-number-panel>

    <company-impact-number-panel :number="peopleWhoFoundHomesCount">
      <template #emoji>
        <div :class="['w-20', { 'saturate-0': peopleWhoFoundHomesCount == 0 }]">
          🏠
        </div>
      </template>
      <template #text>people supported into housing</template>
    </company-impact-number-panel>

    <company-impact-number-panel :number="messagesSentCount">
      <template #emoji>
        <div :class="['w-20', { 'saturate-0': messagesSentCount == 0 }]">
          💌
        </div>
      </template>
      <template #text>encouraging messages sent</template>
    </company-impact-number-panel>

    <company-impact-number-panel
      :number="amountRaised"
      :is-currency="true"
      :increment-amount="Math.round(amountRaised / 100)"
    >
      <template #emoji>
        <div :class="['w-20', { 'saturate-0': amountRaised == 0 }]">💸</div>
      </template>
      <template #text>given directly to members</template>
    </company-impact-number-panel>
  </section>
</template>

<script>
const jobsEmojiOptions = ["👷", "🧑‍🌾", "🧑‍💻", "🧑‍🔧", "🧑‍💼", "🧑‍🏭"].sort(
  () => 0.5 - Math.random()
)

export default {
  props: {
    livesChangedCount: { type: Number },
    peopleWhoFoundJobsCount: { type: Number },
    peopleWhoFoundHomesCount: { type: Number },
    messagesSentCount: { type: Number },
    amountRaised: { type: Number },
  },
  computed: {
    jobsEmojis() {
      return jobsEmojiOptions.slice(0, 3).join()
    },
  },
}
</script>
