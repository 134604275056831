import { apiUrl, headers } from "../../team-tailor"

export default {
  computed: {
    teamTailorApiUrl() {
      return apiUrl
    },
    teamTailorHeaders() {
      return headers
    },
  },
}
