export default {
  methods: {
    showField(id) {
      let eligible = !this.ineligible_location

      switch (id) {
        case "referrer_concerns":
          return eligible
        case "job_ready":
          return (
            eligible &&
            this.selectedContract &&
            this.selectedContract.is_employment
          )
        case "tenancy_ready":
          return (
            eligible &&
            this.selectedContract &&
            this.selectedContract.is_lettings
          )
        default:
          return true
      }
    },
  },
}
