<template>
  <div class="items-start justify-between lg:flex lg:px-12">
    <main class="px-0 sm:px-12 lg:max-w-xl lg:px-0">
      <slot
        name="donation-type"
        :monthlyType="monthlyType"
        :oneOffType="oneOffType"
        :setOneOffType="setOneOffType"
        :setMonthlyType="setMonthlyType"
      >
      </slot>

      <div
        v-if="creatingDuplicateSubscription"
        class="mt-10 rounded bg-azure-100 px-4 py-6 text-center text-white"
      >
        You already have a monthly donation with Beam!
        <br />
        <a
          href="/account/monthly-donation"
          class="text button mt-4 inline-block whitespace-nowrap bg-pink-100 px-8 py-2 text-white hover:bg-pink-200 focus:bg-navy-800"
          >Click here to edit your monthly donation</a
        >
      </div>

      <!-- Hide all of this if they already have a subscription -->
      <div
        :class="{
          'pointer-events-none opacity-40': creatingDuplicateSubscription,
        }"
      >
        <!-- Amount Select -->
        <section class="mt-10">
          <div class="font-medium">Donation amount</div>
          <div
            class="mt-1 text-sm text-bluey-grey-100"
            v-if="showFullyFundLink"
          >
            {{ campaign.member_name }} is only £{{ amountRemaining }} from
            {{ campaign.possessive_pronoun }} target!
            <a @click="setFullyFund" class="blue-link"
              >See this campaign across the line</a
            >.
          </div>

          <checkout-amounts
            ref="checkoutAmounts"
            :amounts="amounts"
            :amount-remaining="amountRemaining"
            v-on:update-selected-amount="onUpdateSelectedAmount"
          ></checkout-amounts>
        </section>

        <!-- Support Costs -->
        <section class="mt-10">
          <div class="font-medium">Beam support costs</div>

          <div class="mt-1 text-sm text-bluey-grey-100">
            We don’t pay ourselves out of your funding without you approving it.
            We suggest 10-30% as a contribution to our award-winning support,
            but we're happy for you to make that call.
          </div>

          <div class="mt-5 flex items-center">
            <select
              class="beam-text-input w-full"
              id="service-fee"
              v-model="beamDonationPercent"
            >
              <tip-amount-option
                v-for="amount in tipAmounts"
                :key="amount"
                :amount="amount"
              >
              </tip-amount-option>
            </select>
            <icon
              name="chevron-down"
              class="pointer-events-none -ml-8 inline-block h-5 w-5 text-azure-100"
            ></icon>
          </div>
        </section>

        <!-- Gift Aid -->
        <gift-aid
          ref="giftAid"
          @gift-aid-updated="giftAidChecked = $event"
          :initial-postcode="initialGiftAidUser.postcode"
          :initial-line1="initialGiftAidUser.house_number"
          :initial-line2="initialGiftAidUser.street"
          :initial-city="initialGiftAidUser.city"
        >
        </gift-aid>

        <!-- Support Message -->
        <section class="mt-10" v-if="showSupportMessage">
          <label for="support-message" class="inline-block font-medium"
            >Message of support (optional)</label
          >
          <div class="mt-1 text-sm text-bluey-grey-100">
            {{ supportMessagePrompt }}
          </div>
          <textarea
            class="beam-text-input mt-5 h-24 w-full sm:h-24"
            v-model="supporterMessage"
            id="support-message"
          ></textarea>
        </section>

        <!-- Fields -->
        <section class="mt-10" v-if="!this.initialUser.email">
          <label for="email-address" class="inline-block font-medium"
            >Email address</label
          >
          <input
            type="email"
            name="email"
            class="beam-text-input mt-5 w-full"
            v-model.trim="emailAddress"
            id="email-address"
          />
        </section>

        <!-- Names -->
        <section class="mt-10" v-if="!this.initialUser.first_name">
          <div class="sm:flex sm:justify-between">
            <div class="flex-1">
              <label for="first-name" class="inline-block font-medium"
                >First name</label
              >
              <input
                type="text"
                class="beam-text-input mt-5 w-full"
                v-model.trim="firstName"
                id="first-name"
                autocomplete="given-name"
              />
            </div>

            <div class="flex-1 sm:ml-8">
              <label
                for="last-name"
                class="mt-10 inline-block font-medium sm:mt-0"
                >Last name</label
              >
              <input
                type="text"
                class="beam-text-input mt-5 w-full"
                v-model.trim="lastName"
                id="last-name"
                autocomplete="family-name"
              />
            </div>
          </div>
        </section>

        <!-- Anonymous checkbox -->
        <section class="mt-10">
          <check-box
            checkbox-label="Show my name on the campaign page"
            :default-checked="true"
            ref="anonymousCheckbox"
          ></check-box>
        </section>

        <!-- Marketing checkbox -->
        <section class="mt-10" v-if="!loggedIn">
          <check-box
            checkbox-label="Receive occasional marketing emails?"
            checkbox-sub-label="You can unsubscribe from any email or from your account page."
            :default-checked="true"
            ref="marketingCheckbox"
          ></check-box>
        </section>

        <!-- Payment Request button -->
        <template v-if="!savedCard">
          <section class="mt-10" v-if="hasPaymentRequest">
            <label for="card-element" class="inline-block font-medium"
              >Quick pay</label
            >
            <stripe-payment-request></stripe-payment-request>
          </section>
        </template>

        <!-- Card details -->
        <section class="mt-10" v-if="!savedCard">
          <label for="card-element" class="inline-block font-medium"
            >Card details</label
          >
          <stripe-card-element ref="stripeElement"></stripe-card-element>
        </section>

        <checkout-saved-card
          :saved-card="savedCard"
          v-on:clear-card-details="clearCardDetails"
        >
        </checkout-saved-card>

        <!-- Monthly Upsell -->
        <transition name="fade">
          <section class="mt-10" v-show="oneOffType">
            <template v-if="clickedUpsell">
              <ConfettiExplosion :particleCount="50" class="ml-auto mr-auto"
            /></template>
            <template v-else>
              <label class="inline-block font-medium"
                >💡 We have an idea we'd like to float</label
              >
              <div
                class="mt-5 rounded bg-pale-grey-100 p-5 text-sm font-medium text-bluey-grey-100"
              >
                <div>
                  Would you be willing to make a smaller monthly donation?
                </div>
                <div class="mt-4">
                  <template v-if="campaign">
                    🚀 More impact - fund {{ campaign.member_name }} today and a
                    new person each month
                  </template>
                  <template v-else>
                    🚀 More impact - fund a new person each month
                  </template>
                </div>
                <div class="mt-4">
                  💌 We'll send stories and updates of the people you're helping
                </div>

                <div class="mt-4">🚫 You can pause or cancel at any time.</div>
              </div>

              <section class="mt-5">
                <check-box
                  @click="handleUpsell"
                  :checkbox-label="`Give £${upsellAmount} per month instead`"
                  :default-checked="false"
                  ref="upsellCheckbox"
                ></check-box>
              </section>
            </template>
          </section>
        </transition>

        <checkout-button></checkout-button>
      </div>
    </main>

    <aside class="sticky top-0 my-10 flex-1 lg:ml-24 lg:block lg:pt-8">
      <div class="hidden lg:block">
        <div class="font-medium">Donation summary</div>

        <div class="mt-5 rounded bg-pale-grey-100 px-10 py-10">
          <ul class="stack-mt-3">
            <li class="flex">
              <div class="w-32 shrink-0 text-sm text-bluey-grey-100">
                Amount
              </div>
              <div class="text-sm">{{ amountDisplay }}</div>
            </li>
            <li class="flex">
              <div class="w-32 shrink-0 text-sm text-bluey-grey-100">
                Gift Aid
              </div>
              <div class="text-sm">{{ giftAidDisplay }}</div>
            </li>
            <li class="flex">
              <div class="w-32 shrink-0 text-sm text-bluey-grey-100">
                Support Costs
              </div>
              <div class="text-sm">{{ beamDonationPercent }}%</div>
            </li>
            <li class="flex" v-if="supporterMessage">
              <div class="w-32 shrink-0 text-sm text-bluey-grey-100">
                Message
              </div>
              <div class="text-sm">{{ supporterMessage }}</div>
            </li>
          </ul>
        </div>
      </div>

      <section class="mt-8">
        <div class="stack-mt-8 px-2 text-sm">
          <div>
            <div class="text-center font-medium">💯 100% Impact</div>
            <div class="mt-1 text-center text-bluey-grey-100">
              100% of your donation goes towards removing financial barriers for
              the people you support.
            </div>
          </div>

          <div>
            <div class="text-center font-medium">🔒 Responsible</div>
            <div class="mt-1 text-center text-bluey-grey-100">
              We hold donations securely and purchase campaign costs on their
              behalf.
            </div>
          </div>

          <div>
            <div class="text-center font-medium">📢 Stay in the loop</div>
            <div class="mt-1 text-center text-bluey-grey-100">
              We will transparently keep you in the loop about how they are
              getting on.
            </div>
          </div>
        </div>
      </section>
    </aside>
  </div>
</template>

<script>
import ConfettiExplosion from "vue-confetti-explosion"
import CheckoutMixin from "./CheckoutMixin"
import CheckoutAmountsMixin from "./CheckoutAmountsMixin"

export default {
  mixins: [CheckoutMixin, CheckoutAmountsMixin],
  components: {
    ConfettiExplosion,
  },
  props: {
    campaign: { type: Object, required: false },
    fundraiser: { type: Object, required: false },
    amountRemaining: { type: Number, required: false },
    defaultDonationType: { type: String, required: true }, // one-off or monthly
    userIsSubscriber: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      donationType: this.defaultDonationType,
      clickedUpsell: false,
    }
  },
  computed: {
    amounts() {
      if (this.oneOffType) {
        return [10, 20, 50]
      } else {
        return [10, 20, 30]
      }
    },
    monthlyType() {
      return this.donationType == "monthly"
    },
    oneOffType() {
      return this.donationType == "one-off"
    },
    amountDisplay() {
      // Used on button and sidebar
      let result = `${this.currency}${this.totalAmountDisplay}`

      if (this.monthlyType) {
        result = `${result} monthly`
      } else if (this.campaign) {
        result = `${result} to ${this.campaign.member_name}`
      }

      return result
    },
    donateButtonText() {
      return `Donate ${this.amountDisplay}`
    },
    supportMessagePrompt() {
      if (this.fundraiser && this.fundraiser.first_name) {
        return `Leave a message for ${this.fundraiser.first_name}`
      } else {
        return "While optional, encouragement is a great source of support."
      }
    },
    postPath() {
      return "/payments.json"
    },
    showFullyFundLink() {
      return this.oneOffType && this.amountRemaining < 300
    },
    showSupportMessage() {
      return this.campaign || this.fundraiser
    },
    upsellAmount() {
      return Math.round(this.selectedAmount / 2.5)
    },
    creatingDuplicateSubscription() {
      return this.userIsSubscriber && this.monthlyType
    },
  },
  mounted() {
    this.setDefaultAmount()
    this.logEvent("checkout-view")
  },
  methods: {
    reset() {
      this.setDefaultAmount()
      this.$refs.checkoutAmounts.otherSelected = false
      if (this.$refs.upsellCheckbox) {
        this.$refs.upsellCheckbox.checked = false
      }
      this.clickedUpsell = false
    },
    setMonthlyType() {
      this.donationType = "monthly"
      this.reset()
    },
    setOneOffType() {
      this.donationType = "one-off"
      this.reset()
    },
    validate() {
      const fields = [
        "custom-amount",
        "email-address",
        "first-name",
        "card-element",
        "line-1",
        "town-city",
        "postcode",
      ]

      this.clearErrorStates(fields)

      if (!this.validateAmount()) return false
      if (!this.validateGiftAid()) return false
      if (!this.validateEmail()) return false
      if (!this.validateFirstName()) return false
      if (!this.looksLikeSpam()) return false

      return true
    },
    postData(
      paymentMethodId,
      paymentIntentId,
      stripeSubscriptionId,
      paymentMethodType
    ) {
      const giftAidComponent = this.$refs.giftAid
      const marketingCheckbox = this.$refs.marketingCheckbox
      const anonymousCheckbox = this.$refs.anonymousCheckbox
      const anonymous = anonymousCheckbox ? !anonymousCheckbox.checked : false

      return {
        payment: {
          amount: this.totalAmountPence,
          user_email: this.emailAddress,
          beam_donation_percent: this.beamDonationPercent,
          first_name: this.firstName,
          last_name: this.lastName,
          support_message: this.supporterMessage,
          default_service_fee: 0,
          gift_aid: this.giftAidChecked,
          giftaid_first_name: this.firstName,
          giftaid_last_name: this.lastName,
          house_number: giftAidComponent.line1,
          street: giftAidComponent.line2,
          city: giftAidComponent.city,
          postcode: giftAidComponent.postcode,
          payment_method: paymentMethodId,
          payment_method_type: paymentMethodType,
          payment_intent: paymentIntentId,
          stripe_subscription_id: stripeSubscriptionId, // Subscription was created after 3DS
          monthly_donation: this.monthlyType,
          fundraiser_id: this.fundraiser ? this.fundraiser.id : null,
          campaign_id: this.campaign ? this.campaign.id : null,
          marketing_consent: marketingCheckbox
            ? marketingCheckbox.checked
            : null,
          anonymous: anonymous,
          spam_catcher: new Date(),
        },
      }
    },
    setFullyFund() {
      this.selectedAmount = this.amountRemaining
      this.$refs.checkoutAmounts.selectOther()
    },
    handleUpsell() {
      this.logEvent("upsell-click")
      this.clickedUpsell = true
      this.selectedAmount = this.upsellAmount
      this.donationType = "monthly"
      this.$refs.checkoutAmounts.selectOther()
    },
  },
}
</script>
