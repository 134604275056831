<template>
  <div class="lg:hidden flex-row justify-between flex px-8 pt-8 bg-white">
    <slot name="mobile-header"></slot>
  </div>
  <div class="flex lg:flex-row flex-col min-h-screen">
    <!-- Left Panel -->
    <div
      class="w-full lg:w-1/2 bg-pale-grey-100 flex-col items-end py-4 lg:pr-36 px-16 hidden lg:flex"
    >
      <slot name="benefits"></slot>
    </div>
    <!-- Right Panel -->
    <div
      class="w-full lg:w-1/2 pt-8 lg:pt-16 flex flex-col justify-start items-center bg-white"
    >
      <div class="lg:px-0 w-full flex flex-col lg:max-w-lg">
        <h2 class="beam-h2 mb-8 text-center lg:mx-0 mx-8">
          Choose how many people to support each month
        </h2>

        <campaign-support-slider-panel
          :form="form"
          v-model="campaigns_to_support"
          :campaign-images="campaignImages"
          class="mb-8 lg:mb-4 mx-8 lg:mx-0"
        >
        </campaign-support-slider-panel>
        <!-- The benefits should show beneath the slider, on mobile only -->
        <div
          class="w-full bg-white lg:bg-pale-grey-100 flex-col lg:items-end py-8 px-8 lg:hidden flex mb-8"
        >
          <slot name="benefits"></slot>
        </div>
        <div class="bg-pale-grey-100 lg:bg-white py-10">
          <div v-if="showInCompanyNotice" class="text-left lg:mx-0 mx-8 mb-4">
            <p>
              You can't create a new company, because you're already signed in
              as
              <strong>{{ currentUserEmail }}</strong
              >, and you're a member of
              <a :href="currentUserCompany.url" class="blue-link">{{
                currentUserCompany.name
              }}</a
              >.
            </p>
            <p class="mt-4">
              If this doesn't seem right, email
              <a href="mailto:companies@beam.org" class="blue-link"
                >companies@beam.org</a
              >
              for help.
            </p>
          </div>
          <div
            v-if="showJoinMatchingCompany"
            class="text-left lg:mx-0 mx-8 mb-4"
          >
            <p>
              You're already signed in as <strong>{{ currentUserEmail }}</strong
              >, but you can't create a company because one already exists using
              the {{ chosenDomain }} email domain.
            </p>
            <p class="mt-4">
              You can join {{ matchingCompany.name }} by going to their join
              link:
              <a :href="matchingCompany.joinUrl" class="blue-link">{{
                matchingCompany.joinUrl
              }}</a>
            </p>
            <p class="mt-4">
              If this doesn't seem right, email
              <a href="mailto:companies@beam.org" class="blue-link"
                >companies@beam.org</a
              >
              for help.
            </p>
          </div>

          <p v-if="showUserDomainNotice" class="text-left lg:mx-0 mx-8 mb-4">
            You're already signed in as <strong>{{ currentUserEmail }}</strong
            >, so your company will be restricted to people with the
            <strong>{{ chosenDomain }}</strong> email domain.
          </p>

          <p
            v-if="currentUserCompany?.hasSubscription"
            class="text-left lg:mx-0 mx-8 mb-4"
          >
            Your company already has a subscription, which you can manage on the
            <a href="/account" class="blue-link">account page</a>.
          </p>

          <div
            v-if="form.errors?.base"
            class="text-red-500 text-sm lg:mx-0 mx-8 mb-8"
            v-html="form.errors['base'][0]"
          ></div>

          <company-form-field
            attribute_name="admin_email"
            placeholder="example@workemail.com"
            :form="form"
            type="email"
            label="Your work email"
            class="lg:mx-0 mx-8"
            :disabled="disableUserFields"
            v-if="showFormFields"
          />
          <company-form-field
            attribute_name="name"
            :form="form"
            label="Company name"
            class="lg:mx-0 mx-8"
            :disabled="disableCompanyFields"
            v-if="showFormFields"
          />
          <company-form-field
            attribute_name="admin_first_name"
            :form="form"
            label="Your first name"
            class="lg:mx-0 mx-8"
            :disabled="disableUserFields"
            v-if="showFormFields"
          />
          <company-form-field
            attribute_name="admin_last_name"
            :form="form"
            label="Your last name"
            class="lg:mx-0 mx-8"
            :disabled="disableUserFields"
            v-if="showFormFields"
          />

          <input type="hidden" name="authenticity_token" :value="csrfToken" />

          <!-- Start payment stuff -->
          <template v-if="!currentUserCompany?.hasSubscription">
            <!-- Payment Request button -->
            <template v-if="!savedCard">
              <section class="mt-10" v-if="hasPaymentRequest">
                <label for="card-element" class="inline-block font-medium"
                  >Quick pay</label
                >
                <stripe-payment-request></stripe-payment-request>
              </section>
            </template>

            <!-- Card details -->
            <section v-if="!savedCard" class="block mx-8 lg:mx-0 mt-12">
              <label for="card-element" class="mb-2">Card details</label>
              <stripe-card-element ref="stripeElement"></stripe-card-element>
            </section>

            <checkout-saved-card
              :saved-card="savedCard"
              v-on:clear-card-details="clearCardDetails"
            >
            </checkout-saved-card>

            <checkout-button></checkout-button>

            <!-- End payment stuff -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckoutMixin from "../checkout/CheckoutMixin"

export default {
  mixins: [CheckoutMixin],
  props: {
    initialForm: { type: Object },
    initialUser: { type: Object },
    csrfToken: { type: String },
    campaignImages: { type: Array },
    currentUserEmail: { type: String },
    currentUserCompany: { type: Object },
    matchingCompany: { type: Object },
    onCompanyDonatePage: { type: Boolean, default: false },
  },
  computed: {
    donateButtonText() {
      return `Donate £${this.displayAmount} a month`
    },
    billingDetails() {
      return {
        name: this.form.attributes.admin_first_name,
        email: this.form.attributes.admin_email,
      }
    },
    amount() {
      return this.campaigns_to_support * 5000
    },
    totalAmount() {
      return this.amount / 100
    },
    displayAmount() {
      return (+this.amount / 100).toLocaleString()
    },
    campaignImagesSubset() {
      return this.campaignImages.slice(0, this.campaigns_to_support)
    },
    chosenDomain() {
      return this.currentUserEmail.split("@")[1]
    },
    disableCompanyFields() {
      return !!this.currentUserCompany || !!this.matchingCompany
    },
    disableUserFields() {
      return !!this.currentUserEmail
    },
    showFormFields() {
      return (
        (!this.disableCompanyFields || !this.disableUserFields) &&
        !this.onCompanyDonatePage
      )
    },
    showInCompanyNotice() {
      return (
        this.currentUserEmail &&
        this.currentUserCompany &&
        !this.onCompanyDonatePage
      )
    },
    showJoinMatchingCompany() {
      return (
        this.currentUserEmail &&
        this.matchingCompany &&
        !this.onCompanyDonatePage
      )
    },
    showUserDomainNotice() {
      return (
        this.currentUserEmail &&
        !this.matchingCompany &&
        !this.currentUserCompany &&
        !this.onCompanyDonatePage
      )
    },
    postPath() {
      return "/payments.json"
    },
  },
  methods: {
    validate() {
      const fields = ["email-address", "first-name", "card-element", "postcode"]

      this.clearErrorStates(fields)
      this.errorMessage = ""

      if (!this.looksLikeSpam()) return false

      return true
    },
    createCompany() {
      return this.axios.post("/companies", {
        company: {
          name: this.form.attributes.name,
          admin_email: this.form.attributes.admin_email,
          admin_first_name: this.form.attributes.admin_first_name,
          admin_last_name: this.form.attributes.admin_last_name,
          campaigns_to_support: this.campaigns_to_support,
        },
      })
    },
    makePayment(result) {
      if (this.savedCard) {
        this.postPayment({ paymentMethodType: "saved-card" })
      } else {
        this.postPayment({
          paymentMethodId: result.paymentMethod.id,
          paymentMethodType: "new-card",
        })
      }
    },
    // Override CheckoutMixin so that we can create the company before actually
    // making the payment.
    handlePaymentMethodSuccess(result) {
      if (this.currentUserCompany) {
        this.makePayment(result)
      } else {
        this.createCompany()
          .then(() => {
            this.makePayment(result)
          })
          .catch((error) => {
            this.form = error.response.data.form
            this.submitting = false
          })
      }
    },
    donateClicked() {
      if (this.submitting) return

      if (localStorage.getItem("spamUser")) {
        return this.displayError("Please contact support")
      }

      this.submitting = true

      if (!this.validate()) return

      this.handleMonthly()

      this.logEvent("donate-clicked", "monthly-" + this.selectedAmount)
    },
    postData(
      paymentMethodId,
      paymentIntentId,
      stripeSubscriptionId,
      paymentMethodType
    ) {
      return {
        payment: {
          amount: this.amount,
          user_email: this.billingDetails.email,
          beam_donation_percent: 0,
          first_name: this.form.attributes.admin_first_name,
          last_name: this.form.attributes.admin_last_name,
          support_message: "",
          default_service_fee: 0,
          gift_aid: false,
          giftaid_first_name: "",
          giftaid_last_name: "",
          payment_method: paymentMethodId,
          payment_method_type: paymentMethodType,
          payment_intent: paymentIntentId,
          stripe_subscription_id: stripeSubscriptionId, // Subscription was created after 3DS
          campaigns_to_support: this.campaigns_to_support,
          monthly_donation: this.monthlyType,
          fundraiser_id: this.fundraiser ? this.fundraiser.id : null,
          campaign_id: this.campaign ? this.campaign.id : null,
          marketing_consent: true,
          anonymous: false,
          spam_catcher: new Date(),
        },
      }
    },
  },
  data() {
    return {
      campaigns_to_support: 2,
      submitting: false,
      form: {},
      monthlyType: true,
    }
  },
  watch: {
    form(newValue) {
      if (newValue?.attributes?.campaigns_to_support) {
        this.campaigns_to_support = newValue.attributes.campaigns_to_support
      }
    },
  },
  beforeMount() {
    this.form = this.initialForm
    this.campaigns_to_support = this.form?.attributes?.campaigns_to_support || 2
  },
}
</script>
