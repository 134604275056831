<template>
  <div class="bg-pale-grey-100 py-8">
    <slot></slot>

    <section
      class="beam-container mt-8 flex flex-col items-center px-8 py-14 text-center md:p-8"
      v-if="currentUserHasJoined && this.amountRaised > 0"
    >
      <h2 class="beam-h2 max-w-md">
        Change even more lives with your teammates
      </h2>
      <!-- The text and actions depend on whether you are logged in and what your email address is -->
      <div class="flex max-w-sm flex-col">
        <p class="mt-3 w-full text-lg">
          Send them this link to get them signed up!
        </p>
        <input
          type="text"
          ref="companyJoinLinkInput"
          @click="selectCompanyJoinLinkInput"
          readonly
          class="mt-6 w-full rounded-lg bg-gray-200 p-2 px-4 text-center"
          :value="companyJoinLink"
        />

        <copy-button
          class="button button-primary mt-3 w-full border-pink-100 bg-pink-100 py-2 px-8"
          :url="companyJoinLink"
          :show-copy-icon="false"
          initialButtonText="Copy invite link"
        >
        </copy-button>
      </div>
    </section>

    <section
      class="beam-container mt-16 flex flex-col items-center px-8 p-0 text-center"
      v-if="!currentUserHasJoined"
    >
      <h2 class="beam-h2 w-full">{{ joinHeaderCopy }}</h2>
      <!-- The text and actions depend on whether you are logged in and what your email address is -->
      <div v-if="currentUserCanJoin">
        <p class="mt-3 max-w-md text-lg">
          Link your Beam account to the company to see the dashboard.
        </p>
        <button
          @click="sendJoinCompanyRequest"
          class="button button-primary mt-3 w-auto w-full border-pink-100 bg-pink-100 p-4 px-8"
        >
          Link Beam account
        </button>
      </div>
      <div v-else>
        <p class="mt-3 max-w-md text-lg">
          Login using your work email to join your colleagues changing lives on
          Beam
        </p>
        <button
          @click="openSignUpModal"
          class="button button-primary mt-3 w-auto w-full border-pink-100 bg-pink-100 p-4 px-8"
        >
          Sign up
        </button>
      </div>
    </section>
  </div>
  <join-company-modal
    :company="company"
    :current-user-email="currentUserEmail"
    :current-user-can-join="currentUserCanJoin"
    :send-join-company-request="sendJoinCompanyRequest"
  ></join-company-modal>
</template>

<script>
import AnalyticsMixin from "../mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    company: { type: Object },
    amountRaised: { type: Number },
    currentUserEmail: { type: String, default: "" },
    currentUserHasJoined: { type: Boolean },
  },
  data() {
    return {
      startingURL: new URL(window.location.href),
    }
  },
  methods: {
    sendJoinCompanyRequest() {
      this.axios
        .patch(`/users/current.json`, {
          user: {
            company_id: this.company.id,
          },
        })
        .then((response) => {
          if (response.data.success == true) {
            window.location.href = `/company_onboarding`
          } else {
            this.handleError(response?.data?.error_message)
          }
        })
        .catch((e) => {
          this.handleError(e?.response?.data?.error_message)
        })
    },
    openSignUpModal() {
      this.logEvent("open-sign-up-modal", {
        company: this.company.name,
      })
      this.$vfm.show("modal-join-company")
    },
    handleError(
      errorMessage = "Something went wrong. Please contact support@beam.org"
    ) {
      this.$root.showFlash(errorMessage, "error")
    },
    selectCompanyJoinLinkInput() {
      this.$refs.companyJoinLinkInput.select()
    },
  },
  computed: {
    currentUserCanJoin() {
      return (
        this.currentUserEmail &&
        this.currentUserEmail.endsWith(`@${this.company.email_domain}`)
      )
    },
    companyJoinLink() {
      return `${this.startingURL.host}/companies/${this.company.slug}/join`
    },
    editUrl() {
      return `/companies/${this.company.slug}/edit`
    },
    joinHeaderCopy() {
      const indefiniteArticle = this.company.name.match(/^[aeiou]/i)
        ? "an"
        : "a"
      return `Are you ${indefiniteArticle} ${this.company.name} employee?`
    },
  },
  created() {
    if (this.startingURL.pathname.endsWith("/join")) {
      this.$nextTick(() => {
        if (!this.currentUserHasJoined) {
          this.openSignUpModal()
        }
        history.pushState(
          {},
          null,
          this.startingURL.pathname.replace("/join", "")
        )
      })
    }
  },
}
</script>
