<template>
  <div
    class="full-screen-mobile masked relative flex overflow-hidden sm:rounded"
  >
    <div class="center-absolute absolute text-center text-white">
      <div
        class="hover-bounce inline-flex h-16 w-16 items-center justify-center rounded-full border-2 border-azure-100"
      >
        <play-button :url="url"></play-button>
      </div>
      <slot name="caption"></slot>
    </div>
    <img style="z-index: -1" :src="image" class="h-full w-full" />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
}
</script>
