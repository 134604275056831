<template>
  <main>
    <member-tabs
      :key="member.unread_notifications_count"
      selected-tab="community"
      :nearest-job-link="nearestJobLink"
      :member="member"
      :caseworker="caseworker"
      :incomplete-actions-count="member.incomplete_member_facing_actions_count"
    ></member-tabs>

    <section v-if="showPostUpdate" class="mx-4 mt-8 md:mx-0">
      <post-update :member-id="member.id"></post-update>
    </section>

    <slot name="live-campaign"></slot>

    <section class="mt-8">
      <div class="flex justify-around bg-white font-bold">
        <span
          @click="updateSelectedTab('notifications')"
          class="align-center flex flex-grow cursor-pointer flex-row justify-center border-b-2 py-2 text-center text-base"
          :class="{
            'border-azure-100 text-azure-100': selectedTab == 'notifications',
          }"
        >
          <icon
            name="user"
            :solid="selectedTab == 'community'"
            :counter-value="unreadNotificationsCount"
            class="h-6 w-6"
          />
          <span class="ml-2">You</span>
        </span>
        <span
          @click="updateSelectedTab('newsfeed')"
          class="align-center flex flex-grow cursor-pointer flex-row justify-center border-b-2 py-2 text-center text-base"
          :class="{
            'border-azure-100 text-azure-100': selectedTab == 'newsfeed',
          }"
        >
          <span class="ml-2">Other people</span>
        </span>
      </div>

      <div
        class="min-h-screen bg-pale-grey-100 sm:mt-6"
        v-show="selectedTab == 'newsfeed'"
      >
        <updates-list ref="newsfeed"></updates-list>
      </div>

      <div class="sm:mt-6" v-show="selectedTab == 'notifications'">
        <member-hub-notifications
          class="px-5 sm:px-10"
          feed-type="for-members"
          ref="notifications"
          v-on:on-unread-count="updateUnreadCount"
          :member-id="member.id"
        ></member-hub-notifications>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  props: {
    caseworker: {
      type: Object,
      required: true,
    },
    showPostUpdate: {
      type: Boolean,
      required: true,
    },
    nearestJobLink: {
      type: String,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: "notifications",
      unreadNotificationsCount: null,
    }
  },
  mounted() {
    this.handleInfiniteScroll()
  },
  methods: {
    updateSelectedTab(newTab) {
      this.selectedTab = newTab
      this.handleInfiniteScroll()
    },
    handleInfiniteScroll() {
      // Can only have one window.scroll handler
      if (this.selectedTab == "newsfeed") {
        this.$refs.newsfeed.handleScroll()
      } else {
        this.$refs.notifications.handleScroll()
      }
    },
    markNotificationsAsRead() {
      this.unreadNotificationsCount = 0
      this.member.unread_notifications_count = 0
      this.axios.post("/members/current/mark_news_items_as_read")
    },
    updateUnreadCount(count) {
      this.unreadNotificationsCount = count
    },
  },
  watch: {
    selectedTab(newTab) {
      if (newTab == "notifications" && this.unreadNotificationsCount > 0) {
        this.markNotificationsAsRead()
      }
    },
  },
}
</script>
