<template>
  <div class="text-pink-100" v-if="missingErrorFields.length">
    Looks like you missed {{ missingErrorFields.length }} of the required
    fields:

    <ul class="mt-2 mb-2 list-disc pl-4">
      <li
        v-for="field in missingErrorFields"
        :key="field"
        class="text-pink-100 hover:text-pink-200 cursor-pointer"
        @click="scrollToField(field)"
      >
        {{ field.question }}
      </li>
    </ul>
  </div>

  <div class="text-pink-100 mt-8" v-if="validationErrorFields.length">
    There are {{ validationErrorFields.length }} validation errors in the
    following fields:

    <ul class="mt-2 mb-4 list-disc pl-4">
      <li
        v-for="field in validationErrorFields"
        :key="field"
        class="text-pink-100 hover:text-pink-200 cursor-pointer"
        @click="scrollToField(field)"
      >
        {{ field.name }}: {{ field.error_text }}
      </li>
    </ul>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto"

export default {
  props: {
    errorFields: { type: Array },
  },
  methods: {
    scrollToField(field) {
      const nearest = document.querySelector('[id*="' + field.id + '"]')

      VueScrollTo.scrollTo("#" + nearest.id, 300, { offset: -250 })
    },
  },
  computed: {
    missingErrorFields() {
      return this.errorFields.filter(
        (field) => field.error_text && field.error_text.includes("required")
      )
    },
    validationErrorFields() {
      return this.errorFields.filter(
        (field) => !field.error_text?.includes("required")
      )
    },
  },
}
</script>
