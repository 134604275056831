<template>
  <div
    class="card-list-shadow mt-5 rounded bg-white p-3"
    :class="{ 'card-hover-small': !isWithdrawn }"
  >
    <a
      :href="campaignUrl"
      class="flex"
      :class="{ 'items-start': updateText, 'items-center': !updateText }"
    >
      <!-- Photo -->
      <div class="shrink-0">
        <img v-lazy="avatarUrl" :alt="memberName" class="h-16 w-16 rounded" />
      </div>

      <!-- Details -->
      <div class="ml-4">
        <div class="font-medium">
          <template v-if="isWithdrawn">
            {{ memberName }}'s campaign was withdrawn
          </template>

          <template v-else>
            <span class="text-pink-100">{{ memberName }}</span>
            {{ stageDisplay }}
          </template>
        </div>

        <!-- Update -->
        <div class="mt-2 text-sm" v-if="updateText">
          {{ updateText }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    memberName: {
      type: String,
    },
    stageDisplay: {
      type: String,
    },
    updateText: {
      type: String,
    },
    slug: {
      type: String,
    },
    avatarUrl: {
      type: String,
    },
    isWithdrawn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    campaignUrl() {
      if (this.isWithdrawn) {
        return "https://help.beam.org/en/articles/1511550-what-happens-if-a-member-doesn-t-complete-training-or-find-work-with-beam"
      } else {
        return `/campaigns/${this.slug}`
      }
    },
  },
}
</script>
