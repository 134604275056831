<template>
  <a @click="goToPage" :href="href">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    href: { type: String },
  },
  methods: {
    goToPage() {
      this.$emit("fancy-link-click")
    },
  },
}
</script>
