<template>
  <section class="beam-container py-12">
    <div class="">
      <h2 class="beam-h2 m-auto mt-2 max-w-[540px] text-center">
        <div>
          <span class="yellow-underline-h2">Doing the right thing</span> just
          became <span class="yellow-underline-h2">incredibly simple</span>
        </div>
      </h2>
      <!-- Carousel controls -->
      <div class="grid grid-cols-1 md:grid-cols-3 mt-16 gap-10">
        <div
          class="border-t-[10px] pt-4 text-left transition-colors"
          :class="{
            'border-yellow-100': this.isSlideSelected(0),
            'border-gray-200': !this.isSlideSelected(0),
          }"
          @click="this.$refs.carousel.slideTo(0)"
        >
          <div class="mb-1 text-lg font-bold">Social good, made easy</div>
          <div class="text-md">
            The easiest way for companies to do good. Get started in minutes
          </div>
        </div>
        <div
          class="border-t-[10px] pt-4 text-left transition-colors"
          :class="{
            'border-yellow-100': this.isSlideSelected(1),
            'border-gray-200': !this.isSlideSelected(1),
          }"
          @click="this.$refs.carousel.slideTo(1)"
        >
          <div class="mb-1 text-lg font-bold">
            Measurable & shareable impact
          </div>
          <div class="text-md">
            Share social impact achievements and the stories of people you've
            supported
          </div>
        </div>
        <div
          class="border-t-[10px] pt-4 text-left transition-colors"
          :class="{
            'border-yellow-100': this.isSlideSelected(2),
            'border-gray-200': !this.isSlideSelected(2),
          }"
          @click="this.$refs.carousel.slideTo(2)"
        >
          <div class="mb-1 text-lg font-bold">Inspire your team</div>
          <div class="text-md">
            Send encouraging messages to people supported and celebrate your
            shared impact
          </div>
        </div>
      </div>
      <!-- The vue3-carousel component doesn't currently support up/down direction -->
      <div
        class="mx-auto mt-8 max-w-[910px] rounded-md border-2 border-gray-200 p-[10px]"
      >
        <carousel
          :items-to-show="1"
          :transition="750"
          :autoplay="3000"
          :pauseAutoplayOnHover="true"
          :wrapAround="true"
          ref="carousel"
          @slide-start="slideStarted"
        >
          <!-- For some reason it is still showing Item x of y - I can't work out how to remove that -->
          <template #slides>
            <slide
              v-for="(screenshot, index) in productScreenshots"
              :key="index"
            >
              <img class="rounded-md" :src="screenshot" />
            </slide>
          </template>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    productScreenshots: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isSlideSelected(index) {
      return this.currentSlide == index
    },
    slideStarted(e) {
      this.currentSlide =
        e.slidingToIndex < e.slidesCount ? e.slidingToIndex : 0
    },
  },
  data() {
    return {
      // We'd use carousel.currentSlide but it's change does not trigger a change of class for
      // the selectors.
      currentSlide: 0,
    }
  },
}
</script>
