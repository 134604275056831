import { Controller } from "@hotwired/stimulus"

import { createApp } from "vue"
import axios from "axios"
import { warnHandler } from "../../packs/error-handlers"

// Register all the Vue components
const files = import.meta.glob("@/src/vue-site/**/*.vue", { eager: true })
const commonFiles = import.meta.glob("@/src/vue-common/*.vue", { eager: true })

import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel"

import { vfmPlugin } from "vue-final-modal"

import VueScrollTo from "vue-scrollto"
// 6kb
import lazyPlugin from "vue3-lazy"

import ActionCableVue from "actioncable-vue"
export default class extends Controller {
  connect() {
    const app = createApp({
      data() {
        return {
          pageComponent: null,
          pageProps: {},
          showFooter: true,
          showFooterLinks: true,
          showMemberHubLinks: false,
          showPageActions: true,
          navDonateHref: null,
          currency: "£",
        }
      },
      computed: {
        signedInStripeCustomer() {
          return gon.current_user && gon.current_user.stripe_customer
        },
      },
      methods: {
        showLoginModal: function (source) {
          this.$vfm.show("modal-sign-in", { userSource: source })
        },
        showFlash: function (message, type) {
          this.$refs.flashAlert.openAlert(message, type)
        },
        closeFlash() {
          this.$refs.flashAlert.close()
        },
        setPageComponent(page) {
          this.pageComponent = page.component
          // Or can do this without registering - what are the tradeoffs?
          // this.pageComponent = require(`../src/vue-site/${page.component}`).default

          if (page.props) {
            this.pageProps = page.props
          }

          if (page.title) {
            document.title = page.title
          }

          this.showFooter = !page.hideFooter
          this.showFooterLinks = !page.hideFooterLinks
          this.showMemberHubLinks = page.showMemberHubLinks
          this.showPageActions = !page.hideActions
          this.navDonateHref = page.navDonateHref
        },
      },
      created() {
        // Initialise Vue app on page load
        const root = document.getElementById("app")

        if (root.dataset.component) {
          const page = { component: root.dataset.component }

          if (root.dataset.pageProps) {
            page.props = JSON.parse(root.dataset.pageProps)
          }

          this.setPageComponent(page)
        }

        if (root.hasAttribute("data-hide-footer")) {
          this.showFooter = false
        }

        if (root.hasAttribute("data-hide-footer-links")) {
          this.showFooterLinks = false
        }

        if (root.hasAttribute("data-show-member-hub-links")) {
          this.showMemberHubLinks = true
        }

        if (root.hasAttribute("data-hide-page-actions")) {
          this.showPageActions = false
        }

        window.onpopstate = () => {
          window.location.reload()
        }
      },
    })

    this.app = app

    Object.entries(files).forEach(([path, definition]) => {
      // Get name of component, based on filename
      // "./components/Fruits.vue" will become "Fruits"
      const componentName = path
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")

      // Register component on this Vue instance
      app.component(componentName, definition.default)
    })

    Object.entries(commonFiles).forEach(([path, definition]) => {
      // Get name of component, based on filename
      // "./components/Fruits.vue" will become "Fruits"
      const componentName = path
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")

      // Register component on this Vue instance
      app.component(componentName, definition.default)
    })

    app.use(ActionCableVue, { connectImmediately: true })

    app.use(vfmPlugin)
    app.config.globalProperties.axios = axios

    app.config.warnHandler = warnHandler

    app.config.compilerOptions.isCustomElement = (tag) =>
      tag.startsWith("trix") || tag.startsWith("turbo")

    // vue3-carousel
    app.component("Carousel", Carousel)
    app.component("Slide", Slide)
    app.component("Navigation", Navigation)
    app.component("Pagination", Pagination)

    app.use(VueScrollTo)
    lazyPlugin.install(app, {})

    const csrfTokenTag = document.querySelector("meta[name=csrf-token]")

    if (csrfTokenTag) {
      axios.defaults.headers.common["X-CSRF-Token"] =
        csrfTokenTag.getAttribute("content")
    }

    app.mount("#app")

    document.addEventListener(
      "turbo:before-cache",
      () => {
        app.unmount("#app")
      },
      { once: true }
    )
  }

  disconnect() {
    this.app.unmount("#app")
  }
}
