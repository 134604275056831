import "~/css/site-css-entrypoint.css"

import * as Turbo from "@hotwired/turbo"
window.Turbo = Turbo
Turbo.start()

// Stimulus
import "../controllers/site"

//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// Import all channels.
// const channels = import.meta.glob('./**/*_channel.js', { eager: true})

import qs from "qs"
window.qs = qs

// Chart JS Default
import {
  blue,
  yellow,
  borderBlue,
  borderYellow,
} from "../src/vue-site/ChartDefaults"

import axios from "axios"
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

import.meta.glob("@/src/vue-common/*.js", { eager: true })

// Navigate to the URL if the frame is missing
// https://github.com/hotwired/turbo/issues/257#issuecomment-1591737862
document.addEventListener("turbo:frame-missing", function (event) {
  event.preventDefault()
  event.detail.visit(event.detail.response)
})

if (import.meta.hot) {
  import.meta.hot.on("vite:beforeFullReload", (payload) => {
    // Hack to skip the reload of the page
    // See https://github.com/vitejs/vite/blob/f402cd278c861e6351b27966d4f98bbb8bc34673/packages/vite/src/client/client.ts#L229
    payload.path = "wont_match_current_path.html"

    Turbo.cache.clear()

    // Maintain scroll position
    const scrollTop = document.documentElement.scrollTop
    addEventListener(
      "turbo:load",
      () => {
        document.documentElement.scrollTop = scrollTop
      },
      { once: true }
    )

    window.setTimeout(() => {
      Turbo.visit(location.pathname + location.search, { action: "replace" })
    }, 50)
  })
}
