<template>
  <button
    class="gtm-video-play h-full w-full focus:outline-none"
    @click="showVideoModal"
  >
    <icon name="play" :solid="true" class="inline-block h-6 w-6 pl-1 pt-1" />
  </button>
</template>

<script>
import VideoMixin from "../vue-common/mixins/VideoMixin"

export default {
  mixins: [VideoMixin],
}
</script>
