<template>
  <form
    ref="form"
    action="/budget_calculator/money_coming_in"
    accept-charset="UTF-8"
    method="post"
    class="grid grid-cols-12 w-full"
  >
    <div class="col-start-3 col-span-8 mb-12">
      <h1 class="beam-h1 text-left mb-12 mt-6">Money Coming In</h1>
      <member-form-section
        section-key="benefits"
        :fields="fieldsForSection('benefits')"
        :form-section-schema="formSectionSchema"
      >
      </member-form-section>

      <warning-panel v-if="receivingLegacyBenefits">
        <template v-slot:body
          >This calculator does not support Legacy Benefits, but you can use the
          BOWB with Policy in Practice's Better Off Calculator</template
        >
      </warning-panel>

      <warning-panel v-if="showJointClaimWarning">
        <template v-slot:title>
          When you move into a property, you'll need to switch to a joint
          Universal Credit claim
        </template>
        <template v-slot:body> Our team will help you with this </template>
      </warning-panel>

      <member-form-section
        section-key="disability"
        :fields="fieldsForSection('disability')"
        :form-section-schema="formSectionSchema"
        v-if="!receivingLegacyBenefits"
      >
      </member-form-section>

      <member-form-section
        v-for="sectionKey in sectionKeys.filter(
          (s) => s !== 'benefits' && s !== 'disability'
        )"
        :section-key="sectionKey"
        :fields="fieldsForSection(sectionKey)"
        :form-section-schema="formSectionSchema"
        v-if="!receivingLegacyBenefits"
      >
      </member-form-section>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary button-large w-full"
            v-if="!receivingLegacyBenefits"
          >
            Save and continue
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import MemberFormShared from "../member-forms/MemberFormShared"
export default {
  mixins: [MemberFormShared],
  methods: {
    showField(id) {
      switch (id) {
        case "joint_uc_claim":
          return (
            this.fieldTrue(this.thisForm.uc_claim) &&
            this.fieldTrue(this.thisForm.member_has_partner)
          )
        case "nrpf":
          return (
            this.fieldFalse(this.thisForm.uc_claim) &&
            this.fieldFalse(this.thisForm.legacy_benefits)
          )
        case "pip_benefits":
        case "carers_allowance":
          return this.fieldTrue(this.thisForm.health_benefits)
        case "care_or_daily_living_component":
        case "mobility_component":
          return this.fieldTrue(this.thisForm.pip_benefits)
        case "pip_benefits_amount":
          return (
            this.fieldEquals(
              this.thisForm.care_or_daily_living_component,
              "Don't know"
            ) ||
            this.fieldEquals(this.thisForm.mobility_component, "Don't know")
          )
        case "carers_allowance_amount":
          return this.fieldTrue(this.thisForm.carers_allowance)
        case "work_hours_per_week":
        case "work_net_earnings":
          return this.fieldTrue(this.thisForm.in_work)
        case "partner_in_work":
          return this.fieldTrue(this.thisForm.member_has_partner)
        case "partner_work_hours_per_week":
        case "partner_work_net_earnings":
          return this.fieldTrue(this.thisForm.partner_in_work)
        case "other_income_amount":
          return this.fieldTrue(this.thisForm.other_income)
        case "savings_amount":
          return this.fieldTrue(this.thisForm.savings)
        default:
          return true
      }
    },
  },
  computed: {
    showJointClaimWarning() {
      return (
        this.fieldTrue(this.thisForm.uc_claim) &&
        this.fieldTrue(this.thisForm.member_has_partner) &&
        this.fieldFalse(this.thisForm.joint_uc_claim)
      )
    },
    receivingLegacyBenefits() {
      return this.fieldTrue(this.thisForm.legacy_benefits)
    },
  },
}
</script>
