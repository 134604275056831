<template>
  <section class="mt-16 py-20 sm:mt-24">
    <div class="text-3xl font-bold text-center">
      Working with 90+ urban, suburban and rural councils and government
      departments across the UK
    </div>
    <!-- The logos need to be black and white -->
    <div class="flex flex-row grayscale justify-center gap-14 mt-8 flex-wrap">
      <img
        v-for="(councilName, filename) in councilLogos"
        :src="`/images/council-logos/${filename}.jpg`"
        :alt="`Logo of ${councilName} Council`"
        class="h-10"
      />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      councilLogos: {
        aberdeenshire: "Aberdeenshire",
        "nottingham-city": "Nottingham City",
        "halton-borough": "Halton Borough",
        "cambridge-city": "Cambridge City",
        "tunbridge-wells-borough": "Tunbridge Wells Borough",
        "coventry-city": "Coventry City",
        southwark: "Southwark",
        haringey: "Haringey",
      },
    }
  },
}
</script>
