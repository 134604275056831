<template>
  <div
    :class="calculatedClass"
    class="rounded-none bg-white sm:w-full p-5 md:p-8 md:mt-8 mt-0 md:border md:rounded-lg"
  >
    <div>
      <!-- Logo -->
      <template v-if="testimonial.logo">
        <img
          class="mb-5 h-8 lg:h-12"
          v-lazy="testimonial.logo"
          :alt="testimonial.logoAlt"
        />
      </template>
      <span
        v-html="quotedTestimonial"
        class="markdown-testimonial mt-1 text-base md:text-xl"
      ></span>
    </div>

    <div class="mt-4 flex items-center">
      <!-- Photo -->
      <template v-if="testimonial.photo">
        <a v-if="testimonial.link" :href="testimonial.link" target="_blank">
          <img
            class="mr-4 w-16 rounded-full"
            :class="{ 'lg:w-56': largePhoto, 'lg:w-20': !largePhoto }"
            v-lazy="testimonial.photo"
            :alt="testimonial.name"
          />
        </a>
        <img
          v-else
          class="mr-4 w-16 rounded-full"
          :class="{ 'lg:w-56': largePhoto, 'lg:w-20': !largePhoto }"
          v-lazy="testimonial.photo"
          :alt="testimonial.name"
        />
      </template>

      <div>
        <div class="mt-2 text-base md:text-xl md:font-medium">
          <a
            v-if="testimonial.link"
            :href="testimonial.link"
            target="_blank"
            class="blue-link"
          >
            {{ testimonial.name }}
          </a>
          <div v-else>
            {{ testimonial.name }}
          </div>
        </div>

        <div class="md:text-xl text-base">
          {{ testimonial.subtitle }}
        </div>
      </div>
    </div>
    <div v-if="!isLast" class="visible md:hidden border mt-6"></div>
  </div>
</template>
<script>
export default {
  props: {
    testimonial: { type: Object },
    index: { type: Number },
    largePhoto: { type: Boolean, default: false },
    dropShadow: { type: Boolean, default: false },
    isLast: { type: Boolean, default: false },
  },
  computed: {
    calculatedClass() {
      const classes = []

      if (this.index < 2) {
        classes.push("md:mt-0")
      }

      if (this.dropShadow) {
        classes.push("card-shadow")
      }

      return classes.join(" ")
    },
    quotedTestimonial() {
      return this.testimonial.description
        .replace("<p>", '<p>"')
        .replace("</p>", '"</p>')
    },
  },
}
</script>
