<template>
  <header class="space-y-4 bg-pale-grey-100 pt-12">
    <div class="pb-2 text-5xl">🧑</div>

    <h1 class="beam-h1 text-left">{{ title }}</h1>

    <div class="text-lg">
      This will help us make sure Beam is a good fit for you.
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title: { type: String },
  },
}
</script>
