<template>
  <section>
    <div
      class="full-screen-width mt-16 bg-pale-grey-100 py-10 sm:mt-20 sm:py-16 lg:mt-32 lg:pb-20 lg:pt-32"
    >
      <div class="beam-container">
        <div class="beam-h2">Support someone today</div>

        <div
          v-if="hasCampaigns"
          class="mt-10 space-y-2.5 sm:flex sm:items-center sm:space-x-5 sm:space-y-0"
        >
          <bottom-cta-campaign
            v-for="campaign in campaigns"
            :key="campaign.id"
            class="mx-auto w-full max-w-xs sm:max-w-none"
            :member-photo="campaign.medium_avatar_url"
            :member-name="campaign.member_name"
            :link="`/campaigns/${campaign.slug}`"
            :sentence="campaign.sentence_stage_display"
            :percent="campaign.percentage_donated"
          >
          </bottom-cta-campaign>
        </div>

        <!-- CTAs -->
        <div class="mt-12 text-center">
          <a
            href="/donate"
            class="button button-primary inline-block px-16 py-3"
            >Donate today</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      campaigns: [],
    }
  },
  computed: {
    hasCampaigns() {
      return this.campaigns.length > 0
    },
  },
  created() {
    let url = "/campaigns/bottom_cta"
    if (gon.campaign_id) {
      url += `?exclude_campaign_id=${gon.campaign_id}`
    }
    this.axios.get(url).then((response) => {
      this.campaigns = response.data.campaigns
    })
  },
}
</script>
