export const blue = "rgba(31, 182, 255, .2)"
export const yellow = "rgba(251, 214, 62, .2)"
export const borderBlue = "rgba(31, 182, 255, 1)"
export const borderYellow = "rgba(251, 214, 62, 1)"

if (typeof Chart !== "undefined") {
  // Chart.defaults.scale.gridLines.display = false
  Chart.defaults.scale.ticks.beginAtZero = true
  Chart.defaults.global.legend.display = false
  Chart.defaults.global.elements.rectangle.backgroundColor = blue
  // Chart.defaults.global.elements.rectangle.borderColor = borderBlue
  // Chart.defaults.global.elements.rectangle.borderWidth = 1
  Chart.defaults.global.responsive = true
  Chart.defaults.global.maintainAspectRatio = false

  Chart.defaults.global.defaultFontFamily = "proximanova"
  Chart.defaults.global.defaultFontColor = "#071754"
  Chart.defaults.global.defaultFontStyle = "bold"
  Chart.defaults.global.defaultFontSize = 16
}
