<template>
  <section class="mt-12 lg:mt-32">
    <div class="flex flex-col items-center sm:flex-row">
      <!-- Left -->
      <div
        class="order-2 mt-32 sm:mr-20 sm:w-1/2 sm:flex-shrink-0 md:order-1 md:mt-0 md:mt-16"
      >
        <div class="relative text-center">
          <img :src="pinkPhoto" class="mx-auto w-80 rounded-sm" />

          <landing-quote
            v-if="bottomQuote"
            :quote="bottomQuote"
            class="absolute -bottom-24 -right-4 sm:-bottom-4 sm:-right-8"
          >
          </landing-quote>

          <landing-quote
            v-if="topQuote"
            class="absolute -left-4 -top-24 sm:-left-0"
            :quote="topQuote"
          >
          </landing-quote>
        </div>

        <!-- Quote bottom -->
        <div class="text-center">
          <div class="mx-auto mt-32 w-80 sm:mt-10">
            <slot name="member-quote"></slot>
          </div>
          <div class="font-medium">{{ memberName }}, Beam user</div>
        </div>
      </div>

      <!-- Right -->
      <div class="order-1 sm:w-1/2 md:order-2">
        <div class="beam-h2 text-left">
          <slot name="title"></slot>
        </div>

        <slot name="subtitle"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    pinkPhoto: { type: String, required: true },
    memberName: { type: String, required: true },
    topQuote: { type: Object, required: false },
    bottomQuote: { type: Object, required: false },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
