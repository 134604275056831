<template>
  <div class="mx-auto max-w-2xl">
    <form
      ref="form"
      action="/member_eligibilities"
      accept-charset="UTF-8"
      method="post"
    >
      <div class="stack-mt-12">
        <member-form-field
          :field="form.member_name"
          v-model="form.member_name.value"
        >
          forcerefresh
        </member-form-field>

        <member-form-field :field="form.email" v-model="form.email.value">
          forcerefresh
        </member-form-field>

        <member-form-field
          :field="form.phone_number"
          v-model="form.phone_number.value"
        >
          forcerefresh
        </member-form-field>
        <!-- Council -->
        <member-form-field
          :field="form.referral_location_id"
          v-model="form.referral_location_id.value"
        >
          forcerefresh
        </member-form-field>

        <!-- Self-Referrals error -->
        <div v-if="errorNoSelfReferrals">
          <p>
            ⚠️ Oh no! Sadly, Beam isn't able to support individuals in your area
            at the moment.
          </p>
        </div>

        <!-- Housing Officer -->
        <member-form-field
          :field="form.housing_officer"
          v-model="form.housing_officer.value"
        >
          forcerefresh
        </member-form-field>

        <!-- Housing Issues -->
        <member-form-field
          :field="form.housing_issues"
          v-model="form.housing_issues.value"
        >
          forcerefresh
        </member-form-field>

        <!-- No housing issues error -->
        <div v-if="errorNoHousingIssues">
          <p>
            ⚠️ Oh no! Sadly, currently Beam is only able to support individuals
            experiencing housing issues.
          </p>
        </div>

        <!-- Benefits -->
        <member-form-field
          :field="form.claiming_benefits"
          v-model="form.claiming_benefits.value"
        >
          forcerefresh
        </member-form-field>

        <!-- Benefits Error -->
        <div v-if="errorNoBenefits">
          <p>
            ⚠️ Oh no! Sadly Beam can only support individuals in your area who
            are claiming benefits.
          </p>
        </div>

        <member-form-field
          :field="form.data_consent"
          v-model="form.data_consent.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div v-if="!disableSubmitButton" class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>

    <support-icon></support-icon>
    <member-support-modal page="eligibility"> </member-support-modal>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberEligibilityConditions from "./MemberEligibilityConditions"
export default {
  mixins: [MemberFormShared, MemberEligibilityConditions],
  props: {
    selfReferralLocations: { type: Array },
  },
  computed: {
    disableSubmitButton() {
      return (
        this.errorNoSelfReferrals ||
        this.errorNoHousingIssues ||
        this.errorNoBenefits
      )
    },
  },
  data() {
    return {
      tableName: "member_eligibility",
      errorNoSelfReferrals: false,
      errorNoHousingIssues: false,
      errorNoBenefits: false,
    }
  },
  watch: {
    "form.referral_location_id.value": function () {
      let locationId = this.form.referral_location_id.value

      if (!locationId) {
        this.errorNoSelfReferrals = false
        return
      }

      this.errorNoSelfReferrals =
        !this.selfReferralLocations.includes(locationId)
    },
    "form.housing_issues.value": function () {
      this.errorNoHousingIssues =
        this.form.housing_issues.value == "None of the above"
    },
    "form.claiming_benefits.value": function () {
      this.errorNoBenefits = this.form.claiming_benefits.value == "No"
    },
  },
}
</script>
