<template>
  <form action="/gift_aid_users" method="post" @submit.prevent="save">
    <div class="">
      Keep your details up to date so we can boost your donations by 25% with
      Gift Aid.
    </div>

    <div class="mt-10 sm:flex sm:justify-between">
      <div class="flex-1">
        <label for="first-name" class="inline-block font-medium"
          >First name</label
        >
        <input
          type="text"
          class="beam-text-input my-3 w-full"
          v-model.trim="gift_aid_user.first_name"
          id="first-name"
          autocomplete="given-name"
          required="true"
        />
      </div>

      <div class="flex-1 sm:ml-8">
        <label for="last-name" class="mt-5 inline-block font-medium sm:mt-0"
          >Last name</label
        >
        <input
          type="text"
          class="beam-text-input my-3 w-full"
          v-model.trim="gift_aid_user.last_name"
          id="last-name"
          autocomplete="family-name"
          required="true"
        />
      </div>
    </div>

    <div class="mt-5 sm:flex sm:justify-between">
      <div class="flex-1">
        <label for="line-1" class="inline-block font-medium"
          >Address line 1</label
        >
        <input
          type="text"
          class="beam-text-input my-3 w-full"
          v-model="gift_aid_user.house_number"
          id="line-1"
          autocomplete="address-line1"
          required="true"
        />
      </div>

      <div class="flex-1 sm:ml-8">
        <label for="postcode" class="mt-5 inline-block font-medium sm:mt-0"
          >Postcode</label
        >
        <input
          type="text"
          class="beam-text-input my-3 w-full"
          v-model.trim="gift_aid_user.postcode"
          id="postcode"
          autocomplete="postal-code"
          required="true"
        />
      </div>
    </div>

    <label for="city" class="mt-5 inline-block font-medium">Town / City</label>
    <input
      type="text"
      class="beam-text-input my-3 w-full"
      v-model="gift_aid_user.city"
      id="city"
      required="true"
    />

    <button class="button button-primary mt-5 w-full px-12 py-3 sm:w-auto">
      Save changes
    </button>
  </form>
</template>

<script>
export default {
  props: {
    initial: Object,
  },
  computed: {
    postData() {
      return JSON.parse(JSON.stringify(this.$data))
    },
  },
  data() {
    return {
      gift_aid_user: {
        first_name: this.initial.first_name,
        last_name: this.initial.last_name,
        house_number: this.initial.house_number,
        city: this.initial.city,
        postcode: this.initial.postcode,
      },
    }
  },
  methods: {
    save(event) {
      this.axios.post(event.target.action, this.postData).then((response) => {
        const data = response.data

        if (data.success) {
          this.$root.showFlash("Gift Aid details saved successfully!", "notice")
        } else {
          this.$root.showFlash(data.error_message, "error")
        }
      })
    },
  },
}
</script>
