<template>
  <div
    class="col-span-12 rounded-md border-2 border-yellow-100 bg-white p-8"
    v-if="hasAcheivedMilestoneBadges || hasChangemakers"
  >
    <div class="mx-auto">
      <template v-if="hasAcheivedMilestoneBadges">
        <h4 class="beam-h4 w-full text-center">
          Building a better society with Beam since
          {{ company.since_date_display }}
        </h4>
        <div
          class="mt-5 flex flex-row flex-wrap items-start justify-center gap-x-4"
        >
          <emoji-milestone-badge
            v-for="milestoneBadge in achievedMilestoneBadges"
            :color="milestoneBadge.color"
            :key="milestoneBadge.name"
          >
            <template #emoji>{{ milestoneBadge.emoji }}</template>
            <template #milestone>{{ milestoneBadge.milestone }}</template>
            <template #description>{{ milestoneBadge.description }}</template>
          </emoji-milestone-badge>
        </div>
      </template>
      <h4 class="beam-h4 mt-8 w-full text-center" v-if="hasChangemakers">
        {{ company.name }} has {{ numberOfChangemakers }}
        {{ numberOfChangemakers === 1 ? "changemaker" : "changemakers" }}
      </h4>
      <p class="mt-5 text-center" v-if="hasChangemakers">
        Changemakers donate at least £1 a month to change lives through Beam.
        {{ hasChangemakers ? "" : "Be the first!" }}
      </p>
      <ul
        class="mx-auto mt-5 max-w-[600px] border-b border-pale-grey-100 pb-3"
        v-if="hasChangemakers"
      >
        <li
          v-for="changemaker in visibleChangemakersOrdered"
          v-bind:key="changemaker.slug"
          class="mt-3 flex flex-row justify-between border-t border-pale-grey-100 pt-3"
        >
          <span class="align-center flex">
            <span
              class="mr-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-yellow-100"
              ><icon name="heart" class="h-4 w-4 text-blue-900"></icon
            ></span>
            <span class="font-medium">{{ changemaker.full_name }}</span>
          </span>
          <span class="text-gray-400"
            >{{ changemaker.number_of_campaigns_supported }} lives changed</span
          >
        </li>
      </ul>
      <div
        class="mt-6 text-center"
        v-if="
          visibleChangemakersOrdered.length < changemakers.length ||
          changemakersExpanded
        "
      >
        <span
          @click="changemakersExpanded = !changemakersExpanded"
          class="cursor-pointer underline"
          >{{ changemakersExpanded ? "Show fewer" : "Show more" }}</span
        >
      </div>
      <div
        class="mt-8 border-t border-pale-grey-100 pt-8 text-center"
        v-if="hasChangemakers"
      >
        <a
          href="https://beam.org/changemakers"
          target="_blank"
          class="cursor-pointer underline"
          >Become a changemaker</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    company: { type: Object },
    changemakers: { type: Array },
    milestoneBadges: { type: Array, default: [] },
  },
  data() {
    return {
      changemakersExpanded: false,
    }
  },
  computed: {
    achievedMilestoneBadges() {
      // TODO: Victor is working on a nicer way to display the "locked" badges, so we're
      // just hiding them for now. I didn't want to remove all the work
      // I did on the backend to return them so it's easier to just hide them here.
      return this.milestoneBadges.filter((badge) => badge.achieved)
    },
    changemakersOrdered() {
      return this.changemakers.sort(
        (a, b) =>
          b.number_of_campaigns_supported - a.number_of_campaigns_supported
      )
    },
    numberOfChangemakers() {
      if (this.changemakers.length) return this.changemakers.length
      return "no"
    },
    hasChangemakers() {
      return this.changemakers.length > 0
    },
    hasAcheivedMilestoneBadges() {
      return this.achievedMilestoneBadges.length > 0
    },
    visibleChangemakersOrdered() {
      if (this.changemakersExpanded) {
        return this.changemakersOrdered
      } else {
        return this.changemakersOrdered.slice(0, 10)
      }
    },
  },
}
</script>
