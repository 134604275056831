<template>
  <header class="bg-pale-grey-100 pt-12">
    <h1 class="beam-h1 text-left">{{ title }}</h1>

    <pre class="mt-4" v-if="utmSourceIndeed">
      Step 2 of 2 - Complete the form to...

      💼 Continue your application for {{ utmCampaign }} with Beam's help

      💪 Book a 1:1 call to discuss next steps

      📱 Unlock access to free CV and interview help
    </pre>

    <pre class="mt-4" v-else>
      Step 2 of 2 - Complete sign-up

      After you submit the form, we'll contact you to discuss the job application and help we can offer.

      If you have any questions please WhatsApp or call Tom on <a href="tel:07934976868" class='blue-link'>07934976868</a>.
    </pre>
  </header>
</template>

<script>
export default {
  props: {
    title: { type: String },
    utmSourceIndeed: { type: Boolean },
  },
}
</script>
