<template>
  <div v-if="caseworker && caseworker.thumb_avatar_url" class="flex">
    <img
      class="mt-6 h-24 w-24 rounded-full"
      :src="caseworker.thumb_avatar_url"
    />
    <div class="ml-2 rounded-md p-4">
      <p class="text-xl font-bold">
        {{ caseworker.first_name }} here. Need some help? Just message me!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caseworker: { type: Object },
    member: { type: Object },
  },
}
</script>
