<template>
  <div>
    <slot
      :showStickyHeader="showStickyHeader"
      :supportersCount="supportersCount"
      :campaignId="campaignId"
      :donatedAmount="donatedAmount"
      :openShareModal="openShareModal"
    >
    </slot>
  </div>
</template>

<script>
import throttle from "lodash.throttle"

import AnalyticsMixin from "./mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    campaignId: { type: Number, required: true },
    isCampaignFunded: { type: Boolean, required: true },
    donatedAmount: { type: String },
    initialSupportersCount: { type: Number },
  },
  data() {
    return {
      showStickyHeader: false,
      throttleTime: 100,
      highlightedLink: "",
      supportersCount: this.initialSupportersCount,
    }
  },
  methods: {
    openShareModal() {
      this.$vfm.show("modal-campaign-share")
    },
    setupObserver() {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          this.showStickyHeader = false
        } else {
          this.showStickyHeader = true
        }
      })

      // Observe a different element on mobile
      let dtop = document.querySelector(".js-campaign-desktop-top")

      if (window.getComputedStyle(dtop).display == "none") {
        observer.observe(document.querySelector(".js-campaign-mobile-top"))
      } else {
        observer.observe(dtop)
      }
    },
    logPageView() {
      if (this.isCampaignFunded) {
        this.logEvent("campaign-view-not-live")
      } else {
        this.logEvent("campaign-view-live")
      }
    },
    scrollSpy() {
      for (let element of document.querySelectorAll(".js-scroll-spy")) {
        if (this.inViewport(element)) {
          this.setSelected(element.parentElement.id)

          break
        }
      }

      document.querySelectorAll("[data-label]").forEach((link) => {
        if (link.dataset.label != this.highlightedLink) {
          link.classList.remove("font-medium")
          link.classList.add("blue-link")
        }
      })
    },
    setSelected(label) {
      let link = document.querySelector("[data-label='" + label + "']")
      link.classList.add("font-medium")
      link.classList.remove("blue-link")
      this.highlightedLink = label
    },
    inViewport(element) {
      var bounding = element.getBoundingClientRect()

      if (bounding.top >= 0 && bounding.bottom < window.innerHeight) {
        return true
      }

      return false
    },
  },
  created() {
    this.logPageView()
  },
  mounted() {
    if ("IntersectionObserver" in window) {
      this.setupObserver()

      this.scrollSpy()

      window.addEventListener(
        "scroll",
        throttle(this.scrollSpy, this.throttleTime)
      )
    }
  },
}
</script>
