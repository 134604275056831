<template>
  <a
    class="card-shadow card-hover-large flex h-full flex-col overflow-hidden rounded-lg bg-white"
    :href="path"
  >
    <div class="relative flex items-end">
      <img
        class="h-40 w-40 shrink-0 lg:h-48 lg:w-48"
        :src="photoUrl"
        :alt="name"
      />
      <div class="p-5">
        <div class="text-lg">
          {{ donatedAmount }}
          <div class="text-sm font-normal text-bluey-grey-100">raised</div>
        </div>

        <div class="mt-5 text-lg">
          <template v-if="!campaignFunded">
            {{ remainingAmount }}
            <div class="text-sm font-normal text-bluey-grey-100">to go</div>
          </template>

          <template v-else>
            {{ target }}
            <div class="text-sm font-normal text-bluey-grey-100">target</div>
          </template>
        </div>
      </div>
    </div>

    <progress-bar
      :percent="percentageDonated"
      :show-amount="false"
    ></progress-bar>
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    name: { type: String },
    photoUrl: { type: String },
    percentageDonated: { type: Number },
    donatedAmount: { type: String },
    remainingAmount: { type: String },
    path: { type: String },
    target: { type: String },
  },
  computed: {
    campaignFunded() {
      return this.percentageDonated >= 100
    },
  },
}
</script>
