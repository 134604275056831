<template>
  <div class="grid grid-cols-12">
    <div class="col-span-12 mt-8 text-left md:col-span-8">
      <h1 class="beam-h1 text-left">{{ sectionHeading }}</h1>

      <div class="mt-4 text-left">
        <p>
          Help {{ memberNameText }} put a face to a name by adding a profile
          pic.
        </p>
      </div>

      <drag-and-drop-image-upload
        upload-button-text="Upload a photo"
        :initialImageUrl="currentUserAvatarUrl"
        record-name="user"
        form-url="/users/current.json"
      >
        <template #instruction-text>
          Drag and drop a profile picture here
        </template>
        <template #success>
          <p class="text-sm text-gray-500">
            Success! You can change your profile picture at any time in your
            account settings.
          </p>
          <button
            class="button button-primary mt-4 inline-block cursor-pointer border-yellow-400 px-8 py-2"
            @click="$emit('go-to-dashboard')"
          >
            Go to {{ company.name }}'s dashboard
          </button>
        </template>
      </drag-and-drop-image-upload>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaign: { type: Object },
    company: { type: Object },
    currentUserAvatarUrl: { type: String, default: null },
    messageWasSent: { type: Boolean, default: false },
  },
  data() {
    return {
      isDraggingOver: false,
      avatarUrl: "/images/missing.png",
      uploading: false,
      errorMessage: null,
    }
  },
  computed: {
    sectionHeading() {
      if (this.messageWasSent) {
        return "💌 Message sent!"
      } else {
        return "📸 Add a profile picture"
      }
    },
    memberNameText() {
      if (this.campaign.member_name && this.messageWasSent) {
        return this.campaign.member_name
      } else {
        return "our members"
      }
    },
  },
  methods: {
    uploadPhoto(file) {
      if (this.uploading) return

      let formData = new FormData()
      formData.append("user[avatar]", file)
      this.isDraggingOver = false
      this.uploading = true
      this.axios
        .patch("/users/current.json", formData)
        .then((response) => {
          const { data } = response
          if (data.success) {
            this.avatarUrl = data.user.thumb_avatar_url
          } else {
            this.errorMessage =
              data.error_message ||
              "Something went wrong, please skip this step or contact support@beam.org"
          }
        })
        .finally(() => {
          this.uploading = false
        })
    },
    handlePhotoUpload() {
      this.uploadPhoto(this.$refs.file.files[0])
    },
    handlePhotoDrop(e) {
      e.preventDefault()
      this.uploadPhoto(e.dataTransfer.files[0])
    },
    handlePhotoDragOver(e) {
      e.preventDefault()
      this.isDraggingOver = true
    },
    handlePhotoDragLeave(e) {
      e.preventDefault()
      this.isDraggingOver = false
    },
  },
  created() {
    if (this.currentUserAvatarUrl) {
      this.avatarUrl = this.currentUserAvatarUrl
    }
  },
}
</script>
