<template>
  <div
    class="items-center justify-center space-y-12 sm:flex sm:flex-row sm:space-y-0"
  >
    <div class="sm:w-1/2">
      <div class="text-pink-100">
        <quote-icon class="inline-block h-4 w-5"></quote-icon>
      </div>
      <div class="beam-h2 mt-2 max-w-xs text-left sm:max-w-sm">
        The social enterprise turning people's lives around
      </div>
      <img
        :src="`/images/big-issue.jpg`"
        alt="Financial Times"
        class="mt-4 w-20"
      />
    </div>

    <div class="sm:w-1/2">
      <div class="text-pink-100">
        <quote-icon class="inline-block h-4 w-5"></quote-icon>
      </div>
      <div class="beam-h2 mt-2 max-w-xs text-left sm:max-w-md">
        Applying the matchmaking mindset to tackle social problems
      </div>
      <img
        :src="`/images/financial-times.jpg`"
        alt="Financial Times"
        class="mt-4 w-40"
      />
    </div>
  </div>

  <div class="my-12 hidden bg-white px-24 py-12 sm:block">
    <img :src="`/images/press.jpg`" />
  </div>
</template>
