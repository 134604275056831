<template>
  <div>
    <ul class="mt-5 flex justify-between">
      <li
        @click="selectAmount(amount)"
        class="checkout-amount"
        v-for="amount in amounts"
        :key="amount"
        :class="{ selected: isAmountSelected(amount) }"
      >
        {{ currency }}{{ amount }}
      </li>

      <li
        @click="selectOther"
        class="checkout-amount"
        :class="{ selected: otherSelected }"
      >
        Other
      </li>
    </ul>

    <transition name="slide-fade">
      <div
        v-if="otherSelected"
        class="mt-5 rounded bg-pale-grey-100 px-5 py-5 sm:px-8 sm:py-8"
      >
        <label for="custom-amount" class="font-medium">
          Please enter custom amount
        </label>
        <div class="relative mt-5">
          <!-- Could've vertically centered with flex but didn't work in IE11 -->
          <div class="center-absolute-y absolute pl-4 text-bluey-grey-100">
            {{ currency }}
          </div>
          <input
            type="number"
            class="beam-text-input w-full pl-8"
            v-model="amount"
            id="custom-amount"
          />
        </div>

        <div class="mt-2 text-xs text-bluey-grey-100" v-if="amountRemaining">
          £{{ amountRemaining.toLocaleString() }} remaining.
          <a @click="fullyFund" class="blue-link"
            >See this campaign over the line!</a
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    amounts: { type: Array },
    amountRemaining: { type: Number, required: false },
  },
  computed: {
    currency() {
      return this.$root.currency
    },
    amount: {
      get() {
        if (this.$parent.selectedAmount) {
          return this.$parent.selectedAmount
        }
      },
      set(modifiedValue) {
        this.$parent.selectedAmount = modifiedValue
      },
    },
  },
  data() {
    return {
      otherSelected: false,
    }
  },
  methods: {
    selectOther() {
      this.otherSelected = true
    },
    isAmountSelected(amount) {
      if (this.otherSelected) {
        return false
      }

      return this.$parent.selectedAmount == amount
    },
    selectAmount(amount) {
      this.otherSelected = false
      this.$emit("update-selected-amount", amount)
    },
    fullyFund() {
      this.$parent.selectedAmount = this.amountRemaining
    },
  },
}
</script>
