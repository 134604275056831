<template>
  <div class="rounded bg-white p-5 sm:p-8">
    <!-- Update Top -->
    <div class="flex items-start justify-start">
      <!-- Member Photo -->
      <div class="mr-3 shrink-0">
        <a :href="update.campaign_link">
          <img
            class="h-12 w-12 rounded-full sm:h-16 sm:w-16"
            :src="update.campaign_avatar_url"
            :alt="update.campaign_member_name_display"
          />
        </a>
      </div>

      <!-- Top Right -->
      <div>
        <!-- Sentence -->
        <div class="text-sm sm:text-base">
          <a
            class="pink-link inline-block font-medium"
            :href="update.campaign_link"
            >{{ update.campaign_member_name_display }}</a
          >
          {{ update.action_display }}
        </div>

        <!-- Bottom stuff -->
        <div class="mt-1 text-xs text-bluey-grey-100">
          <span
            class="mr-2 rounded-full bg-pink-100 px-2 py-px text-white"
            v-if="update.is_draft"
            >draft</span
          >
          <a class="mr-2" :href="`/news/${update.id}`">
            <timeago :time="update.posted_at"></timeago>
          </a>
          <span class="mr-2">{{ update.stage_display }}</span>

          <span
            class="hidden font-medium xs:inline"
            v-if="update.member_supported"
          >
            <icon
              name="check"
              class="inline-block h-4 w-4 pt-1 text-pink-100"
            ></icon>
            Donated!
          </span>
        </div>
      </div>
    </div>

    <!-- Update Text -->
    <div
      class="markdown-wrapper mb-4 mt-5 text-sm sm:mb-6 sm:mt-8 sm:text-base"
      v-if="update.update_text"
      v-html="sanitizedUpdateText"
    ></div>

    <!-- Facebook Video -->
    <div class="mt-4" v-if="update.has_facebook_video">
      <div
        class="fb-video"
        :data-href="update.video_embed_url"
        :data-show-text="false"
        data-allowfullscreen="false"
        data-width="auto"
      ></div>
    </div>

    <!-- YouTube Video -->
    <div class="mt-4" v-if="update.has_youtube_video">
      <span class="video-wrapper">
        <iframe
          :src="update.video_embed_url"
          frameborder="0"
          allowfullscreen="true"
          height="168"
          width="300"
        ></iframe>
      </span>
    </div>

    <!-- Uploaded Video -->
    <video
      class="mx-auto mt-4"
      v-if="update.has_uploaded_video"
      :src="update.video_embed_url"
      controls
    ></video>

    <!-- Update Photo -->
    <div
      class="mt-4 overflow-hidden text-center"
      style="max-height: 750px"
      v-if="shouldShowPhoto(update)"
    >
      <img class="inline-block" :src="update.photo_url" :alt="update.caption" />
    </div>

    <div
      class="mt-3 text-center text-xs text-bluey-grey-100"
      v-if="update.caption"
    >
      {{ update.caption }}
    </div>

    <!-- Update Like / Share -->
    <update-like-share
      :donate-link="update.campaign_donate_link"
      :like-count="update.likes_count"
      :campaign-id="update.campaign_id"
      :liked-by-user="update.liked_by_user"
    >
    </update-like-share>

    <!-- Comments -->
    <div :class="{ 'mt-5 sm:mt-8': sortedComments.length }">
      <update-comment
        v-for="(comment, index) in sortedComments"
        :comment="comment"
        :index="index"
        :key="comment.id"
        v-on:delete-comment="onDeleteComment"
      ></update-comment>
    </div>

    <!-- Post Comment -->
    <input
      v-model.trim="newCommentText"
      @keyup.enter="postComment"
      enterkeyhint="send"
      placeholder="Press Enter to Comment"
      type="text"
      class="beam-text-input mt-5 w-full sm:mt-8"
      @click="focused = true"
    />

    <div class="text-right" :class="{ hidden: !focused }">
      <button @click="postComment" class="button button-secondary mt-3 px-4">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import DOMPurify from "dompurify"

import FacebookMixin from "./mixins/FacebookMixin"
import AnalyticsMixin from "./mixins/AnalyticsMixin"

export default {
  mixins: [FacebookMixin, AnalyticsMixin],
  props: { update: Object },
  data() {
    return {
      comments: this.update.comments,
      newCommentText: "",
      focused: false,
      posting: false,
    }
  },
  computed: {
    memberName() {
      return this.update.campaign_member_name
    },
    sortedComments() {
      return this.comments.sort(function (a, b) {
        return a.id - b.id
      })
    },
    buttonText() {
      return this.posting ? "Posting..." : "Post"
    },
    sanitizedUpdateText() {
      return DOMPurify.sanitize(this.update.update_text)
    },
  },
  methods: {
    shouldShowPhoto(update) {
      return (
        update.has_photo &&
        !update.has_facebook_video &&
        !update.has_youtube_video
      )
    },
    postComment() {
      if (this.isCommentBlank()) {
        // this.$root.showFlash('Please enter your comment', "notice");
        return
      }

      this.posting = true

      var postOpts = {
        comment: {
          comment_text: this.newCommentText,
          campaign_update_id: this.update.id,
          source: gon.feed_source,
        },
      }

      this.newCommentText = ""

      this.axios
        .post("/comments", postOpts)
        .then((response) => {
          this.logEvent("update-comment")

          if (this.maybeShowLogin()) {
            return
          }

          const comment = response.data.comment
          this.comments.push(comment)
        })
        .catch(() => {
          this.$root.showFlash(
            "Hmm, something went wrong posting your comment",
            "error"
          )
        })
        .finally(() => {
          this.posting = false
        })
    },
    isCommentBlank() {
      return this.newCommentText == null || this.newCommentText.length == 0
    },
    maybeShowLogin() {
      if (gon.current_user) {
        return false
      }

      this.$root.showLoginModal("post_comment")

      return true
    },
    onDeleteComment(commentId) {
      var index = this.comments.findIndex(function (comment) {
        return comment.id === commentId
      })

      this.comments.splice(index, 1)

      this.axios.delete("/comments/" + commentId)
    },
  },
  created() {
    if (this.update.has_facebook_video) {
      this.getFacebookSDK()
    }
  },
}
</script>
