<template>
  <progress-bar
    parent-classes="full-screen-width bg-yellow-200 h-1 lg:sticky top-24 z-20"
    :percent="stepsPercent"
    :show-amount="false"
  ></progress-bar>

  <main class="full-screen-width bg-pale-grey-100 px-5 pb-24">
    <div class="mx-auto max-w-2xl space-y-8">
      <browser-not-supported-banner v-if="browserNotSupported" />
      <already-signed-up-banner v-if="alreadySignedUp" />

      <form
        ref="form"
        action="/member_sign_ups"
        accept-charset="UTF-8"
        v-if="!alreadySignedUp"
        method="post"
      >
        <div class="stack-mt-12">
          <member-form-step
            :key="currentStep"
            :fields="this.stepFields(currentStep)"
          >
            <template #header>
              <member-sign-up-form-step-self-referral
                v-if="currentStep == 'hello' && completedEligibility"
                title="Get help from Beam 📝"
                :utmSourceIndeed="utmSourceIndeed"
              />
              <member-sign-up-form-step-hello
                v-else-if="currentStep == 'hello'"
                :title="formSteps[currentStep]"
                :intro-video-id="introVideoId"
              />
              <member-sign-up-form-step-data-consent
                v-else-if="currentStep == 'data_consent'"
                :title="formSteps[currentStep]"
                :lives-changed="livesChanged"
              />
              <member-sign-up-form-step-about-you-intro
                v-else-if="currentStep == 'about_you_intro'"
                :title="formSteps[currentStep]"
              />
              <member-sign-up-form-step-your-job-needs-intro
                v-else-if="currentStep == 'your_job_needs_intro'"
                :title="formSteps[currentStep]"
              />
              <member-sign-up-form-step-your-living-situation-intro
                v-else-if="currentStep == 'your_living_situation_intro'"
                :title="formSteps[currentStep]"
              />
              <member-sign-up-form-step-working-with-beam
                v-else-if="currentStep == 'working_with_beam'"
                :title="formSteps[currentStep]"
              />
              <h1 v-else class="beam-h1 pt-12 text-left">
                {{ formSteps[currentStep] }}
              </h1>
            </template>
          </member-form-step>
        </div>

        <div class="mt-4 space-y-8 pt-5">
          <member-form-errors
            v-if="formHasErrors"
            :error-fields="errorFields"
          ></member-form-errors>

          <div class="flex">
            <button
              @click.prevent="stepForward"
              type="submit"
              class="button button-primary inline-block px-16 py-3"
            >
              {{ buttonText }}
            </button>
          </div>

          <div
            class="flex cursor-pointer items-center text-center"
            v-if="!atFirstStep"
            @click="stepBack"
          >
            <icon class="mr-2 h-5 w-5" name="arrow-left"></icon>
            Back
          </div>
        </div>
      </form>

      <we-are-here-to-help
        v-if="currentStep == 'hello'"
        class="mt-8"
        :caseworkers="caseworkers"
      />
    </div>
    <support-icon />
  </main>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberSignUpFormConditions from "./MemberSignUpFormConditions"
export default {
  props: {
    completedEligibility: { type: Boolean },
    utmSourceIndeed: { type: Boolean },
    utmCampaign: { type: String },
    memberName: { type: String },
    alreadySignedUp: { type: Boolean },
    browserNotSupported: { type: Boolean },
    caseworkers: { type: Array },
    introVideoId: { type: String },
    livesChanged: { type: Number },
  },
  mixins: [MemberFormShared, MemberSignUpFormConditions],
  data() {
    return {
      tableName: "member_sign_up",
    }
  },
  computed: {
    // Overrides buttonText() from MemberFormShared.
    buttonText() {
      if (this.atFinalStep) {
        return this.saving ? "Submitting..." : "Submit"
      } else {
        return this.stepButtonText
      }
    },
    stepButtonText() {
      switch (this.currentStep) {
        case "hello":
          return "Sign up now"
        case "data_consent":
          return "Let's go!"
        default:
          return "Continue"
      }
    },
  },
  methods: {
    adjustDocumentTypeQuestion() {
      const docType = this.form.right_to_work_or_rent_document_type
      const employmentValue = this.form.employment_screener.value
      const lettingsValue = this.form.lettings_screener.value

      if (employmentValue === "Yes" && lettingsValue === "Yes") {
        docType.radio_options = docType.radio_options_per_type["work_and_rent"]
        docType.question = docType.questions["work_and_rent"]
      } else if (employmentValue === "Yes") {
        docType.radio_options = docType.radio_options_per_type["work_only"]
        docType.question = docType.questions["work_only"]
      } else if (lettingsValue === "Yes") {
        docType.radio_options = docType.radio_options_per_type["rent_only"]
        docType.question = docType.questions["rent_only"]
      }
    },

    adjustH1() {
      if (this.currentStep != "right_to_work_and_rent") {
        return
      }

      const employmentValue = this.form.employment_screener.value
      const lettingsValue = this.form.lettings_screener.value

      if (employmentValue === "Yes" && lettingsValue === "Yes") {
        this.formSteps[this.currentStep] = "Right to work and rent"
      } else if (employmentValue === "Yes") {
        this.formSteps[this.currentStep] = "Right to work"
      } else if (lettingsValue === "Yes") {
        this.formSteps[this.currentStep] = "Right to rent"
      }
    },
    adjustDocumentProofQuestion() {
      const docType = this.form.right_to_work_or_rent_document_type
      const rightToWorkProof = this.form.right_to_work_or_rent_proof

      if (this.fieldContains(docType, "Share code")) {
        rightToWorkProof.question = rightToWorkProof.questions["share_code"]
      } else if (this.fieldContains(docType, "BRP")) {
        rightToWorkProof.question =
          rightToWorkProof.questions["brp_or_eu_identity_card"]
      } else if (this.fieldContains(docType, "passport")) {
        rightToWorkProof.question = rightToWorkProof.questions["passport"]
      } else if (this.fieldContains(docType, "birth certificate")) {
        rightToWorkProof.question =
          rightToWorkProof.questions["birth_certificate"]
      } else if (this.fieldContains(docType, "Official document")) {
        rightToWorkProof.question =
          rightToWorkProof.questions["official_document"]
      }
    },
  },
  watch: {
    "form.employment_screener.value": function () {
      this.adjustDocumentTypeQuestion()
    },
    "form.lettings_screener.value": function () {
      this.adjustDocumentTypeQuestion()
    },
    "form.right_to_work_or_rent_document_type.value": function () {
      this.adjustDocumentProofQuestion()
    },
    currentStep: function () {
      this.adjustH1()
    },
    "form.careers_for_alerts.value": function () {
      // Set priority careers to the selected careers
      this.form.priority_career.image_select_options =
        this.form.careers_for_alerts.image_select_options.filter((option) =>
          this.form.careers_for_alerts.value.includes(option.id)
        )

      // Clear previously selected priority career (in case it was just hidden)
      this.form.priority_career.value = null
    },
  },
}
</script>
