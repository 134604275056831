<template>
  <div>
    <header>
      <h1 class="beam-h1">Get in touch</h1>
    </header>

    <main class="mt-8 sm:mt-12">
      <div class="beam-header-intro">
        If you have any feedback on what we are doing at Beam or just want to
        say hi, please either:
      </div>

      <ul class="mt-5 text-center text-sm sm:mt-8 sm:text-base">
        <li>
          <icon
            name="envelope"
            :solid="true"
            class="inline-block h-5 w-5 pt-1 text-pink-100"
          ></icon>
          &nbsp; Careers:
          <a class="text-azure-100" href="mailto:hello@beam.org" target="blank"
            >careers@beam.org</a
          >
        </li>
        <li>
          <icon
            name="envelope"
            :solid="true"
            class="inline-block h-5 w-5 pt-1 text-pink-100"
          ></icon>
          &nbsp; Referrals:
          <a class="text-azure-100" href="mailto:hello@beam.org" target="blank"
            >referrals@beam.org</a
          >
        </li>
        <li>
          <icon
            name="envelope"
            :solid="true"
            class="inline-block h-5 w-5 pt-1 text-pink-100"
          ></icon>
          &nbsp; General enquiries:
          <a class="text-azure-100" href="mailto:hello@beam.org" target="blank"
            >hello@beam.org</a
          >
        </li>
        <li class="mt-2">
          <twitter-icon
            class="inline-block h-5 w-5 pt-1 text-pink-100"
          ></twitter-icon>
          &nbsp; Tweet us:
          <a
            class="text-azure-100"
            href="https://twitter.com/wearebeam"
            target="blank"
            >@wearebeam</a
          >
        </li>
        <li class="mt-2">
          <icon
            name="phone"
            :solid="true"
            class="inline-block h-5 w-5 pt-1 text-pink-100"
          ></icon>
          &nbsp; Call us:
          <a class="text-azure-100" href="tel:02038193509">0203 819 3509</a>
        </li>
      </ul>

      <div class="beam-header-intro">
        If you're a journalist wanting to write a story, please head over to
        <a class="blue-link" href="/press">our media page</a> for more
        information.
      </div>

      <div class="mt-8 text-center sm:mt-10 lg:mt-16">
        <a
          href="https://help.beam.org"
          class="button button-secondary inline-block w-full px-0 py-3 xs:w-auto xs:px-16"
          target="blank"
          >See our full FAQs</a
        >
      </div>
    </main>
  </div>
</template>
