export default {
  methods: {
    showField(id) {
      let form = this.$parent.form ? this.$parent.form : this.form

      switch (id) {
        case "asylum_refugee":
          return (
            this.fieldStartsWith(form.birth_country, "eu") ||
            this.fieldStartsWith(form.birth_country, "not the uk or eu")
          )
        case "number_of_children":
          return this.fieldStartsWith(form.parent, "yes")
        case "children_live_with":
          return this.fieldStartsWith(form.parent, "yes")
        case "qualifications_other":
          return this.fieldContains(form.qualifications, "other")
        case "current_job":
        case "current_job_details":
        case "current_work_income":
          return this.fieldStartsWith(form.currently_working, "yes")
        case "work_interest":
        case "work_history":
          return this.fieldStartsWith(form.currently_working, "no")
        case "last_year_worked":
          return (
            this.fieldStartsWith(form.work_history, "yes") ||
            this.fieldStartsWith(form.currently_working, "yes")
          )
        case "income_other":
          return this.fieldContains(form.current_income, "other")
        case "savings_assets_warning":
          return this.fieldStartsWith(form.savings_assets_screener, "yes")
        case "current_benefits":
          return this.fieldMatches(form.current_income, /^benefits/i)
        case "universal_credit_total":
          return this.fieldContains(form.current_benefits, "universal credit")
        case "universal_credit_housing":
          return this.fieldContains(form.current_benefits, "universal credit")
        case "housing_benefit_total":
          return this.fieldContains(form.current_benefits, "housing benefit")
        case "dhp_total":
          return this.fieldContains(form.current_benefits, "dhp")
        case "child_benefit_total":
          return this.fieldContains(form.current_benefits, "child benefit")
        case "child_tax_credit_total":
          return this.fieldContains(form.current_benefits, "child tax credits")
        case "working_tax_credit_total":
          return this.fieldContains(
            form.current_benefits,
            "working tax credits"
          )
        case "pip_total":
          return this.fieldContains(form.current_benefits, "pip")
        case "income_support_total":
          return this.fieldContains(form.current_benefits, "income support")
        case "ssp_total":
          return this.fieldContains(form.current_benefits, "ssp")
        case "jsa_total":
          return this.fieldContains(form.current_benefits, "jsa")
        case "esa_total":
          return this.fieldContains(form.current_benefits, "esa")
        case "dla_total":
          return this.fieldContains(form.current_benefits, "dla")
        case "carers_allowance_total":
          return this.fieldContains(form.current_benefits, "Carer's allowance")
        case "debt_current_management":
        case "debt_future_management":
          return this.fieldContains(form.debt_arrears, "yes")
        case "mental_health_high_risk_details":
          return this.fieldStartsWith(form.mental_health_high_risk, "yes")
        case "physical_health_risk_management":
          return this.fieldStartsWith(form.physical_health_medication, "yes")
        case "mental_health_contact":
          return this.fieldStartsWith(form.mental_health_treatment, "yes")
        case "mental_health_prs_check":
          return (
            this.fieldStartsWith(form.mental_health_treatment, "yes") ||
            this.fieldStartsWith(form.mental_health_high_risk, "yes")
          )
        case "accessibility_details":
          return (
            this.fieldContains(
              form.accessibility_requirements,
              "I have accessibility requirements"
            ) ||
            this.fieldContains(
              form.accessibility_requirements,
              "Someone in my household has accessibility requirements"
            )
          )
        case "physical_health_details":
          return this.fieldStartsWith(form.physical_health_other, "yes")
        case "physical_health_medication":
        case "physical_health_risk_management":
        case "gp_contact_details":
          return (
            this.fieldStartsWith(form.physical_health_other, "yes") ||
            this.fieldContains(
              form.accessibility_requirements,
              "Someone in my household has accessibility requirements"
            ) ||
            this.fieldContains(
              form.accessibility_requirements,
              "I have accessibility requirements"
            )
          )
        case "substance_history":
          return (
            this.fieldStartsWith(form.drug_use, "yes") ||
            this.fieldStartsWith(form.alcohol_use, "yes")
          )
        case "substance_use":
          return (
            this.fieldStartsWith(form.drug_use, "yes") ||
            this.fieldStartsWith(form.alcohol_use, "yes")
          )
        case "substance_risk_management":
          return (
            this.fieldStartsWith(form.drug_use, "yes") ||
            this.fieldStartsWith(form.alcohol_use, "yes")
          )
        case "substance_support":
          return (
            this.fieldStartsWith(form.drug_use, "yes") ||
            this.fieldStartsWith(form.alcohol_use, "yes")
          )
        case "substance_contact":
          return this.fieldStartsWith(form.substance_support, "yes")
        case "gambling_details":
          return this.fieldStartsWith(form.gambling, "yes")
        case "gambling_risk_management":
          return this.fieldStartsWith(form.gambling, "yes")
        case "gambling_support":
          return this.fieldStartsWith(form.gambling, "yes")
        case "gambling_contact":
          return this.fieldStartsWith(form.gambling_support, "yes")
        case "offending_history":
          return this.fieldStartsWith(form.criminal_record, "yes")
        case "offending_risk_management":
          return this.fieldStartsWith(form.criminal_record, "yes")
        case "probation":
          return this.fieldStartsWith(form.criminal_record, "yes")
        case "probation_details":
          return this.fieldStartsWith(form.probation, "yes")
        case "risk_to_safety_details":
          return this.fieldStartsWith(form.personal_safety_risk, "yes")
        case "emergency_contact":
          return this.fieldStartsWith(form.emergency_contact_opt_in, "yes")
        case "r2r_reminder":
          return this.fieldStartsWith(form.r2r_check, "no")
        case "future_booking":
          return this.fieldStartsWith(form.future_session, "yes")
        default:
          return true
      }
    },
  },
}
