<template>
  <div
    :class="{
      'sticky top-0 block lg:mb-4 lg:hidden': sticky,
      'mb-8 lg:mb-4': showBottomMargin,
      'z-40': mobileMenuOpen,
      'z-20': !mobileMenuOpen,
    }"
  >
    <slot
      :showActions="showActions"
      :mobileMenuOpen="mobileMenuOpen"
      :openMobileMenu="openMobileMenu"
      :closeMobileMenu="closeMobileMenu"
      :nav-donate-href="navDonateHref"
    >
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    showActions: { type: Boolean },
    showBottomMargin: { type: Boolean, default: true },
    sticky: { type: Boolean, default: true },
    navDonateHref: { type: String },
  },
  data() {
    return {
      mobileMenuOpen: false,
    }
  },
  methods: {
    openMobileMenu() {
      this.mobileMenuOpen = true
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false
    },
  },
}
</script>
