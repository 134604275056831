<template>
  <div class="stack-mt-8">
    <header class="space-y-4 bg-pale-grey-100 pt-12">
      <div class="pb-2 text-5xl">💷</div>

      <h1 class="beam-h1 text-left">{{ title }}</h1>
    </header>

    <info-banner
      :header="'We help people into jobs first'"
      :body="'This is so you can get back on your feet as soon as possible. We can also fund training for you to do alongside work.'"
    />

    <div class="space-y-6 bg-white p-8 text-lg">
      <div class="flex items-center space-x-4">
        <img
          class="h-20 rounded-full bg-azure-100"
          :src="`/images/sign_up/mel.png`"
          alt="A headshot of Mel on a blue background"
        />
        <div>
          <div class="font-bold">Mel started a job as a Kitchen Assistant</div>
          “Beam has helped me with the skills that I need to shine.”
        </div>
      </div>
      <div class="flex items-center space-x-4">
        <img
          class="h-20 rounded-full bg-yellow-100"
          :src="`/images/sign_up/danny.png`"
          alt="A headshot of Danny on a yellow background"
        />
        <div>
          <div class="font-bold">Danny started a job in Construction</div>
          “I’m loving every minute of it and couldn’t have done it without you!”
        </div>
      </div>
      <div class="flex items-center space-x-4">
        <img
          class="h-20 rounded-full bg-pink-100"
          :src="`/images/sign_up/iman.png`"
          alt="A headshot of Iman on a pink background"
        />
        <div>
          <div class="font-bold">Iman started a job in Customer Service</div>
          “Starting work has given me so much confidence. I really enjoy my new
          role.”
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
  },
}
</script>
