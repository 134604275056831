<template>
  <div class="mx-auto max-w-2xl">
    <form
      ref="form"
      action="/member_past_presents"
      accept-charset="UTF-8"
      method="post"
    >
      <div class="stack-mt-12">
        <member-form-field
          v-for="field in form"
          :key="field.id"
          :field="field"
          v-model="field.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <case-note-section-intro />

      <div v-if="additional" class="stack-mt-12 mt-6">
        <member-form-field
          v-for="field in additional"
          :key="field.id"
          :field="field"
          v-model="field.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            v-if="memberSpecified"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberPastPresentConditions from "./MemberPastPresentConditions"
export default {
  mixins: [MemberFormShared, MemberPastPresentConditions],
  data() {
    return {
      tableName: "member_past_present",
      shouldConfirmReload: true,
    }
  },
  watch: {
    "form.member_id.value": {
      handler() {
        if (
          this.form?.member_id?.value &&
          !location.href.includes(`member_id=${this.form.member_id.value}`)
        ) {
          this.shouldConfirmReload = false
          location.href = `/member_past_presents/new?member_id=${this.form.member_id.value}`
        }
      },
      immediate: true,
    },
  },
  methods: {
    // Overriding this method so that when member ID changes we can stop it from preventing unload
    handleUnload(e) {
      if (this.shouldConfirmReload) {
        return MemberFormShared.methods.handleUnload.call(this, e)
      }
    },
  },
}
</script>
