<template>
  <transition name="fade">
    <div
      class="relative rounded border-2 border-dashed border-pale-lilac-100 bg-white px-8 py-12 text-center sm:p-12"
      v-if="!hidden"
    >
      <!-- Hide button -->
      <div
        class="absolute right-0 top-0 mr-4 mt-2 cursor-pointer text-xl text-azure-100"
        :data-label="notice.id"
        @click="hide"
      >
        <icon name="x-mark" class="h-6 w-6"></icon>
      </div>

      <div v-if="notice.title" class="mb-12 font-medium sm:text-lg lg:text-xl">
        {{ notice.title }}
      </div>

      <div
        class="markdown-wrapper text-sm sm:text-base"
        v-html="notice.formatted_subtitle"
      ></div>

      <!-- CTA button -->
      <div class="mt-12">
        <a
          :href="notice.button_path"
          :data-label="notice.id"
          class="button button-primary px-12 py-2 sm:py-3"
        >
          {{ notice.button_cta }}
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import AnalyticsMixin from "./mixins/AnalyticsMixin"
import LocalStorageMixin from "./mixins/LocalStorageMixin"

export default {
  mixins: [AnalyticsMixin, LocalStorageMixin],
  props: {
    notice: Object,
  },
  data: function () {
    return {
      hidden: true,
    }
  },
  methods: {
    show() {
      this.hidden = false
    },
    hide() {
      this.saveToStorage()

      this.hidden = true
    },
    saveToStorage() {
      let hiddenNotices = this.fetchHiddenNotices()

      hiddenNotices.push(this.notice.id)

      localStorage.setItem("hiddenNotices", JSON.stringify(hiddenNotices))
    },
  },
}
</script>
