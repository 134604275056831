<template>
  <div class="flex space-x-2">
    <!-- Date -->
    <select v-model="date" class="form-select sm:text-sm sm:leading-5">
      <option v-for="d in 31" :value="d" :key="d">
        {{ d }}
      </option>
    </select>

    <!-- Month -->
    <select v-model="month" class="form-select sm:text-sm sm:leading-5">
      <option v-for="m in months" :value="m" :key="m">
        {{ m }}
      </option>
    </select>

    <!-- Year -->
    <select v-model="year" class="form-select sm:text-sm sm:leading-5">
      <option v-for="y in years" :value="y" :key="y">
        {{ y }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { type: String },
  },
  emits: ["update:modelValue"],
  computed: {
    fullDate() {
      return `${this.date}/${this.month}/${this.year}`
    },
    years() {
      const startYear = new Date().getFullYear() + 1
      return Array.from({ length: 100 }, (value, index) => startYear - index)
    },
    months() {
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]
    },
    fullySelected() {
      return this.date && this.month && this.year
    },
  },
  data() {
    return {
      date: "",
      month: "",
      year: "",
    }
  },
  watch: {
    fullDate() {
      if (this.fullySelected) {
        this.$emit("update:modelValue", this.fullDate)
      }
    },
  },
  created() {
    if (this.modelValue) {
      let initialDate = new Date(this.modelValue)
      this.date = initialDate.getDate()
      this.month = this.months[initialDate.getMonth()]
      this.year = initialDate.getFullYear()
    }
  },
}
</script>
