<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-start bg-white p-5">
      <div class="mr-5 flex w-20 shrink-0 items-start">
        <img class="w-full rounded-full" :src="champion.avatarUrl" />
      </div>
      <div>
        <p class="mt-1">{{ champion.quote }}</p>
        <p class="mt-2">
          <span class="font-bold">{{ champion.name }}</span>
          <span class="font-bold text-gray-400" v-if="champion.subtitle">
            ·</span
          >
          {{ champion.subtitle }}
        </p>
      </div>
    </div>

    <company-champion-modal
      v-if="allowEdit"
      :name="`modal-company-champion-${championNumber}`"
      :champion-name="champion.name"
      :champion-subtitle="champion.subtitle"
      :champion-quote="champion.quote"
      :champion-avatar-url="champion.avatarUrl"
      :champion-number="championNumber"
      :form-url="formUrl"
      :authenticity-token="authenticityToken"
      @image-upload-success="handleImageUploadSuccess"
      @saved="handleChampionSaved"
    >
      <template #title
        ><h2 class="beam-h2 text-left">
          Edit champion {{ championNumber }}
        </h2></template
      >
    </company-champion-modal>
    <div
      v-if="allowEdit"
      class="button button-secondary mt-5 w-full cursor-pointer p-4"
      @click="$vfm.show(`modal-company-champion-${championNumber}`)"
    >
      Edit champion {{ championNumber }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    company: Object,
    championNumber: { type: String, default: "one" },
    allowEdit: { type: Boolean, default: false },
    authenticityToken: String,
    formUrl: String,
  },
  data() {
    return {
      champion: {},
    }
  },
  watch: {
    company(newValue) {
      this.setChampionData(newValue)
    },
  },
  methods: {
    handleImageUploadSuccess(imageUrl) {
      this.champion.avatarUrl = imageUrl
    },
    handleChampionSaved(formData) {
      this.setChampionData(formData)
    },
    setChampionData(company) {
      this.champion = {
        name: company[`champion_${this.championNumber}_name`],
        subtitle: company[`champion_${this.championNumber}_subtitle`],
        quote: company[`champion_${this.championNumber}_quote`],
        avatarUrl: company[`medium_champion_${this.championNumber}_avatar_url`],
      }
    },
  },
  created() {
    this.setChampionData(this.company)
  },
}
</script>
