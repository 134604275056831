<template>
  <carousel
    :items-to-show="3"
    :breakpoints="{
      320: { itemsToShow: 1 },
      480: { itemsToShow: 2 },
      1000: { itemsToShow: 3 },
    }"
  >
    <template #slides>
      <slide v-for="item in parsedItems" :key="item.id">
        <div class="mx-4 my-10 w-card xs:mx-auto lg:w-80">
          <campaign-card v-if="showCampaignCard" :campaign="item" />
          <fundraiser-card v-if="showFundraiserCard" :fundraiser="item" />
        </div>
      </slide>
    </template>
    <template #addons>
      <navigation>
        <template v-slot:prev>
          <icon
            name="arrow-left"
            class="hover-bounce h-8 w-8 text-azure-100"
          ></icon>
        </template>
        <template v-slot:next>
          <icon
            name="arrow-right"
            class="hover-bounce h-8 w-8 text-azure-100"
          ></icon>
        </template>
      </navigation>
      <pagination />
    </template>
  </carousel>
</template>

<script>
export default {
  props: {
    items: { type: Array },
    itemsType: {
      type: String,
      validator(value) {
        return ["campaign", "fundraiser"].includes(value)
      },
    },
  },
  data() {
    return {
      parsedItems: [],
    }
  },
  computed: {
    showCampaignCard() {
      return this.itemsType === "campaign"
    },
    showFundraiserCard() {
      return this.itemsType === "fundraiser"
    },
  },
  created() {
    this.parsedItems = this.items.map((item) => JSON.parse(item))
  },
  watch: {
    items() {
      this.parsedItems = this.items.map((item) => JSON.parse(item))
    },
  },
}
</script>
