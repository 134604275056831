<template>
  <transition name="slide-fade">
    <div
      v-if="open"
      class="center-absolute-x fixed bottom-0 mb-4 flex w-11/12 items-center justify-between rounded px-6 py-6 font-medium text-white shadow-md sm:max-w-2xl lg:max-w-4xl"
      :class="typeClasses"
    >
      <!-- Text: Raw HTML -->
      <div v-html="message"></div>

      <!-- Close icon -->
      <div @click="close" class="ml-4 cursor-pointer">
        <icon name="x-mark" class="h-6 w-6"></icon>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    defaultMessage: String,
    defaultType: String,
    defaultOpen: Boolean,
  },
  data() {
    return {
      open: this.defaultOpen,
      message: this.defaultMessage,
      type: this.defaultType,
    }
  },
  methods: {
    close() {
      this.open = false
      this.message = ""
      this.type = ""
    },
    openAlert(message, type) {
      this.open = true
      this.message = message
      this.type = type
    },
  },
  computed: {
    typeClasses() {
      if (this.type == "error") {
        return ["bg-pink-100"]
      } else {
        return ["bg-azure-100"]
      }
    },
  },
}
</script>
