export default {
  methods: {
    showField(id) {
      switch (id) {
        case "next_available":
          return this.fieldEquals(this.form.which_interview, "none")
        default:
          return true
      }
    },
  },
}
