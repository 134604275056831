<template>
  <div class="bg-white">
    <div
      class="flex space-x-4 border-2 border-azure-100 bg-azure-10 bg-opacity-5 p-4"
    >
      <icon
        name="information-circle"
        class="h-14 w-14 rounded-full text-azure-100"
      ></icon>
      <div class="space-y-2">
        <div class="text-xl font-bold">{{ header }}</div>
        <div>{{ body }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: { type: String },
    body: { type: String },
  },
}
</script>
