<template>
  <div>
    <div class="sticky top-0 z-10 flex bg-white font-bold sm:pb-6">
      <span
        @click="updateSelectedTab('current')"
        class="flex-grow cursor-pointer border-b-2 py-2 text-center"
        :class="{ 'border-azure-100 text-azure-100': selectedTab == 'current' }"
      >
        Current
      </span>
      <span
        @click="updateSelectedTab('past')"
        class="flex-grow cursor-pointer border-b-2 py-2 text-center"
        :class="{ 'border-azure-100 text-azure-100': selectedTab == 'past' }"
      >
        Past
      </span>
    </div>
    <div
      class="full-screen-mobile mb-10 bg-pale-grey-100 p-5 sm:rounded md:pb-10"
    >
      <template
        v-for="(job_applications, groupKey) in groupedApplications"
        :key="groupKey"
      >
        <div>
          <h2 class="mx-6 py-4 text-center text-xl font-bold">
            {{ groupKey }}
          </h2>

          <member-job-application-row
            v-for="job_application in job_applications"
            v-bind="job_application"
            :key="job_application.id"
            :member-id="memberId"
          >
          </member-job-application-row>
        </div>
      </template>

      <div class="bg-white py-16 text-center" v-if="showNoJobApplications">
        <span class="font-medium">
          <template v-if="selectedTab == 'current'">
            No current job applications - please speak to your caseworker!
          </template>
          <template v-else-if="selectedTab == 'past'">
            No past job applications found!
          </template>
        </span>
      </div>

      <div class="mt-8 text-center" v-if="!last_page">
        <button
          v-if="!loading"
          @click="fetchPage()"
          class="blue-link inline-block text-sm sm:text-base"
        >
          See more
        </button>

        <button
          v-if="loading"
          class="blue-link inline-block text-sm sm:text-base"
        >
          Loading...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import groupBy from "lodash.groupby"

export default {
  props: {
    currentStatuses: { type: Array, required: false },
    memberId: { type: Number, required: false },
    pastStatuses: { type: Array, required: false },
  },
  data: function () {
    return {
      groupedApplications: {},
      job_applications: [],
      page: 1,
      last_page: false,
      loading: false,
      selectedTab: "current",
    }
  },
  computed: {
    showNoJobApplications() {
      return !this.loading && this.job_applications.length == 0
    },
    statusesForFetch() {
      if (this.selectedTab == "current") {
        return this.currentStatuses
      } else if (this.selectedTab == "past") {
        return this.pastStatuses
      }
    },
    dataUrl() {
      return (
        "/member_job_applications" +
        qs.stringify(
          {
            member_id: this.memberId,
            page: this.page,
            status: this.statusesForFetch,
          },
          { addQueryPrefix: true, arrayFormat: "brackets" }
        )
      )
    },
  },
  created() {
    this.fetchPage()
  },
  methods: {
    updateSelectedTab(newTab) {
      this.selectedTab = newTab
      this.groupedApplications = {}
      this.job_applications = []
      this.page = 1
      this.last_page = false
      this.fetchPage()
    },
    fetchPage() {
      if (this.last_page) {
        return
      }

      this.loading = true

      this.axios.get(this.dataUrl).then((response) => {
        const data = response.data
        this.page += 1
        this.job_applications = this.job_applications.concat(
          data.job_applications
        )
        this.groupedApplications = this.groupApplications(this.job_applications)

        this.last_page = data.last_page
        this.loading = false
      })
    },
    groupApplications(arr) {
      return groupBy(arr, (application) => {
        return application.job.careers
      })
    },
  },
}
</script>
