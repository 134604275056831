<template>
  <slot name="title"><h4 class="beam-h4 mb-4 text-left">Leaderboard</h4></slot>
  <div class="flex flex-row">
    <div
      class="mb-4 mr-4 cursor-pointer"
      v-for="tabHeader in dataSetHeaders"
      :key="tabHeader"
    >
      <span
        class="border-yellow-100 pb-1 font-bold"
        :class="{ 'border-b-8': leaderboardTab == tabHeader }"
        @click="leaderboardTab = tabHeader"
      >
        {{ tabHeader }}
      </span>
    </div>
  </div>
  <div>
    <div
      class="mt-4 flex flex-col justify-between border-b border-gray-200"
      v-for="(item, index) in items"
      :key="item.email"
    >
      <!--
        If there's an item in the array which isn't in the top X,
        it is shown with an ellipsis above it
      -->
      <div v-if="index === outOfLeaderboardItemIndex" class="mb-1 h-5">
        <icon name="ellipsis-horizontal" class="h-5 w-5" />
      </div>
      <div
        class="flex flex-row justify-between"
        :class="{ 'font-bold text-azure-100': item.is_current }"
      >
        <div class="align-start flex-center flex flex-row">
          <fancy-link :href="item.link_url" v-if="item.link_url">{{
            labelFor(item)
          }}</fancy-link>
          <span v-else>{{ labelFor(item) }}</span>
          <span class="ml-2 inline-block">{{ emojiFor(item) }}</span>
        </div>
        <div>{{ item.score }}</div>
      </div>
    </div>
    <div v-if="items.length === 0">
      <p class="mt-4">It's looking quiet around here...</p>
      <p class="mt-4">Send a message to secure your place!</p>
    </div>
    <div
      class="mt-4 rounded-sm bg-gray-200 px-4 py-8 text-center"
      v-if="totalAmount !== null"
    >
      <div class="inline-block">
        <number-counter-group
          class="text-lg font-bold"
          :increment-amount="10"
          :number="totalAmount"
          :is-currency="false"
        ></number-counter-group>
      </div>
      <div class="ml-2 inline-block text-sm font-bold" v-if="totalAmountLabel">
        {{ totalAmountLabel }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataSets: { type: Object, default: {} },
    totalAmounts: { type: Object, default: {} },
    totalAmountLabels: { type: Object, default: {} },
    outOfLeaderboardItemIndex: { type: Number, default: 5 },
  },
  data() {
    return {
      leaderboardTab: null,
    }
  },
  computed: {
    dataSetHeaders() {
      return Object.keys(this.dataSets)
    },
    items() {
      if (Object.keys(this.dataSets).length === 0) {
        return []
      } else {
        return this.dataSets[this.leaderboardTab]
      }
    },
    totalAmount() {
      if (Object.keys(this.totalAmounts).length === 0) {
        return null
      } else {
        return this.totalAmounts[this.leaderboardTab]
      }
    },
    totalAmountLabel() {
      if (Object.keys(this.totalAmountLabels).length === 0) {
        return null
      } else {
        return this.totalAmountLabels[this.leaderboardTab]
      }
    },
  },
  watch: {
    dataSets() {
      this.setStartTab()
    },
  },
  methods: {
    setStartTab() {
      if (
        !this.leaderboardTab ||
        Object.keys(this.dataSets).indexOf(this.leaderboardTab) === -1
      ) {
        this.leaderboardTab = Object.keys(this.dataSets)[0]
      }
    },
    labelFor(item) {
      return item.name || item.email
    },
    emojiFor(item) {
      if (item.score == this.items[0].score) {
        return "🥇"
      } else if (this.items.length > 1 && item.score == this.items[1].score) {
        return "🥈"
      } else if (this.items.length > 2 && item.score == this.items[2].score) {
        return "🥉"
      }
    },
  },
  beforeMount: function () {
    this.setStartTab()
  },
}
</script>
