<template></template>

<script>
// Slides in at the bottom and is hideable
// Uses flash
export default {
  props: {
    message: String,
    timeout: Number,
  },
  mounted: function () {
    if (localStorage.getItem("slideBannerShown")) {
      return
    }
    this.$root.showFlash(this.message, "notice")

    localStorage.setItem("slideBannerShown", true)

    if (this.timeout) {
      setTimeout(() => {
        this.$root.closeFlash()
      }, this.timeout)
    }
  },
}
</script>
