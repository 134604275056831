<template>
  <vue-final-modal
    :name="name"
    @before-open="beforeOpen"
    :lock-scroll="true"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-8 text-sm sm:text-base max-w-xl max-h-full overflow-y-scroll"
  >
    <modal-close-button :name="name"></modal-close-button>

    <div v-if="loading" class="text-center">
      <spinner-icon class="inline-flex h-4 w-4" />
    </div>

    <div class="markdown-wrapper" v-html="content"></div>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
  },
  data() {
    return {
      content: "",
      loading: false,
      airtableName: "",
      open: false,
    }
  },
  methods: {
    beforeOpen(event) {
      if (
        this.content &&
        event.ref.params.value.airtableName == this.airtableName
      ) {
        return
      } else {
        this.content = ""
      }

      this.airtableName = event.ref.params.value.airtableName

      this.loading = true
      this.axios
        .get(`/airtable/popup?popup_name=${this.airtableName}`)
        .then((response) => {
          this.content = response.data.popup.content
          this.loading = false
        })
    },
  },
}
</script>
