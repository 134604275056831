<template>
  <div>
    <div v-if="!loading && payments.length">
      This is a record of all your donations, and how they were split amongst
      campaigns (we strive to be as fair as possible with split donations, but
      the amounts will not always be equal as they are rounded to the nearest
      pence). You can also
      <a class="blue-link" :href="csvDownloadUrl" download
        >download a CSV of your donation history</a
      >.
    </div>

    <div v-else-if="!loading">
      You have yet to make your first donation. Why not
      <fancy-link class="blue-link" href="/campaigns"
        >browse the campaigns</fancy-link
      >
      to make your first donation.
    </div>

    <!-- Date and Amount -->
    <template v-for="payment in payments" :key="payment.id">
      <div>
        <div class="mt-10 flex items-center justify-between">
          <div class="font-medium text-bluey-grey-100">{{ payment.date }}</div>
          <div class="flex items-center font-medium">
            {{ payment.amount }}
            <a
              :href="payment.stripe_receipt_url"
              target="blank"
              v-if="payment.stripe_receipt_url"
              class="ml-1"
            >
              <icon
                name="document-text"
                :solid="true"
                class="-mb-1 inline-block h-6 w-6 text-bluey-grey-100"
              ></icon>
            </a>
            <template v-if="payment.is_gift">Gift</template>
          </div>
        </div>

        <campaign-card-small
          v-for="donation in payment.donations"
          :key="donation.id"
          :member-name="donation.member_name"
          :stage-display="donation.stage_display"
          :slug="donation.slug"
          :avatar-url="donation.avatar_url"
          :is-withdrawn="donation.is_withdrawn"
        ></campaign-card-small>

        <div
          class="mt-3 text-sm text-bluey-grey-100"
          v-if="payment.beam_amount != '£0'"
        >
          including {{ payment.beam_amount }} support costs
        </div>
      </div>
    </template>

    <div class="mt-8 text-center" v-if="!last_page">
      <button
        v-if="!loading"
        @click="fetchPage()"
        class="blue-link inline-block text-sm sm:text-base"
      >
        See more
      </button>

      <button
        v-if="loading"
        class="blue-link inline-block text-sm sm:text-base"
      >
        Loading...
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    csvDownloadUrl: { type: String },
  },
  data: function () {
    return {
      payments: [],
      page: 1,
      last_page: false,
      loading: false,
    }
  },
  methods: {
    fetchPage() {
      if (this.last_page) {
        return
      }

      this.loading = true

      const _this = this
      this.axios
        .get(`/users/current/donation_history?page=${this.page}`)
        .then(function (response) {
          _this.page += 1
          _this.payments = _this.payments.concat(response.data.payments)
          _this.last_page = response.data.last_page
          _this.loading = false
        })
    },
  },
  created() {
    this.fetchPage()
  },
}
</script>
