export default {
  methods: {
    showField(id) {
      switch (id) {
        case "types_of_incident":
          return this.fieldEquals(this.thisForm.high_risk, true)
        default:
          return true
      }
    },
  },
}
