<template>
  <header class="full-screen-width bg-white px-5 pb-8 pt-12">
    <div class="mx-auto max-w-2xl space-y-4">
      <div class="text-left text-xl font-bold">You're in the right place!</div>

      <h1 class="beam-h1 text-left">
        Beam has helped {{ livesChanged.toLocaleString() }} people find a job or
        housing
      </h1>

      <div class="flex">
        <people-stack :people="exampleMembers" />
        <div class="flex items-center text-lg">
          + {{ nonExampleMembersCount }} more people
        </div>
      </div>
    </div>
  </header>

  <div class="mx-auto mt-4 max-w-2xl text-lg">
    To start working with you, we need to collect some basic information.
  </div>

  <div class="space-y-6 bg-white p-8 text-lg">
    <div class="flex items-center space-x-4">
      <div class="text-5xl">🧑</div>
      <div>
        <div class="font-bold">About you</div>
        Takes 2 minutes
      </div>
    </div>
    <div class="flex items-center space-x-4">
      <div class="text-5xl">💷</div>
      <div>
        <div class="font-bold">Your job needs</div>
        Takes 2 minutes
      </div>
    </div>
    <div class="flex items-center space-x-4">
      <div class="text-5xl">🏠</div>
      <div>
        <div class="font-bold">Your living situation</div>
        Takes 6 minutes
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    livesChanged: { type: Number },
  },
  data() {
    return {
      exampleMembers: [
        {
          id: 1,
          tiny_avatar_url: "/images/sign_up/abdullah.png",
          image_classes: "bg-azure-100",
        },
        {
          id: 2,
          tiny_avatar_url: "/images/sign_up/barry.png",
          image_classes: "bg-yellow-100",
        },
        {
          id: 3,
          tiny_avatar_url: "/images/sign_up/makeda.png",
          image_classes: "bg-pink-100",
        },
      ],
    }
  },
  computed: {
    nonExampleMembersCount() {
      return this.livesChanged - this.exampleMembers.length
    },
  },
}
</script>
