<template>
  <card
    :name="fundraiser.name"
    :photoUrl="fundraiser.thumb_photo_url"
    :percentageDonated="fundraiser.fundraised_percent"
    :donatedAmount="fundraiser.amount_raised_formatted"
    :target="fundraiser.goal_formatted"
    :remainingAmount="fundraiser.amount_remaining_formatted"
    :path="`/fundraisers/${fundraiser.slug}`"
  >
    <div class="h-40 p-5">
      <div class="text-xl font-medium">{{ fundraiser.name }}</div>
      <div
        class="fadey-snippet relative mt-4 overflow-hidden text-sm"
        style="height: 80px"
      >
        {{ fundraiser.snippet_display }}
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    fundraiser: {
      type: Object,
    },
  },
}
</script>
