<template>
  <div
    class="card-shadow card-hover-large mx-auto flex flex-col overflow-hidden rounded-lg bg-white"
    v-cloak
  >
    <a :href="link.toLowerCase()" class="flex flex-col">
      <div class="flex items-center justify-between p-5">
        <div class="mr-3 font-medium lg:text-xl">
          <span class="text-pink-100">{{ memberName }}</span>
          {{ sentence }}
        </div>

        <div class="flex-shrink-0">
          <img
            class="h-24 w-24 rounded-full"
            v-lazy="memberPhoto"
            :alt="memberName"
          />
        </div>
      </div>

      <progress-bar
        parent-classes="bg-yellow-200 h-2"
        :percent="percent"
        :show-amount="false"
      ></progress-bar>

      <div class="blue-link p-5 text-sm font-medium underline sm:text-base">
        Support {{ memberName }} today
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    memberPhoto: { type: String },
    memberName: { type: String },
    link: { type: String },
    sentence: { type: String },
    percent: { type: Number },
    snippet: { type: String },
  },
}
</script>
