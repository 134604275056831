<template>
  <div class="">
    <header>
      <h1 class="beam-h1">{{ headerText }}</h1>
    </header>

    <main>
      <section class="mt-8 sm:mt-16">
        <div class="mt-16 text-center" v-if="showLinks">
          <div>
            You can see who you've supported and how they're getting on by
            visiting your Impact Page.
          </div>
          <div class="mt-8">
            You can also chart your progress towards becoming a Super Beamer!
          </div>
          <div class="mt-8">
            <a :href="`/${userName}`" class="blue-link"
              >View your Impact page</a
            >
          </div>
        </div>

        <!-- Campaign link -->
        <div class="mt-8 text-center" v-if="campaign">
          <a :href="campaignLink" class="blue-link"
            >Back to {{ campaign.member_name }}'s campaign</a
          >
        </div>

        <!-- Fundraiser link -->
        <div class="mt-8 text-center" v-if="false">
          <a :href="fundraiserLink" class="blue-link"
            >Back to {{ fundraiser.name }}</a
          >
        </div>
      </section>
    </main>

    <thank-you-modal
      :twitter-share-url="twitterShareUrl"
      :whatsapp-share-url="whatsappShareUrl"
      :facebook-share-url="facebookShareUrl"
      :copy-share-url="copyShareUrl"
      :split-donation="splitDonation"
    >
    </thank-you-modal>
  </div>
</template>

<script>
import FacebookMixin from "./mixins/FacebookMixin"
import AnalyticsMixin from "./mixins/AnalyticsMixin"

export default {
  mixins: [FacebookMixin, AnalyticsMixin],
  props: {
    campaign: { type: Object },
    fundraiser: { type: Object },
    gift: { type: Object },
    subscription: { type: Object },
    twitterShareUrl: { type: String },
    whatsappShareUrl: { type: String },
    facebookShareUrl: { type: String },
    copyShareUrl: { type: String },
    userId: { type: Number },
    userName: { type: String },
    splitDonation: { type: Boolean },
  },
  created() {
    this.getFacebookSDK()

    this.logEvent("thankyou-view")

    this.maybeLogMonthlyDonation()
    this.maybeLogOneOffDonation()
    this.maybeLogGiftDonation()
  },
  computed: {
    showLinks() {
      return this.userName
    },
    campaignLink() {
      return `/campaigns/${this.campaign.slug}`
    },
    fundraiserLink() {
      return `/fundraisers/${this.fundraiser.slug}`
    },
    headerText() {
      return "Thank you!"
    },
  },
  mounted() {
    this.$vfm.show("modal-thank-you", {
      campaign: this.campaign,
      gift: this.gift,
      subscription: this.subscription,
      fundraiser: this.fundraiser,
    })
  },
}
</script>
