<template>
  <div ref="logo_bar" class="animate-bar h-full w-0 bg-yellow-100"></div>
</template>

<script>
export default {
  methods: {
    setWidthAndAnimate() {
      this.$refs.logo_bar.classList.remove("w-0")
      this.$refs.logo_bar.classList.add("w-full")
    },
  },
  mounted: function () {
    this.setWidthAndAnimate()
  },
}
</script>
