<template>
  <div
    class="full-screen-mobile mt-8 bg-pale-grey-100 px-5 py-10 sm:rounded sm:px-10"
  >
    <campaign-message
      v-for="message in messages"
      v-bind="message"
      :key="message.id"
    ></campaign-message>

    <div class="bg-white py-16 text-center" v-if="noMessages">
      <span class="font-medium">No supporters so far!</span>

      <div class="mt-8">
        Why not <a :href="donateLink" class="blue-link">be the first?</a>
      </div>
    </div>

    <div class="mt-8 text-center" v-if="!finished">
      <button
        v-if="!loading"
        @click="fetchPage()"
        class="blue-link inline-block text-sm sm:text-base"
      >
        See more
      </button>

      <button
        v-if="loading"
        class="blue-link inline-block text-sm sm:text-base"
      >
        Loading...
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaignId: { type: Number, required: false },
    fundraiserId: { type: Number, required: false },
    donateLink: { type: String, required: true },
  },
  data: function () {
    return {
      messages: [],
      page: 1,
      finished: false,
      loading: false,
    }
  },
  computed: {
    noMessages() {
      return this.messages.length == 0
    },
    dataUrl() {
      let url = ""

      if (this.fundraiserId) {
        url = `/fundraisers/${this.fundraiserId}/supporter_messages?page=${this.page}`
      } else {
        url = `/campaigns/${this.campaignId}/supporter_messages?page=${this.page}`
      }

      return url
    },
  },
  created() {
    this.fetchPage()
  },
  methods: {
    fetchPage() {
      if (this.finished) {
        return
      }

      this.loading = true

      this.axios.get(this.dataUrl).then((response) => {
        const data = response.data
        this.page += 1
        this.messages = this.messages.concat(data.messages)

        this.finished = data.finished
        this.loading = false
      })
    },
  },
}
</script>
