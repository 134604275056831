<template>
  <div
    @click="toggleDescription()"
    class="mb-3 cursor-pointer rounded bg-white p-5 shadow sm:p-8"
  >
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <img :src="image" :alt="name" class="h-12 w-12 rounded" />
        <div class="ml-4">
          <div class="text-sm sm:text-base">{{ name }}</div>
          <div class="mt-1 text-xs text-bluey-grey-100 sm:text-sm">
            {{ type }}
          </div>
        </div>
      </div>
      <div
        class="text-azure-100"
        :class="{ hidden: descriptionOpen, block: !descriptionOpen }"
      >
        <icon name="chevron-down" class="inline-block h-5 w-5 pt-1"></icon>
      </div>
      <div
        class="text-azure-100"
        :class="{ block: descriptionOpen, hidden: !descriptionOpen }"
      >
        <icon name="chevron-up" class="inline-block h-5 w-5 pt-1"></icon>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="descriptionOpen" class="mt-8 text-sm">
        <slot name="description"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import AnalyticsMixin from "./mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: ["name", "type", "description", "image"],
  data: function () {
    return {
      descriptionOpen: false,
    }
  },
  methods: {
    toggleDescription: function () {
      this.descriptionOpen = !this.descriptionOpen
    },
  },
}
</script>
