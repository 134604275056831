<template>
  <div class="mx-auto max-w-2xl">
    <form ref="form" action="/milestones" accept-charset="UTF-8" method="post">
      <div class="stack-mt-12">
        <member-form-field
          v-for="field in form"
          :key="field.id"
          :field="field"
          v-model="field.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MilestoneConditions from "./MilestoneConditions"
export default {
  mixins: [MemberFormShared, MilestoneConditions],
  data() {
    return {
      tableName: "milestone",
      currentId: 1,
      initialMilestoneAttachmentsRequired: null,
    }
  },
  created() {
    this.initialMilestoneAttachmentsRequired =
      this.form.milestone_attachments.required
  },
  methods: {
    validateField(field) {
      // Validation specifically for milestone date on non-housing outcomes
      if (field.id == "milestone_date" && !this.housingMilestone()) {
        const date = new Date(field.value)
        if (date > new Date()) {
          field.error_text = "Please enter a date in the past."
          this.errorFields.push(field)
        }
      }

      MemberFormShared.methods.validateField.call(this, field)
    },
    // If it's a work milestone, we do want to require
    // attachments. If it's not, we want to reset it to the
    // initial value.
    // We also need to handle updating the milestone attributes
    // from the server when the milestone type changes.
    handleChangedMilestoneType() {
      if (this.form.milestone_type.value == "Work Milestone") {
        this.form.milestone_attachments.required = true
      } else {
        this.form.milestone_attachments.required =
          this.initialMilestoneAttachmentsRequired
      }
      this.updateMilestoneAttributes()
    },
    updateMilestoneAttributes() {
      if (!this.form.member_id.value || !this.form.milestone_type.value) {
        // TODO: Remove this
        return
      }

      const params = {
        member_id: this.form.member_id.value,
        milestone_type: this.form.milestone_type.value,
      }

      const typeLowerCase = this.form.milestone_type.value.toLowerCase()

      if (typeLowerCase.includes("work")) {
        params.work_status = this.form.work_status.value
      } else if (typeLowerCase.includes("housing")) {
        params.housing_status = this.form.housing_status.value
      }

      // Clear all errors
      this.form.milestone_attribute_id.error_text = ""
      this.errorFields = []

      // Make a request to beamadmin/milestone_attributes with the params
      // and update the milestone_attribute_id field with the response

      this.axios
        .get("/beamadmin/milestone_attributes", { params })
        .then((response) => {
          this.form.milestone_attribute_id.select_options = response.data
          // If it's empty, show error text for that field
          if (response.data.length == 0) {
            this.form.milestone_attribute_id.error_text =
              "No remaining options are available for this milestone type."
            this.errorFields.push(this.form.milestone_attribute_id)
          }
        })

      // TODO: Remove this
      this.form.milestone_attribute_id.select_options = [
        {
          label: `Options for ${this.form.milestone_type.value} with current ID`,
          sublabel: `The options will go here`,
          value: 1,
        },
      ]
    },
  },
  // Watch the member_id, milestone_type, work_status and housing_status fields, and if they change then run the updateMilestoneAttributes method
  watch: {
    "form.member_id.value": "updateMilestoneAttributes",
    "form.milestone_type.value": "handleChangedMilestoneType",
    "form.work_status.value": "updateMilestoneAttributes",
    "form.housing_status.value": "updateMilestoneAttributes",
  },
}
</script>
