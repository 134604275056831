<template>
  <div>
    <header>
      <template v-if="hasLiveCampaigns">
        <h1 class="beam-h1">
          {{ headerTitle }}
        </h1>

        <!-- Intro -->
        <div>
          <div v-if="!isBlackHistoryMonthPage" class="beam-header-intro">
            Our campaigns provide funding for people to get new jobs and homes.
            So far,
            <a href="/campaigns/funded" class="blue-link"
              >{{ livesChangedCount }} people</a
            >
            have started new careers and found secure housing through Beam.
          </div>

          <div v-if="isBlackHistoryMonthPage" class="beam-header-intro">
            To mark #BlackHistoryMonth and #WorldHomelessDay on 10 October, we
            wanted to highlight the link between race and homelessness.
          </div>

          <div v-if="isBlackHistoryMonthPage" class="beam-header-intro">
            Did you know that
            <a
              class="blue-link"
              target="_blank"
              href="https://www.bigissue.com/news/housing/black-people-are-more-than-three-times-as-likely-to-experience-homelessness/"
              >black people are three times as likely to experience homelessness
              (Govt data, 2020)</a
            >? The impact of discrimination and institutional racism mean that
            black people are the most likely to be in vulnerable housing and
            financial situations. We want to be part of the solution. You can be
            too.
          </div>

          <div v-if="isBlackHistoryMonthPage" class="beam-header-intro">
            So far, {{ livesChangedCount }} people from black backgrounds have
            started new careers and found secure housing through Beam. Here's
            <a
              href="/campaigns/regina-dental-nurse-training"
              class="blue-link"
              target="_blank"
              >Regina's story</a
            >. And
            <a
              href="/campaigns/regina-dental-nurse-training"
              class="blue-link"
              target="_blank"
              >Decoda's</a
            >. And
            <a
              href="/campaigns/regina-dental-nurse-training"
              class="blue-link"
              target="_blank"
              >Tony's</a
            >.
          </div>

          <div class="beam-header-intro" v-html="peopleLinkHeading"></div>
        </div>
      </template>

      <template v-else>
        <h1 class="beam-h1">All of our campaigns have been funded!</h1>

        <div class="beam-header-intro">
          We will have more campaigns live very soon. Until then, you can still
          <a href="/donate" class="blue-link">make a donation</a> and it will be
          allocated to the next campaign on the website.
        </div>

        <div class="mt-8 text-center">
          <a
            href="/donate"
            class="button button-primary inline-block w-full px-0 py-3 xs:w-auto xs:px-16"
            >Donate</a
          >
        </div>

        <div v-if="!isBlackHistoryMonthPage" class="beam-header-intro">
          Our campaigns provide funding for people to get new jobs and homes. So
          far,
          <a href="/campaigns/funded" class="blue-link"
            >{{ livesChangedCount }} people</a
          >
          have started new careers and found secure housing through Beam.
        </div>

        <div v-if="isBlackHistoryMonthPage" class="beam-header-intro">
          Our campaigns provide funding for people to get new jobs and homes. So
          far, {{ livesChangedCount }} people of Black heritage have started new
          careers and found secure housing through Beam.
        </div>

        <main class="my-10 sm:my-20 lg:flex">
          <div
            class="full-screen-mobile flex flex-col flex-wrap items-center justify-around bg-pale-grey-100 px-5 py-10 sm:w-full sm:flex-row sm:rounded sm:px-10 sm:py-10"
          >
            <div
              v-for="campaign in campaigns"
              :key="campaign.id"
              class="mt-5 w-card lg:w-80"
            >
              <campaign-card :campaign="campaign"></campaign-card>
            </div>
          </div>
        </main>
      </template>
    </header>

    <main v-if="hasLiveCampaigns" class="my-10 sm:my-20 lg:flex">
      <!-- Campaigns Outer -->
      <div
        class="full-screen-mobile flex flex-col flex-wrap items-center justify-around bg-pale-grey-100 px-5 py-10 sm:w-full sm:flex-row sm:rounded sm:px-10 sm:py-10"
      >
        <!-- Individual Campaign Card -->
        <div
          v-for="(campaign, index) in campaigns"
          :key="campaign.id"
          class="mt-5 w-card lg:w-80"
        >
          <campaign-card :campaign="campaign"></campaign-card>

          <div
            v-if="index == 3"
            class="card-shadow mt-5 w-card rounded-lg bg-white p-8 lg:hidden"
          >
            <campaigns-split-donation></campaigns-split-donation>
          </div>
        </div>
      </div>

      <!-- General Donation Desktop -->
      <aside
        class="sticky top-0 ml-12 hidden h-full w-64 shrink-0 px-2 lg:block"
      >
        <campaigns-split-donation class="my-10"></campaigns-split-donation>
      </aside>
    </main>

    <div class="mb-24">
      <faq-section page-type="Campaigns Page" :show-link="true"></faq-section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaigns: { type: Array },
    livesChangedCount: { type: Number },
    gender: { type: String },
    refugees: { type: Boolean },
    hasLiveCampaigns: { type: Boolean },
    isBlackHistoryMonthPage: { type: Boolean, default: false },
  },
  computed: {
    headerTitle() {
      let word = null

      const heritage = this.isBlackHistoryMonthPage
        ? ` from a Black background`
        : ""

      if (this.gender == "female" && this.campaigns.length == 1) {
        word = "woman needs"
      } else if (this.gender == "female" && this.campaigns.length != 1) {
        word = "women need"
      } else if (this.refugees == true && this.campaigns.length == 1) {
        word = "refugee needs"
      } else if (this.refugees == true && this.campaigns.length != 1) {
        word = "refugees need"
      } else if (this.campaigns.length == 1) {
        word = `person${heritage} needs`
      } else {
        word = `people${heritage} need`
      }

      return `Some people who need your support today`
    },
    peopleLinkHeading() {
      let linkList = `${this.peopleLink(this.campaigns[0])}`
      if (this.campaigns.length > 2) {
        linkList += `, ${this.peopleLink(
          this.campaigns[1]
        )} and ${this.peopleLink(this.campaigns[2])}`
      } else if (this.campaigns.length === 2) {
        linkList += ` and ${this.peopleLink(this.campaigns[1])}`
      }
      if (this.refugees) {
        return `By donating to people like ${linkList}, your money will reach refugees living in Cambridge, and support them towards a brighter future.`
      } else {
        return `You can give people like ${linkList} a brighter future today.`
      }
    },
  },
  methods: {
    peopleLink(campaign) {
      return `<a href="/campaigns/${campaign.slug}" class='blue-link'>${campaign.member_name}</a>`
    },
  },
}
</script>
