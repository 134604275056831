<template>
  <div
    class="flex items-center justify-between text-xs sm:text-sm"
    :class="{ 'mt-1 sm:mt-2': !firstChild }"
  >
    <div>
      <a
        :href="comment.user_link"
        class="inline-block font-medium"
        :class="{
          'blue-link': !comment.member_id,
          'text-pink-100': comment.member_id,
        }"
        v-if="comment.user_link"
        >{{ comment.name }}
      </a>
      <span class="text-bluey-grey-100" v-else>{{ comment.name }}</span>
      <span class="ml-1">{{ comment.comment_text }}</span>
    </div>

    <div>
      <span v-if="comment.is_thanked">
        <icon
          name="heart"
          :solid="true"
          class="mr-1 inline-block h-5 w-5 pt-1 text-pink-100"
          title="Thanked"
        ></icon>
      </span>

      <span
        v-if="showDelete"
        @click="deleteComment(comment)"
        title="Delete Comment"
      >
        <icon
          name="x-circle"
          class="inline-block h-5 w-5 cursor-pointer pt-1"
        ></icon>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: { type: Object },
    index: { type: Number },
  },
  methods: {
    deleteComment(comment) {
      this.$emit("delete-comment", comment.id)
    },
  },
  computed: {
    firstChild() {
      return this.index == 0
    },
    showDelete() {
      if (!gon.current_user) {
        return false
      }
      return this.comment.user_id == gon.current_user.id
    },
  },
}
</script>
