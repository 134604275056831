<template>
  <div>
    <header>
      <h1 class="beam-h1">Press enquiries</h1>

      <div class="beam-header-intro">
        Interested in covering Beam or the stories of the inspiring people we
        support? Get in touch at
        <a class="blue-link" href="mailto:montana@beam.org" target="blank"
          >media@beam.org</a
        >.
      </div>
    </header>

    <main>
      <div class="beam-header-intro">
        You're welcome to
        <a
          href="https://www.dropbox.com/sh/wjcepwwh096i6zq/AABpD4V2q6PW0bKT0Jb4tcCja?dl=0"
          class="blue-link"
          target="blank"
          >download our logo and photos of the people we support</a
        >, showing their inspiring journeys out of homelessness.
      </div>

      <div class="mt-8 flex flex-col justify-center sm:flex-row">
        <img
          class="mx-4 hidden h-48 rounded sm:block"
          :src="`/media/christianah.jpg`"
          alt=""
        />
        <img
          class="mx-4 hidden h-48 rounded sm:block"
          :src="`/media/members-in-training.jpg`"
          alt=""
        />
        <img
          class="mx-auto w-4/5 rounded sm:mx-4 sm:h-48 sm:w-auto"
          :src="`/media/javad.jpg`"
          alt=""
        />
      </div>
    </main>
  </div>
</template>
