<template>
  <div class="mb-5">
    <div
      class="flex cursor-pointer items-center justify-between text-xl font-medium sm:text-2xl"
      @click="toggleMenuItem()"
    >
      <span>{{ title }}</span>
      <div v-show="!menuOpen">
        <icon name="chevron-down" class="h-8 w-8 pt-1"></icon>
      </div>
      <div v-show="menuOpen">
        <icon name="chevron-up" class="h-8 w-8 pt-1"></icon>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="menuOpen">
        <nav class="ml-8 mt-6">
          <ul>
            <slot name="links"></slot>
          </ul>
        </nav>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data: function () {
    return {
      menuOpen: false,
    }
  },
  methods: {
    toggleMenuItem: function () {
      this.menuOpen = !this.menuOpen
    },
  },
}
</script>
