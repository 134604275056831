<template>
  <div>
    <slot
      :showActions="showActions"
      :openParentMenu="openParentMenu"
      :nav-donate-href="navDonateHref"
    >
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    showActions: { type: Boolean },
    navDonateHref: { type: String },
  },
  methods: {
    openParentMenu() {
      this.$emit("open-mobile-menu")
    },
  },
}
</script>
