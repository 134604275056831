<template>
  <vue-final-modal
    name="modal-video-feedback"
    @before-open="beforeOpen"
    @closed="closed"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md max-w-6xl overflow-hidden"
  >
    <div class="aspect-h-9 aspect-w-16 relative h-0">
      <div
        class="flex flex-col items-center justify-center px-4"
        width="560"
        height="315"
      >
        <h2 class="text-center text-xl font-bold">
          Thank you for watching the video!
        </h2>
        <p class="text-center text-xl">Did you find it useful?</p>
        <div class="flex justify-center">
          <button class="rounded-full px-4 py-2 font-bold" @click="onYes">
            <icon name="hand-thumb-up" class="h-16 w-16 text-green-500"></icon>
            Yes
          </button>
          <button
            class="gtm-video-feedback-no ml-4 rounded-full px-4 py-2 font-bold"
            @click="onNo"
          >
            <icon name="hand-thumb-down" class="h-16 w-16 text-red-500"></icon>
            No
          </button>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      actionId: null,
      sessionStartTime: null,
      sessionEndTime: null,
      videoId: "",
      videoTitle: "",
      beforeUnloadListener: null,
      feedbackRecorded: false,
    }
  },
  methods: {
    beforeOpen(event) {
      const { actionId, videoId, sessionStartTime, sessionEndTime } =
        event.ref.params.value
      this.actionId = actionId
      this.videoId = videoId
      this.sessionStartTime = sessionStartTime
      this.sessionEndTime = sessionEndTime
      this.beforeUnloadListener = window.addEventListener(
        "beforeunload",
        this.sendSessionCreateRequest
      )
    },
    onYes() {
      this.sendSessionCreateRequest("helpful")
    },
    onNo() {
      this.sendSessionCreateRequest("unhelpful")
    },
    closed() {
      this.sendSessionCreateRequest()
      this.feedbackRecorded = false
      this.actionId = null
      this.videoId = null
      this.sessionStartTime = null
      this.sessionEndTime = null
      this.beforeUnloadListener = null
    },
    sendSessionCreateRequest(feedback_response) {
      window.removeEventListener("beforeunload", this.sendSessionCreateRequest)
      if (this.feedbackRecorded) {
        return
      }
      this.beforeUnloadListener = null
      this.axios
        .post("/member_video_sessions", {
          member_video_session: {
            member_action_id: this.actionId,
            video_id: this.videoId,
            started_at: this.sessionStartTime,
            ended_at: this.sessionEndTime,
            feedback_response: feedback_response,
          },
        })
        .then(() => {
          this.open = false
          this.feedbackRecorded = true
        })
        .catch((error) => {
          console.error(error)
          this.open = false
        })
    },
  },
}
</script>
