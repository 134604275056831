<template>
  <div
    :class="calculatedClass"
    class="rounded bg-white p-5 sm:mx-4 sm:w-5/12 md:p-8 lg:mx-8"
  >
    <div>
      <!-- Logo -->
      <template v-if="testimonial.logo">
        <img
          class="mb-5 h-8 lg:h-12"
          v-lazy="testimonial.logo"
          :alt="testimonial.logoAlt"
        />
      </template>

      <div class="text-pink-100">
        <quote-icon class="inline-block h-4 w-5"></quote-icon>
      </div>

      <div
        v-html="testimonial.description"
        class="markdown-testimonial mt-1 text-sm lg:text-base"
      ></div>
    </div>

    <div class="mt-4 flex items-center">
      <!-- Photo -->
      <template v-if="testimonial.photo">
        <a v-if="testimonial.link" :href="testimonial.link" target="_blank">
          <img
            class="mr-4 w-16 rounded-full"
            :class="{ 'lg:w-56': largePhoto, 'lg:w-20': !largePhoto }"
            v-lazy="testimonial.photo"
            :alt="testimonial.name"
          />
        </a>
        <img
          v-else
          class="mr-4 w-16 rounded-full"
          :class="{ 'lg:w-56': largePhoto, 'lg:w-20': !largePhoto }"
          v-lazy="testimonial.photo"
          :alt="testimonial.name"
        />
      </template>

      <div>
        <div class="mt-2 text-sm font-medium">
          <a
            v-if="testimonial.link"
            :href="testimonial.link"
            target="_blank"
            class="blue-link"
          >
            {{ testimonial.name }}
          </a>
          <div v-else>
            {{ testimonial.name }}
          </div>
        </div>

        <div class="text-sm text-bluey-grey-100">
          {{ testimonial.subtitle }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    testimonial: { type: Object },
    index: { type: Number },
    largePhoto: { type: Boolean, default: false },
    dropShadow: { type: Boolean, default: false },
  },
  computed: {
    calculatedClass() {
      const classes = ["mt-8"]

      if (this.index < 2) {
        classes.push("md:mt-0")
      }

      if (this.dropShadow) {
        classes.push("card-shadow")
      }

      return classes.join(" ")
    },
  },
}
</script>
