<template>
  <vue-final-modal
    :name="name"
    @before-open="beforeOpen"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-8 sm:p-10 max-w-md"
  >
    <modal-close-button :name="name"></modal-close-button>

    <sign-in-form :user-source="userSource">
      <template #google-sign-in-button>
        <slot name="google-sign-in-button"></slot>
      </template>
    </sign-in-form>
  </vue-final-modal>
</template>

<script>
import AnalyticsMixin from "../mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    companyId: { type: Number },
  },
  data: function () {
    return {
      name: "modal-sign-in",
      userSource: null,
      open: false,
    }
  },
  methods: {
    beforeOpen(event) {
      this.logEvent("sign-in-view")

      this.userSource = event.ref.params.value.userSource
    },
    beforeClose(event) {
      // Reset the data
      Object.assign(this.$data, this.$options.data.apply(this))
    },
  },
}
</script>
