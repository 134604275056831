export default {
  methods: {
    showField(id) {
      let form = this.thisForm

      switch (id) {
        case "member_present":
          return this.joinedOrOutcome()
        case "beam_experience":
          return this.memberIsPresent()
        case "member_still_in_work":
          return (
            this.memberIsPresent() &&
            this.fieldContains(form.beam_services, "jobs") &&
            this.fieldStartsWith(form.stage_reached, "achieved")
          )
        case "job_end_reason":
          return (
            this.memberIsPresent() &&
            this.fieldStartsWith(form.member_still_in_work, "no")
          )
        case "add_work_milestone":
          return (
            this.fieldContains(form.beam_services, "jobs") &&
            this.fieldStartsWith(form.stage_reached, "achieved")
          )
        case "no_work_start_reasons":
          return (
            this.memberIsPresent() &&
            this.fieldContains(form.beam_services, "jobs") &&
            !this.fieldStartsWith(form.stage_reached, "achieved")
          )
        case "future_interest":
          return (
            this.memberIsPresent() &&
            !this.fieldStartsWith(form.stage_reached, "achieved")
          )
        case "action_plan":
          return (
            this.memberIsPresent() &&
            this.fieldStartsWith(form.future_interest, "yes")
          )
        case "job_review":
          return (
            this.memberIsPresent() &&
            this.fieldStartsWith(form.member_still_in_work, "yes")
          )
        case "job_pay":
          return (
            this.memberIsPresent() &&
            this.fieldStartsWith(form.member_still_in_work, "yes")
          )
        case "lettings_review":
        case "rent_arrears":
          return (
            this.memberIsPresent() &&
            this.fieldContains(form.beam_services, "homes") &&
            !this.fieldStartsWith(form.stage_reached, "achieved")
          )
        case "rent_arrears_amount":
          return (
            this.memberIsPresent() &&
            this.fieldStartsWith(form.rent_arrears, "yes")
          )
        case "supporter_update":
          return (
            this.memberIsPresent() &&
            this.fieldStartsWith(form.member_still_in_work, "yes")
          )
        case "member_claiming_benefits":
          return this.joinedOrOutcome()
        case "member_benefits":
          return this.fieldStartsWith(form.member_claiming_benefits, "yes")
        case "universal_credit":
          return this.fieldContains(form.member_benefits, "universal credit")
        case "housing_benefit":
          return this.fieldContains(form.member_benefits, "housing benefit")
        case "discretionary_housing_payment":
          return this.fieldContains(form.member_benefits, "dhp")
        case "child_benefit":
          return (
            this.fieldContains(form.member_benefits, "child tax credits") ||
            this.fieldContains(form.member_benefits, "child benefit")
          )
        case "working_tax_credits":
          return this.fieldContains(form.member_benefits, "working tax credits")
        case "income_support":
          return this.fieldContains(form.member_benefits, "income support")
        case "ssp":
          return this.fieldContains(form.member_benefits, "ssp")
        case "jsa":
          return this.fieldContains(form.member_benefits, "jsa")
        case "esa":
          return this.fieldContains(form.member_benefits, "esa")
        case "campaigns_page":
          return this.joinedOrOutcome()
        case "budget_items_remaining":
          return this.fieldStartsWith(form.campaigns_page, "yes")
        case "housing_status_at_referral":
          return this.joinedOrOutcome()
        case "moved_on_from_homelessness":
          return this.fieldStartsWith(
            form.housing_status_at_referral,
            "homeless"
          )
        case "add_moved_on_milestone":
          return this.fieldStartsWith(form.moved_on_from_homelessness, "yes")
        case "moved_into_temp_accomm":
          return this.fieldStartsWith(
            form.housing_status_at_referral,
            "at risk"
          )
        case "prevention_milestone_due":
          return this.fieldStartsWith(form.moved_into_temp_accomm, "no")
        case "add_prevention_milestone":
          return (
            (this.fieldStartsWith(form.moved_into_temp_accomm, "no") &&
              this.fieldStartsWith(form.prevention_milestone_due, "yes")) ||
            (this.fieldStartsWith(form.moved_into_temp_accomm, "no") &&
              this.fieldStartsWith(form.member_still_in_work, "yes"))
          )
        case "budget_items_actions":
          return this.fieldStartsWith(form.budget_items_remaining, "yes")
        case "milestones_added":
          return this.fieldStartsWith(form.campaigns_page, "yes")
        case "campaigns_page_consent":
          return (
            this.fieldStartsWith(form.campaigns_page, "yes") &&
            this.fieldStartsWith(form.milestones_added, "yes")
          )
        case "service_signposted_to":
          return !this.fieldStartsWith(form.stage_reached, "achieved")
        case "safety_log_reason":
          return this.fieldContains(form.failure_reason, "risk report")
        case "ineligibility_reason":
          return this.fieldContains(form.failure_reason, "not currently ready")
        case "decline_reason":
          return this.fieldContains(form.failure_reason, "declined beam")
        case "failed_to_start_work_reason":
          return this.fieldContains(form.failure_reason, "failed to start work")
        case "failed_to_find_housing_reason":
          return this.fieldContains(
            form.failure_reason,
            "failed to find housing"
          )
        case "not_interested_in_beam_job":
          return this.fieldContains(form.failure_reason, "beam-supported job")
        case "failure_reason":
          return (
            this.fieldNotEmpty(form.beam_services) &&
            this.fieldNotEmpty(form.stage_reached) &&
            !this.fieldStartsWith(form.stage_reached, "achieved")
          )
        case "failure_reason_other":
          return this.fieldStartsWith(form.milestones_added, "no")
        case "withdraw_prompt":
          return (
            this.fieldStartsWith(form.campaigns_page, "yes") &&
            this.fieldStartsWith(form.milestones_added, "no")
          )
        case "giving_back":
          return this.memberIsPresent()
        default:
          return true
      }
    },
    memberIsPresent() {
      return this.fieldStartsWith(this.thisForm.member_present, "yes")
    },
    joinedOrOutcome() {
      return (
        this.fieldStartsWith(this.thisForm.stage_reached, "joined") ||
        this.fieldStartsWith(this.thisForm.stage_reached, "achieved")
      )
    },
  },
}
