<template>
  <div>
    <label
      :for="attribute_name"
      class="block"
      :class="{ 'cursor-not-allowed opacity-50': disabled }"
      v-if="label"
    >
      {{ label }}
    </label>
    <slot>
      <input
        :type="type"
        :name="`company[${attribute_name}]`"
        :id="attribute_name"
        :placeholder="placeholder"
        class="beam-text-input w-full rounded-b-none rounded-t-sm md:rounded-sm md:rounded-r-none mt-2 mb-5 block"
        :class="{ 'cursor-not-allowed opacity-50': disabled }"
        :value="form?.attributes?.[attribute_name]"
        :readonly="disabled"
        v-model="form.attributes[attribute_name]"
        required
      />
    </slot>
    <div
      v-if="hasErrors"
      class="text-red-500 text-sm mb-5 bloc"
      v-html="form.errors[attribute_name][0]"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    form: { type: Object },
    attribute_name: { type: String },
    placeholder: { type: String },
    type: { type: String, default: "text" },
    label: { type: String },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    hasErrors() {
      return this.form?.errors?.[this.attribute_name]
    },
  },
}
</script>
