<template>
  <transition name="slide-fade">
    <div v-show="shouldDisplayField(field)">
      <member-form-label :field="field" :id="field.id" :visible="showLabel">
        <span class="whitespace-pre-line" v-html="field.question"></span>
      </member-form-label>
      <div v-if="showFieldId" class="font-mono text-xs text-navy-800">
        {{ field.id }}
      </div>

      <div
        v-if="field.previous_value_html"
        class="member-form-sublabel stack-mt-4"
        v-html="field.previous_value_html"
      ></div>

      <div
        v-if="field.help_link"
        class="member-form-sublabel stack-mt-4"
        v-html="field.help_link"
      ></div>

      <div v-if="field.error_text" class="mt-2 text-pink-100">
        {{ field.error_text }}
      </div>

      <!-- Select -->
      <div class="mt-5 rounded-md shadow-sm" v-if="selectField">
        <select
          :id="field.id"
          v-model="field.value"
          class="form-select w-full"
          :multiple="field.multiple_select"
        >
          <option
            :key="option.value"
            v-for="option in transformedSelectOptions"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>

      <!-- Fancy Select -->
      <div class="mt-5 rounded-md shadow-sm" v-if="fancySelectField">
        <fancy-select
          v-model="field.value"
          :options="field.select_options"
          :ajax-url="field.select_ajax_url"
          :multiple-select="field.multiple_select"
          :initial-selected="field.value"
          :id="field.id"
        >
        </fancy-select>
      </div>

      <!-- Radio -->
      <div
        class="member-form-radio-group"
        role="radiogroup"
        :aria-label="field.question"
        v-if="field.radio_options && !field.image_select_options"
      >
        <div
          class="member-form-radio"
          v-for="(value, key) in field.radio_options"
          :key="key"
        >
          <input
            type="radio"
            :id="valueId(key)"
            :value="value"
            v-model="field.value"
            class="form-radio"
            :disabled="disabled"
          />
          <label class="text-base" :for="valueId(key)">{{
            field.use_key_as_label ? key : value
          }}</label>
        </div>
      </div>

      <!-- Radio label only inline -->
      <div
        class="mt-5 flex space-x-2 md:space-x-5"
        v-if="field.inline_label_only_radio_options"
      >
        <div
          v-for="(label, value) in field.inline_label_only_radio_options"
          :key="value"
        >
          <!-- TODO: Change :value to value when writing to DB -->
          <input
            type="radio"
            :id="valueId(value)"
            :value="label"
            v-model="field.value"
            class="form-radio hidden"
          />
          <label
            class="cursor-pointer rounded-lg bg-gray-200 px-4 py-2 text-base next-to-checked:bg-gray-500 next-to-checked:text-white"
            :for="valueId(value)"
          >
            {{ label }}
          </label>
        </div>
      </div>

      <!-- Text -->
      <div
        class="member-form-input relative mt-5 rounded-md shadow-sm"
        v-if="textField"
      >
        <div
          v-if="field.currency"
          class="center-absolute-y absolute pl-4 text-navy-800"
        >
          £
        </div>
        <input
          :type="textFieldType"
          :id="field.id"
          v-model="field.value"
          :required="field.required"
          :placeholder="computedPlaceholder"
          @blur="handleBlur"
          min="0"
          class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          :class="{ 'pl-8': field.currency }"
          role="presentation"
          autocomplete="off"
          :disabled="disabled"
        />
      </div>

      <!-- Textarea -->
      <div
        class="member-form-input mt-5 rounded-md shadow-sm"
        v-if="field.textarea"
      >
        <textarea
          :id="field.id"
          rows="3"
          v-model="field.value"
          :required="field.required"
          class="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        >
        </textarea>
      </div>

      <!-- Checkboxes -->
      <div
        class="stack-mt-4 mt-5 member-form-checkbox-group"
        v-if="field.checkbox_options && !field.image_select_options"
      >
        <div
          class="relative flex items-start"
          v-for="(label, value) in field.checkbox_options"
          :key="value"
        >
          <div class="member-form-checkbox">
            <input
              type="checkbox"
              v-model="field.value"
              :id="valueId(value)"
              :value="field.use_key_as_label ? value : label"
              class="form-checkbox"
              :checked="field.value == true"
            />
            <label :for="valueId(value)">{{ label }}</label>
          </div>
        </div>
      </div>

      <!-- Image Select -->
      <div
        class="mt-5 grid grid-cols-3 gap-1"
        v-if="field.image_select_options"
      >
        <div
          class="relative h-full w-full cursor-pointer overflow-hidden rounded-md border-4 object-cover"
          :class="{
            'border-white':
              (!Array.isArray(field.value) && field.value !== option.id) ||
              (Array.isArray(field.value) && !field.value.includes(option.id)),
            'border-pink-100':
              field.value === option.id ||
              (Array.isArray(field.value) && field.value.includes(option.id)),
          }"
          v-for="option in field.image_select_options"
          :key="option.id"
          @click="onImageClick(option)"
          :id="valueId(option.id)"
        >
          <img v-if="option.src" :src="option.src" />
          <div
            class="absolute inset-x-0 bottom-0 h-full w-full bg-gray-600 opacity-60"
          ></div>
          <div
            class="absolute inset-x-0 bottom-0 w-full p-2 text-sm font-bold leading-4 text-white md:text-lg"
          >
            {{ option.id }}
          </div>
        </div>
      </div>

      <!-- Attachment -->
      <div class="mt-5 bg-white" v-if="field.attachment">
        <div
          class="flex justify-center rounded-md border-2 border-dashed border-gray-300 hover:border-azure-100"
          @drop.prevent="onFileChange"
          @dragover.prevent
        >
          <label
            class="h-full w-full cursor-pointer px-6 pb-6 pt-5 text-center"
          >
            <input
              type="file"
              @change="onFileChange"
              class="hidden"
              multiple
              ref="fileInput"
              :name="`file[${field.id}]`"
            />

            <icon
              name="arrow-up-circle"
              class="h-20 w-20 text-navy-200 mx-auto"
              :solid="true"
            ></icon>

            <p class="font-bold text-xl mt-3">Drag and drop a file here</p>

            <p class="mt-1 text-navy-800">PNG, JPG, GIF up to 10MB</p>
            <p class="text-navy-800">or</p>

            <div class="relative">
              <button
                type="button"
                class="button button-secondary inline-block mt-3 px-12 py-3"
                @click="$refs.fileInput.click()"
              >
                Choose a file
              </button>
            </div>
          </label>
        </div>

        <ul v-if="fileUploads">
          <li
            class="flex items-center"
            v-for="(file, index) in fileUploads"
            :key="file.upload_url"
          >
            {{ file.file_name }}
            <span class="cursor-pointer" @click="deleteFile(index)">
              <icon name="trash" class="h-4 w-4"></icon>
            </span>
          </li>
        </ul>
      </div>

      <!-- Date -->
      <div class="mt-5" v-if="field.date">
        <date-picker :id="field.id" v-model="field.value"></date-picker>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    field: { type: Object },
    placeholder: { type: String },
    modelValue: { type: null },
    showLabel: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      fileUploads: [], // Cache the file objects to display the file name. modelValue is an array of file URLs to be submitted.
    }
  },
  computed: {
    computedPlaceholder() {
      if (this.placeholder) return this.placeholder
    },
    selectField() {
      return (
        this.field.select_options &&
        !this.field.multiple_select &&
        !this.field.searchable_select
      )
    },
    fancySelectField() {
      return (
        this.field.select_ajax_url ||
        (this.field.select_options && this.field.multiple_select) ||
        this.field.searchable_select
      )
    },
    textField() {
      return !(
        this.selectField ||
        this.fancySelectField ||
        this.field.radio_options ||
        this.field.inline_label_only_radio_options ||
        this.field.checkbox_options ||
        this.field.attachment ||
        this.field.textarea ||
        this.field.date ||
        this.field.image_select_options
      )
    },
    textFieldType() {
      if (this.field.number || this.field.currency) {
        return "number"
      } else if (this.field.validate == "email") {
        return "email"
      } else if (["tel", "mob_uk"].includes(this.field.validate)) {
        return "tel"
      }

      return "text"
    },
    transformedSelectOptions() {
      // Sometimes the select_options are an Object, sometimes an Array.
      if (this.field.select_options && this.field.select_options[0].label) {
        return this.field.select_options
      }

      return this.field.select_options.map((option) => {
        return {
          label: option,
          value: option,
        }
      })
    },
    showFieldId() {
      // For devs
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get("show_field_id") == "true"
    },
  },
  inject: ["shouldDisplayField"],
  methods: {
    valueId(value) {
      return `${this.field.id}-${value}`
    },
    deleteFile(index) {
      this.fileUploads.splice(index, 1)
    },
    onImageClick(image) {
      if (this.field.multiple_select) {
        if (image.id == "None of the above") {
          if (this.field.value.includes(image.id)) {
            this.field.value = this.field.value.filter((id) => id !== image.id)
          } else {
            this.field.value = [image.id]
          }
        } else {
          this.field.value = this.field.value.filter(
            (id) => id !== "None of the above"
          )
          if (this.field.value.includes(image.id)) {
            this.field.value = this.field.value.filter((id) => id !== image.id)
          } else {
            this.field.value.push(image.id)
          }
        }
      } else {
        if (this.field.value === image.id) {
          this.field.value = null
        } else {
          this.field.value = image.id
        }
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files

      files = Array.from(files)

      files.forEach((file) => {
        let formData = new FormData()

        formData.set("attachment", file)
        formData.set("folder_name", "member-forms")

        this.axios
          .post(`/file_uploads`, formData)
          .then((response) => {
            if (response.data.success) {
              this.fileUploads.push(response.data)
            }
          })
          .catch((e) => {
            this.$root.showFlash(
              "Sorry, there was a problem uploading your attachment. Please try refreshing the page or contact support.",
              "error"
            )
          })
      })
    },
    validateTelephoneNumber() {
      if (this.textField && this.textFieldType == "tel") {
        this.axios
          .get(`/twilio/validate_phone_number?phone_number=${this.modelValue}`)
          .then((response) => {
            if (response.data.success) {
              this.field.value = response.data.phone_number
              this.field.error_text = null
            } else if (response?.data?.error_message) {
              this.field.error_text = response.data.error_message
            } else {
              this.field.error_text = "Please enter a valid phone number"
            }
          })
      }
    },
    handleBlur() {
      this.validateTelephoneNumber()
    },
  },
  watch: {
    "field.value": function () {
      // Remove errors on keydown.
      this.$emit("update:modelValue", this.field.value, this.field)
    },
    fileUploads: {
      handler() {
        this.$emit(
          "update:modelValue",
          this.fileUploads.map((el) => el.upload_url)
        )
      },
      deep: true,
    },
  },
}
</script>
