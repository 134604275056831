<template>
  <button @click="thank" class="button button-tertiary flex items-center px-4">
    <template v-if="thanked">
      <span class="mr-2 text-pink-100">
        <icon name="heart" :solid="true" class="h-4 w-4" title="Thank"></icon>
      </span>

      Thanked!
    </template>

    <template v-else>
      <span class="mr-2 text-bluey-grey-100">
        <icon name="heart" class="h-4 w-4" title="Thank"></icon>
      </span>

      Thank {{ userName }}
    </template>
  </button>
</template>

<script>
export default {
  props: {
    memberId: { type: Number, required: false },
    donationId: { type: Number, required: false },
    userId: { type: Number, required: true },
    userName: { type: String, required: true },
    newsItemId: { type: Number, required: false },
    source: { type: String, required: false },
    initialThanked: { type: Boolean, required: false },
  },
  data() {
    return {
      thanked: this.initialThanked,
    }
  },
  methods: {
    thank() {
      if (this.thanked) return

      this.thanked = true

      const opts = {
        member_thank: {
          member_id: this.memberId,
          donation_id: this.donationId,
          user_id: this.userId,
          news_item_id: this.newsItemId,
          source: this.source,
        },
      }

      this.axios.post("/member_thanks", opts)
    },
  },
}
</script>
