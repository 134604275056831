<template>
  <button
    class="js_ga_share js_copy button button-tertiary inline-flex items-center justify-center"
    :data-source="source"
    :data-clipboard-text="url"
    data-action="Copy Link"
  >
    <icon
      name="clipboard-document"
      class="mr-2 inline-block h-5 w-5"
      :solid="true"
      v-if="showCopyIcon"
    ></icon>
    {{ buttonText }}
  </button>
</template>

<script>
import ClipboardJS from "clipboard"

export default {
  props: {
    url: String,
    source: String,
    showCopyIcon: { type: Boolean, default: true },
    initialButtonText: { type: String, default: "Copy Link" },
  },
  data() {
    return {
      buttonText: this.initialButtonText,
    }
  },
  created() {
    const clipboard = new ClipboardJS(".js_copy")

    clipboard.on("success", () => {
      if (this.buttonText) {
        this.buttonText = "Copied!"
      } else {
        this.$root.showFlash("Copied the link! Just paste it to share.")
      }
    })
  },
}
</script>
