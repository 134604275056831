<template>
  <div>
    <slot
      :user="user"
      :openDesktopTab="openDesktopTab"
      :giftAidUser="giftAidUser"
      :updateUser="updateUser"
      :updateTab="updateTab"
      :scrollToTop="scrollToTop"
      :subscription="subscription"
      :onSubscriptionUpdated="onSubscriptionUpdated"
    >
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    initialDesktopTab: { type: String, default: null },
    initialUser: Object,
    initialGiftAidUser: Object,
    initialSubscription: { type: Object },
  },
  data() {
    return {
      openDesktopTab: this.initialDesktopTab,
      user: this.initialUser,
      giftAidUser: this.initialGiftAidUser,
      subscription: this.initialSubscription,
    }
  },
  methods: {
    updateTab(tab) {
      this.scrollToTop()
      this.openDesktopTab = tab
    },
    updateUser(user) {
      this.user = user
    },
    scrollToTop() {
      this.$scrollTo(document.querySelector("#app"))
    },
    onSubscriptionUpdated(subscription) {
      this.subscription = subscription
    },
  },
}
</script>
