<template>
  <div>
    <header class="mx-auto sm:max-w-lg lg:max-w-3xl">
      <h1 class="beam-h1">Why we created Beam</h1>
    </header>

    <main>
      <!-- Alex video (Mobile) -->
      <section
        class="full-screen-mobile relative z-0 mt-10 bg-pale-grey-100 px-8 pb-10 sm:hidden"
      >
        <div
          class="full-screen-mobile masked mt-8 flex items-center justify-center"
        >
          <!-- Play button -->
          <div class="absolute text-center text-white">
            <div
              class="hover-bounce inline-flex h-16 w-16 items-center justify-center rounded-full border-2 border-azure-100"
            >
              <play-button
                url="https://www.youtube.com/embed/_zsj6uek4Qw"
              ></play-button>
            </div>
            <div class="mt-3">
              <div class="text-sm font-medium">Alex at TEDx</div>
              <div class="text-xs">(16:40)</div>
            </div>
          </div>
          <img style="z-index: -1" :src="`/images/newsite/alex.jpg`" />
        </div>

        <!-- Text -->
        <div class="mt-8 text-sm">
          Alex Stephany, our founder, was inspired to build Beam after getting
          to know a homeless man at his local Tube station. The man had spent
          decades out of work. Alex would buy him cups of coffee and pairs of
          socks, but could see his condition going from very bad to even worse.
        </div>
        <div class="mt-8 text-sm">
          When the man had a heart attack, Alex asked himself: “What could we do
          to make a real difference to that man’s life?” The answer lay in
          giving him the skills to support himself. Alex knew that’d cost much
          more than a coffee. But what if everyone chipped in?
        </div>
        <div class="mt-8 text-sm">
          At Beam, we crowdfund new career opportunities for homeless men and
          women. We provide them with a support network, from our caseworkers to
          the kind strangers who fund their campaigns. We use technology to find
          scalable solutions to the toughest social problems - starting with
          homelessness.
        </div>
      </section>

      <!-- Alex video (Tablet + Desktop) -->
      <section
        class="relative ml-12 mt-20 hidden justify-end rounded bg-pale-grey-100 px-16 py-16 sm:flex lg:mr-12"
      >
        <div class="center-absolute-y absolute left-0 -ml-12 w-1/2 lg:w-2/5">
          <img
            :src="`/images/newsite/alex.jpg`"
            :srcset="
              (`/images/newsite/alex@2x.jpg 2x`,
              `/images/newsite/alex@3x.jpg 3x`)
            "
          />
        </div>

        <div class="w-1/2 lg:mr-24 lg:w-2/5">
          <div class="text-left text-sm lg:text-base">
            In 2016, our founder Alex Stephany became friends with a homeless
            man who would sit outside his local train station. Alex would stop
            to chat with him and buy him coffee. The man had been out of work
            longer than he could remember.
          </div>
          <div class="mt-8 text-left text-sm lg:text-base">
            As the months passed, Alex became determined to find ways to help
            the man leave homelessness for good. But he knew he couldn’t do that
            on his own. That’s when Alex had the idea to crowdfund for homeless
            people.
          </div>
          <div class="mt-8 text-left text-sm lg:text-base">
            Through the power of people and technology, we transform how the
            world supports those left behind.
          </div>

          <!-- Play button -->
          <div class="mt-10 flex items-center">
            <div
              class="hover-bounce inline-flex h-16 w-16 items-center justify-center rounded-full border-2 border-azure-100"
            >
              <play-button
                url="https://www.youtube.com/embed/_zsj6uek4Qw"
              ></play-button>
            </div>
            <div class="ml-4 text-left">
              <div class="text-sm font-medium">Alex at TEDx</div>
              <div class="text-xs">(16:40)</div>
            </div>
          </div>
        </div>
      </section>

      <!-- How it works -->
      <section class="mt-16 px-3 sm:mx-16 sm:justify-between">
        <div>
          <h2 class="beam-h2">A new collaborative model</h2>
        </div>

        <div>
          <div class="beam-header-intro">
            We believe that when individuals, charities, government and
            companies work together, millions of people can benefit. People like
            Nick.
          </div>
        </div>
      </section>

      <!-- Nick -->
      <section class="mt-16 px-3 sm:mt-20">
        <!-- Mobile -->
        <div class="mx-auto sm:w-128 lg:hidden">
          <div class="text-center text-pink-100">
            <quote-icon class="inline-block h-4 w-5"></quote-icon>
          </div>
          <div class="beam-h3 mx-auto mt-3">
            I've been clean nearly a year. I'm healthy, and I've got my
            relationship back with my family.
          </div>
        </div>

        <div class="lg:hidden">
          <video-with-image
            class="mt-8"
            url="https://www.youtube.com/embed/KmEQ5F1yCHY"
            image="/images/newsite/nickvideo.jpg"
          >
            <template #caption>
              <div class="mt-3 text-sm">
                <span class="font-medium">Nick's story</span> (2:34)
              </div>
            </template>
          </video-with-image>
        </div>

        <div class="mt-16 items-center justify-start sm:flex">
          <div
            class="mx-auto max-w-card shrink-0 sm:mx-16 sm:w-80 sm:max-w-full"
          >
            <campaign-card :campaign="nickCampaign"></campaign-card>
          </div>

          <div class="">
            <!-- Right -->
            <div class="hidden lg:block">
              <div class="text-pink-100">
                <quote-icon class="inline-block h-4 w-5"></quote-icon>
              </div>
              <div class="beam-h4 mt-5 w-128 text-left">
                I've been clean nearly a year, I'm healthy, and I've got my
                relationship back with my family.
              </div>
            </div>
            <div
              class="mt-8 text-sm sm:mt-0 sm:text-left sm:text-base lg:mt-8 lg:w-128"
            >
              Nick became homeless after struggling with addiction. He was
              referred to us by a charity partner, then 443 Beam supporters
              helped him raise £2,853 to become a barber and find a job. In his
              spare time, Nick now cuts hair in a homeless shelter.
            </div>

            <div class="mt-8 text-center sm:text-left">
              <fancy-link
                href="/stories"
                class="button button-secondary inline-block whitespace-nowrap px-10 py-3"
                >More success stories</fancy-link
              >
            </div>
          </div>
        </div>

        <div class="hidden lg:mx-auto lg:mt-32 lg:block lg:max-w-3xl">
          <video-with-image
            class="mt-8"
            url="https://www.youtube.com/embed/KmEQ5F1yCHY"
            image="/images/newsite/nickvideo.jpg"
          >
            <template #caption>
              <div class="mt-3 text-sm">
                <span class="font-medium">Nick's story</span> (2:34)
              </div>
            </template>
          </video-with-image>
        </div>
      </section>

      <!-- Benefits list -->
      <section>
        <div
          class="full-screen-mobile mt-16 bg-pale-grey-100 p-8 sm:mt-32 sm:rounded sm:p-12"
        >
          <div class="mt-5 rounded bg-white p-5 sm:p-8 lg:p-10">
            <div class="font-medium sm:text-xl">👨‍👩‍👧‍👦 For individuals</div>
            <div class="mt-5 text-sm sm:text-base">
              Help a person get a stable career or home by funding their
              campaign. You can also send people an encouraging message.
              <a class="blue-link" href="/campaigns">Support someone</a>.
            </div>
          </div>

          <div class="mt-5 rounded bg-white p-5 sm:p-8 lg:p-10">
            <div class="font-medium sm:text-xl">💓 For charities</div>
            <div class="mt-5 text-sm sm:text-base">
              Charities like Shelter and St Mungo’s refer their beneficiaries to
              Beam. Our service is free for charities and beneficiaries.
            </div>
          </div>

          <div class="mt-5 rounded bg-white p-5 sm:p-8 lg:p-10">
            <div class="font-medium sm:text-xl">🏛 For government</div>
            <div class="mt-5 text-sm sm:text-base">
              Local authorities and central government trust Beam to deliver
              employment and housing services to some of their most vulnerable
              residents.
            </div>
          </div>

          <div class="mt-5 rounded bg-white p-5 sm:p-8 lg:p-10">
            <div class="font-medium sm:text-xl">💼 For companies</div>
            <div class="mt-5 text-sm sm:text-base">
              Dozens of employers, from startups to household names like Bupa,
              Pret and Arriva, use Beam Recruit to hire diverse talent.
              <a class="blue-link" href="/companies">Learn more</a>.
            </div>
          </div>
        </div>
      </section>

      <section class="mt-16 sm:mt-20 lg:mt-32">
        <h2 class="beam-h2">Business and tech for good</h2>

        <div class="beam-header-intro">
          Imagine if businesses didn’t measure success in profit but in the
          positive impact they have on people and the planet. Imagine if the
          most disadvantaged people could access products and services – as good
          as Uber or Airbnb – for free.
        </div>

        <div class="beam-header-intro">
          At Beam, we’re making this a reality as an
          <a class="blue-link" href="/awards">award-winning</a> social impact
          business building tech for good.
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    nickCampaign: {
      type: Object,
    },
  },
}
</script>
