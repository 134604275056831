<template>
  <div>
    <div
      class="mb-2 flex w-full flex-col rounded-sm bg-white px-4 py-4"
      :class="{ 'cursor-pointer': completed }"
    >
      <play-thumbnail
        v-if="videoEmbedUrl"
        :url="videoEmbedUrl"
        :thumbnailUrl="videoThumbnailUrl"
        :button-text="playButtonText"
        class="mr-2 w-full max-w-sm flex-shrink-0 overflow-hidden shadow-md"
        @video-ended="onVideoEnd"
        @video-closed="onVideoClose"
      ></play-thumbnail>
      <div class="flex flex-row">
        <input
          type="checkbox"
          @change="toggle"
          :checked="completed"
          :key="`complete-${action.id}`"
          :id="`complete-${action.id}`"
          class="member-hub-checkbox form-checkbox mr-2 shrink-0 self-center"
        />
        <label
          :for="`complete-${action.id}`"
          class="flex flex-row items-center"
          @click="clickLabel"
        >
          <div>
            <div class="self-center font-medium">
              <play-link
                class="cursor-pointer"
                v-if="clickableAction"
                :url="videoEmbedUrl"
                >{{ action.name }}</play-link
              >
              <span v-else>{{ action.name }}</span>
            </div>
            <div class="block flex self-center">
              <span
                v-if="action.completed_at"
                class="justify-left flex text-xs"
              >
                Completed
                <div class="ml-1">
                  <timeago :time="action.completed_at"></timeago>
                </div>
              </span>
              <span v-else class="justify-left flex items-center text-xs">
                Added
                <div class="ml-1">
                  <timeago :time="action.created_at"></timeago>
                </div>
              </span>

              <!-- Link -->
              <div
                v-if="!action.completed_at && action.help_link"
                class="ml-auto"
              >
                <div @click.stop="">
                  <a
                    :href="action.help_link"
                    target="_blank"
                    class="flex font-bold text-blue-400"
                  >
                    Click here
                    <icon
                      name="arrow-top-right-on-square"
                      class="ml-1 h-5 w-5"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    action: { type: Object },
    videoId: { type: Number },
    videoEmbedUrl: { type: String },
    videoThumbnailUrl: { type: String },
    videoTitle: { type: String },
  },
  computed: {
    completed() {
      return this.action.completed_at !== null
    },
    clickableAction() {
      // If there is a video embed URL then it's clickable - reusable other cases too
      return !!this.videoEmbedUrl
    },
    playButtonText() {
      if (this.videoTitle.length <= 16) {
        return this.videoTitle
      }
    },
  },
  methods: {
    toggle() {
      this.completed ? this.submitUncomplete() : this.submitComplete()
    },
    clickLabel(e) {
      if (this.clickableAction) {
        e.preventDefault()
      }
    },
    submitComplete() {
      this.axios.post(`/member_facing_actions/${this.action.id}/complete`)
      this.action.completed_at = new Date().toISOString()
      this.$emit("completed")
    },
    submitUncomplete() {
      this.axios.post(`/member_facing_actions/${this.action.id}/uncomplete`)
      this.action.completed_at = null
      this.$emit("uncompleted")
    },
    onVideoEnd() {
      this.$vfm.hide("modal-video")
    },
    onVideoClose(event, p) {
      this.$vfm.show("modal-video-feedback", {
        ...event,
        actionId: this.action.id,
        videoId: this.videoId,
      })
    },
  },
}
</script>
