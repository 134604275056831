<template>
  <div class="bg-white p-4">
    <div class="flex">
      <people-stack :people="caseworkers" />
      <div class="flex items-center font-bold">We're here to help</div>
    </div>

    <div class="flex items-end">
      Any questions, send us a message
      <span class="hidden sm:block"
        ><support-icon classes="mx-1 p-1" iconClasses="h-4 w-4"
      /></span>
      or call us on 07562 614 419.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caseworkers: { type: Array },
  },
}
</script>
