<template>
  <div class="flex flex-col">
    <div class="flex items-center justify-center sm:block">
      <div class="mr-2 sm:mr-0 sm:text-5xl">
        <template v-if="emoji">{{ emoji }}</template>
        <icon class="inline-block h-16 w-16" :name="icon" v-if="icon"></icon>
      </div>
      <div class="text-lg font-medium sm:mt-2">
        {{ title }}
      </div>
    </div>
    <div
      v-if="subtitle"
      class="ml-3 mr-3 mt-3 whitespace-pre-wrap text-sm text-bluey-grey-100"
    >
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    emoji: { type: String },
    icon: { type: String },
    title: { type: String },
    subtitle: { type: String },
  },
}
</script>
