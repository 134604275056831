<template>
  <vue-final-modal
    :name="name"
    v-model="open"
    classes="flex absolute justify-center items-center w-full my-10"
    content-class="flex-grow bg-white rounded-md relative p-8 sm:p-10 max-w-xl overflow-y-scroll h-full"
  >
    <slot name="title"></slot>
    <input type="hidden" name="_method" value="patch" />
    <input type="hidden" name="authenticity_token" :value="authenticityToken" />
    <label
      :for="quoteAttribute"
      class="mt-5 block text-lg font-bold leading-7 text-blue-950"
      >Quote</label
    >
    <div class="font-normal leading-snug text-slate-600">
      Tell your company why you're supporting Beam. 280 characters max.
    </div>
    <textarea
      :id="quoteAttribute"
      rows="5"
      maxlength="280"
      v-model="form[`company[${quoteAttribute}]`]"
      class="form-input mt-2 block w-full items-start self-stretch p-3"
    >
    </textarea>

    <label
      :for="nameAttribute"
      class="mt-5 block text-lg font-bold leading-7 text-blue-950"
      >Name</label
    >
    <input
      type="text"
      :id="nameAttribute"
      v-model="form[`company[${nameAttribute}]`]"
      class="form-input mt-2 block w-full items-start self-stretch p-3"
    />

    <label
      :for="subtitleAttribute"
      class="mt-5 block text-lg font-bold leading-7 text-blue-950"
      >Subtitle</label
    >
    <input
      type="text"
      :id="subtitleAttribute"
      v-model="form[`company[${subtitleAttribute}]`]"
      class="form-input mt-2 block w-full items-start self-stretch p-3"
    />

    <label
      :for="avatarUrlAttribute"
      class="mt-5 block text-lg font-bold leading-7 text-blue-950"
      >Profile pic</label
    >
    <drag-and-drop-image-upload
      upload-button-text="Upload profile pic"
      :initial-image-url="form[`company[${avatarUrlAttribute}]`]"
      :attachment-attribute-name="`champion_${this.championNumber}_avatar`"
      record-name="company"
      :response-attribute-name="`medium_${avatarUrlAttribute}`"
      :form-url="formUrl"
      @success="handleImageUploadSuccess"
      class="mt-2"
    >
      <template #instruction-text>
        Drag and drop a new profile pic here
      </template>
      <template #success><div></div></template>
    </drag-and-drop-image-upload>

    <button @click="submitForm" class="button button-primary mt-4 w-full p-4">
      Save
    </button>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    formUrl: String,
    authenticityToken: String,
    championNumber: { type: String, default: "one" },
    name: String,
    championName: String,
    championSubtitle: String,
    championQuote: String,
    championAvatarUrl: String,
  },
  data: function () {
    return {
      open: false,
      form: {},
    }
  },
  computed: {
    nameAttribute() {
      return `champion_${this.championNumber}_name`
    },
    subtitleAttribute() {
      return `champion_${this.championNumber}_subtitle`
    },
    quoteAttribute() {
      return `champion_${this.championNumber}_quote`
    },
    avatarUrlAttribute() {
      return `champion_${this.championNumber}_avatar_url`
    },
  },
  methods: {
    submitForm(e) {
      e.preventDefault()

      let formData = new FormData()

      for (let key in this.form) {
        formData.append(key, this.form[key])
      }

      formData.append("authenticity_token", this.authenticityToken)
      formData.append("_method", "patch")
      formData.append("commit", "Save")

      this.axios.post(this.formUrl, formData).then((response) => {
        this.open = false
        this.$emit("saved", {
          [`${this.nameAttribute}`]:
            response.data.company[`${this.nameAttribute}`],
          [`${this.subtitleAttribute}`]:
            response.data.company[`${this.subtitleAttribute}`],
          [`${this.quoteAttribute}`]:
            response.data.company[`${this.quoteAttribute}`],
          [`medium_${this.avatarUrlAttribute}`]:
            response.data.company[`medium_${this.avatarUrlAttribute}`],
        })
      })
    },
    beforeClose(event) {
      // Reset the data
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    handleImageUploadSuccess(imageUrl) {
      this.$emit("image-upload-success", imageUrl)
    },
  },
  created() {
    this.form = {
      [`company[${this.nameAttribute}]`]: this.championName,
      [`company[${this.subtitleAttribute}]`]: this.championSubtitle,
      [`company[${this.quoteAttribute}]`]: this.championQuote,
      [`company[${this.avatarUrlAttribute}]`]: this.championAvatarUrl,
    }
  },
}
</script>
