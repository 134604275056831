<template>
  <div>
    <landing-header>
      <template #title>
        <div>Become a <span class="yellow-underline-h1">Changemaker</span></div>
      </template>

      <template #body>
        <div>
          Changemakers is our community of
          {{ changemakerCount.toLocaleString() }} inspirational monthly
          supporters committed to transforming the lives of homeless people and
          refugees.
        </div>
        <div class="mt-8">
          <a class="button button-primary button-large" href="/donate">
            Join Changemakers
          </a>

          <div class="mt-4 text-base text-bluey-grey-100">
            Give what you can. Pause or cancel anytime.
          </div>
        </div>
      </template>

      <template #photo>
        <img :src="`/images/changemakers.jpg`" />
      </template>
    </landing-header>

    <!-- How it works section -->
    <section class="mt-16 sm:mt-20">
      <div
        class="full-screen-width bg-pale-grey-100 py-10 sm:rounded sm:py-16 lg:py-20"
      >
        <div class="beam-container">
          <div class="beam-h1 text-left sm:px-8 sm:text-center">
            How being a Beam<br />Changemaker works
          </div>

          <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
            <template #title
              ><span class="yellow-underline-h2">Automatically support </span>
              <br />
              a different homeless person <br />
              or refugee every month
            </template>

            <template #body>
              <div class="inline-block max-w-lg">
                Each month we’ll automatically assign 100% of your donation to a
                homeless person or refugee. <br />
                You’ll also receive an email to hear their story.
              </div>
            </template>

            <template #image>
              <img
                :src="`/images/changemaker-calendar.png`"
                loading="lazy"
                class="inline-block sm:w-106"
              />
            </template>
          </landing-left-text>

          <landing-left-text :image-left="true" class="mt-16 sm:mt-20 lg:mt-32">
            <template #title>
              Choose to
              <span class="yellow-underline-h2">send a message</span>
              of hope alongside your donation
            </template>

            <template #body>
              <div class="inline-block max-w-lg">
                You’ll have the option to send a message of encouragement to the
                people you help. Often it’s the boost of confidence they need to
                keep going!
              </div>
            </template>

            <template #image>
              <img
                loading="lazy"
                :src="`/images/hana-message.png`"
                class="inline-block sm:w-128"
              />
            </template>
          </landing-left-text>

          <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
            <template #title
              ><span class="yellow-underline-h2">Get monthly updates</span> on
              how you’ve made a difference
            </template>

            <template #body>
              <div class="inline-block max-w-lg">
                We’ll let you know how the people you support are getting on.
                Follow their journey and track your impact!
              </div>
            </template>

            <template #image>
              <img
                :src="`/images/hana-update.png`"
                loading="lazy"
                class="inline-block sm:w-128"
              />
            </template>
          </landing-left-text>

          <landing-left-text :image-left="true" class="mt-16 sm:mt-20 lg:mt-32">
            <template #title
              >Be a part of a
              <span class="yellow-underline-h2">wider community</span> of
              Changemakers
            </template>

            <template #body>
              <div class="inline-block max-w-lg">
                Be the first to hear about Beam events and meetups, and help us
                decide the future of Changemakers.
              </div>
            </template>

            <template #image>
              <img
                :src="`/images/hana-speaking.png`"
                loading="lazy"
                class="inline-block sm:w-128"
              />
            </template>
          </landing-left-text>

          <div class="mt-16 text-center">
            <a
              href="/donate"
              class="button button-primary inline-block px-16 py-3"
              >Become a Changemaker</a
            >
          </div>
        </div>
      </div>
    </section>

    <!-- Impact dashboard section -->
    <section>
      <div class="mx-auto max-w-xl">
        <img loading="lazy" :src="`/images/impact-dashboard.png`" />
      </div>

      <div class="mx-auto">
        <div class="beam-h1">
          Your impact dashboard is a
          <br />
          <span class="yellow-underline-h1"
            >memento to the lives you change</span
          >
        </div>

        <div class="mx-auto mt-5 max-w-lg text-center sm:text-lg">
          Be reminded of the good you’ve done. Every month we’ll add the person
          you’ve supported to your dashboard.
        </div>
      </div>

      <div class="mt-5 text-center">
        <a href="/donate" class="button button-primary inline-block px-16 py-3"
          >Become a Changemaker</a
        >
      </div>
    </section>

    <section class="mt-16 sm:mt-20">
      <div
        class="full-screen-width bg-pale-grey-100 py-10 sm:rounded sm:py-16 lg:py-20"
      >
        <div class="beam-container">
          <div class="beam-h1">Meet some Changemakers</div>

          <div class="mx-auto mt-5 max-w-2xl text-center sm:mt-8 sm:text-lg">
            Drawn mostly from the fields of tech, financial and professional
            services, Changemakers donate whatever they can monthly and give the
            encouragement people need to succeed on their path out of
            homelessness.
          </div>

          <div
            class="mt-12 items-center justify-around space-y-3 sm:flex sm:items-stretch sm:space-x-5 sm:space-y-0"
          >
            <div
              class="flex-1 space-y-6 bg-white p-5 text-left sm:p-8 sm:text-center"
            >
              <div class="text-xl font-medium sm:h-24">
                “I love the tangible impact of my support”
              </div>
              <div class="flex items-center space-x-3 sm:block sm:space-x-0">
                <img
                  :src="`/images/ben.jpeg`"
                  class="h-12 w-12 rounded-full sm:mx-auto sm:h-40 sm:w-40"
                />
                <div class="text-sm sm:mt-5 sm:text-base">
                  <div class="font-medium">Ben Fletcher</div>
                  <div>Co-Founder & CEO, The Mothership</div>
                </div>
              </div>
            </div>

            <div
              class="flex-1 space-y-6 bg-white p-5 text-left sm:p-8 sm:text-center"
            >
              <div class="text-xl font-medium sm:h-24">
                “It’s amazing to be able to give directly to those who need it
                and see the impact it has on the individual."
              </div>
              <div class="flex items-center space-x-3 sm:block sm:space-x-0">
                <img
                  :src="`/images/margaret.jpeg`"
                  class="h-12 w-12 rounded-full sm:mx-auto sm:h-40 sm:w-40"
                />
                <div class="text-sm sm:mt-5 sm:text-base">
                  <div class="font-medium">Margaret Adam</div>
                  <div>Global Industry Marketing, Salesforce</div>
                </div>
              </div>
            </div>

            <div
              class="flex-1 space-y-6 bg-white p-5 text-left sm:p-8 sm:text-center"
            >
              <div class="text-xl font-medium sm:h-24">
                "What’s a better gift than facilitating a life changing second
                chance? Beam does this every day."
              </div>
              <div class="flex items-center space-x-3 sm:block sm:space-x-0">
                <img
                  :src="`/images/rutger.jpeg`"
                  class="h-12 w-12 rounded-full sm:mx-auto sm:h-40 sm:w-40"
                />
                <div class="text-sm sm:mt-5 sm:text-base">
                  <div class="font-medium">Rutger Bruining</div>
                  <div>Founder & CEO, StoryTerrace</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-5 bg-white py-10 text-center">
            <img
              :src="`/images/stars.png`"
              class="inline w-64 sm:w-auto sm:max-w-xl"
            />
            <div class="heading-level-1 mb-1 mt-5 font-medium">9.3 / 10</div>
            ‘How likely would you be to recommend Beam’
            <br />
            Average from 602 supporters
          </div>

          <div class="beam-h2 mt-20">
            Beam is social impact
            <br />
            <span class="yellow-underline-h2"
              >as you’d like to see it done</span
            >
          </div>

          <div
            class="mt-8 items-center justify-around space-y-3 sm:mt-10 sm:flex sm:space-x-5 sm:space-y-0"
          >
            <div class="flex-1 bg-white p-8 text-center">
              <emoji-block
                emoji="💯"
                title="100% giving"
                subtitle="Every penny of your donation goes to the individual - never our salaries or overheads."
              ></emoji-block>
            </div>

            <div class="flex-1 bg-white p-8 text-center">
              <emoji-block
                emoji="🪟"
                title="Transparent"
                subtitle="Transparency is one of our core values. It’s why we publish hundreds of data points for anyone to see."
              ></emoji-block>
            </div>

            <div class="flex-1 bg-white p-8 text-center">
              <emoji-block
                emoji="🏆"
                title="Award winning"
                subtitle="33 awards and counting, &#10; including one of Wired’s top 10 &#10; startups of 2021."
              ></emoji-block>
            </div>
          </div>

          <div class="mt-8 text-center">
            <a
              href="/donate"
              class="button button-primary inline-block px-16 py-3"
              >Become a Changemaker</a
            >
          </div>

          <!-- FAQs -->
          <div class="mb-8">
            <faq-section
              bg-color="bg-white"
              page-type="Changemakers"
              :show-link="false"
            ></faq-section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    changemakerCount: {
      type: Number,
      required: true,
    },
  },
}
</script>
