<template>
  <div :class="parentClasses">
    <div
      class="flex h-full items-center bg-yellow-100 text-sm font-medium"
      style="width: 1px"
    >
      <span class="ml-5" v-if="showAmount">{{ percent }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
    },
    showAmount: {
      type: Boolean,
      default: true,
    },
    parentClasses: {
      type: String,
      required: false,
      default: "bg-yellow-200 h-6",
    },
  },
  data: function () {
    return {
      observer: null,
    }
  },
  computed: {
    barWidth() {
      return Math.min(this.percent, 100)
    },
    barDiv() {
      return this.$el.childNodes[0]
    },
  },
  mounted: function () {
    if ("IntersectionObserver" in window) {
      this.setupObserver()
    } else {
      this.setWidthAndAnimate()
    }
  },
  methods: {
    setWidthAndAnimate() {
      this.barDiv.style.width = `${this.barWidth}%`
      this.barDiv.classList.add("animate-bar")
    },
    setupObserver() {
      // Destructuring first entry
      this.observer = new IntersectionObserver(([entry]) => {
        if (entry && entry.intersectionRatio > 0) {
          this.setWidthAndAnimate()

          this.observer.disconnect()
        }
      })

      this.observer.observe(this.barDiv)
    },
  },
  watch: {
    percent() {
      this.barDiv.style.width = `${this.barWidth}%`
    },
  },
}
</script>
