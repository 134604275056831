<template>
  <div
    class="cursor-pointer rounded-full bg-azure-100 text-white shadow"
    :class="classes"
    @click="this.$vfm.show('modal-member-support')"
  >
    <icon name="chat-bubble-oval-left-ellipsis" :class="iconClasses"></icon>
  </div>
</template>

<script>
export default {
  props: {
    classes: { type: String, default: "fixed bottom-0 right-0 m-4 p-2 sm:m-6" },
    iconClasses: { type: String, default: "h-10 w-10" },
  },
}
</script>
