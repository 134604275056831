<template>
  <vue-final-modal
    name="modal-video"
    @before-open="beforeOpen"
    @closed="closed"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md max-w-6xl overflow-hidden"
  >
    <div class="aspect-h-9 aspect-w-16 relative h-0">
      <div
        class="absolute left-0 top-0 h-full w-full"
        width="560"
        height="315"
        id="youtube-player"
      ></div>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  data() {
    return {
      videoId: "",
      open: false,
      player: null,
      onVideoEnd: null,
      onVideoClose: null,
      sessionStartTime: null,
      sessionEndTime: null,
    }
  },
  created() {
    // Requires that VideoMixin was included in whatever opened this modal
    window.onYouTubeIframeAPIReady = () => {
      this.player = new YT.Player("youtube-player", {
        height: "315",
        width: "560",
        videoId: this.videoId,
        playerVars: {
          playsinline: 1,
          autoplay: 1,
          controls: 1,
          modestbranding: 1,
          showinfo: 0,
          cc_load_policy: 1,
          cc_lang_pref: "en",
          widget_referrer: window.location.href,
        },
        events: {
          onStateChange: this.onPlayerStateChange,
        },
      })
    }
  },
  methods: {
    beforeOpen(event) {
      const { url, onVideoEnd, onVideoClose } = event.ref.params.value
      // If the video isn't youtube, throw an error
      if (!url.includes("youtube.com/embed")) {
        throw new Error("Video URL must be a YouTube Embed URL")
      }
      this.videoId = url.split("embed/")[1]
      this.onVideoEnd = onVideoEnd
      this.onVideoClose = onVideoClose
    },
    onPlayerStateChange(e) {
      if (e.data === YT.PlayerState.ENDED) {
        this.setSessionEndTime()
        if (this.onVideoEnd) {
          this.onVideoEnd()
        }
      } else if (e.data === YT.PlayerState.PLAYING) {
        if (!this.sessionStartTime) {
          this.sessionStartTime = new Date()
        }
      }
    },
    closed() {
      this.videoId = ""
      this.setSessionEndTime()

      if (this.onVideoClose) {
        this.onVideoClose({
          sessionStartTime: this.sessionStartTime,
          sessionEndTime: this.sessionEndTime,
        })
      }
    },
    setSessionEndTime() {
      if (!this.sessionEndTime) {
        this.sessionEndTime = new Date()
      }
    },
  },
  watch: {
    videoId() {
      if (!this.videoId) {
        this.player.stopVideo()
        this.onVideoEnd = null
      } else {
        this.player.loadVideoById(this.videoId)
      }
    },
  },
}
</script>
