<template>
  <div class="items-start justify-between lg:flex lg:px-12">
    <main class="px-0 sm:px-12 lg:max-w-xl lg:px-0">
      <slot
        name="gift-type"
        :emailDeliveryType="emailDeliveryType"
        :pdfType="pdfType"
        :setPdfDeliveryType="setPdfDeliveryType"
        :setEmailDeliveryType="setEmailDeliveryType"
      >
      </slot>

      <!-- Amount Select -->
      <section class="mt-10">
        <div class="font-medium">Gift amount</div>

        <checkout-amounts
          ref="checkoutAmounts"
          :amounts="amounts"
          v-on:update-selected-amount="onUpdateSelectedAmount"
        ></checkout-amounts>
      </section>

      <section class="mt-10">
        <div class="font-medium">Choose a style for your gift card</div>

        <ul class="mt-1 hidden flex-wrap justify-between sm:flex">
          <li
            @click="setSelectedGiftDesign(design)"
            class="checkout-amount mx-0 my-2 py-0"
            style="width: 175px"
            :class="{ selected: design.id == selectedGiftDesign.id }"
            v-for="design in giftDesigns"
            :key="design.id"
          >
            <img
              class="rounded-sm"
              :src="design.medium_photo_url"
              :alt="design.name"
            />
            <div class="py-1 text-sm">{{ design.name }}</div>
          </li>
        </ul>

        <ul class="mt-1 sm:hidden">
          <carousel :items-to-show="1">
            <template #slides>
              <slide v-for="design in giftDesigns" :key="design.id">
                <li
                  @click="setSelectedGiftDesign(design)"
                  class="checkout-amount my-2 w-80 py-0"
                  :class="{ selected: design.id == selectedGiftDesign.id }"
                >
                  <img
                    class="rounded-sm"
                    :src="design.medium_photo_url"
                    :alt="design.name"
                  />
                  <div class="py-1 text-sm">{{ design.name }}</div>
                </li>
              </slide>
            </template>
            <template #addons>
              <navigation>
                <template v-slot:prev>
                  <icon
                    name="arrow-left"
                    class="hover-bounce h-8 w-8 text-azure-100"
                  ></icon>
                </template>
                <template v-slot:next>
                  <icon
                    name="arrow-right"
                    class="hover-bounce h-8 w-8 text-azure-100"
                  ></icon>
                </template>
              </navigation>
              <pagination />
            </template>
          </carousel>
        </ul>
      </section>

      <!-- Gift Count -->
      <!-- <section v-if="pdfType" class="mt-10">
        <div class="font-medium">Number of Gifts</div>
        <input
          type="number"
          class="w-full mt-5 beam-text-input"
          min="1"
          v-model.trim="giftsCount"
          id="gifts-count"
        />
      </section> -->

      <!-- Support Costs -->
      <section class="mt-10">
        <div class="font-medium">Beam support costs</div>

        <div class="mt-1 text-sm text-bluey-grey-100">
          We don’t pay ourselves out of your funding without you approving it.
          We suggest 10-30% as a contribution to our award-winning support, but
          we're happy for you to make that call.
        </div>

        <div class="mt-5 flex items-center">
          <select
            class="beam-text-input w-full"
            id="service-fee"
            v-model="beamDonationPercent"
          >
            <tip-amount-option
              v-for="amount in tipAmounts"
              :key="amount"
              :amount="amount"
            >
            </tip-amount-option>
          </select>
          <icon
            name="chevron-down"
            class="pointer-events-none -ml-8 inline-block h-5 w-5 text-azure-100"
          ></icon>
        </div>
      </section>

      <!-- Gift Aid -->
      <gift-aid
        ref="giftAid"
        @gift-aid-updated="giftAidChecked = $event"
        :initial-postcode="initialGiftAidUser.postcode"
        :initial-line1="initialGiftAidUser.house_number"
        :initial-line2="initialGiftAidUser.street"
        :initial-city="initialGiftAidUser.city"
      >
      </gift-aid>

      <!-- Recipient Fields -->
      <section class="mt-10" v-if="emailDeliveryType">
        <label for="recipient-email" class="inline-block font-medium"
          >Recipient email *</label
        >
        <input
          type="email"
          name="email"
          class="beam-text-input mt-5 w-full"
          v-model.trim="recipientEmail"
          id="recipient-email"
        />
      </section>

      <!-- Recipient Names -->
      <section class="mt-10" v-if="emailDeliveryType">
        <div class="sm:flex sm:justify-between">
          <div class="flex-1">
            <label for="recipient-first-name" class="inline-block font-medium"
              >Recipient first name *</label
            >
            <input
              type="text"
              class="beam-text-input mt-5 w-full"
              v-model.trim="recipientFirstName"
              id="recipient-first-name"
              autocomplete="given-name"
            />
          </div>

          <div class="flex-1 sm:ml-8">
            <label
              for="recipient-last-name"
              class="mt-10 inline-block font-medium sm:mt-0"
              >Recipient last name *</label
            >
            <input
              type="text"
              class="beam-text-input mt-5 w-full"
              v-model.trim="recipientLastName"
              id="recipient-last-name"
              autocomplete="family-name"
            />
          </div>
        </div>
      </section>

      <!-- Support Message -->
      <section class="mt-10">
        <label for="support-message" class="inline-block font-medium"
          >Message to recipient</label
        >
        <textarea
          class="beam-text-input mt-5 h-24 w-full sm:h-24"
          v-model="supporterMessage"
          id="support-message"
          maxlength="500"
        ></textarea>
      </section>

      <!-- Buyer email -->
      <section class="mt-10" v-if="!this.initialUser.email">
        <label for="email-address" class="inline-block font-medium"
          >Your email</label
        >
        <input
          type="email"
          name="email"
          class="beam-text-input mt-5 w-full"
          v-model.trim="emailAddress"
          id="email-address"
        />
      </section>

      <!-- Names -->
      <section class="mt-10">
        <div class="sm:flex sm:justify-between">
          <div class="flex-1">
            <label for="first-name" class="inline-block font-medium"
              >Your first name</label
            >
            <input
              type="text"
              class="beam-text-input mt-5 w-full"
              v-model.trim="firstName"
              id="first-name"
              autocomplete="given-name"
            />
          </div>

          <div class="flex-1 sm:ml-8">
            <label
              for="last-name"
              class="mt-10 inline-block font-medium sm:mt-0"
              >Your last name</label
            >
            <input
              type="text"
              class="beam-text-input mt-5 w-full"
              v-model.trim="lastName"
              id="last-name"
              autocomplete="family-name"
            />
          </div>
        </div>
      </section>

      <!-- Payment Request button -->
      <template v-if="!savedCard">
        <section class="mt-10" v-if="hasPaymentRequest">
          <label for="card-element" class="inline-block font-medium"
            >Quick pay</label
          >
          <stripe-payment-request></stripe-payment-request>
        </section>
      </template>

      <!-- Card details -->
      <section class="mt-10" v-if="!savedCard">
        <label for="card-element" class="inline-block font-medium"
          >Card details</label
        >
        <stripe-card-element ref="stripeElement"></stripe-card-element>
      </section>

      <checkout-saved-card
        :saved-card="savedCard"
        v-on:clear-card-details="clearCardDetails"
      >
      </checkout-saved-card>

      <!-- Donate button -->
      <checkout-button></checkout-button>

      <faq-section title="Gift FAQs" page-type="Gift Checkout" id="gift-faqs">
      </faq-section>
    </main>

    <!-- Sidebar -->
    <aside class="sticky top-0 my-10 ml-24 hidden flex-1 pt-8 lg:block">
      <div class="font-medium">Gift summary</div>

      <div class="mt-5 rounded bg-pale-grey-100 px-10 py-10">
        <ul class="stack-mt-3">
          <li class="flex">
            <div class="w-32 shrink-0 text-sm text-bluey-grey-100">
              Delivery Method
            </div>
            <div class="text-sm">{{ deliveryTypeDisplay }}</div>
          </li>
          <li class="flex">
            <div class="w-32 shrink-0 text-sm text-bluey-grey-100">
              Gift Amount
            </div>
            <div class="text-sm">{{ amountDisplay }}</div>
          </li>
          <li class="flex" v-if="selectedGiftDesign.id">
            <div class="w-32 shrink-0 text-sm text-bluey-grey-100">
              Gift Style
            </div>
            <div class="text-sm">{{ selectedGiftDesign.name }}</div>
          </li>
          <li class="flex">
            <div class="w-32 shrink-0 text-sm text-bluey-grey-100">
              Gift Aid
            </div>
            <div class="text-sm">{{ giftAidDisplay }}</div>
          </li>
          <li class="flex" v-if="supporterMessage">
            <div class="w-32 shrink-0 text-sm text-bluey-grey-100">Message</div>
            <div class="text-sm">{{ supporterMessage }}</div>
          </li>
        </ul>
      </div>

      <section class="mt-8">
        <div class="px-2 text-sm">
          <div class="text-center font-medium">💼 Buying in bulk?</div>
          <div class="mt-1 text-center text-bluey-grey-100">
            <a
              class="blue-link"
              href="https://airtable.com/shrofNE7fMXtMjFa6"
              target="_blank"
              >Let us know</a
            >
            if you want to buy a large number of gifts for your team or
            customers.
          </div>
        </div>
      </section>
    </aside>
  </div>
</template>

<script>
import CheckoutMixin from "./CheckoutMixin"
import CheckoutAmountsMixin from "./CheckoutAmountsMixin"

export default {
  mixins: [CheckoutMixin, CheckoutAmountsMixin],
  props: {
    giftDesigns: { type: Array },
  },
  data() {
    return {
      deliveryType: "email",
      recipientEmail: "",
      recipientFirstName: "",
      recipientLastName: "",
      giftsCount: 1,
      selectedGiftDesign: {},
    }
  },
  computed: {
    emailDeliveryType() {
      return this.deliveryType == "email"
    },
    pdfType() {
      return this.deliveryType == "pdf"
    },
    amounts() {
      return [25, 50, 100]
    },
    amountDisplay() {
      return `${this.currency}${this.totalAmountDisplay}`
    },
    totalAmount() {
      // Returns a Float
      if (!this.selectedAmount) {
        return 0
      }

      let amount = this.selectedAmount + parseFloat(this.beamAmount())

      return amount * this.giftsCount
    },
    donateButtonText() {
      return `Gift ${this.amountDisplay}`
    },
    deliveryTypeDisplay() {
      if (this.deliveryType == "email") {
        return "Email"
      } else {
        return "PDF"
      }
    },
    postPath() {
      return "/gifts.json"
    },
  },
  created() {
    this.setDefaultAmount()
    // this.logEvent('checkout-view');
  },
  methods: {
    setSelectedGiftDesign(design) {
      this.selectedGiftDesign = design
    },
    setEmailDeliveryType() {
      this.deliveryType = "email"
    },
    setPdfDeliveryType() {
      this.deliveryType = "pdf"
    },
    validate() {
      const fields = [
        "custom-amount",
        "email-address",
        "first-name",
        "recipient_email",
        "recipient_first_name",
        "recipient_last_name",
        "gifts-count",
      ]

      this.clearErrorStates(fields)

      if (!this.validateAmount()) return false
      if (!this.validateGiftAid()) return false
      if (!this.validateRecipientDetails()) return false
      if (!this.validateEmail()) return false
      if (!this.validateFirstName()) return false
      if (!this.validateGiftsCount()) return false

      return true
    },
    postData(paymentMethodId, paymentIntentId, paymentMethodType) {
      const giftAidComponent = this.$refs.giftAid

      return {
        gift: {
          amount: this.totalAmountPence,
          recipient_email: this.recipientEmail,
          recipient_first_name: this.recipientFirstName,
          recipient_last_name: this.recipientLastName,
          user_email: this.emailAddress,
          beam_donation_percent: this.beamDonationPercent,
          first_name: this.firstName,
          last_name: this.lastName,
          gift_count: this.giftsCount,
          gift_aid: this.giftAidChecked,
          giftaid_first_name: this.firstName,
          giftaid_last_name: this.lastName,
          house_number: giftAidComponent.line1,
          street: giftAidComponent.line2,
          city: giftAidComponent.city,
          postcode: giftAidComponent.postcode,
          gift_message: this.supporterMessage,
          delivery_method: this.deliveryType,
          payment_method: paymentMethodId,
          payment_intent: paymentIntentId,
          payment_method_type: paymentMethodType,
          gift_design_id: this.selectedGiftDesign.id,
        },
      }
    },
  },
}
</script>
