<template>
  <header>
    <div class="flex flex-col sm:flex-row">
      <div class="sm:mr-12">
        <div
          v-if="pillSlotProvided"
          class="inline-block rounded bg-pale-grey-100 px-3 py-2 text-xs font-medium uppercase sm:text-base"
        >
          <slot name="pill"></slot>
        </div>
        <h1 class="beam-h1 mt-2 text-left">
          <slot name="title"></slot>
        </h1>

        <div
          class="mt-6 text-left text-base sm:mr-24 sm:text-lg lg:mt-8 lg:text-xl"
        >
          <slot name="body"></slot>
        </div>
      </div>
      <div class="mt-8 flex-shrink-0 text-center sm:mt-0 sm:w-106">
        <slot name="photo"></slot>

        <div class="text-sm text-bluey-grey-100">
          <slot name="caption"></slot>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    pillSlotProvided() {
      return !!this.$slots.pill
    },
  },
}
</script>
