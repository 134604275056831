<template>
  <vue-final-modal
    :name="name"
    @before-open="beforeOpen"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-5 max-w-md"
  >
    <modal-close-button :name="name"></modal-close-button>

    <div class="text-center">
      <div class="mt-4 text-lg font-medium sm:text-xl">
        Help {{ memberName }} leave homelessness for good
      </div>

      <div class="mt-8 text-sm sm:text-base">
        Share {{ memberName }}’s campaign on social media to spread the word and
        encourage friends to donate. Sharing a campaign brings in an extra £20
        of donations on average.
      </div>

      <!-- Facebook -->
      <div class="mt-8">
        <button
          @click="facebookShare(facebookShareUrl)"
          class="button js_ga_share inline-flex w-48 items-center justify-center bg-facebook py-3 text-white"
          data-source="campaign_share_popup"
          data-action="Facebook"
        >
          <facebook-icon class="mr-2 inline-block h-4 w-4"></facebook-icon>
          <span>Share</span>
        </button>
      </div>

      <!-- Twitter -->
      <div class="mt-4">
        <a
          :href="twitterShareUrl"
          class="button js_ga_share inline-flex w-48 items-center justify-center bg-twitter py-3 text-white"
          target="_blank"
          data-source="campaign_share_popup"
          data-action="Twitter"
        >
          <twitter-icon class="mr-2 inline-block h-4 w-4"></twitter-icon>
          <span>Twitter</span>
        </a>
      </div>

      <!-- WhatsApp -->
      <div class="mt-4">
        <a
          :href="whatsappShareUrl"
          class="button js_ga_share inline-flex w-48 items-center justify-center bg-whatsapp py-3 text-white"
          target="_blank"
          data-source="campaign_share_popup"
          data-action="WhatsApp"
        >
          <whatsapp-icon class="mr-2 inline-block h-4 w-4"></whatsapp-icon>
          <span>WhatsApp</span>
        </a>
      </div>

      <!-- Copy -->
      <div class="mt-4">
        <copy-button
          class="w-48 py-3"
          :url="copyShareUrl"
          source="campaign_share_popup"
        >
        </copy-button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import FacebookMixin from "../mixins/FacebookMixin"

export default {
  mixins: [FacebookMixin],
  props: {
    showImmediately: { type: Boolean, default: false },
    memberName: { type: String },
    campaignId: { type: Number },
  },
  data() {
    return {
      name: "modal-campaign-share",
      twitterShareUrl: "",
      facebookShareUrl: "",
      whatsappShareUrl: "",
      copyShareUrl: "",
      open: false,
    }
  },
  methods: {
    beforeOpen(event) {
      this.getFacebookSDK()

      this.axios
        .get(`/campaigns/${this.campaignId}/share_urls`)
        .then((response) => {
          const data = response.data
          this.twitterShareUrl = data.twitter
          this.facebookShareUrl = data.facebook
          this.whatsappShareUrl = data.whatsapp
          this.copyShareUrl = data.copy
        })
    },
  },
  mounted() {
    if (this.showImmediately) {
      this.$vfm.show(this.name)
    }
  },
}
</script>
