<template>
  <div>
    <header>
      <h1 class="beam-h1">We'll go further to help you find the right job</h1>

      <div class="beam-header-intro">
        We're an employment service with a difference.
      </div>

      <ul
        class="mx-auto mt-10 w-full space-y-6 bg-pale-grey-100 p-8 sm:max-w-3xl"
      >
        <li>💪 Transform your confidence with 1:1 job support</li>
        <li>
          💸 Unlock your aspirations with access to funding for training and
          more
        </li>
        <li>
          😊 Feel positive about your future with intros to top employers and
          application help
        </li>
      </ul>

      <div class="beam-header-intro">All 100% free with no strings.</div>

      <div class="mt-16 text-center">
        <a
          :href="signUpUrl"
          target="blank"
          class="button button-primary inline-block px-16 py-3"
          >👉 Register interest</a
        >
      </div>
    </header>

    <main class="mb-16">
      <section class="mt-16 sm:mt-24">
        <div>
          <h2 class="beam-h2">
            Watch how you can benefit from Beam's free help
          </h2>
        </div>

        <div class="mx-auto mt-8 max-w-3xl">
          <play-link :url="videoEmbedUrl" class="relative">
            <img :src="videoThumbnailUrl" class="h-full w-full object-cover" />

            <div
              class="center-absolute absolute inline-flex h-16 w-16 items-center justify-center rounded-full border-2 border-white bg-azure-100 text-white"
            >
              <icon name="play" :solid="true" class="h-6 w-6" />
            </div>
          </play-link>
        </div>
      </section>

      <!-- Partners -->
      <section class="mt-16 sm:mt-24">
        <div class="mx-auto max-w-4xl">
          <h2 class="beam-h2">
            Beam works with top employers who can offer flexible shifts,
            training and good pay.
          </h2>
        </div>

        <ul
          class="mt-8 flex flex-wrap justify-center sm:mt-12 sm:justify-around"
        >
          <team-partner
            v-for="partner in partners"
            :key="partner.id"
            :name="partner.name"
            :show-name="true"
            :photo-path="partner.photo"
          ></team-partner>
        </ul>
      </section>

      <!-- How it works -->
      <section class="mt-16 sm:mt-24">
        <how-it-works-item
          :index="1"
          photo="/images/newsite/how-it-works-two.jpg"
          photo-width="lg:h-56"
        >
          <template #title>
            <div class="font-medium sm:text-xl">
              We'll pay for what you need to get a job
            </div>
            <div class="font-normal italic">Such as a laptop or childcare</div>
          </template>
        </how-it-works-item>

        <how-it-works-item
          :index="2"
          photo="/images/newsite/electricians.png"
          photo-alt-text="Hussein, Claude and Leo in training"
          photo-caption="Hussein, Claude and Leo in training"
          photo-width="lg:h-56 lg:w-56"
          caption-class="-mt-5"
        >
          <template #title>
            <div class="font-medium sm:text-xl">
              1:1 support from a Beam job coach
            </div>
            <div class="font-normal italic">
              We'll take the time to listen and guide you
            </div>
          </template>
        </how-it-works-item>

        <how-it-works-item
          :index="3"
          photo="/images/newsite/davina-square.jpg"
          photo-alt-text="Davina working in an office"
          photo-caption="Davina working in an office"
          :last-item="true"
          photo-width="lg:h-56"
        >
          <template #title>
            <div class="font-medium sm:text-xl">
              Fast-track job opportunities with top employers
            </div>
            <div class="font-normal italic">That you won't find elsewhere</div>
          </template>
        </how-it-works-item>

        <div class="mt-8 text-center sm:mt-16">
          <a
            :href="signUpUrl"
            target="blank"
            class="button button-primary inline-block px-16 py-3"
            >👉 Register interest</a
          >
        </div>

        <section class="mt-16 sm:mt-24">
          <div>
            <h2 class="beam-h2">
              Decoda and 1,079 others have changed their lives
            </h2>
          </div>
        </section>

        <div
          class="full-screen-width mt-8 flex h-88 flex-wrap justify-center overflow-hidden sm:mt-16 sm:h-128"
        >
          <stories-campaign-thumb
            v-for="campaign in thumbnailCampaigns"
            :key="campaign.id"
            :campaign="campaign"
            :link-to-campaign="false"
          >
          </stories-campaign-thumb>
        </div>

        <div class="mt-8 text-center sm:mt-16">
          <a
            :href="signUpUrl"
            target="blank"
            class="button button-primary inline-block px-16 py-3"
            >👉 Register interest</a
          >
        </div>
      </section>

      <div class="mb-24">
        <faq-section
          page-type="Referrals Landing Page"
          :show-link="false"
        ></faq-section>
      </div>
    </main>

    <support-icon></support-icon>
    <member-support-modal :member-id="memberId" page="join-page">
    </member-support-modal>
  </div>
</template>

<script>
export default {
  props: {
    partners: { type: Array },
    thumbnailCampaigns: { type: Array },
    memberId: { type: Number },
    videoEmbedUrl: { type: String },
    videoThumbnailUrl: { type: String },
  },
  computed: {
    signUpUrl() {
      if (this.memberId) {
        return "/member_sign_ups/new?member_id=" + this.memberId
      } else {
        return "/member_sign_ups/new"
      }
    },
  },
}
</script>
