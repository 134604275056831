<script>
import MemberFormShared from "./MemberFormShared"

export default {
  mixins: [MemberFormShared],
  methods: {
    showField(id) {
      const form = this.form

      switch (id) {
        case "what_would_you_highlight_as_positive":
          return this.fieldMatches(form.how_likely_to_recommend, /^[1-6]$/i)
        case "what_would_you_change_to_improve_score":
          return this.fieldMatches(form.how_likely_to_recommend, /^[1-6]$/i)
        case "best_thing_about_your_experience":
          return this.fieldMatches(
            form.how_likely_to_recommend,
            /^(10|[7-9])$/i
          )
        case "one_thing_you_would_change":
          return this.fieldMatches(
            form.how_likely_to_recommend,
            /^(10|[7-9])$/i
          )
        case "work_email":
          return this.fieldEmpty(form.partner_contact_id)
        case "organisation_you_work_for":
          return this.fieldEmpty(form.partner_contact_id)
        case "your_name_and_role":
          return this.fieldEmpty(form.partner_contact_id)
        default:
          return true
      }
    },
  },
}
</script>
