<template>
  <section class="mt-10">
    <div class="font-medium">Add Gift Aid</div>

    <div class="mt-1 text-sm text-bluey-grey-100">
      This will boost your future donations by 25% at no cost to you.
    </div>

    <check-box
      checkbox-label="Yes, please add Gift Aid"
      ref="giftAidCheckbox"
      :default-checked="defaultChecked"
      v-on:checkbox-updated="onCheckboxUpdated"
    ></check-box>

    <transition name="slide-fade">
      <div v-show="showForm" class="mt-5 rounded bg-pale-grey-100 px-5 py-5">
        <div class="text-sm">
          You must be a UK taxpayer. If you pay less Income Tax and/or Capital
          Gains Tax than the amount of Gift Aid claimed on all your donations in
          that tax year, it is your responsibility to pay any difference.
        </div>
        <div class="mt-5 text-sm">
          If your name, address or tax status should change in the future, or
          you wish to cancel your declaration, please contact Beam.
        </div>
        <label for="postcode-lookup" class="mt-8 inline-block font-medium"
          >Enter your postcode</label
        >
        <input
          type="text"
          class="beam-text-input mt-5 w-full"
          v-model.trim="postcode"
          id="postcode-lookup"
          autocomplete="postal-code"
          placeholder="Enter your postcode"
        />

        <div class="mt-3 text-center">
          <a
            @click="searchPostcodes"
            href="javascript:;"
            class="button button-secondary inline-block px-16 py-3"
            >Find address</a
          >
        </div>

        <template v-if="postcodeResults">
          <div class="mt-8 font-medium">
            Select address
            <div class="mt-5 flex items-center">
              <select
                id="postcode-results"
                class="beam-text-input w-full"
                v-model="selectedIndex"
                @change="onSelectPostcode"
              >
                <option
                  v-for="(result, index) in postcodeResults"
                  :key="index"
                  :value="index"
                >
                  {{ result.line_1 }} {{ result.line_2 }}
                </option>
              </select>
              <icon
                name="chevron-down"
                class="-ml-8 inline-block h-5 w-5 text-azure-100"
              ></icon>
            </div>
          </div>
        </template>

        <template v-if="performedSearch">
          <label for="line-1" class="mt-8 inline-block font-medium"
            >Address line 1</label
          >
          <input
            type="text"
            class="beam-text-input mt-5 w-full"
            v-model="line1"
            id="line-1"
            autocomplete="address-line1"
          />

          <label for="line-2" class="mt-8 inline-block font-medium"
            >Address line 2</label
          >
          <input
            type="text"
            class="beam-text-input mt-5 w-full"
            v-model="line2"
            id="line-2"
            autocomplete="address-line2"
          />

          <label for="town-city" class="mt-8 inline-block font-medium"
            >Town / City</label
          >
          <input
            type="text"
            class="beam-text-input mt-5 w-full"
            v-model="city"
            id="town-city"
          />

          <label for="postcode" class="mt-8 inline-block font-medium"
            >Postcode</label
          >
          <input
            type="text"
            class="beam-text-input mt-5 w-full"
            v-model="postcode"
            id="postcode"
          />
        </template>
      </div>
    </transition>
  </section>
</template>

<script>
import IdealPostcodes from "ideal-postcodes-core"

export default {
  props: ["initialPostcode", "initialLine1", "initialLine2", "initialCity"],
  data() {
    return {
      checked: false,
      showForm: false,
      performedSearch: false,
      postcodeClient: null,
      postcodeResults: null,
      postcode: this.initialPostcode,
      line1: this.initialLine1,
      line2: this.initialLine2,
      city: this.initialCity,
      selectedIndex: null,
    }
  },
  methods: {
    validate() {
      if (this.checked) {
        if (!this.postcode) {
          this.$parent.displayError("Please enter your postcode for Gift Aid")
          return false
        }

        if (!this.line1) {
          this.$parent.displayError(
            "Please enter your address line 1 for Gift Aid",
            "line-1"
          )
          return false
        }

        if (!this.city) {
          this.$parent.displayError(
            "Please enter your city for Gift Aid",
            "town-city"
          )
          return false
        }
      }

      return true
    },
    onCheckboxUpdated(isChecked) {
      this.checked = isChecked

      // Only show form after the user clicks checked
      // Rather than by default
      this.showForm = this.checked
    },
    searchPostcodes() {
      if (!this.postcode) return

      this.performedSearch = true

      this.postcodeClient.lookupPostcode(
        {
          postcode: this.postcode,
        },
        this.handleResults
      )
    },
    handleResults(error, addresses) {
      // error is set but if you have no lookups left
      // Show a generic error
      if (!addresses || addresses.length == 0) {
        this.$root.showFlash(
          "Your postcode could not be found. Please type in your address.",
          "error"
        )
      } else {
        this.postcodeResults = addresses
        this.selectedIndex = 0
        this.onSelectPostcode()
      }
    },
    onSelectPostcode() {
      const address = this.postcodeResults[this.selectedIndex]

      this.line1 = address.line_1
      this.line2 = address.line_2
      this.city = address.post_town
      this.postcode = address.postcode
    },
  },
  computed: {
    defaultChecked() {
      if (this.initialPostcode) return true
      return false
    },
  },
  watch: {
    checked(newChecked, oldChecked) {
      this.$emit("gift-aid-updated", newChecked)
    },
  },
  created() {
    this.postcodeClient = new IdealPostcodes.Client({
      api_key: gon.postcodes_api_key,
    })

    this.checked = this.defaultChecked
  },
}
</script>
