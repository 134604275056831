<template>
  <landing-header>
    <template #pill>Beam for homeless people</template>

    <template #title>
      <div>
        Reduce homelessness with Beam's
        <span class="yellow-underline-h1">innovative support model</span>
      </div>
    </template>

    <template #body>
      <div>
        Beam partners with government to design and deliver services that
        support diverse groups (including families in temporary accommodation,
        ex-offenders, care leavers and refugees) out of homelessness for good.
      </div>
      <div class="mt-8">
        A dedicated caseworker enables them to overcome barriers and fulfil
        their potential through employment, housing or English as a second
        language (ESOL) support.
      </div>
    </template>

    <template #photo>
      <img
        :src="`/images/john.png`"
        alt="John"
        class="bg-azure-100 md:rounded-sm"
      />
    </template>

    <template #caption>
      John who worked with Beam to become a gardener
    </template>
  </landing-header>

  <member-groups-councils></member-groups-councils>

  <section class="full-screen-width mt-16 bg-pale-grey-100 py-20 sm:mt-24">
    <div class="beam-container">
      <!-- Bar Chart -->
      <div class="bg-white px-5 py-10 text-center lg:px-16 lg:py-16">
        <h2 class="beam-h2 mx-auto max-w-xl">
          Beam saves the government
          <span class="yellow-underline-h2">£31,300 per person</span>
          supported into work
        </h2>

        <div class="mt-12 text-center">
          <img
            :src="`/images/homeless-people-chart.png`"
            alt="Beam saves the government £31,300 per person supported into work"
            class="mx-auto md:max-w-2xl"
          />
        </div>

        <div class="mt-8">
          Source:
          <a
            href="https://beamorg.s3.eu-west-2.amazonaws.com/uploads/beam_impact_report_+may_2021.pdf"
            target="_blank"
            class="text-sm underline sm:text-base"
            >McKinsey Impact Report</a
          >
        </div>
      </div>

      <!-- Success Story -->
      <success-story
        class="-mx-8"
        :campaign="{
          member_name: 'Regina',
        }"
        video-url="https://www.youtube.com/embed/V_gaseiICfw"
        :link-to-more="false"
        photo="/images/regina-yellow.png"
      >
        <template #title>Now she has a place to call home.</template>
        <template #subtitle
          ><p>Regina became homeless after leaving an abusive relationship.</p>
          <p>
            Beam's one-to-one, person-centred support allowed Regina to enter
            employment and move into a new home with her daughter.
          </p></template
        >
      </success-story>
    </div>
  </section>

  <landing-how-it-works class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>How it works</template>

    <template #body>
      Beam achieves market-leading outcomes by removing financial barriers,
      empowering the community and using pioneering technology.
    </template>
  </landing-how-it-works>

  <!-- Remove all barriers -->
  <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>Remove all barriers to work and housing</template>

    <template #body>
      <div class="flex flex-col gap-2.5">
        <p>
          Beam provides one-to-one and group support, ensuring homeless
          individuals have everything they need to find employment and secure
          housing.
        </p>
        <p>
          We provide an integrated employment and housing service to seamlessly
          support residents with house hunting after employment has increased
          their affordability. We also provide employment support to improve
          tenancy sustainment after someone has been housed.
        </p>
        <p>
          Our dedicated frontline casework teams facilitate same-day rent
          guarantor agreements and organise payments to landlords, maximising
          success in a competitive market.
        </p>
      </div>
    </template>

    <template #image>
      <div class="max-w-card sm:w-80 sm:max-w-full flex flex-col text-center">
        <div class="w-full relative">
          <span
            class="absolute bg-yellow-100 text-navy-800 font-bold rounded-full w-1/3 h-1/3 top-1/3 left-1/3 text-4xl text-bold text-center content-center"
            >94%</span
          >
          <img :src="`/images/member-groups/remove-all-barriers.jpg`" />
        </div>
        <h3 class="text-navy-800 font-bold mt-5">
          Sustainment after 12 months
        </h3>
        <p>
          Market-leading outcomes are achieved through ongoing support from our
          team
        </p>
      </div>
    </template>
  </landing-left-text>

  <!-- Analytics -->
  <landing-left-text
    class="mt-16 sm:mt-20 lg:mt-32"
    :image-left="true"
    :text-right-align="false"
  >
    <template #title>Best-in-class analytics and reporting</template>

    <template #body>
      <div class="flex flex-col gap-2.5">
        <p>
          Our caseworkers track KPIs related to contract delivery using Beam's
          proprietary software tooling. Our delivery teams then provide regular
          analytical reporting to customers on progress, insights and key
          milestones.
        </p>
        <p>
          This detailed reporting is tailored to each customer's individual
          needs, and ensures we seamlessly embed into customers' internal
          processes and ways of working.
        </p>
      </div>
    </template>

    <template #image>
      <div class="max-w-card sm:w-[592px] sm:max-w-full">
        <img :src="`/images/member-groups/analytics.jpg`" />
      </div>
    </template>
  </landing-left-text>

  <!-- Awards -->
  <section class="mt-16 sm:mt-24">
    <h2 class="beam-h2">Beam is proud to be...</h2>

    <ul class="mt-8 flex flex-wrap justify-center sm:mt-12 sm:justify-around">
      <team-partner
        class="w-1/3 sm:w-auto"
        v-for="award in awards"
        :key="award.id"
        :name="award.name"
        :show-name="true"
        :photo-path="award.photo"
      ></team-partner>
    </ul>
  </section>

  <section class="mt-16 sm:mt-24">
    <h2 class="beam-h2">What our customers say</h2>
    <landing-testimonials :testimonials="testimonials"></landing-testimonials>
  </section>

  <landing-bottom-section group-descriptor="homeless"></landing-bottom-section>
</template>

<script>
export default {
  props: {
    homelessCampaign: {
      type: Object,
      required: true,
    },
    awards: { type: Array },
    csrfToken: { type: String },
    testimonials: { type: Array },
  },
}
</script>
