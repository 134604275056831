<template>
  <landing-header>
    <template #pill>Beam for refugees</template>

    <template #title>
      <div>
        Enable asylum seekers and refugees to
        <span class="yellow-underline-h1">thrive in UK communities</span>
      </div>
    </template>

    <template #body>
      <div>
        Beam partners with government to support asylum seekers and refugees to
        understand their entitlements in the UK and integrate into communities.
      </div>
      <div class="mt-8">
        A dedicated caseworker enables them to overcome barriers and fulfil
        their potential through employment, housing or English language (ESOL)
        support.
      </div>
    </template>

    <template #photo>
      <img
        :src="`/images/newsite/hana.png`"
        alt="Hana"
        class="bg-pink-100 md:rounded-sm"
      />
    </template>

    <template #caption> Hana worked with Beam to become a beautician </template>
  </landing-header>

  <member-groups-councils></member-groups-councils>

  <section class="full-screen-width mt-16 bg-pale-grey-100 py-20 sm:mt-24">
    <div class="beam-container">
      <!-- Bar Chart -->
      <div class="bg-white px-5 py-10 text-center lg:px-16 lg:py-16">
        <h2 class="beam-h2 mx-auto max-w-xl">
          Refugees using Beam are
          <span class="yellow-underline-h2">three times as likely</span> to
          secure jobs
        </h2>

        <div class="mt-12 text-center">
          <img
            :src="`/images/refugees-chart.png`"
            alt="Refugees using Beam are three times as likely to secure jobs"
            class="mx-auto md:max-w-2xl"
          />
        </div>

        <div class="mt-4 hidden text-center font-medium sm:block">
          % of users who start work with intervention
        </div>

        <div class="mt-8">
          Source:
          <a
            href="https://beamorg.s3.eu-west-2.amazonaws.com/uploads/beam_impact_report_+may_2021.pdf"
            target="_blank"
            class="text-sm underline sm:text-base"
            >McKinsey Impact Report</a
          >
        </div>
      </div>

      <!-- Success Story -->
      <success-story
        class="-mx-8"
        :campaign="{
          member_name: 'Javad',
        }"
        :link-to-more="false"
        photo="/media/javad.jpg"
        text-after-name="was an unemployed refugee."
      >
        <template #title>Now he's working as a digger operator.</template>
        <template #subtitle
          ><p>
            After fleeing persecution, Javad was supported with one-to-one
            tailored support from his caseworker.
          </p>
          <p>
            He's got an advanced qualification in construction and is now
            working as a digger operator.
          </p></template
        >
      </success-story>
    </div>
  </section>

  <landing-how-it-works class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>How it works</template>

    <template #body>
      Beam achieves market-leading outcomes by removing financial barriers,
      empowering the community and using pioneering technology.
    </template>
  </landing-how-it-works>

  <!-- Remove all barriers -->
  <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>Remove all barriers to work and housing</template>

    <template #body>
      <div class="flex flex-col gap-2.5">
        <p>
          Beam provides focused one-to-one and group support for asylum seekers
          and refugees, both within hotels and supported accommodation, and
          virtually. Our bespoke programmes equip individuals with an
          understanding of their entitlements in the UK, the employment and
          housing landscape, and the specific opportunities different
          communities present.
        </p>
        <p>
          We provide an integrated employment and housing service, to seamlessly
          support residents with house hunting after employment has increased
          their affordability. We also provide employment support to improve
          tenancy sustainment after someone has been housed.
        </p>
        <p>
          Our dedicated delivery teams can provide rapid support when hotel
          accommodation provision changes, facilitate same-day rent guarantor
          agreements and organise payments to landlords - all maximising success
          in a competitive market.
        </p>
      </div>
    </template>

    <template #image>
      <div class="max-w-card sm:w-80 sm:max-w-full flex flex-col text-center">
        <div class="w-full relative">
          <span
            class="absolute bg-yellow-100 text-navy-800 font-bold rounded-full w-1/3 h-1/3 top-1/3 left-1/3 text-4xl text-bold text-center content-center"
            >94%</span
          >
          <img :src="`/images/member-groups/remove-all-barriers.jpg`" />
        </div>
        <h3 class="text-navy-800 font-bold mt-5">
          Sustainment after 12 months
        </h3>
        <p>Achieved through ongoing support from our team</p>
      </div>
    </template>
  </landing-left-text>

  <!-- Tailoring our services -->
  <landing-quotes-section
    pink-photo="/images/abdullah-pink.png"
    member-name="Abdullah"
  >
    <template #title>
      Tailoring our services to meet the needs of individuals
    </template>

    <template #subtitle>
      <p class="text-xl mt-5">
        One-to-one support is combined with educational workshops and printed
        materials, tailored to residents' first language.
      </p>
      <p class="mt-5 text-xl">
        We also partner with forward-thinking landlords and employers,
        supporting individuals with employment that can improve affordability
        and access to the private rented sector (PRS), or with higher-skilled
        and higher-paid employment that is in line with their previous
        experience.
      </p>
    </template>

    <template #member-quote>
      “I feel like I'm expanding my life compared to before when I just wanted
      to survive.”
    </template>
  </landing-quotes-section>

  <!-- Analytics -->
  <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>Best-in-class analytics and reporting</template>

    <template #body>
      <div class="flex flex-col gap-2.5">
        <p>
          Our caseworkers track KPIs related to contract delivery using Beam's
          proprietary software tooling. Our delivery teams then provide regular
          analytical reporting to customers on progress, insights and key
          milestones.
        </p>
        <p>
          This detailed reporting is tailored to each customer's individual
          needs, and ensures we seamlessly embed into their internal processes
          and ways of working.
        </p>
      </div>
    </template>

    <template #image>
      <div class="max-w-card sm:w-[592px] sm:max-w-full">
        <img :src="`/images/member-groups/analytics.jpg`" />
      </div>
    </template>
  </landing-left-text>

  <!-- Awards -->
  <section class="mt-16 sm:mt-24">
    <h2 class="beam-h2">Beam is proud to be...</h2>

    <ul class="mt-8 flex flex-wrap justify-center sm:mt-12 sm:justify-around">
      <team-partner
        class="w-1/3 sm:w-auto"
        v-for="award in awards"
        :key="award.id"
        :name="award.name"
        :show-name="true"
        :photo-path="award.photo"
      ></team-partner>
    </ul>
  </section>

  <section class="mt-16 sm:mt-24">
    <h2 class="beam-h2">What our customers say</h2>
    <landing-testimonials :testimonials="testimonials"></landing-testimonials>
  </section>

  <!-- Contact -->
  <contact-section
    :csrf-token="csrfToken"
    submission-page="refugees"
    class="mt-16 lg:mt-32"
    title="I support refugees
"
  >
    <template #subtitle> See how Beam can support refugee integration</template>
  </contact-section>

  <landing-bottom-section group-descriptor="a refugee"></landing-bottom-section>
</template>

<script>
export default {
  props: {
    refugeesCampaign: {
      type: Object,
      required: true,
    },
    awards: { type: Array },
    csrfToken: { type: String },
    testimonials: { type: Array },
  },
}
</script>
