<template>
  <div>
    <header>
      <slot name="header"></slot>
    </header>

    <main class="my-10 sm:my-20 lg:flex">
      <div
        class="full-screen-mobile flex flex-col flex-wrap items-center justify-around bg-pale-grey-100 px-5 py-10 sm:w-full sm:flex-row sm:rounded sm:px-10 sm:py-10"
      >
        <div
          v-for="(fundraiser, index) in fundraisers"
          :key="fundraiser.id"
          class="mt-5 w-card lg:w-80"
        >
          <fundraiser-card :fundraiser="fundraiser"></fundraiser-card>

          <div v-if="index == 3" class="w-card sm:my-16 lg:hidden">
            <slot name="prompt"></slot>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    fundraisers: { type: Array },
  },
}
</script>
