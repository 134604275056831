<template>
  <div class="mx-3 mt-3 overflow-hidden rounded-lg">
    <a class="block" :href="campaignLink">
      <img
        class="card-hover-small h-20 w-20 rounded sm:h-32 sm:w-32"
        v-lazy="campaign.thumb_avatar_url"
        :alt="campaign.member_name"
      />
      <div class="mt-2 text-center text-xs sm:text-base">
        {{ campaign.member_name }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    campaign: { type: Object },
    linkToCampaign: { type: Boolean, default: true },
  },
  computed: {
    campaignLink() {
      if (this.linkToCampaign) {
        return `/campaigns/${this.campaign.slug}`
      }

      return null
    },
  },
}
</script>
