<template>
  <div class="flex flex-row items-end space-x-6 member-form-bill-field">
    <member-form-field
      class="member-form-bill-field-currency"
      :key="visibleCurrencyField.id"
      :field="visibleCurrencyField"
      :show-label="showCurrencyLabel"
      v-model="visibleCurrencyField.value"
      :disabled="usingAverage"
    >
      forcerefresh
    </member-form-field>
    <div class="inline-member-form-radio-group my-1.5">
      <member-form-field
        v-if="useAverageField"
        :key="useAverageField.id"
        :field="useAverageField"
        :show-label="false"
        v-model="useAverageField.value"
      >
        forcerefresh
      </member-form-field>
      <member-form-field
        v-if="!usingAverage"
        :key="frequencyField.id"
        :field="frequencyField"
        :show-label="false"
        v-model="frequencyField.value"
        :disabled="usingAverage"
      >
        forcerefresh
      </member-form-field>
      <div
        v-if="usingAverage"
        class="member-form-radio-group"
        role="radiogroup"
      >
        <div class="member-form-radio">
          <input type="radio" class="form-radio" disabled checked />
          <label class="text-base">Monthly</label>
        </div>
        <div class="member-form-radio">
          <input type="radio" class="form-radio" disabled />
          <label class="text-base">Weekly</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fieldPrefix: { type: String },
    fields: { type: Object },
    showCurrencyLabel: { type: Boolean, default: true },
  },
  data() {
    return {
      initialRequired: this.fields[this.fieldPrefix].required,
    }
  },
  computed: {
    originalCurrencyField() {
      return this.fields[this.fieldPrefix]
    },
    visibleCurrencyField() {
      if (this.useAverageField?.value) {
        return this.fields[this.fieldPrefix + "_average"]
      }
      return this.originalCurrencyField
    },
    frequencyField() {
      return this.fields[this.fieldPrefix + "_frequency"]
    },
    useAverageField() {
      return this.fields[this.fieldPrefix + "_use_average"]
    },
    usingAverage() {
      return this.useAverageField?.value
    },
  },
  methods: {
    setRequired() {
      if (this.usingAverage) {
        this.originalCurrencyField.required = false
      } else {
        this.originalCurrencyField.required = this.initialRequired
      }
    },
  },
  created() {
    this.setRequired()
  },
  // When the average is used, the regular currency field should be made non-required:
  watch: {
    usingAverage: {
      handler() {
        this.setRequired()
      },
    },
  },
}
</script>
