<template>
  <section
    class="full-screen-width mt-16 bg-pale-grey-100 py-20 sm:mt-20 lg:mt-32"
  >
    <div class="beam-container">
      <press-quotes></press-quotes>

      <div class="mt-12 bg-pale-lilac-100" style="height: 2px"></div>

      <div class="mt-12 flex flex-col items-center sm:flex-row lg:mt-16">
        <div class="sm:w-1/2 sm:pr-20">
          <div class="beam-h2 text-left">
            Any questions?
            <div class="inline sm:block">Get in touch</div>
          </div>

          <div class="mt-6 text-left text-sm sm:text-xl">
            Drop us a line at
            <a href="mailto:partner@beam.org" class="underline"
              >partner@beam.org</a
            >
            and we'll get back to you as soon as possible.
          </div>

          <div class="mt-4 text-left text-sm sm:text-xl">
            Are you {{ groupDescriptor }}?
            <a href="mailto:hello@beam.org" class="underline"
              >Get help from Beam now</a
            >
          </div>
        </div>

        <div class="mt-8 sm:mt-0 sm:w-1/2">
          <img :src="`/images/chloe.jpg`" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    groupDescriptor: {
      type: String,
      required: true,
    },
  },
}
</script>
