<template>
  <div
    class="mt-5 items-center space-y-3 sm:mt-12 lg:flex lg:space-x-5 lg:space-y-0"
  >
    <small-success-box photo="/images/hana-pink-small.jpg"
      ><a
        href="/campaigns/hana-beautician-training-2"
        :class="{ underline: useLinks, 'pointer-events-none': !useLinks }"
        >Hana</a
      >
      was <span class="font-medium">homeless</span> <br />
      Now she's working as a
      <span class="font-medium">beautician</span>
    </small-success-box>
    <small-success-box photo="/images/kateryna-blue.png">
      <a
        href="/campaigns/kateryna"
        :class="{ underline: useLinks, 'pointer-events-none': !useLinks }"
        >Kateryna</a
      >
      was <span class="font-medium">a refugee</span> <br />
      We helped her to find <span class="font-medium">stable housing</span>
    </small-success-box>
    <small-success-box photo="/images/tony-yellow.png">
      <a
        href="/campaigns/tony-electrical-training"
        :class="{ underline: useLinks, 'pointer-events-none': !useLinks }"
        >Tony</a
      >
      was <span class="font-medium">homeless</span> <br />
      Now he's working as an
      <span class="font-medium">electrician</span>
    </small-success-box>
  </div>
</template>

<script>
export default {
  props: {
    // Company onboarding doesn't want the links to be clickable
    useLinks: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
