<template>
  <section>
    <div
      class="flex flex-col items-center justify-between sm:flex-row gap-x-16 gap-y-8"
      :class="{ 'beam-container': fullWidth }"
    >
      <div
        class="sm:mt-0 sm:w-1/2"
        :class="{ 'sm:order-1': !imageLeft, 'sm:order-2': imageLeft }"
      >
        <div
          v-if="$slots.pill"
          class="mb-2 inline-block rounded bg-pale-grey-100 px-3 py-2 text-xs font-medium uppercase sm:text-base"
        >
          <slot name="pill"></slot>
        </div>
        <div
          class="beam-h2 text-left"
          :class="{
            'sm:text-left': !shouldAlignTextRight,
            'sm:text-right': shouldAlignTextRight,
          }"
        >
          <slot name="title"></slot>
        </div>

        <div
          class="mt-3 text-left text-sm sm:mt-5 sm:text-xl"
          :class="{
            'sm:text-left': !shouldAlignTextRight,
            'sm:text-right': shouldAlignTextRight,
          }"
        >
          <slot name="body"></slot>
        </div>
      </div>

      <!-- Image -->
      <div :class="{ 'sm:order-2': !imageLeft, 'sm:order-1': imageLeft }">
        <slot name="image"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    imageLeft: {
      type: Boolean,
      default: false,
    },
    textRightAlign: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    // This is a computed property that returns a boolean value
    // based on the value of the `textRightAlign` prop,
    // or the `imageLeft` prop if `textRightAlign` is not
    shouldAlignTextRight() {
      if (typeof this.textRightAlign !== "boolean") {
        return this.imageLeft
      }

      return this.textRightAlign
    },
  },
}
</script>
