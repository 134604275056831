<template>
  <section class="mt-16 sm:mt-20 lg:mt-32">
    <h2 class="beam-h2">
      We've successfully funded {{ fundedCount }} campaigns so far
    </h2>

    <div
      class="full-screen-width mt-8 flex h-88 flex-wrap justify-center overflow-hidden sm:mt-16 sm:h-128"
    >
      <stories-campaign-thumb
        v-for="campaign in thumbnailCampaigns"
        :key="campaign.id"
        :campaign="campaign"
      >
      </stories-campaign-thumb>
    </div>

    <!-- Bolds here -->
    <div class="mx-auto mt-8 max-w-lg text-center sm:mt-10 sm:text-lg lg:mt-20">
      That's <span class="font-medium">{{ totalPayments }}</span> donated.
      That's
      <span class="font-medium">{{ totalUsers.toLocaleString() }}</span> Beam
      supporters. That's
      <span class="font-medium">{{ fundedCount }}</span> amazing people who have
      been granted another opportunity to achieve their potential.
    </div>

    <div class="mt-8 text-center sm:mt-10 lg:mt-16">
      <a
        href="/campaigns/funded"
        class="button button-secondary inline-block px-12 py-3"
        >See lives changed</a
      >
    </div>
  </section>
</template>

<script>
export default {
  props: {
    thumbnailCampaigns: {
      type: Array,
    },
    fundedCount: {
      type: Number,
    },
    totalPayments: {
      type: String,
    },
    totalUsers: {
      type: Number,
    },
  },
}
</script>
