export default {
  methods: {
    showField(id) {
      const form = this.thisForm

      switch (id) {
        case "member_id":
          return form.member_id.value ? false : true
        case "manager_sign_off_comments":
        case "r2w_check":
        case "reference_check":
        case "homelessness_check":
        case "safety_check":
        case "safe_to_join_employment":
        case "safe_to_join_lettings":
        case "data_and_consent_form_completed":
          return false
        case "language_and_communication_risk_notes":
          // This is based on convention and won't work unless one of the possible risks starts with "No "
          const riskLabelFieldName = id.replace(/_notes$/, "")
          return (
            this.fieldNotEmpty(form[riskLabelFieldName]) &&
            !this.fieldStartsWith(form[riskLabelFieldName], "No ")
          )
        default:
          // If member ID is set, say true - otherwise say false
          return form.member_id.value ? true : false
      }
    },
  },
  watch: {
    "form.beam_service.value"(newValue) {
      return [
        "financial_and_debt_risk",
        "mental_health_risk",
        "physical_health_risk",
        "substance_misuse_risk",
        "gambling_risk",
        "criminal_justice_risk",
        "safety_and_domestic_abuse_risk",
        "language_and_communication_risk",
      ].forEach((fieldKey) => {
        this.form[fieldKey].required = newValue == "Homes" ? true : false
      })
    },
  },
}
