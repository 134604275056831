<template>
  <div
    v-if="notifications.length > 0"
    class="stack-mt-5 full-screen-mobile bg-pale-grey-100 py-10 sm:rounded"
  >
    <member-news-item
      v-for="newsItem in notifications"
      :key="newsItem.id"
      :news-item="newsItem"
      :member-id="memberId"
    >
    </member-news-item>
  </div>
</template>

<script>
export default {
  props: {
    memberId: Number,
    feedType: String,
  },
  data() {
    return {
      notifications: [],
      page: 0,
      finished: false,
      fetching: false,
    }
  },
  created() {
    this.fetchPage()
  },
  mounted() {
    this.handleScroll()
  },
  unmounted() {
    window.onscroll = null
  },
  methods: {
    fetchPage() {
      if (this.finished || this.fetching) {
        return
      }

      this.fetching = true

      let url = `/members/${this.memberId}/news_items?page=${this.page}&feed_type=${this.feedType}`

      this.axios.get(url).then((response) => {
        const data = response.data
        this.fetching = false
        this.notifications = this.notifications.concat(data.notifications)

        this.$emit("on-unread-count", data.unread_count)

        this.page += 1

        if (data.notifications.length == 0) {
          this.finished = true
        }
      })
    },
    handleScroll() {
      window.onscroll = () => {
        var scrollPos = window.scrollY || document.documentElement.scrollTop
        if (
          scrollPos + window.innerHeight + 2000 >=
          document.documentElement.scrollHeight
        ) {
          this.fetchPage()
        }
      }
    },
  },
}
</script>
