<template>
  <div class="mx-3 mt-3 overflow-hidden rounded-lg">
    <img
      class="card-hover-small h-40 w-40 rounded object-cover sm:h-50 sm:w-50"
      v-lazy="path"
      :alt="alt"
    />
  </div>
</template>

<script>
export default {
  props: {
    alt: { type: String },
    path: { type: String },
  },
}
</script>
