<template>
  <option :value="amount">
    {{ amount }}%
    <template v-if="showCurrencyAmount">
      ({{ $parent.currency }}{{ $parent.beamAmount(amount) }})
    </template>
  </option>
</template>

<script>
export default {
  props: {
    amount: Number,
  },
  computed: {
    showCurrencyAmount() {
      return this.amount > 0
    },
  },
}
</script>
