import ValidationsMixin from "./ValidationsMixin"
import PaymentRequestMixin from "./PaymentRequestMixin"
import AnalyticsMixin from "../mixins/AnalyticsMixin"

import * as StripeApi from "../../stripe.js"

export default {
  mixins: [AnalyticsMixin, ValidationsMixin, PaymentRequestMixin],
  props: {
    initialGiftAidUser: { type: Object },
    initialUser: { type: Object },
    initialCard: { type: Object },
    defaultAmount: { type: Number, required: false },
    nameSpamRegex: { type: String, required: false },
    emailSpamRegex: { type: String, required: false },
  },
  data() {
    return {
      beamDonationPercent: 0,
      emailAddress: this.initialUser.email,
      errorMessage: "",
      firstName: this.initialUser.first_name,
      lastName: this.initialUser.last_name,
      giftAidChecked: false,
      savedCard: this.initialCard,
      selectedAmount: null,
      submitting: false,
      supporterMessage: "",
      hasPaymentRequest: true,
      paymentResponse: null,
    }
  },
  computed: {
    fullName() {
      return `${this.firstName} ${this.lastName}`.trim()
    },
    giftAidDisplay() {
      return this.giftAidChecked ? "Yes" : "No"
    },
    loggedIn() {
      return this.initialUser.email
    },
    billingDetails() {
      return {
        name: this.fullName,
        email: this.emailAddress,
      }
    },
    totalAmountPence() {
      return Math.round(this.totalAmount * 100)
    },
    currency() {
      return this.$root.currency
    },
  },
  methods: {
    clearCardDetails() {
      this.savedCard = null
    },
    setDefaultAmount() {
      if (this.defaultAmount && this.donationType == this.defaultDonationType) {
        // Default amount passed in URL
        this.selectedAmount = this.defaultAmount
        if (this.amounts.indexOf(this.selectedAmount) < 0) {
          // Open Other for default amount
          this.$refs.checkoutAmounts.selectOther()
        }
      } else {
        this.selectedAmount = this.amounts[0]
      }
    },
    onUpdateSelectedAmount(amount) {
      this.selectedAmount = amount
    },
    displayError(message, elId) {
      this.errorMessage = message

      if (elId) {
        const el = document.getElementById(elId)

        if (el) {
          el.classList.add("beam-input-error")
        }
      }

      this.submitting = false
    },
    donateClicked() {
      if (this.submitting) return

      this.submitting = true

      if (localStorage.getItem("spamUser")) {
        return this.displayError("Please contact support")
      }

      if (!this.validate()) return

      if (this.monthlyType) {
        this.handleMonthly()
        this.logEvent("donate-clicked", "monthly-" + this.selectedAmount)

        if (this.clickedUpsell) {
          this.logEvent("donate-upsell", this.selectedAmount)
        }
      } else {
        this.logEvent("donate-clicked", "one-off-" + this.selectedAmount)
        this.handleOneOff()
      }
    },
    handlePaymentMethodSuccess(result) {
      this.postPayment({
        paymentMethodId: result.paymentMethod.id,
        paymentMethodType: "new-card",
      })
    },
    handleMonthly() {
      if (this.savedCard) {
        this.postPayment({ paymentMethodType: "saved-card" })
      } else {
        StripeApi.createPaymentMethod(
          this.$refs.stripeElement.elementCard,
          this.billingDetails,
          (result) => {
            this.handlePaymentError(result.error)
          },
          this.handlePaymentMethodSuccess
        )
      }
    },
    handleOneOff() {
      const callback = (result) => {
        if (result.error) {
          this.handlePaymentError(result.error)
        } else {
          if (result.paymentIntent.status === "succeeded") {
            let paymentMethodType
            if (this.savedCard) {
              paymentMethodType = "saved-card"
            } else {
              paymentMethodType = "new-card"
            }

            this.postPayment({
              paymentMethodId: result.paymentIntent.payment_method,
              paymentIntentId: result.paymentIntent.id,
              paymentMethodType: paymentMethodType,
            })
          }
        }
      }

      this.axios
        .post(`/payment_intents`, {
          amount: this.totalAmountPence,
          description: "one-off",
        })
        .then((result) => {
          let paymentMethod = null

          if (this.savedCard) {
            paymentMethod = gon.default_payment_source
          } else {
            paymentMethod = {
              card: this.$refs.stripeElement.elementCard,
              billing_details: this.billingDetails,
            }
          }

          StripeApi.confirmIntentWithMethod(
            result.data.secret,
            paymentMethod,
            callback
          )
        })
        .catch((error) => {
          this.handleServerError()
        })
    },
    postPayment(opts = {}) {
      const data = this.postData(
        opts.paymentMethodId,
        opts.paymentIntentId,
        opts.stripeSubscriptionId,
        opts.paymentMethodType
      )

      this.axios
        .post(this.postPath, data)
        .then((response) => {
          this.handleServerResponse(response.data)
        })
        .catch((error) => {
          this.handleServerError()
        })
    },
    handleServerResponse(data) {
      if (this.paymentResponse) {
        this.paymentResponse.complete("success")
      }

      if (data.stripe_subscription) {
        // Only getting in here if the subscription is incomplete
        const { latest_invoice } = data.stripe_subscription
        const { payment_intent } = latest_invoice
        const { client_secret, status } = payment_intent

        if (payment_intent.last_payment_error) {
          this.logEvent(
            "payment-error",
            payment_intent.last_payment_error.decline_code
          )

          if (payment_intent.last_payment_error.decline_code == "fraudulent") {
            localStorage.setItem("spamUser", true)
            this.displayError("Please contact support")
          }
          return
        }

        if (status === "requires_action") {
          const callback = (result) => {
            if (result.error) {
              this.handlePaymentError(result.error)
            } else {
              // Not sending the payment method type here
              this.postPayment({
                stripeSubscriptionId: data.stripe_subscription.id,
              })
            }
          }

          StripeApi.confirmPaymentIntent(client_secret, callback)
        } else if (status === "requires_payment_method") {
          this.clearCardDetails()
          this.displayError(
            "Sorry, that payment did not go through. Please try a new card.",
            "card-element"
          )
        }
      }

      if (data.success) {
        location = data.redirect_url
      } else if (data.error_message) {
        this.displayError(data.error_message)
      }
    },
    handlePaymentError(error) {
      let errorMessage = error.message

      if (error.code == "incomplete_zip") {
        errorMessage = "Please enter your Postcode on the Card details form."
      }

      if (
        error.payment_intent &&
        error.payment_intent.status == "requires_payment_method"
      ) {
        this.clearCardDetails()
      }

      this.displayError(errorMessage, "card-element")
    },
    handleServerError() {
      this.displayError(
        "We're sorry, there was a problem. Please contact support."
      )
    },
  },
  watch: {
    errorMessage(newMessage) {
      if (!newMessage) return
      this.$scrollTo(document.getElementById("donate-button"), 500, {})
    },
  },
}
