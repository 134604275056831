<template>
  <div class="mt-16">
    <div
      class="full-screen-mobile rounded bg-pale-grey-100 pt-10"
      :class="{ 'sm:pb-24': quotes, 'sm:pb-10': !quotes }"
    >
      <div class="px-8 sm:flex sm:items-center">
        <div class="order-2 sm:px-8 md:w-1/2 lg:px-16">
          <div class="beam-h2 sm:text-left text-4xl">
            {{ campaign.member_name }}
            {{ textAfterName }}.
          </div>
          <div class="beam-h2 sm:text-left text-4xl">
            <slot name="title"></slot>
          </div>

          <div
            class="mt-8 text-center sm:text-left sm:text-xl gap-2.5 flex flex-col"
          >
            <slot name="subtitle"></slot>
          </div>

          <div v-if="linkToMore" class="mt-8 text-center sm:text-left">
            <fancy-link
              href="/stories"
              class="button button-secondary inline-block w-full px-0 py-3 xs:w-auto xs:px-8 lg:px-12"
              >Read more success stories</fancy-link
            >
          </div>

          <div
            v-else
            class="mt-10 flex items-center justify-center sm:justify-start lg:mt-16"
          >
            <!-- Video button -->
            <template v-if="videoUrl">
              <div
                class="hover-bounce inline-flex h-12 w-12 items-center justify-center rounded-full border-2 border-azure-100"
              >
                <play-button :url="videoUrl"></play-button>
              </div>
              <div class="ml-2 text-sm">
                <div class="font-medium">
                  Play {{ campaign.member_name }}'s story
                </div>
              </div>
            </template>

            <!-- Link to campaign -->
            <template v-if="!videoUrl && campaign.slug">
              <a
                :href="`/campaigns/${campaign.slug}`"
                class="button button-secondary px-12 py-3"
                >Read {{ campaign.member_name }}'s story</a
              >
            </template>
          </div>
        </div>

        <!-- Photo -->
        <div
          class="full-screen-mobile order-1 mt-10 overflow-hidden sm:mt-0 sm:w-1/2"
        >
          <img
            v-lazy="photoPath"
            :alt="campaign.member_name"
            class="md:rounded-sm"
          />
        </div>
      </div>
    </div>

    <!-- Supporter Messages -->
    <div v-if="quotes" class="-mt-12 flex justify-center xs:mx-auto">
      <div
        v-for="(quote, index) in quotes"
        :key="quote.id"
        class="relative mx-4 xs:max-w-card"
        :class="storyClasses(index)"
      >
        <div class="card-shadow rounded-lg bg-white p-5 text-center">
          <div class="text-xs sm:text-sm">{{ quote.text }}</div>

          <div class="mt-3 text-xs">
            <span class="mr-1 font-medium">{{ quote.name }}</span>
            <span class="text-bluey-grey-100">{{ quote.amount }} donation</span>
          </div>
        </div>
        <div class="mx-auto mt-3 h-12 w-12 overflow-hidden rounded-full">
          <img :src="quote.photo" :alt="quote.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaign: Object,
    linkToMore: Boolean,
    quotes: Array,
    videoUrl: String,
    photo: String,
    textAfterName: {
      type: String,
      default: "was homeless",
    },
  },
  computed: {
    photoPath() {
      if (this.photo) {
        return this.photo
      }

      return `/images/newsite/${this.campaign.member_name.toLowerCase()}.png`
    },
  },
  methods: {
    storyClasses(index) {
      if (index == 0) return

      let result = ["hidden"]

      index == 1 ? result.push("sm:block") : result.push("lg:block")

      return result
    },
  },
}
</script>
