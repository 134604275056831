<template>
  <div class="stack-mt-5">
    <div class="text-xl sm:text-2xl">
      {{ donatedAmount }}
      <div class="mt-1 text-base text-bluey-grey-100 sm:text-lg">
        raised of £{{ target.toLocaleString() }}
      </div>
    </div>
    <div class="text-lg sm:text-xl">
      {{ supportersCount }}
      <div class="mt-1 text-base text-bluey-grey-100 sm:text-lg">
        supporters
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    donatedAmount: { type: String },
    supportersCount: { type: Number },
    target: { type: Number },
  },
}
</script>
