<template>
  <card
    :name="campaign.member_name"
    :photoUrl="campaign.medium_avatar_url"
    :percentageDonated="campaign.percentage_donated_display"
    :donatedAmount="campaign.donated_amount_display"
    :target="campaign.formatted_target"
    :remainingAmount="campaign.remaining_amount_display"
    :path="`/campaigns/${campaign.slug}`"
  >
    <div class="p-5" :class="cardHeight">
      <div class="text-xl font-medium">
        <span class="text-pink-100">{{ campaign.member_name }}</span>
        {{ campaign.sentence_stage_display }}
      </div>
      <div
        v-if="showSnippet"
        class="fadey-snippet relative mt-4 overflow-hidden text-sm"
        style="height: 64px"
      >
        {{ campaign.snippet_display }}
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    campaign: { type: Object },
    showSnippet: { type: Boolean, default: true },
  },
  computed: {
    cardHeight() {
      if (this.showSnippet) {
        return "h-42"
      } else {
        return "h-24"
      }
    },
  },
}
</script>
