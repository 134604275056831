<template>
  <a
    v-bind:href="'/member_job_applications/' + id"
    class="card-shadow mb-3 block cursor-pointer rounded bg-white p-5 sm:p-8"
  >
    <!-- Top -->
    <div class="flex">
      <div>
        <div class="flex items-center pb-4 md:font-bold">
          <div
            v-if="job.employer.thumb_avatar_url"
            class="mr-4 h-10 w-10 shrink-0"
          >
            <img
              class="h-10 w-10 rounded-full"
              :src="job.employer.thumb_avatar_url"
              alt=""
            />
          </div>
          {{ job.job_title }} @ {{ job.employer.name }}
        </div>

        <div class="flex">
          <div v-if="job.locations_count" class="flex pr-6 text-sm">
            <div class="pr-2">
              <icon name="map-pin" class="h-6 w-6" />
            </div>
            {{ job.locations_count }}
          </div>

          <div v-if="job.pay" class="flex pr-6 text-sm">
            <div class="pr-2">
              <icon name="currency-pound" class="h-6 w-6" />
            </div>
            {{ job.pay }}
          </div>

          <div
            v-if="job.status_for_members"
            class="hidden pr-6 text-sm md:flex"
          >
            <div class="pr-2">
              <icon name="calendar" class="h-6 w-6" />
            </div>
            {{ job.status_for_members }}
          </div>

          <div
            v-if="formatted_closest_job_location"
            class="hidden pr-6 text-sm md:flex"
          >
            <a :href="route_map_link" class="blue-link flex">
              <img
                class="h-5 w-5 pr-2"
                :src="`/icons/google-maps.svg`"
                alt="Google Maps Icon"
              />
              {{ formatted_closest_job_location }}
            </a>
          </div>
        </div>
      </div>
      <div
        :class="status_colour_class"
        class="ml-auto self-center rounded-full px-2.5 py-0.5 font-bold text-white"
      >
        {{ member_status_label }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    formatted_closest_job_location: { type: String },
    header: { type: String },
    id: { type: Number },
    job: { type: Object },
    memberId: { type: Number },
    member_status_label: { type: String },
    route_map_link: { type: String },
    status: { type: String },
    status_colour: { type: String },
  },
  computed: {
    status_colour_class() {
      return "bg-" + this.status_colour + "-500"
    },
  },
}
</script>
