<template>
  <a
    :href="caseworker.beam_call_link"
    class="button button-tertiary mt-6 flex items-center justify-between px-4 py-2 text-sm"
  >
    <icon name="calendar" :solid="true" class="h-6 w-6" />
    <div class="mx-auto whitespace-nowrap">Book Appointment</div>
  </a>
</template>

<script>
export default {
  props: {
    caseworker: { type: Object },
  },
}
</script>
