<template>
  <div>
    <header>
      <h1 class="beam-h1">
        What if we gave you the savings to move into a new home?
      </h1>

      <ul
        class="mx-auto mt-10 w-full space-y-6 bg-pale-grey-100 p-8 sm:max-w-3xl"
      >
        <li>💸 Paid your first month's rent?</li>
        <li>🤔 Gave you a simple affordability budget?</li>
        <li>🔑 Opened doors to landlords you can trust?</li>
      </ul>

      <div class="beam-header-intro">All 100% free with no strings.</div>

      <div class="mt-16 text-center">
        <a
          :href="signUpUrl"
          target="blank"
          class="button button-primary inline-block px-16 py-3"
          >👉 Register interest</a
        >
      </div>
    </header>

    <main class="mb-16">
      <section class="mt-16 sm:mt-24">
        <div>
          <h2 class="beam-h2">
            Watch how you can benefit from Beam's free help
          </h2>
        </div>

        <div class="mx-auto mt-8 max-w-3xl">
          <play-link :url="videoEmbedUrl" class="relative">
            <img :src="videoThumbnailUrl" class="h-full w-full object-cover" />

            <div
              class="center-absolute absolute inline-flex h-16 w-16 items-center justify-center rounded-full border-2 border-white bg-azure-100 text-white"
            >
              <icon name="play" :solid="true" class="h-6 w-6" />
            </div>
          </play-link>
        </div>
      </section>

      <!-- How it works -->
      <section class="mt-16 sm:mt-24">
        <how-it-works-item
          :index="1"
          photo="/images/homes-daniel.jpeg"
          photo-width="lg:h-56"
        >
          <template #title>
            <div class="font-medium sm:text-xl">
              We'll pay for what you need to settle into your new home
            </div>
            <div class="font-normal italic">
              Such as a deposit, one month's rent and furniture vouchers
            </div>
          </template>
        </how-it-works-item>

        <how-it-works-item
          :index="2"
          photo="/images/homes-family.jpeg"
          photo-width="lg:h-56 lg:w-56"
          caption-class="-mt-5"
        >
          <template #title>
            <div class="font-medium sm:text-xl">
              Discover how much you can afford to pay in rent
            </div>
            <div class="font-normal italic">
              We'll give you an easy-to-understand budget
            </div>
          </template>
        </how-it-works-item>

        <how-it-works-item
          :index="3"
          photo="/images/homes-ian.jpeg"
          photo-width="lg:h-56"
        >
          <template #title>
            <div class="font-medium sm:text-xl">
              Personalised support every step of the way
            </div>
            <div class="font-normal italic">
              We'll help you with all of the complicated paperwork and searching
              for a home
            </div>
          </template>
        </how-it-works-item>

        <how-it-works-item
          :index="4"
          photo="/images/homes-joel.jpeg"
          :last-item="true"
          photo-width="lg:h-56"
        >
          <template #title>
            <div class="font-medium sm:text-xl">
              Access landlords open to you
            </div>
            <div class="font-normal italic">
              We can open doors to top landlords who love to work with us
            </div>
          </template>
        </how-it-works-item>

        <div class="mt-8 text-center sm:mt-16">
          <a
            :href="signUpUrl"
            target="blank"
            class="button button-primary inline-block px-16 py-3"
            >👉 Register interest</a
          >
        </div>

        <success-story
          class="-mx-8"
          :campaign="{
            member_name: 'Jae',
            slug: 'jae',
          }"
          photo="/images/jae.jpeg"
          :link-to-more="false"
        >
          <template #title
            >She's now moved into her new home and started work.</template
          >

          <template #subtitle
            >Beam raised £2,278 from 27 supporters for Jae's deposit, first
            month's rent and housing essentials.
          </template>
        </success-story>

        <section class="mt-16 sm:mt-24">
          <div>
            <h2 class="beam-h2">
              Jae and 1,079 others have changed their lives
            </h2>
          </div>
        </section>

        <div
          class="full-screen-width mt-8 flex h-88 flex-wrap justify-center overflow-hidden sm:mt-16 sm:h-128"
        >
          <stories-campaign-thumb
            v-for="campaign in thumbnailCampaigns"
            :key="campaign.id"
            :campaign="campaign"
            :link-to-campaign="false"
          >
          </stories-campaign-thumb>
        </div>

        <div class="mt-8 text-center sm:mt-16">
          <a
            :href="signUpUrl"
            target="blank"
            class="button button-primary inline-block px-16 py-3"
            >👉 Register interest</a
          >
        </div>
      </section>

      <div class="mb-24">
        <faq-section
          page-type="Join Homes Landing Page"
          :show-link="false"
        ></faq-section>
      </div>
    </main>

    <support-icon></support-icon>
    <member-support-modal :member-id="memberId" page="join-homes-page">
    </member-support-modal>
  </div>
</template>

<script>
export default {
  props: {
    thumbnailCampaigns: { type: Array },
    memberId: { type: Number },
    videoEmbedUrl: { type: String },
    videoThumbnailUrl: { type: String },
  },
  computed: {
    signUpUrl() {
      if (this.memberId) {
        return "/member_sign_ups/new?member_id=" + this.memberId
      } else {
        return "/member_sign_ups/new"
      }
    },
  },
}
</script>
