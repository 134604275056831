export default {
  computed: {
    interviewFeedbackSession() {
      return this.fieldContains(
        this.thisForm.session_plan,
        "Reviewing feedback on how an interview went (≈ 20 minutes)"
      )
    },
    coverLetterSession() {
      return this.fieldContains(
        this.thisForm.session_plan,
        "Creating a cover letter or supporting statement (≈ 30 minutes)"
      )
    },
    interviewPreparationSession() {
      return this.fieldContains(
        this.thisForm.session_plan,
        "Preparing for interview (≈ 60 minutes)"
      )
    },
    applicationFormSession() {
      return this.fieldContains(
        this.thisForm.session_plan,
        "Completing an application form (≈ 15 minutes)"
      )
    },
    carerSelected() {
      return this.fieldContains(
        this.thisForm.interview_job_select,
        "Caring for the elderly in a care home"
      )
    },
    supportWorkerSelected() {
      return this.fieldContains(
        this.thisForm.interview_job_select,
        "Caring for people with disabilities"
      )
    },
    adminSelected() {
      return this.fieldContains(
        this.thisForm.interview_job_select,
        "Working in an office"
      )
    },
    constructionOperativeSelected() {
      return this.fieldContains(
        this.thisForm.interview_job_select,
        "Working on a construction site (multiple roles)"
      )
    },
    warehouseOperativeSelected() {
      return this.fieldContains(
        this.thisForm.interview_job_select,
        "Picking orders in a warehouse"
      )
    },
    hospitalityCustomerFacingSelected() {
      return this.fieldContains(
        this.thisForm.interview_job_select,
        "Serving people in a restaurant"
      )
    },
    hospitalityNonCustomerFacingSelected() {
      return this.fieldContains(
        this.thisForm.interview_job_select,
        "Preparing food in a restaurant"
      )
    },
    supermarketSelected() {
      return this.fieldContains(
        this.thisForm.interview_job_select,
        "Helping people in shops"
      )
    },
  },
  methods: {
    showField(id) {
      switch (id) {
        case "next_steps":
          return this.fieldContains(
            this.thisForm.live_vacancies,
            "Yep - some of these look good!"
          )
        case "employer_feedback_reflections":
          return this.interviewFeedbackSession
        case "employer_feedback_good":
          return this.interviewFeedbackSession
        case "employer_feedback_bad":
          return this.interviewFeedbackSession
        case "employer_feedback_plan":
          return this.interviewFeedbackSession
        case "application_optin":
          return this.coverLetterSession
        case "interview_job_select":
          return this.interviewPreparationSession
        case "interview_history":
          return this.interviewPreparationSession
        case "interview_company":
          return this.interviewPreparationSession
        case "interview_qualities":
          return this.interviewPreparationSession
        case "interview_team":
          return (
            this.warehouseOperativeSelected ||
            this.hospitalityNonCustomerFacingSelected ||
            this.hospitalityCustomerFacingSelected ||
            this.supermarketSelected
          )
        case "interview_problems":
          return this.adminSelected || this.hospitalityNonCustomerFacingSelected
        case "interview_targets":
          return this.warehouseOperativeSelected
        case "interview_supervisor":
          return (
            this.warehouseOperativeSelected ||
            this.hospitalityNonCustomerFacingSelected ||
            this.constructionOperativeSelected
          )
        case "interview_pressure":
          return (
            this.warehouseOperativeSelected ||
            this.hospitalityNonCustomerFacingSelected ||
            this.constructionOperativeSelected
          )
        case "interview_learning":
          return this.constructionOperativeSelected
        case "interview_health_safety":
          return this.constructionOperativeSelected
        case "interview_qualifications":
          return this.constructionOperativeSelected
        case "interview_difficult_customer":
          return (
            this.supermarketSelected || this.hospitalityCustomerFacingSelected
          )
        case "intervew_customer_product":
          return (
            this.supermarketSelected || this.hospitalityCustomerFacingSelected
          )
        case "interview_customer_service":
          return (
            this.supermarketSelected || this.hospitalityCustomerFacingSelected
          )
        case "interview_shift_replacement":
          return (
            this.supermarketSelected ||
            this.hospitalityCustomerFacingSelected ||
            this.hospitalityNonCustomerFacingSelected
          )
        case "interview_sensitive_information":
          return this.adminSelected
        case "interview_it_knowledge":
          return this.adminSelected
        case "interview_priorities":
          return this.adminSelected
        case "interview_uncertain":
          return this.adminSelected
        case "interview_carer":
          return this.carerSelected || this.supportWorkerSelected
        case "interview_care_experience":
          return this.carerSelected || this.supportWorkerSelected
        case "interview_safeguarding":
          return this.carerSelected || this.supportWorkerSelected
        case "interview_person_care":
          return this.carerSelected || this.supportWorkerSelected
        case "interview_client_support":
          return this.carerSelected || this.supportWorkerSelected
        case "interview_client_collapsed":
          return this.carerSelected || this.supportWorkerSelected
        case "interview_learning_difficulties":
          return this.carerSelected || this.supportWorkerSelected
        case "interview_dignity":
          return this.carerSelected || this.supportWorkerSelected
        case "interview_hours":
          return this.interviewPreparationSession
        case "interview_questions":
          return this.interviewPreparationSession
        case "application_support":
          return this.applicationFormSession
        case "completion_optin":
          return this.interviewPreparationSession
        case "online_applications_detail":
          return this.fieldContains(
            this.thisForm.session_plan,
            "Learning how to apply for jobs online (≈ 15 minutes)"
          )
        case "supporter_update_confirmation":
          return (
            this.fieldContains(this.thisForm.job_support_week, "Week 9-12") ||
            this.fieldContains(
              this.thisForm.job_support_week,
              "Week 17-20 (Extension)"
            ) ||
            this.fieldContains(
              this.thisForm.job_support_week,
              "Week 24+ (Extension)"
            )
          )
        case "supporter_update_photo":
          return (
            this.fieldContains(this.thisForm.job_support_week, "Week 9-12") ||
            this.fieldContains(
              this.thisForm.job_support_week,
              "Week 17-20 (Extension)"
            ) ||
            this.fieldContains(
              this.thisForm.job_support_week,
              "Week 24+ (Extension)"
            )
          )
        case "catch_up_time":
          return !this.fieldContains(
            this.thisForm.job_support_week,
            "Week 24+ (Extension)"
          )
        default:
          return true
      }
    },
  },
}
