<template>
  <div class="mx-auto max-w-2xl">
    <form
      ref="form"
      action="/member_lettings_futures"
      accept-charset="UTF-8"
      method="post"
    >
      <div class="stack-mt-12">
        <member-form-field
          @update:modelValue="memberChange"
          :field="form.member_id"
          v-model="form.member_id.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.dream_for_future"
          v-model="form.dream_for_future.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.job_support_opt_in"
          v-model="form.job_support_opt_in.value"
        >
          forcerefresh
        </member-form-field>
        <!-- TEST THESE -->
        <member-form-field
          :field="form.lettings_housing_agreement"
          v-model="form.lettings_housing_agreement.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.lettings_better_off_budgeting"
          v-model="form.lettings_better_off_budgeting.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.campaign_opt_in"
          v-model="form.campaign_opt_in.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.pr_opt_in"
          v-model="form.pr_opt_in.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.ready_to_join"
          v-model="form.ready_to_join.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.future_next_steps"
          v-model="form.future_next_steps.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.book_house_hunting_with_beam"
          v-model="form.book_house_hunting_with_beam.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.sorry_script"
          v-model="form.sorry_script.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <case-note-section-intro />

      <div v-if="additional" class="stack-mt-12 mt-12">
        <member-form-field
          v-for="field in additional"
          :key="field.id"
          :field="field"
          v-model="field.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberLettingsFutureConditions from "./MemberLettingsFutureConditions"
export default {
  mixins: [MemberFormShared, MemberLettingsFutureConditions],
  data() {
    return {
      tableName: "member_lettings_future",
    }
  },
  methods: {
    memberChange() {
      location.href = `/member_lettings_futures/new?member_id=${this.form.member_id.value}`
      // this.updateMemberHelplink("any_job_matches")
      // this.updateMemberHelplink("changed_job_matches_confirmation")
    },
  },
}
</script>
