<template>
  <vue-final-modal
    :name="name"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-5 max-w-xl"
  >
    <modal-close-button :name="name"></modal-close-button>

    <div class="text-center text-xl font-medium">Edit your fundraiser</div>

    <div class="mt-6">
      <label for="fundraiser-name" class="font-medium">Name</label>
      <input
        type="text"
        name="fundraiser-name"
        id="fundraiser-name"
        class="beam-text-input mt-2 w-full"
        v-model.trim="fundraiserName"
      />
    </div>

    <div class="mt-6">
      <label for="fundraiser-description" class="font-medium"
        >Description</label
      >
      <textarea
        type="text"
        name="fundraiser-description"
        id="fundraiser-description"
        class="beam-text-input mt-2 h-40 w-full"
        v-model.trim="fundraiserDescription"
      >
      </textarea>
    </div>

    <div class="mt-6">
      <label for="fundraiser-goal" class="font-medium">Target</label>
      <div class="relative mt-2">
        <div class="center-absolute-y absolute pl-4 text-bluey-grey-100">£</div>
        <input
          type="number"
          name="fundraiser-goal"
          id="fundraiser-goal"
          class="beam-text-input w-full pl-8"
          v-model.number="fundraiserGoal"
        />
      </div>
    </div>

    <div class="mt-6 text-right">
      <button
        class="button button-secondary px-6 py-2"
        @click="updateFundraiser"
      >
        Update
      </button>
    </div>

    <div
      class="mt-6 cursor-pointer text-center text-xs text-pink-100"
      @click="deleteFundraiser"
    >
      Delete your fundraiser
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    fundraiserId: {
      type: Number,
    },
    initialFundraiser: {
      type: Object,
    },
  },
  data: function () {
    return {
      name: "modal-fundraiser-edit",
      fundraiserName: this.initialFundraiser.name,
      fundraiserDescription: this.initialFundraiser.description,
      fundraiserGoal: this.initialFundraiser.goal,
      open: false,
    }
  },
  computed: {
    postData() {
      return {
        fundraiser: {
          name: this.fundraiserName,
          description: this.fundraiserDescription,
          goal: this.fundraiserGoal,
        },
      }
    },
  },
  methods: {
    updateFundraiser() {
      this.axios
        .put(`/fundraisers/${this.fundraiserId}.json`, this.postData)
        .then((response) => {
          const data = response.data

          if (!data.success) {
            this.$root.showFlash(data.error_message, "error")
          } else {
            location.reload()
          }
        })
    },
    deleteFundraiser() {
      if (confirm("Are you sure you want to delete your fundraiser?")) {
        const url = `/fundraisers/${this.fundraiserId}.json`
        this.axios.delete(url).then((response) => {
          window.location = response.data.redirect_url
        })
      }
    },
  },
}
</script>
