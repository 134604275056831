<template>
  <div>
    <div class="border-bottom flex flex-row rounded-sm border-gray-300 pb-4">
      <div>
        <a :href="campaignUrl" class="beam-link" target="_blank">
          <span class="font-bold text-pink-100">{{
            campaign.member_name
          }}</span>
          {{ campaign.sentence_stage_display }}
        </a>
        <p>{{ numberOfColleaguesMessagedText(campaign) }}</p>
        <div class="relative mt-5 text-sm">
          <div
            class="flex flex-col gap-4 md:gap-6 md:flex-row border border-gray-200 p-4"
          >
            <div class="order-2 md:order-1">
              <div class="font-bold">{{ campaign.member_name }}'s story</div>
              <pre class="whitespace-pre-wrap text-gray-500">{{
                campaign.story
              }}</pre>
            </div>
            <div class="shrink-0 flex justify-center">
              <img :src="campaign.medium_avatar_url" class="h-56 w-56" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showComments" class="mt-4 w-full">
      <div class="mb-4 w-full" v-if="currentUserHasJoined">
        <p class="border-grey-200 mb-3 border-b pb-2 font-bold">
          Send {{ campaign.member_name }} an encouraging message
        </p>
        <div
          class="mt-4 flex w-full grow-0 flex-col whitespace-nowrap md:flex-row"
        >
          <button
            @click="copySuggestionToTextarea(suggestedMessages[0])"
            class="button button-tertiary mb-4 w-full flex-1 whitespace-pre-wrap border-yellow-400 px-4 py-1 md:mb-0 md:mr-4 md:w-auto md:shrink md:overflow-hidden md:text-ellipsis md:whitespace-nowrap"
          >
            {{ suggestedMessages[0] }}
          </button>
          <button
            @click="copySuggestionToTextarea(suggestedMessages[1])"
            class="button button-tertiary mb-4 w-full flex-1 whitespace-pre-wrap border-yellow-400 px-4 py-1 md:mb-0 md:w-auto md:shrink md:overflow-hidden md:text-ellipsis md:whitespace-nowrap"
          >
            {{ suggestedMessages[1] }}
          </button>
        </div>
        <div class="flex flex-col items-end">
          <!-- Error message -->
          <div v-if="errorMessage" class="mt-4 text-red-500">
            {{ errorMessage }}
          </div>
          <textarea
            class="beam-text-input mt-4 w-full rounded-md border-2 p-2"
            placeholder="Write your own message here"
            ref="messageTextarea"
            v-model="commentText"
          ></textarea>
          <div class="flex w-full flex-row items-baseline justify-between">
            <a
              :href="campaignDonateUrl"
              class="blue-link ml-2 mr-4 hidden sm:block"
              target="_blank"
            >
              Donate to {{ campaign.member_name }}'s campaign
            </a>
            <button
              class="button space-around mt-4 flex w-auto flex-grow-0 flex-row items-center justify-center px-16 py-2"
              @click="sendMessage"
              :disabled="sendButtonDisabled"
              :class="{
                'button-primary': !showThankYou,
                'cursor-not-allowed opacity-50': sendButtonDisabled,
                'hover:scale-1 animate-thud cursor-default bg-green-600 text-white transition-colors disabled:opacity-100':
                  showThankYou,
              }"
            >
              <icon
                name="check"
                class="mr-2 inline-block h-5 w-5 text-white"
                v-if="showThankYou"
              />{{ sendMessageButtonText }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-for="comment in comments"
        v-bind:key="comment.id"
        class="border-bottom flex flex-row border-gray-300 pb-4"
      >
        <div
          class="align-right inline-block w-14 shrink-0 items-end justify-end text-right"
        >
          <img
            :src="comment.author_avatar"
            class="inline-block h-10 w-10 rounded-full"
          />
        </div>
        <div class="ml-4 rounded-sm bg-pale-grey-100 px-4 py-2 text-sm">
          <p class="font-bold">
            {{ comment.author_name
            }}<span
              v-if="comment.author_job_title"
              class="inline font-normal text-gray-500"
            >
              • {{ comment.author_job_title }}</span
            >
          </p>
          <p>{{ comment.text }}</p>
        </div>
      </div>
      <div v-if="hasMoreComments" class="mt-4">
        <a
          class="cursor-pointer text-left font-medium hover:underline"
          @click="fetchComments"
          >Load more messages</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsMixin from "../mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    campaign: { type: Object },
    campaignUpdateId: { type: Number },
    storyLineLimit: { type: Number, default: 4 },
    showComments: { type: Boolean, default: false },
    company: { type: Object },
    commentSource: { type: String, default: "company_page" },
    currentUserHasJoined: { type: Boolean, default: false },
  },
  data() {
    return {
      storyExpanded: false,
      sendingMessage: false,
      showThankYou: false,
      errorMessage: null,
      commentsPage: 1,
      comments: [],
      hasMoreComments: false,
      commentText: "",
      possibleMessages: [
        "Keep going {name}!",
        "You are doing great!",
        "You are doing so well!",
        "What an inspiring story, good luck {name}!",
        "You are an inspiration!",
        "Good luck with your campaign, {name}!",
        "Thanks for sharing, good luck!",
        "Proud of you, {name}. Keep up the good work!",
        "Well done, {name}. You're doing fantastic!",
        "So pleased for you, {name}. Keep shining!",
        "Good luck, {name}! 💯",
        "Whoop! Congratulations, {name}! :)",
        "Proud of your progress, {name}. Keep going!",
        "Sending positive vibes your way, {name}. Keep pushing forward!",
        "Wishing you continued success, {name}. You've got this!",
        "Good luck on your journey, {name}!",
        "You're an inspiration, {name}. Keep shining bright!",
        "Behind you all the way, {name}!",
        "Sending positive energy your way, {name}!",
      ],
    }
  },
  computed: {
    sendButtonDisabled() {
      return this.sendingMessage || this.commentText.trim() === ""
    },
    campaignUrl() {
      return `/campaigns/${this.campaign.slug}`
    },
    campaignDonateUrl() {
      return `/campaigns/${this.campaign.slug}/donate?utm_content=company-campaign-feed-card`
    },
    sendMessageButtonText() {
      if (this.showThankYou) {
        return "Sent!"
      } else if (this.sendingMessage) {
        return "Sending..."
      } else {
        return "Send message"
      }
    },
    suggestedMessages() {
      return this.possibleMessages
        .map((message) => {
          return message.replace("{name}", this.campaign.member_name)
        })
        .sort(() => Math.random() - 0.5)
        .slice(0, 2)
    },
  },
  methods: {
    expandStory() {
      this.logEvent("campaign-story-expanded")
      this.storyExpanded = true
    },
    collapseStory() {
      this.storyExpanded = false
    },
    copySuggestionToTextarea(message) {
      this.logEvent("prefilled-message-selected", message)
      this.commentText = message
      this.focusInTextarea()
    },
    focusInTextarea() {
      this.$refs.messageTextarea.focus()
    },
    fetchComments() {
      this.axios
        .get(
          `/campaign_updates/${this.campaignUpdateId}/comments?company_slug=${this.company.slug}`,
          {
            params: {
              page: this.commentsPage,
            },
          }
        )
        .then((response) => {
          this.comments = this.comments.concat(response.data.comments)
          this.hasMoreComments = response.data.has_more
          if (this.hasMoreComments) {
            this.commentsPage += 1
          }
        })
    },
    numberOfColleaguesMessagedText(campaign) {
      if (campaign.num_colleagues_commented == 1) {
        if (this.currentUserHasJoined) {
          return `1 colleague has messaged ${campaign.member_name}`
        }
        return `1 ${this.company.name} employee has messaged ${campaign.member_name} to show their support!`
      } else if (campaign.num_colleagues_commented) {
        if (this.currentUserHasJoined) {
          return `${campaign.num_colleagues_commented} colleagues have messaged ${campaign.member_name} to show their support!`
        }
        return `${campaign.num_colleagues_commented} ${this.company.name} employees have messaged ${campaign.member_name} to show their support!`
      } else {
        // This message is never shown when non-employees are viewing, because we only show engaged campaigns
        return `None of your colleagues have messaged ${campaign.member_name} yet 😕 Be the first!`
      }
    },
    sendMessage() {
      if (this.showThankYou || this.sendButtonDisabled) {
        return
      }
      const message = this.commentText.trim()
      const payload = {
        comment_text: message,
        campaign_update_id: this.campaignUpdateId,
        source: this.commentSource,
      }
      if (message.length > 0) {
        this.sendingMessage = true
        this.axios
          .post("/comments", payload)
          .then((response) => {
            const { comment } = response.data
            if (comment && this.showComments) {
              this.commentText = ""
              this.comments.unshift({
                author_name: comment.name,
                text: comment.comment_text,
                author_avatar: comment.user_avatar,
                id: comment.id,
              })
              // Because we are simply using pages rather than offset/limit
              // (because I am working fast + lazy) we need to remove the last
              // comment if we have more than 3 comments and there are more
              // comments to load. If not, it's okay to just add one to the top.
              if (this.comments.length > 3 && this.hasMoreComments) {
                this.comments.pop()
              }
              this.sendingMessage = false
              this.showThankYou = true
              this.$root.showFlash("Thank you for your comment.")
              setTimeout(() => {
                this.showThankYou = false
                this.$root.closeFlash()
              }, 3000)
            }
            this.$emit("message-sent")
          })
          .catch((error) => {
            this.errorMessage =
              error.response.data.error_message ||
              "Something went wrong, please skip this step or contact support@beam.org"
            this.sendingMessage = false
          })
      }
    },
  },
  created() {
    if (this.showComments) {
      this.fetchComments()
    }
  },
}
</script>
