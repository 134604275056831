export default {
  methods: {
    showField(id) {
      let form = this.thisForm

      switch (id) {
        case "uc_claimant":
          return this.fieldStartsWith(form.job_support_opt_in, "yes")
        case "uc_mythbusting":
          return this.fieldContains(form.uc_claimant, "yes")
        case "any_job_matches":
          return this.fieldStartsWith(form.job_support_opt_in, "yes")
        case "career_selection":
          return this.fieldStartsWith(form.any_job_matches, "there are no")
        case "career_hours_confirmation":
          return (
            this.fieldNotEmpty(form.career_selection) &&
            !this.fieldContains(form.career_selection, "none")
          )
        case "beam_career_confirmation":
          return (
            this.fieldNotEmpty(form.career_selection) &&
            !this.fieldContains(form.career_selection, "none")
          )
        case "beam_career_questions":
          return (
            this.fieldContains(form.career_hours_confirmation, "questions") ||
            this.fieldContains(form.beam_career_confirmation, "questions")
          )
        case "job_matches_confirmation":
          return this.fieldStartsWith(form.any_job_matches, "let's review")
        case "change_job_matches":
          return this.fieldStartsWith(form.job_matches_confirmation, "no")
        case "changed_job_matches_confirmation":
          return this.fieldStartsWith(form.change_job_matches, "yes")
        case "confirm_job_interest":
          return this.fieldStartsWith(form.job_matches_confirmation, "yes")
        case "better_off_opt_in":
          return this.fieldContains(form.job_matches_confirmation, "yes")
        case "job_better_off_budgeting":
          return this.fieldStartsWith(form.better_off_opt_in, "yes")
        case "have_cv_already":
          return (
            this.fieldStartsWith(form.job_support_opt_in, "yes") &&
            !this.notRightForMember()
          )
        case "polished_cv":
          return (
            (this.fieldStartsWith(form.have_cv_already, "yes") ||
              this.fieldStartsWith(form.have_cv_already, "not yet")) &&
            !this.notRightForMember()
          )
        case "funding_required":
          return (
            this.fieldStartsWith(form.job_matches_confirmation, "yes") ||
            (this.fieldStartsWith(form.beam_career_confirmation, "yes") &&
              !this.fieldContains(form.career_selection, "none"))
          )
        case "childcare_budget":
          return this.fieldStartsWith(form.funding_required, "yes")
        case "acheive_with_beam":
        case "happy":
        case "two_years":
        case "personality_traits":
        case "top_skills":
          return this.memberNeedsCvOrCampaign()
        case "other_skills":
          return (
            this.memberNeedsCvOrCampaign() &&
            this.fieldContains(
              form.top_skills,
              "I have some other skills that aren't on this list"
            )
          )
        case "languages":
        case "attended_school":
          return this.memberNeedsCv()
        case "school_name":
          return (
            this.memberNeedsCv() &&
            this.fieldStartsWith(form.attended_school, "yes")
          )
        case "previously_employed":
          return this.memberNeedsCv()
        case "job_title_1":
        case "employer_1":
        case "start_date_1":
        case "end_date_1":
        case "responsibilities_1":
        case "add_another_job_1":
          return this.fieldStartsWith(form.previously_employed, "yes")
        case "job_title_2":
        case "employer_2":
        case "start_date_2":
        case "end_date_2":
        case "responsibilities_2":
        case "add_another_job_2":
          return this.fieldStartsWith(form.add_another_job_1, "yes")
        case "job_title_3":
        case "employer_3":
        case "start_date_3":
        case "end_date_3":
        case "responsibilities_3":
        case "add_another_job_3":
          return this.fieldStartsWith(form.add_another_job_2, "yes")
        case "job_title_4":
        case "employer_4":
        case "start_date_4":
        case "end_date_4":
        case "responsibilities_4":
        case "add_another_job_4":
          return this.fieldStartsWith(form.add_another_job_3, "yes")
        case "campaign_opt_in":
          return this.showCampaignFields()
        case "campaign_budget":
          return this.showCampaignFields()
        case "first_paycheck":
          return this.fieldStartsWith(form.beam_career_confirmation, "yes")
        case "campaign_name":
        case "support_to_achieve":
        case "advice":
        case "thank_you_message":
        case "photo_request":
        case "pr_opt_in":
          return this.memberNeedsCampaign()
        case "ready_to_join":
          return (
            this.fieldStartsWith(form.beam_career_confirmation, "yes") ||
            this.fieldStartsWith(form.job_matches_confirmation, "yes")
          )
        case "book_welcome_to_beam":
          return (
            this.fieldStartsWith(form.job_support_opt_in, "yes") &&
            this.fieldStartsWith(form.ready_to_join, "yes")
          )
        case "future_next_steps":
          return this.fieldStartsWith(form.ready_to_join, "no")
        case "sorry_script":
          return this.notRightForMember()
        default:
          return true
      }
    },
    notRightForMember() {
      const form = this.thisForm
      return (
        this.fieldStartsWith(form.job_support_opt_in, "no") ||
        this.fieldContains(form.beam_career_confirmation, "no ") ||
        this.fieldContains(form.career_selection, "none") ||
        this.fieldStartsWith(form.job_matches_confirmation, "no")
      )
    },
    showCampaignFields() {
      const form = this.thisForm
      return this.fieldStartsWith(form.funding_required, "yes")
    },
    // Category A:
    memberNeedsCvOrCampaign() {
      // Situation 1, 3 or 4
      return this.memberNeedsCv() || this.memberNeedsCampaign()
    },
    // Category B:
    memberNeedsCv() {
      // Situation 3 or 4
      const form = this.thisForm
      return this.fieldStartsWith(form.have_cv_already, "no,")
    },
    // Category C:
    memberNeedsCampaign() {
      // Situation 1 or 3
      const form = this.thisForm
      return this.fieldEquals(form.campaign_opt_in, true)
    },
  },
}
