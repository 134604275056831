<template>
  <section class="mt-10 text-center lg:mb-20">
    <div
      id="error-message"
      v-if="$parent.errorMessage"
      class="mb-4 font-medium text-pink-100"
    >
      {{ $parent.errorMessage }}
    </div>

    <button
      id="donate-button"
      @click="$parent.donateClicked"
      class="button-primary button inline-flex items-center justify-center px-12 py-3 sm:px-16"
    >
      <spinner-icon class="inline-flex h-5 w-5" v-show="$parent.submitting" />

      <icon
        v-show="!$parent.submitting"
        name="lock-closed"
        class="inline-block h-5 w-5"
        :solid="true"
      ></icon>
      <span class="ml-2">{{ $parent.donateButtonText }}</span>
    </button>
  </section>
</template>
