<template>
  <div>
    <div class="relative my-8">
      <div class="absolute inset-0 flex items-center">
        <div class="w-full border-t border-gray-300"></div>
      </div>
      <div class="relative flex justify-center">
        <span class="bg-white px-3 text-lg font-medium"> Case Note </span>
      </div>
    </div>

    <div>
      <p class="member-form-sublabel">
        Submitting the form will automatically create a case note for this
        meeting. If you need to add any extra details use the boxes below.
      </p>
    </div>
  </div>
</template>
