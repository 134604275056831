<template>
  <header class="bg-pale-grey-100 pt-12">
    <h1 class="beam-h1 text-left">{{ title }}</h1>

    <div class="mt-4">
      We help people into jobs and homes. If it’s your first time here, watch
      the video to learn more about how we can help you.
    </div>

    <play-thumbnail
      v-if="videoEmbedUrl"
      :url="videoEmbedUrl"
      :thumbnailUrl="videoThumbnailUrl"
      class="mt-8"
    ></play-thumbnail>
  </header>
</template>

<script>
export default {
  props: {
    title: { type: String },
    introVideoId: { type: String },
  },
  computed: {
    videoEmbedUrl() {
      return `https://www.youtube.com/embed/${this.introVideoId}`
    },
    videoThumbnailUrl() {
      return `https://i.ytimg.com/vi/${this.introVideoId}/maxresdefault.jpg`
    },
  },
}
</script>
