<template>
  <div class="text-center">
    <template v-if="!submitted">
      <div class="text-xl font-medium">
        <slot name="heading">Sign up / log in</slot>
      </div>
      <form
        action="/session"
        method="post"
        v-on:submit.prevent.once="createSession"
      >
        <div class="mt-5 text-left sm:mt-8">
          <div v-if="this.$slots['email-label']">
            <label for="email" class="mb-2 block font-medium"
              ><slot name="email-label"></slot
            ></label>
          </div>
          <input
            v-model.trim="email"
            type="email"
            name="email"
            id="email"
            class="beam-text-input w-full"
            placeholder="Enter your email address"
            @focus="showContinueButton = true"
            required
          />
        </div>

        <div class="mt-8 text-left md:flex md:flex-row" v-if="showNameFields">
          <div class="md:mr-2">
            <div>
              <label for="first_name" class="mb-2 block font-medium"
                >First name</label
              >
            </div>
            <input
              v-model.trim="firstName"
              type="text"
              name="first_name"
              id="first_name"
              class="beam-text-input w-full"
              required
            />
          </div>

          <div class="mt-8 md:mt-0">
            <div>
              <label for="last_name" class="mb-2 block font-medium"
                >Last name</label
              >
            </div>
            <input
              v-model.trim="lastName"
              type="text"
              name="last_name"
              id="last_name"
              class="beam-text-input w-full"
              required
            />
          </div>
        </div>

        <div class="mt-4 text-xs text-bluey-grey-100">
          No passwords! Just click the link in the email we send you.
        </div>

        <!-- Button -->
        <transition name="slide-fade">
          <div v-if="showContinueButton" class="mt-4">
            <button
              class="button button-secondary js_tag_manager_email_sign_in w-full px-16 py-3"
            >
              Continue
            </button>
          </div>
        </transition>
      </form>

      <!-- Google -->
      <div v-if="this.$slots['google-sign-in-button']">
        <div class="mt-4">or</div>
        <div class="mt-4">
          <slot name="google-sign-in-button"></slot>
        </div>
      </div>
    </template>

    <template v-if="submittedWithError">
      <div class="text-xl font-medium">That didn't work!</div>

      <div class="my-6">
        Hmm there was a problem signing you in with
        <span class="font-medium">{{ email }}</span
        >.
      </div>

      <div class="my-6">
        Please try again or
        <a href="mailto:help@beam.org" class="blue-link">contact support</a>.
      </div>
    </template>

    <template v-if="newUserId">
      <div class="text-xl font-medium">
        <slot name="success-heading">You're in!</slot>
      </div>

      <div class="mt-6">
        <slot name="success-body" v-bind:email="email">
          You'll receive updates about Beam, the campaigns and more to
          <span class="font-medium">{{ email }}</span
          >.
          <p class="mt-4">
            Please check your spam folder if you don't get the email!
          </p>
        </slot>
      </div>
    </template>

    <template v-if="userLoggedIn">
      <div class="text-xl font-medium">To continue, check your email!</div>

      <div class="my-6">
        No password required, just click the link in the email we sent to
        <span class="font-medium">{{ email }}</span
        >.
      </div>

      <div class="my-6">
        Please check your spam folder if you don't get the email!
      </div>
    </template>
  </div>
</template>

<script>
import FacebookMixin from "./mixins/FacebookMixin"

export default {
  mixins: [FacebookMixin],
  props: {
    userSource: { type: String },
    companyId: { type: Number },
    showNameFields: { type: Boolean, default: false },
    /**
     * Function which will be run with the email as an argument
     * validated before submitting. If it returns `false`, no
     * session request will be
     */
    validateEmail: { type: Function, default: () => true },
  },
  data() {
    return {
      email: null,
      firstName: null,
      lastName: null,
      submitted: false,
      submittedWithError: false,
      userLoggedIn: false,
      newUserId: null,
      showContinueButton: false,
    }
  },
  methods: {
    createSession() {
      if (!this.validateEmail(this.email)) {
        return
      }

      const data = {
        email: this.email,
        user_source: this.userSource,
        company_id: this.companyId,
        first_name: this.firstName,
        last_name: this.lastName,
      }

      this.axios
        .post("/session.json", data)
        .then((response) => {
          const data = response.data
          this.submitted = true

          if (data.success) {
            this.submittedWithError = false

            if (data.new_user) {
              this.newUserId = data.user_id
              this.logSignUp(data.user_id, "email")
            } else {
              this.userLoggedIn = true
            }
          } else {
            this.submittedWithError = true
          }
        })
        .catch((error) => {
          if (error.name == "AxiosError") {
            this.$root.showFlash(
              error?.response?.data?.error_message || "There was an error!",
              "error"
            )
          }
          throw error
        })
    },
  },
}
</script>
