<template>
  <div
    class="mx-auto mt-8 max-w-3xl rounded bg-red-600 py-8 text-center font-medium text-white"
  >
    <div class="max-w-md mx-auto">
      <h3 class="font-bold">This form has already been completed.</h3>

      <p class="mt-4">
        Each sign-up form can only be used by one person. If you think there’s
        been a mistake,
        <a
          @click="this.$vfm.show('modal-member-support')"
          class="cursor-pointer underline"
        >
          please contact support.
        </a>
      </p>
    </div>
  </div>
</template>
