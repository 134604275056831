<template>
  <div class="group mx-4 bg-white p-4">
    <div
      class="group flex w-full flex-col items-start justify-between rounded-xl"
    >
      <img
        class="mb-2 mr-2 w-16 rounded-full bg-yellow-100"
        :src="caseworker.thumb_avatar_url"
        v-if="caseworker.thumb_avatar_url"
      />
      <div class="flex w-full justify-between">
        <div>
          <div class="mx-auto text-xl font-bold">
            {{ meeting.name }}
          </div>
          <div class="pt-1 text-sm">
            {{ meeting.time_formatted }}
            <span v-if="caseworker.first_name">
              with
              <span class="font-medium">{{ caseworker.first_name }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <a
      :href="meeting.url"
      class="button button-primary mt-6 block w-full px-3 py-2 md:w-1/2"
    >
      Join video call
    </a>

    <div
      class="mt-6 border-t-2 border-solid border-gray-200 pt-6"
      v-if="will_attend === null"
    >
      <div class="font-medium">Can you attend this appointment?</div>
      <div class="mt-2 flex space-x-2">
        <button
          @click="cannotAttend"
          class="button button-tertiary flex-grow px-3 py-2"
        >
          No
        </button>
        <button
          @click="willAttend"
          class="button button-primary flex-grow px-3 py-2"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caseworker: { type: Object },
    meeting: { type: Object },
  },
  data() {
    return {
      will_attend: this.meeting.will_attend,
    }
  },
  methods: {
    willAttend() {
      this.axios
        .post(`/member_meetings/${this.meeting.id}/will_attend`)
        .then((response) => {
          if (response.data.success) {
            this.$root.showFlash("Thanks for letting us know!", "success")
          }
        })
      this.will_attend = true
    },
    cannotAttend() {
      this.axios
        .post(`/member_meetings/${this.meeting.id}/cannot_attend`)
        .then((response) => {
          if (response.data.success) {
            const recipient = this.caseworker.first_name
              ? this.caseworker.first_name
              : "Beam"
            this.$root.showFlash(
              `Thanks for letting us know you can't come to your next appointment. Send ${recipient} a WhatsApp or SMS message to rearrange.`,
              "success"
            )
          }
        })
      this.will_attend = false
    },
  },
}
</script>
