<template>
  <header class="bg-pale-grey-100 pt-12">
    <h1 class="beam-h1 text-left">{{ title }}</h1>
  </header>

  <div class="space-y-6 bg-white p-8 text-lg">
    <div class="text-2xl font-bold">Our Code of Conduct</div>

    <div class="space-y-2">
      <div class="font-bold">Stay Engaged</div>
      <div>
        Arrive at sessions on time and prepare anything needed in advance.
      </div>
      <div>
        If you miss two appointments or fail to respond to our messages for two
        weeks then we will close your case with Beam.
      </div>
    </div>
    <div class="space-y-2">
      <div class="font-bold">Be Respectful</div>
      <div>
        Meetings with Beam staff should be treated as if you’re in a new job!
        This means we expect people to behave professionally and we will not
        tolerate abuse.
      </div>
    </div>
    <div class="font-bold">
      A breach of the Code of Conduct will result in a written warning and two
      of these may lead to Beam’s service being withdrawn.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
  },
}
</script>
