<template>
  <li
    class="mx-1 my-2 shrink-0 text-center xs:mx-3"
    :class="{ 'cursor-pointer': bio }"
    @click="openPopup"
  >
    <img
      :src="photoPath"
      class="inline-block h-20 w-20 rounded object-contain sm:h-24 sm:w-24"
      :alt="name"
      :title="name"
    />

    <div v-if="showName" class="mt-3 text-center text-sm">{{ name }}</div>
  </li>
</template>

<script>
export default {
  props: ["photoPath", "name", "showName", "bio", "link", "title"],
  methods: {
    openPopup() {
      if (!this.bio) return

      this.$vfm.show("team-member-modal", {
        name: this.name,
        title: this.title,
        photo: this.photoPath,
        bio: this.bio,
        link: this.link,
      })
    },
  },
}
</script>
