<template>
  <transition name="slide-fade">
    <div
      v-if="open"
      @click="accept"
      class="center-absolute-x fixed bottom-0 flex w-full items-center justify-between bg-pale-grey-100 px-4 py-2 text-sm font-medium text-navy-800 shadow sm:mb-2 sm:max-w-2xl sm:rounded sm:px-6 sm:py-3 sm:text-base z-40"
    >
      <div>
        This site uses
        <a href="/privacy#cookies" class="underline">cookies</a> to enhance your
        user experience.
      </div>

      <div class="ml-2 flex items-center">
        <div
          class="button button-secondary whitespace-nowrap px-3 py-1 sm:px-4"
        >
          Allow
        </div>

        <div class="ml-4">Decline</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: function () {
    return {
      open: false,
    }
  },
  methods: {
    accept() {
      this.open = false
      localStorage.setItem("cookieConsentShown", true)
    },
  },
  mounted: function () {
    if (localStorage.getItem("cookieConsentShown")) {
      return
    }

    this.open = true
  },
}
</script>
