<template>
  <div class="items-center justify-between sm:flex">
    <div class="sm:flex">
      <div
        class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 font-medium sm:h-10 sm:w-10 lg:text-xl"
      >
        {{ stepCount }}
      </div>
      <div class="mt-3 sm:ml-6 sm:mt-0">
        <div class="beam-h3 max-w-xs text-left">
          <slot name="title"></slot>
        </div>
        <div class="mt-2 max-w-md lg:text-xl">
          <slot name="subtitle"></slot>
        </div>
      </div>
    </div>

    <slot name="details"></slot>
  </div>
</template>

<script>
export default {
  props: {
    stepCount: {
      type: Number,
      required: true,
    },
  },
}
</script>
