<template>
  <div class="card-list-shadow max-w-xs rounded bg-white p-4 text-left">
    <div class="mt-1 text-sm lg:text-base">“{{ quote.quoteText }}”</div>
    <div class="mt-2 flex items-center">
      <img
        class="mr-2 h-8 w-8 rounded-full"
        :alt="quote.quoterName"
        :src="quote.quoterPhoto"
      />
      <div class="text-sm font-medium lg:text-base">{{ quote.quoterName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quote: { type: Object },
  },
}
</script>
