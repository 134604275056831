<template>
  <div class="grid grid-cols-12">
    <h1 class="beam-h1 col-span-12 text-left">👋 Welcome to Beam</h1>

    <div class="col-span-12 mt-8 text-left md:col-span-8">
      <p>
        We're so excited that {{ company.name }} has partnered with Beam.
        Already, Beam has supported {{ livesChangedCount }} homeless people and
        refugees into stable jobs and homes.
      </p>
      <p class="mt-4">
        With your and your teammates support, we'll change even more lives. So,
        where do you come in?
      </p>

      <button
        class="button button-primary mt-8 w-auto flex-grow-0 px-16 py-2"
        @click="ctaClicked"
      >
        Let's get started
      </button>
    </div>

    <campaign-success-boxes
      class="col-span-12"
      :use-links="false"
    ></campaign-success-boxes>
  </div>
</template>

<script>
export default {
  props: {
    livesChangedCount: { type: Number },
    company: { type: Object },
  },
  methods: {
    ctaClicked() {
      this.$emit("next-cta-clicked")
    },
  },
}
</script>
