<template>
  <member-tabs
    key="todo-list"
    selected-tab="todo"
    :nearest-job-link="nearestJobLink"
    :member="member"
    :caseworker="caseworker"
    :incomplete-actions-count="incompleteActionsCount"
  ></member-tabs>

  <section class="bg-pale-grey-100 py-4 sm:w-96 md:mx-auto md:mt-10 md:w-auto">
    <p class="items-left text-small mx-4 justify-start">
      {{ greeting() }}, {{ member.first_name }}!
    </p>

    <h1 class="beam-h1 items-left mx-4 mb-4 mt-2 flex">Your appointments</h1>

    <div class="mx-4 bg-gray-200 p-3" v-if="!nextMeeting.name">
      You have no upcoming appointments
    </div>

    <member-meeting
      v-if="nextMeeting.name"
      :caseworker="nextMeetingCaseworker"
      :meeting="nextMeeting"
    ></member-meeting>

    <transition-group tag="div" name="fade" class="m-4">
      <ConfettiExplosion
        v-if="showConfetti"
        :particleCount="50"
        class="ml-auto mr-auto"
      />

      <h1
        class="beam-h1 items-left mb-4 mt-2 flex"
        v-if="incompleteActionsCount > 0"
      >
        Your to do list
      </h1>

      <member-facing-action
        v-for="action in incompleteActions"
        :key="action.id"
        :action="action"
        :video-id="action.video_id"
        :video-embed-url="action.video_embed_url"
        :video-thumbnail-url="action.video_thumbnail_url"
        :video-title="action.video_title"
        v-on:completed="handleActionComplete()"
        v-on:uncompleted="handleActionUncomplete()"
      ></member-facing-action>

      <h1 class="beam-h1 items-left mb-4 mt-2 flex">Completed tasks</h1>

      <div class="bg-gray-200 p-3" v-if="completedActions.length == 0">
        You have no completed tasks
      </div>

      <member-facing-action
        v-for="action in completedActions"
        :key="action.id"
        :action="action"
        :video-id="action.video_id"
        :video-embed-url="action.video_embed_url"
        :video-thumbnail-url="action.video_thumbnail_url"
        :video-title="action.video_title"
        v-on:completed="handleActionComplete()"
        v-on:uncompleted="handleActionUncomplete()"
      ></member-facing-action>
    </transition-group>
  </section>
</template>

<script>
import ConfettiExplosion from "vue-confetti-explosion"

export default {
  props: {
    caseworker: { type: Object },
    member: { type: Object },
    nearestJobLink: { type: String },
    nextMeeting: { type: Object },
    nextMeetingCaseworker: { type: Object },
    actions: { type: Array },
  },
  components: {
    ConfettiExplosion,
  },
  computed: {
    incompleteActionsCount() {
      return this.orderedActions.filter((action) => !action.completed_at).length
    },
    allComplete() {
      return this.incompleteActionsCount === 0
    },
    incompleteActions() {
      return this.orderedActions.filter((action) => !action.completed_at)
    },
    completedActions() {
      return this.orderedActions.filter((action) => !!action.completed_at)
    },
  },
  data() {
    return {
      orderedActions: this.sortedActions(this.actions),
      showConfetti: false,
    }
  },
  methods: {
    celebrate() {
      this.showConfetti = true
      setTimeout(() => {
        this.showConfetti = false
      }, 1000)
    },
    greeting() {
      const currentHour = new Date().getHours()
      if (currentHour >= 18 || currentHour < 4) {
        return "Good evening"
      } else if (currentHour >= 12) {
        return "Good afternoon"
      } else {
        return "Good morning"
      }
    },
    header(action) {
      if (this.isFirstComplete(action)) {
        return "Completed tasks"
      }
    },
    isFirstComplete(action) {
      return this.orderedActions.filter((a) => a.completed_at)[0] === action
    },
    sortedActions(actions) {
      return actions.sort((a, b) => {
        if (!a.completed_at && b.completed_at) {
          return -1
        } else if (a.completed_at && !b.completed_at) {
          return 1
        } else {
          if (a.row_order < b.row_order) {
            return -1
          } else if (a.row_order > b.row_order) {
            return 1
          } else {
            return 0
          }
        }
      })
    },
    handleActionComplete(e) {
      if (this.allComplete) {
        this.celebrate()
      }

      this.orderedActions = this.sortedActions(this.orderedActions)
    },
    handleActionUncomplete() {
      this.orderedActions = this.sortedActions(this.orderedActions)
    },
  },
}
</script>
