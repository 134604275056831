if (window.Stripe && !window.stripe) {
  window.stripe = Stripe(gon.stripe_publishable_key)
}

/*
  Used when creating subscriptions with a new card
*/
export function createPaymentMethod(
  cardElement,
  billingDetails,
  errorCallback,
  successCallback
) {
  stripe
    .createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails,
    })
    .then((result) => {
      if (result.error) {
        errorCallback(result)
      } else {
        successCallback(result)
      }
    })
}

/*
  Used from the Account page when adding a card
*/
export function confirmCardSetup(
  cardElement,
  billingDetails,
  errorCallback,
  successCallback
) {
  stripe
    .confirmCardSetup(gon.setup_intent_secret, {
      payment_method: {
        card: cardElement,
        billing_details: billingDetails,
      },
    })
    .then((result) => {
      if (result.error) {
        errorCallback(result)
      } else {
        successCallback(result)
      }
    })
}

/*
  Used when creating subscriptions and authentication is needed
*/
export function confirmPaymentIntent(piSecret, callback) {
  stripe
    .confirmCardPayment(piSecret, {
      save_payment_method: true,
      setup_future_usage: "off_session",
    })
    .then((result) => {
      callback(result)
    })
}

/*
  Used when making a one-off payment with a saved card (payment method) or new card
*/
export function confirmIntentWithMethod(piSecret, paymentMethod, callback) {
  stripe
    .confirmCardPayment(piSecret, {
      payment_method: paymentMethod,
      setup_future_usage: "off_session",
    })
    .then((result) => {
      callback(result)
    })
}
