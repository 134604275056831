<template>
  <div class="rounded bg-white px-5 pb-5 pt-8">
    <div class="flex h-16 items-center">
      <img :src="photo" :alt="altText" class="max-h-full" />
    </div>

    <div class="mt-8 text-pink-100">
      <quote-icon class="inline-block h-4 w-5"></quote-icon>
    </div>

    <div class="mt-3 text-sm">
      <slot name="quote"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["photo", "altText"],
}
</script>
