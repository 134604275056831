<template>
  <form
    ref="form"
    action="/budget_calculator/money_going_out"
    accept-charset="UTF-8"
    method="post"
    class="grid grid-cols-12 w-full"
  >
    <div class="col-start-3 col-span-8 mb-12">
      <h1 class="beam-h1 text-left mb-12 mt-6">Money Going Out</h1>
      <h2 class="beam-h2 text-left mb-4">Bills</h2>
      <fieldset class="member-form-fieldset">
        <member-form-bill-field
          v-for="fieldName in [
            'gas_and_electricity',
            'water',
            'council_tax',
            'mobile_phone',
            'internet',
          ]"
          :fields="form"
          :field-prefix="fieldName"
        >
        </member-form-bill-field>
        <member-form-field
          key="tv_licence"
          :field="form.tv_licence"
          v-model="form.tv_licence.value"
          class="mb-6"
        >
          forcerefresh
        </member-form-field>
      </fieldset>
      <h2 class="beam-h2 text-left mb-4">Essentials</h2>
      <fieldset class="member-form-fieldset">
        <member-form-bill-field
          v-for="fieldName in [
            'food',
            'toiletries',
            'travel',
            'child_costs',
            'child_care',
          ]"
          :fields="form"
          :field-prefix="fieldName"
        >
        </member-form-bill-field>
      </fieldset>
      <h2 class="beam-h2 text-left mb-4">Everything else</h2>
      <fieldset class="member-form-fieldset">
        <member-form-bill-field
          v-for="fieldName in [
            'cigarettes_vapes',
            'subscriptions',
            'debt_repayments',
          ]"
          :fields="form"
          :field-prefix="fieldName"
        >
        </member-form-bill-field>
      </fieldset>
      <h2 class="beam-h2 text-left mb-4">Other</h2>
      <fieldset class="member-form-fieldset">
        <div v-for="(value, index) in otherExpensesFields">
          <member-form-field
            :key="otherExpensesFields[index].id.id"
            :field="otherExpensesFields[index].id"
            v-model="otherExpensesFields[index].id.value"
          ></member-form-field>
          <member-form-field
            :key="otherExpensesFields[index].name.id"
            :field="otherExpensesFields[index].name"
            v-model="otherExpensesFields[index].name.value"
          ></member-form-field>
          <member-form-bill-field
            :fields="otherExpensesFields[index]"
            :show-currency-label="false"
            field-prefix="amount"
          >
          </member-form-bill-field>
        </div>

        <div class="flex justify-end">
          <button
            @click.prevent="addOtherExpenseField"
            type="button"
            class="button button-secondary button-large w-full flex items-center justify-center"
          >
            <icon name="plus-small" class="mr-2 h-5 w-5"></icon> Add another
            expense
          </button>
        </div>
      </fieldset>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary button-large w-full"
          >
            Save and continue
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import MemberFormShared from "../member-forms/MemberFormShared"
import NoConditions from "../member-forms/NoConditions"
export default {
  mixins: [MemberFormShared, NoConditions],
  methods: {
    shouldValidate(field) {
      if (
        field?.id?.startsWith("other_expense") ||
        !field?.id?.endsWith("_frequency")
      ) {
        return MemberFormShared.methods.shouldValidate.call(this, field)
      }

      if (field?.id?.endsWith("_frequency")) {
        const amountField = field.id.replace("_frequency", "")
        if (!this.thisForm[amountField].value) return false
      }
    },
    createOtherExpenseField(index, { id, name, amount, amount_frequency }) {
      return {
        id: {
          id: `other_expense_${index}_id`,
          value: id,
          error_text: "",
          required: false,
          hidden: true,
        },
        name: {
          id: `other_expense_${index}_name`,
          question: `Name of expense`,
          value: name,
          error_text: "",
          required: true,
        },
        amount: {
          id: `other_expense_${index}_amount`,
          value: amount,
          error_text: "",
          required: true,
          currency: true,
        },
        amount_frequency: {
          id: `other_expense_${index}_amount_frequency`,
          value: amount_frequency,
          error_text: "",
          required: true,
          use_key_as_label: true,
          radio_options: { Monthly: "monthly", Weekly: "weekly" },
        },
      }
    },
    addOtherExpenseField() {
      const form = this.thisForm
      let index = form.other_expenses_attributes.value.length
      form.other_expenses_attributes.value.push(
        this.createOtherExpenseField(index, {
          id: null,
          name: "",
          amount: "",
          amount_frequency: null,
        })
      )
      this.otherExpensesFields = form.other_expenses_attributes.value
    },
  },
  data() {
    return {
      numberOfOtherExpenses: 0,
      otherExpensesFields: [],
    }
  },
  mounted() {
    const form = this.thisForm
    this.otherExpensesFields = form.other_expenses_attributes.value.map(
      (otherExpense, index) => {
        return this.createOtherExpenseField(index, {
          id: otherExpense.id,
          name: otherExpense.name,
          amount: otherExpense.amount_pennies / 100,
          amount_frequency: otherExpense.amount_frequency,
        })
      }
    )
    form.other_expenses_attributes.value = this.otherExpensesFields
  },
}
</script>
