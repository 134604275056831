import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["breakdownPanel", "chevronDown", "chevronUp"]

  showOrHide() {
    if (this.data.get("hasBreakdown") === "false") {
      return
    }
    this.breakdownPanelTarget.classList.toggle("hidden")
    this.chevronDownTarget.classList.toggle("hidden")
    this.chevronUpTarget.classList.toggle("hidden")
  }
}
