import AnalyticsMixin from "../mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  methods: {
    getFacebookSDK() {
      if (window.fbInstalled) {
        return
      }
      window.fbInstalled = true
      const s = document.createElement("script")
      s.setAttribute("src", "https://connect.facebook.net/en_US/sdk.js")
      document.body.appendChild(s)
    },
    facebookShare(url) {
      FB.ui({ method: "share", href: url })

      this.$vfm.hide("modal-update-share")
      this.$vfm.hide("modal-campaign-share")
    },
  },
}
