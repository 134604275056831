<template>
  <div>
    <slot
      :fundraiserId="fundraiserId"
      :updatesCount="updatesCount"
      :openShareModal="openShareModal"
      :openEditModal="openEditModal"
      :openEditPhoto="openEditPhoto"
      :updatePosted="updatePosted"
    >
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    fundraiserId: { type: Number, required: true },
    initialUpdatesCount: { type: Number, required: true },
  },
  data() {
    return {
      updatesCount: this.initialUpdatesCount,
    }
  },
  methods: {
    openShareModal() {
      this.$vfm.show("modal-fundraiser-share")
    },
    openEditModal() {
      this.$vfm.show("modal-fundraiser-edit")
    },
    openEditPhoto() {
      this.$vfm.show("modal-fundraiser-photo")
    },
    updatePosted() {
      this.updatesCount += 1
    },
  },
}
</script>
