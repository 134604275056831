<template>
  <fancy-link
    :href="moreUrl"
    class="card-shadow card-hover-large mb-7 block flex flex-row rounded-sm bg-white last:mb-0"
  >
    <div
      class="align-center items-left flex h-48 w-1/2 flex-col place-content-center px-6 align-middle"
    >
      <div class="beam-h3 text-left">{{ name }}</div>
      <div class="text-left">
        <p class="underline">Find out more</p>
      </div>
    </div>
    <img class="h-48 w-1/2 object-cover object-right" :src="photoUrl" />
  </fancy-link>
</template>

<script>
export default {
  props: {
    name: { type: String, default: null },
    moreUrl: { type: String, default: null },
    photoUrl: { type: String, default: "https://picsum.photos/id/237/400/300" },
  },
}
</script>
