<template>
  <div class="mx-auto max-w-2xl">
    <form
      ref="form"
      action="/member_employment_futures"
      accept-charset="UTF-8"
      method="post"
    >
      <div class="stack-mt-12">
        <member-form-field
          @update:modelValue="memberChange"
          :field="form.member_id"
          v-model="form.member_id.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.dream_for_future"
          v-model="form.dream_for_future.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.job_support_opt_in"
          v-model="form.job_support_opt_in.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.uc_claimant"
          v-model="form.uc_claimant.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.uc_mythbusting"
          v-model="form.uc_mythbusting.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.any_job_matches"
          v-model="form.any_job_matches.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.job_matches_confirmation"
          v-model="form.job_matches_confirmation.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.confirm_job_interest"
          v-model="form.confirm_job_interest.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.change_job_matches"
          v-model="form.change_job_matches.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.changed_job_matches_confirmation"
          v-model="form.changed_job_matches_confirmation.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          @update:modelValue="careerChange"
          :field="form.career_selection"
          v-model="form.career_selection.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.career_hours_confirmation"
          v-model="form.career_hours_confirmation.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.beam_career_confirmation"
          v-model="form.beam_career_confirmation.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.beam_career_questions"
          v-model="form.beam_career_questions.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.better_off_opt_in"
          v-model="form.better_off_opt_in.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.job_better_off_budgeting"
          v-model="form.job_better_off_budgeting.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.have_cv_already"
          v-model="form.have_cv_already.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.polished_cv"
          v-model="form.polished_cv.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.funding_required"
          v-model="form.funding_required.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.campaign_opt_in"
          v-model="form.campaign_opt_in.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.campaign_budget"
          v-model="form.campaign_budget.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.childcare_budget"
          v-model="form.childcare_budget.value"
        >
          forcerefresh
        </member-form-field>
        <p
          class="border-black-500 block border-b border-t py-3 text-lg font-medium leading-5"
          v-if="showPersonalityAndSkillQuestionIntro"
        >
          Great! Last few questions now to get the information we need for your
          CV and/or donations page
        </p>
        <member-form-field
          :field="form.acheive_with_beam"
          v-model="form.acheive_with_beam.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field :field="form.happy" v-model="form.happy.value">
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.two_years"
          v-model="form.two_years.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.personality_traits"
          v-model="form.personality_traits.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.top_skills"
          v-model="form.top_skills.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.other_skills"
          v-model="form.other_skills.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.languages"
          v-model="form.languages.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.attended_school"
          v-model="form.attended_school.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.school_name"
          v-model="form.school_name.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.previously_employed"
          v-model="form.previously_employed.value"
        >
          forcerefresh
        </member-form-field>
        <!-- Start: job_title_1 etc -->
        <member-form-field
          v-for="fieldId in previousWorkFields"
          :key="fieldId"
          :field="form[fieldId]"
          v-model="form[fieldId].value"
        >
          forcerefresh
        </member-form-field>
        <!-- End: job_title_1 etc -->
        <member-form-field
          :field="form.campaign_name"
          v-model="form.campaign_name.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.support_to_achieve"
          v-model="form.support_to_achieve.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field :field="form.advice" v-model="form.advice.value">
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.thank_you_message"
          v-model="form.thank_you_message.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.photo_request"
          v-model="form.photo_request.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.pr_opt_in"
          v-model="form.pr_opt_in.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.ready_to_join"
          v-model="form.ready_to_join.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.book_welcome_to_beam"
          v-model="form.book_welcome_to_beam.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.future_next_steps"
          v-model="form.future_next_steps.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.sorry_script"
          v-model="form.sorry_script.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <case-note-section-intro />

      <div v-if="additional" class="stack-mt-12 mt-12">
        <member-form-field
          v-for="field in additional"
          :key="field.id"
          :field="field"
          v-model="field.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberEmploymentFutureConditions from "./MemberEmploymentFutureConditions"
export default {
  props: {
    careers: { type: Array },
  },
  mixins: [MemberFormShared, MemberEmploymentFutureConditions],
  computed: {
    selectedCareer() {
      return this.careers.find((career) => {
        return career.name == this.form.career_selection.value
      })
    },
    showPersonalityAndSkillQuestionIntro() {
      return this.memberNeedsCvOrCampaign()
    },
  },
  data() {
    return {
      tableName: "member_employment_future",
      previousWorkFields: [1, 2, 3, 4]
        .map((n) => [
          `job_title_${n}`,
          `employer_${n}`,
          `start_date_${n}`,
          `end_date_${n}`,
          `responsibilities_${n}`,
          `add_another_job_${n}`,
        ])
        .flat()
        .filter((e) => e != "add_another_job_4"),
    }
  },
  methods: {
    careerChange() {
      if (this.selectedCareer) {
        this.form.career_hours_confirmation.question =
          this.selectedCareer.typical_hours +
          "<br><br> Does this schedule work for you?"
        this.form.beam_career_confirmation.question =
          this.selectedCareer.sanitized_role_requirements +
          "<br> How do these role requirements sound?"
      }
    },
    memberChange() {
      this.updateMemberHelplink("any_job_matches")
      this.updateMemberHelplink("changed_job_matches_confirmation")
    },
  },
  created() {
    this.memberChange()
  },
}
</script>
