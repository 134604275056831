<template>
  <section class="mt-10" v-if="savedCard">
    <label class="inline-block font-medium">Saved card details</label>
    <div
      class="mt-5 flex items-center justify-between rounded border-2 border-pale-lilac-100 bg-white px-5 py-5"
    >
      <div class="flex items-center">
        <img
          class="w-8"
          :src="`/cards/visa.png`"
          v-if="savedCard.brand == 'Visa'"
        />
        <img
          class="w-8"
          :src="`/cards/mastercard.png`"
          v-if="savedCard.brand == 'Mastercard'"
        />
        <img
          class="w-8"
          :src="`/cards/amex.png`"
          v-if="savedCard.brand == 'American Express'"
        />
        <label class="ml-3"
          >{{ savedCard.brand }} ending in {{ savedCard.last4 }}</label
        >
      </div>

      <div @click="clearCardDetails" class="cursor-pointer">
        <icon
          name="trash"
          class="inline-block h-5 w-5 pt-1 text-azure-100"
          :solid="true"
        ></icon>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    savedCard: { type: Object },
  },
  methods: {
    clearCardDetails() {
      this.$emit("clear-card-details")
    },
  },
}
</script>
