export default {
  methods: {
    showField(id) {
      const form = this.thisForm

      // Either supports_lettings is true or the query param includes `override_lettings=true`
      const supportsLettings =
        this.fieldEquals(form.supports_lettings, true) ||
        window.location.search.includes("override_lettings=true")
      const noMemberSpecified = this.fieldEmpty(form.member_id)

      switch (id) {
        case "member_id":
          return noMemberSpecified
        case "book_house_hunting_with_beam":
          return (
            supportsLettings && this.fieldStartsWith(form.ready_to_join, "yes")
          )
        case "future_next_steps":
          return (
            supportsLettings && this.fieldStartsWith(form.ready_to_join, "no")
          )
        case "sorry_script":
          return !supportsLettings && !noMemberSpecified
        default:
          return supportsLettings || noMemberSpecified
      }
    },
  },
}
