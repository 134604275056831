<template>
  <svg
    width="256px"
    height="180px"
    viewBox="0 0 256 180"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid"
  >
    <g>
      <path
        d="M 250.51 151.209 C 247.522 162.252 238.896 170.878 227.854 173.866 C 207.988 179.284 128.033 179.284 128.033 179.284 C 128.033 179.284 48.077 179.284 28.211 173.866 C 17.169 170.878 8.543 162.252 5.555 151.209 C -2.95 117.323 -0.619 63.537 5.39 28.239 C 8.379 17.196 17.005 8.57 28.047 5.582 C 47.913 0.164 127.868 0 127.868 0 C 127.868 0 207.824 0 227.689 5.418 C 238.732 8.406 247.358 17.032 250.346 28.075 C 258.801 62.109 256.848 115.861 250.51 151.209 Z M 102.421 128.06 L 168.749 89.642 L 102.421 51.224 Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
</template>
