import AnalyticsMixin from "../mixins/AnalyticsMixin"

export default {
  template: `
    <div class="mx-auto max-w-2xl">
      <form ref="form" :action="submitUrl" accept-charset="UTF-8" method="post">
        <div class="stack-mt-12">
          <member-form-field
            v-for="field in form"
            :key="field.id"
            :field="field"
            v-model="field.value"
          >
            forcerefresh
          </member-form-field>
        </div>

        <div class="mt-8 pt-5">
          <member-form-errors
            v-if="formHasErrors"
            :error-fields="errorFields"
          ></member-form-errors>

          <div class="flex justify-end">
            <button
              @click.prevent="submit"
              type="submit"
              class="button button-primary inline-block px-16 py-3"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </form>
    </div>`,

  mixins: [AnalyticsMixin],

  props: {
    formSchema: { type: Object },
    formSectionSchema: { type: Object },
    additionalFields: { type: Object },
    formType: { type: String, default: "post" },
    shouldHandleUnload: { type: Boolean, default: true },
    formSteps: { type: Object, default: {} },
    submitUrl: { type: String },
    formScope: { type: String },
  },

  computed: {
    buttonText() {
      return this.saving ? "Submitting..." : "Submit"
    },
    thisForm() {
      return this.$parent.form ? this.$parent.form : this.form
    },
    formHasErrors() {
      return this.errorFields.length > 0
    },
    fieldsCompleted() {
      return Object.entries(this.form).filter(
        (value) =>
          !!value[1].value &&
          !(Array.isArray(value[1].value) && value[1].value.length == 0) &&
          !value[1].hidden
      )
    },
    oneStepForm() {
      return this.steps.length == 0
    },
    atFirstStep() {
      if (this.oneStepForm) {
        return true
      } else {
        return this.steps[0] == this.currentStep
      }
    },
    atFinalStep() {
      if (this.oneStepForm) {
        return true
      } else {
        return this.steps[this.steps.length - 1] == this.currentStep
      }
    },
    stepsPercent() {
      return (
        (this.steps.indexOf(this.currentStep) / (this.steps.length - 1)) * 100
      )
    },
    previousStepFinishedAt() {
      if (this.form.step_at.value.length > 0) {
        return this.form.step_at.value[this.form.step_at.value.length - 1]
          .finished_at
      } else {
        // If there are no steps recorded, use the timestamp when the form was started.
        return Date.parse(this.form.new_at.value)
      }
    },
    sectionKeys() {
      return Object.keys(this.formSectionSchema)
    },
  },
  data() {
    return {
      saving: false,
      form: this.formSchema,
      additional: this.additionalFields,
      errorFields: [],
      steps: Object.keys(this.formSteps),
      currentStep: Object.keys(this.formSteps)[0],
    }
  },
  provide: function () {
    // Grant access to shouldDisplayField to descendant components like MemberFormField using `inject: ['shouldDisplayField']`
    // https://vuejs.org/v2/guide/components-edge-cases.html#Dependency-Injection
    return {
      shouldDisplayField: this.shouldDisplayField,
    }
  },
  methods: {
    fieldsForSection(sectionKey) {
      return Object.keys(this.form)
        .filter((key) => this.form[key].section == sectionKey)
        .reduce((obj, key) => {
          obj[key] = this.form[key]
          return obj
        }, {})
    },
    shouldDisplayField(field) {
      if (field.always_visible) return true

      if (field.hidden) return false

      return this.showField(field.id)
    },
    shouldSubmitField(field) {
      if (field.always_visible || field.hidden) return true

      return this.showField(field.id)
    },
    shouldValidate(field) {
      if (this.shouldDisplayField(field)) {
        return field.required || field.validate
      }

      return false
    },
    postToServer() {
      if (this.saving) return
      this.saving = true

      if (this.form.step_at) {
        // Necessary otherwise Rails stores the object as a stringfied Ruby Hash, rather than valid JSON.
        this.form.step_at.value = JSON.stringify(this.form.step_at.value)
      }

      let data = {}

      for (const key in this.form) {
        let field = this.form[key]

        // If the field is not visible, we shouldn't submit it, as it can cause problems eg. on radio inputs if we submit an empty array.
        // If we add member forms which allow update, this will no longer work and will need a better solution (since they might need to
        // remove something).
        if (!this.shouldSubmitField(field)) {
          continue
        }

        if (Array.isArray(field)) {
          // Convert it to an array of hashes
          // e.g custom field values
          let hash = field.map((item) => {
            return { id: item.id, value: item.value }
          })

          data[key] = hash
        } else if (
          Array.isArray(field.value) &&
          field.value.length > 0 &&
          typeof field.value[0] == "object"
        ) {
          let valuesArray = field.value.map((item) => {
            let transformedItem = {}
            Object.keys(item).forEach((key) => {
              transformedItem[key] = item[key].value
            })

            return transformedItem
          })

          data[key] = valuesArray
        } else {
          data[key] = field.value
        }
      }

      if (this.additional) {
        for (const key in this.additional) {
          let field = this.additional[key]
          data[key] = field.value
        }
      }

      let opts = {}
      opts[this.formScope || this.tableName] = data

      this.axios({
        method: this.formType,
        url: this.$refs.form.action,
        data: opts,
      })
        .then((response) => {
          let data = response.data
          if (data.success) {
            window.removeEventListener("beforeunload", this.handleUnload)

            location.href = data.redirect_path
          } else {
            this.$root.showFlash(`Error ${data.error_message}`, "error")
          }
        })
        .catch((e) => {
          this.$root.showFlash(
            "Sorry, there was a problem saving your form. Please try again or contact support.",
            "error"
          )
        })
        .finally(() => {
          this.saving = false
        })
    },
    submit() {
      this.clearErrors()

      let fieldsToValidate
      if (this.oneStepForm) {
        fieldsToValidate = this.form
      } else {
        fieldsToValidate = this.stepFields(this.currentStep)
      }

      for (const key in fieldsToValidate) {
        this.arrayWrap(this.form[key]).forEach((item) => {
          if (item.dynamic_object_array) {
            // If the field is a dynamic array of objects, we need to validate each item in the array.
            this.arrayWrap(item.value).forEach((childItem) => {
              Object.keys(childItem).forEach((key) => {
                if (this.shouldValidate(childItem[key])) {
                  this.validateField(childItem[key])
                }
              })
            })
          } else if (this.shouldValidate(item)) {
            this.validateField(item)
          }
        })
      }

      if (!this.formHasErrors) {
        this.postToServer()
      }
    },
    clearErrors() {
      this.errorFields = []

      for (const key in this.form) {
        this.arrayWrap(this.form[key]).forEach((item) => {
          item.error_text = null
        })
      }
    },
    validateField(field) {
      // 0 is falsey
      if (field.value === 0) return
      if (field.validate == "boolean" && typeof field.value === "undefined") {
        field.error_text =
          "This field is required. You have to enter something before submitting."

        this.errorFields.push(field)
        return
      } else if (
        (!field.value || field.value.length == 0) &&
        field.validate !== "boolean"
      ) {
        field.error_text =
          "This field is required. You have to enter something before submitting."

        this.errorFields.push(field)
        return
      } else if (field.validate == "email") {
        // Basic email regex.
        if (!/^[^@ ]+@[^@ ]+\.[^@ ]+$/.test(field.value)) {
          field.error_text = "Please enter a valid email address."
          this.errorFields.push(field)
        }
      } else if (field.validate == "tel") {
        // This allows numbers, spaces, +, - and (). Feel free to add more if requested.
        if (!/^[0-9 +-\\(\\)]+$/.test(field.value)) {
          field.error_text = "Please enter a valid phone number."
          this.errorFields.push(field)
        }
      } else if (field.validate == "mob_uk") {
        // Validates UK mobile number starting with 0, 0044 or +44 and between 10 and 15 digits.
        if (
          !/^(0|0044|\+44)7[\d]{7,11}$/.test(field.value.replace(/\s+/g, ""))
        ) {
          field.error_text =
            "Please enter a UK mobile number starting with 07 or +44."
          this.errorFields.push(field)
        }
      }
    },
    fieldStartsWith(field, string) {
      if (!field.value) return false

      if (typeof field.value.toLowerCase !== "function") {
        throw TypeError(
          `The value for ${
            field.id
          } must be a string, but it is type ${typeof field.value}`
        )
      }

      return field.value.toLowerCase().startsWith(string.toLowerCase())
    },
    fieldContains(field, string) {
      if (!field.value) return false

      string = string.toLowerCase()

      if (Array.isArray(field.value)) {
        return field.value.some((el) => el.toLowerCase().indexOf(string) >= 0)
      } else {
        return field.value.toLowerCase().indexOf(string) >= 0
      }
    },
    fieldMatches(field, pattern) {
      if (!field.value) return false

      if (Array.isArray(field.value)) {
        return field.value.some((el) => pattern.test(el))
      } else {
        return pattern.test(field.value)
      }
    },
    fieldEquals(field, value) {
      return field.value === value
    },
    fieldTrue(field) {
      return this.fieldEquals(field, true)
    },
    fieldFalse(field) {
      return this.fieldEquals(field, false)
    },
    fieldEmpty(field) {
      // If `value` comes back as a boolean then we can be sure the field has been selected
      if (typeof field?.value == "boolean") {
        return false
      }
      return !field || !field.value || field.value.length == 0
    },
    fieldNotEmpty(field) {
      // If `value` comes back as a boolean then we can be sure the field has been selected
      if (typeof field?.value == "boolean") {
        return true
      }
      return field && field.value && field.value.length > 0
    },
    handleUnload(e) {
      // Cancel the event
      e.preventDefault()
      // Chrome requires returnValue to be set
      e.returnValue = ""
    },
    updateMemberHelplink(key) {
      if (!this.form[key]) return

      // You can add a link to elements on airtable in the Helptext column and use this function to update the member_id
      // in the link.
      // If the Member has already been changed on the form, extract the ID which will be members/ followed by some numbers
      // eg: https://beam.org/beamforce/members/12345?tab=jobs
      let oldMemberId = this.form[key]["help_link"].match(/members\/\d+/g)
      if (oldMemberId) {
        oldMemberId = oldMemberId[0]
      } else {
        // If the member-id has not been set yet eg: https://beam.org/beamforce/members/member-id?tab=jobs
        oldMemberId = "members/member-id"
      }

      // Update the member-id in the link.
      if (this.form.member_id.value) {
        this.form[key]["help_link"] = this.form[key]["help_link"].replaceAll(
          oldMemberId,
          "members/" + this.form.member_id.value
        )
      }
    },
    stepForward() {
      this.clearErrors()

      for (const key in this.stepFields(this.currentStep)) {
        let field = this.form[key]

        if (this.shouldValidate(field)) {
          this.validateField(field)
        }
      }

      if (!this.formHasErrors) {
        this.takeStep(1)
      }
    },
    arrayWrap(value) {
      return [value].flat()
    },
    stepBack() {
      this.clearErrors()

      this.takeStep(-1)
    },
    recordStep() {
      // Record the timestamp of the step and duration since the last one.
      const now = Date.now()
      this.form.step_at.value.push({
        step: this.currentStep,
        finished_at: now,
        duration: now - this.previousStepFinishedAt,
      })

      // Log on Google Tag Manager. If we add more multi-step forms, we'll need to add the new event name (eg. member-referral-step) to Google Tag Manager.
      if (this.tableName == "member_sign_up") {
        this.logEvent("member-sign-up-step", this.currentStep)
      }
    },
    takeStep(value) {
      this.recordStep()

      if (value > 0 && this.atFinalStep) {
        this.submit()

        return
      }

      const visibleSteps = this.steps.filter((step) => {
        return this.showStep(step)
      })

      // Get the index of the current step
      const currentStepIndex = visibleSteps.indexOf(this.currentStep)

      // Find the next step that has at least one visible field
      const nextStep = visibleSteps[Math.ceil(currentStepIndex + value, 0)]

      // Move to the next step
      this.currentStep = nextStep

      window.scrollTo(0, 0)
    },
    stepFields(step) {
      return Object.fromEntries(
        Object.entries(this.form).filter(([_key, value]) => {
          return value.step && value.step == step
        })
      )
    },
  },
  created() {
    if (this.shouldHandleUnload) {
      window.addEventListener("beforeunload", this.handleUnload)
    }
  },
}
