<template>
  <div class="rounded bg-white p-5">
    <div class="flex h-24 items-center justify-between">
      <div class="">
        <div class="font-medium">
          {{ title }}
        </div>
        <div class="mt-1 text-xs text-bluey-grey-100">
          {{ winnerText }}
        </div>
      </div>

      <div class="overflow-hidden rounded" style="max-width: 30%">
        <img :src="photo" :alt="title" />
      </div>
    </div>

    <div class="mt-8 text-pink-100">
      <quote-icon class="inline-block h-4 w-5"></quote-icon>
    </div>

    <div class="mt-3 text-sm">
      <slot name="quote"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "photo", "winnerText"],
}
</script>
