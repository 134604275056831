export default {
  methods: {
    showField(id) {
      let form = this.thisForm

      switch (id) {
        case "work_status":
          return this.workMilestone()
        case "work_salary_change":
          return this.fieldContains(form.work_status, "sustained work")
        case "work_contract":
        case "is_self_employed_role":
        case "employer_id":
        case "work_sector":
        case "work_role":
        case "work_role_custom":
        case "work_level":
          return this.startedWorkOrSalaryChange()
        case "member_is_registered_self_employed":
          return this.fieldEquals(form.is_self_employed_role, true)
        case "work_source":
          return this.startedWork()
        case "work_salary_mode":
          return this.startedWorkOrSalaryChange()
        case "work_annual_salary_pennies":
          return (
            this.fieldContains(form.work_salary_mode, "annual") ||
            this.fieldContains(form.work_salary_mode, "both")
          )
        case "work_hourly_salary_pennies":
          return (
            this.fieldContains(form.work_salary_mode, "hourly") ||
            this.fieldContains(form.work_salary_mode, "both")
          )
        case "work_weekly_hours":
          return this.fieldNotEmpty(form.work_salary_mode)
        case "housing_situation":
          return this.housingMilestone()
        case "housing_status":
          return this.housingMilestone()
        case "housing_fast_tracked":
          return this.housingMilestone()
        case "tenancy_id":
          return this.housingMilestone()
        case "success_explanation":
        case "success_explanation_actions":
          return (
            this.workMilestone() ||
            this.fieldStartsWith(form.housing_status, "Prevented from") ||
            this.fieldStartsWith(form.housing_status, "Moved on from")
          )
        default:
          return true
      }
    },
    setWorkRoleRequiredValue() {
      // They both start out required, but if one is filled in, the other
      // becomes optional.

      const form = this.thisForm
      const workRoleValueBlank = !form.work_role.value
      const workRoleCustomValueBlank = !form.work_role_custom.value

      if (workRoleValueBlank) {
        form.work_role_custom.required = true
      } else {
        form.work_role_custom.required = false
        form.work_role_custom.error_text = ""
      }

      if (workRoleCustomValueBlank) {
        form.work_role.required = true
      } else {
        form.work_role.required = false
        form.work_role.error_text = ""
      }
    },
    startedWorkOrSalaryChange() {
      let form = this.thisForm
      return (
        this.workMilestone() &&
        (this.startedWork() || this.fieldEquals(form.work_salary_change, true))
      )
    },
    startedWork() {
      let form = this.thisForm
      return (
        this.workMilestone() &&
        this.fieldContains(form.work_status, "started work")
      )
    },
    workMilestone() {
      let form = this.thisForm
      return this.fieldContains(form.milestone_type, "work")
    },
    housingMilestone() {
      let form = this.thisForm
      return this.fieldContains(form.milestone_type, "housing")
    },
  },
  watch: {
    // Specifically when it's a housing sustainment milestone being
    // created, we need to make tenancy_id and housing_situation required, and when it's
    // swapped to a different type of housing milestone, we need to
    // make it not required and clear only that error.
    "form.housing_status.value"(newValue) {
      const fieldRequired =
        newValue.toLowerCase().includes("moved on from homelessness") ||
        newValue.toLowerCase().includes("prevented from homelessness")
      return ["tenancy_id", "housing_situation"].forEach((fieldKey) => {
        this.form[fieldKey].required = fieldRequired
        if (!fieldRequired) {
          this.form[fieldKey].error_text = ""
          if (!this.form[fieldKey].value) {
            this.errorFields = this.errorFields.filter(
              (field) => field.id !== fieldKey
            )
          }
        }
      })
    },
    "form.work_source.value"(newValue) {
      // Default value of employer_id.select_ajax_url is is_employer_partner=true
      // If newValue=true, set employer_id.select_ajax_url to have is_employer_partner=true
      // If newValue=false, set employer_id.select_ajax_url to have is_employer_partner=false

      const isEmployerPartner = newValue === "Beam employer partner"

      this.form.employer_id.select_ajax_url =
        this.form.employer_id.select_ajax_url.replace(
          /is_employer_partner=(true|false)/,
          `is_employer_partner=${isEmployerPartner}`
        )
    },
    "form.work_role.value"() {
      this.setWorkRoleRequiredValue()
    },
    "form.work_role_custom.value"() {
      this.setWorkRoleRequiredValue()
    },
  },
}
