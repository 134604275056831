<template>
  <fundraisers-page :fundraisers="fundraisers">
    <template #header>
      <h1 class="beam-h1">Fundraisers</h1>
    </template>

    <template #prompt>
      <div class="my-10 text-left">
        <div class="text-xl font-medium">Feeling inspired?</div>
        <div class="mt-5 text-sm sm:text-base">
          You can raise money for Beam by running your own fundraiser. Get
          involved <a href="/fundraisers/new" class="blue-link">here</a>.
        </div>
      </div>
    </template>
  </fundraisers-page>
</template>

<script>
export default {
  props: {
    fundraisers: {
      type: Array,
    },
  },
}
</script>
