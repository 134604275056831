<template>
  <div
    class="flex mt-8 transition-transform duration-300 ease-in-out"
    ref="imageCarousel"
    :style="{
      transform: `translateX(-${currentIndex * (100 / imagesPerView)}%)`,
    }"
  >
    <div
      v-for="(image, index) in displayImages"
      :key="index"
      class="flex-shrink-0"
      :style="{ width: `${100 / imagesPerView}%` }"
    >
      <img
        class="w-full md:h-[476px] h-[215px] object-cover"
        v-lazy="image.path"
        :alt="image.alt"
      />
    </div>
  </div>
  <div
    class="absolute inset-0 flex items-center justify-between pointer-events-none md:hidden"
  >
    <!-- Buttons for smaller screens -->
    <button
      class="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-2 rounded-full pointer-events-auto mt-3 ml-2"
      @click="cycleImages(-1)"
    >
      <ChevronLeftIcon class="h-4 w-4" />
    </button>
    <button
      class="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-2 rounded-full pointer-events-auto mt-3 mr-2"
      @click="cycleImages(1)"
    >
      <ChevronRightIcon class="h-4 w-4" />
    </button>
  </div>

  <!-- Buttons for larger screens -->
  <div class="hidden md:flex absolute bottom-4 left-4 space-x-2">
    <button
      class="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-2 rounded-full"
      @click="cycleImages(-1)"
    >
      <ChevronLeftIcon class="h-5 w-5" />
    </button>
    <button
      class="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-2 rounded-full"
      @click="cycleImages(1)"
    >
      <ChevronRightIcon class="h-5 w-5" />
    </button>
  </div>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/16/solid"
import { ChevronLeftIcon } from "@heroicons/vue/16/solid"
export default {
  props: {
    galleryImages: { type: Array, required: true },
  },
  data() {
    return {
      currentIndex: 0,
      totalImages: 0,
      images: [],
      isSmallScreen: false,
    }
  },
  computed: {
    icons() {
      return { ChevronLeftIcon, ChevronRightIcon }
    },
    displayImages() {
      return [...this.images, ...this.images.slice(0, this.imagesPerView)]
    },
    imagesPerView() {
      return this.isSmallScreen ? 2 : 4
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenSize()
      window.addEventListener("resize", this.checkScreenSize)

      this.images = this.galleryImages
      this.totalImages = this.galleryImages
      const containerWidth = this.$el.offsetWidth
      this.$refs.imageCarousel.style.width = `${Math.floor(containerWidth) * 100}%`
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize)
  },
  methods: {
    cycleImages(direction) {
      const totalImages = this.images.length
      this.currentIndex += direction

      if (this.currentIndex >= totalImages) {
        this.$nextTick(() => {
          this.currentIndex = 0
        })
      } else if (this.currentIndex < 0) {
        this.currentIndex = totalImages - 1
      }
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 768
    },
  },
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
}
</script>
