<template>
  <section>
    <div
      class="sm:full-screen-mobile bg-pale-grey-100 px-5 py-10 lg:px-16 lg:py-16"
    >
      <div class="beam-h2 text-left sm:text-center">{{ title }}</div>
      <div class="mt-4 text-left text-sm sm:text-center sm:text-xl">
        <slot name="subtitle"></slot>
      </div>
      <form
        class="m-auto my-8 flex flex-col items-end text-center md:w-96"
        method="post"
        data-preserve-scroll="true"
        :action="formUrl"
      >
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@workemail.com"
          class="beam-text-input w-full rounded-b-none rounded-t-sm md:rounded-sm md:rounded-r-none"
          required
        />
        <input
          type="company"
          name="company"
          id="company"
          placeholder="(Optional) Company"
          class="beam-text-input mt-1 w-full border-collapse rounded-b-none rounded-t-sm md:rounded-sm md:rounded-r-none"
          required
        />

        <input type="hidden" name="authenticity_token" :value="csrfToken" />

        <input type="hidden" name="submission_page" :value="submissionPage" />

        <button
          class="button button-primary mt-1 w-full rounded-b-sm rounded-t-none border-2 border-pink-100 px-4 py-5 md:w-1/2 md:rounded-sm md:rounded-l-none"
        >
          Register interest
        </button>
      </form>
      <div class="mt-4 text-left text-sm sm:text-center sm:text-base">
        Or email us at
        <a href="mailto:partner@beam.org" class="underline">partner@beam.org</a>
        if you have any questions
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    submissionPage: {
      type: String,
    },
    csrfToken: {
      type: String,
    },
    formUrl: {
      type: String,
      default: "/marketing_contacts",
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
