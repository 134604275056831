export default {
  methods: {
    showField(id) {
      let form = this.$parent.form ? this.$parent.form : this.form

      switch (id) {
        case "reasons":
          return this.fieldStartsWith(form.reservations, "yes")
        case "homelessness_risk":
          return this.fieldContains(form.member_relationship, "support worker")
        default:
          return true
      }
    },
  },
}
