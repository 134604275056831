<template>
  <section
    class="rounded bg-pale-grey-100 p-5 sm:p-8 lg:mt-0 lg:bg-white lg:py-0"
  >
    <!-- Mobile heading -->
    <div
      class="flex cursor-pointer items-center justify-between font-medium sm:text-xl lg:hidden"
      :id="type"
      @click="toggleAccordion"
    >
      <div class="font-medium">
        <icon
          class="mr-2 inline-block h-6 w-6 pt-1 text-azure-100"
          :name="icon"
        ></icon>
        {{ title }}
      </div>
      <div v-show="!isAccordionOpen">
        <icon class="mr-2 h-6 w-6 text-azure-100" name="chevron-down"></icon>
      </div>
      <div v-show="isAccordionOpen">
        <icon class="mr-2 h-6 w-6 text-azure-100" name="chevron-up"></icon>
      </div>
    </div>

    <transition name="slide-fade">
      <!-- Need a single div for the transition -->
      <div class="pb-4 pt-8 sm:pb-6 sm:pt-12 lg:py-0" v-if="isOpen">
        <!-- Desktop heading -->
        <h3 class="heading-level-3 mb-12 hidden font-medium lg:block">
          {{ title }}
        </h3>
        <slot name="content"></slot>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    type: String,
  },
  data: function () {
    return {
      isAccordionOpen: false,
    }
  },
  computed: {
    isOpen() {
      return this.isAccordionOpen || this.type == this.$parent.openDesktopTab
    },
  },
  methods: {
    toggleAccordion: function () {
      this.isAccordionOpen = !this.isAccordionOpen
    },
  },
}
</script>
