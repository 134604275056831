<template>
  <div class="stack-mt-8">
    <header class="space-y-4 bg-pale-grey-100 pt-12">
      <div class="pb-2 text-5xl">🏠</div>

      <h1 class="beam-h1 text-left">{{ title }}</h1>
    </header>

    <info-banner
      :header="'We help people find homes in their budget'"
      :body="'We know finding a home can be tough, so we’ll only show you properties we know you can afford. '"
    />

    <div class="space-y-6 bg-white p-8 text-lg">
      <div class="text-xl font-bold">
        Beam has helped hundreds of people like you into homes
      </div>
      <labelled-outcome-image
        bg-image="/images/sign_up/housed_ian.png"
        member-image="/images/sign_up/ian.png"
        member-bg-colour="bg-azure-100"
        member-image-alt="A headshot of Ian on a blue background"
        member-story="Beam helped Ian move into a 1 bed flat"
        outcome-label="Housed by Beam"
      />
      <labelled-outcome-image
        bg-image="/images/sign_up/housed_janice.png"
        member-image="/images/sign_up/janice.png"
        member-bg-colour="bg-yellow-100"
        member-image-alt="A headshot of Janice on a yellow background"
        member-story="Beam helped Janice move into a 2 bed flat"
        outcome-label="Housed by Beam"
      />
      <labelled-outcome-image
        bg-image="/images/sign_up/housed_joel.png"
        member-image="/images/sign_up/joel.png"
        member-bg-colour="bg-pink-100"
        member-image-alt="A headshot of Joel on a pink background"
        member-story="Beam helped Joel move into a house share"
        outcome-label="Housed by Beam"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
  },
}
</script>
