<template>
  <landing-header>
    <template #pill>Beam for long-term unemployed </template>

    <template #title>
      <div>
        Beam's community-powered model supports people
        <span class="yellow-underline-h1">back into the workforce</span>
      </div>
    </template>

    <template #body>
      <div>
        Beam supports people who have been out of the job market for a long
        time, by removing their unique barriers and connecting them with
        progressive employers. Each person receives one-to-one mentorship from a
        Beam caseworker and access to a supportive online community.
      </div>
      <div class="mt-8">
        Beam's innovative approach is boosting engagement and reducing isolation
        among the long-term unemployed.
      </div>
    </template>

    <template #photo>
      <img :src="`/images/iman.jpg`" alt="Iman" />
    </template>

    <template #caption>
      Iman worked with Beam to become a hospitality worker
    </template>
  </landing-header>

  <!-- Awards -->
  <section class="mt-16 sm:mt-24">
    <h2 class="beam-h2">Beam is proud to be...</h2>

    <ul class="mt-8 flex flex-wrap justify-center sm:mt-12 sm:justify-around">
      <team-partner
        class="w-1/3 sm:w-auto"
        v-for="award in awards"
        :key="award.id"
        :name="award.name"
        :show-name="true"
        :photo-path="award.photo"
      ></team-partner>
    </ul>
  </section>

  <section class="full-screen-width mt-16 bg-pale-grey-100 py-20 sm:mt-24">
    <div class="beam-container">
      <!-- Bar Chart -->
      <div class="bg-white px-5 py-10 text-center lg:px-16 lg:py-16">
        <h2 class="beam-h2 mx-auto max-w-xl">
          Beam's beneficiaries have been out of work for 3 years on average
        </h2>

        <div class="mt-12 text-center">
          <img
            :src="`/images/prison-leavers-chart.jpg`"
            alt="Prison leavers that use Beam are 92% less likely to reoffend"
            class="mx-auto hidden max-w-2xl sm:block"
          />
          <img
            :src="`/images/prison-leavers-chart-mobile.jpg`"
            alt="Prison leavers that use Beam are 92% less likely to reoffend"
            class="sm:hidden"
          />
        </div>

        <div class="mt-4 hidden text-center font-medium sm:block">
          % of people who start work with intervention
        </div>

        <div class="mt-8">
          Source:
          <a
            href="https://beamorg.s3.eu-west-2.amazonaws.com/uploads/beam_impact_report_+may_2021.pdf"
            target="_blank"
            class="text-sm underline sm:text-base"
            >McKinsey Impact Report</a
          >
        </div>
      </div>

      <!-- Success Story -->
      <success-story
        class="-mx-8"
        :campaign="{
          member_name: 'Marzena\'s',
        }"
        :link-to-more="false"
        photo="/images/marzena-yellow.jpg"
        text-after-name=" addiction took over her life."
      >
        <template #title>Now she's clean and back at work. </template>
        <template #subtitle
          >Marzena lost her job and home after struggling with addiction. Thanks
          to Beam's support, she's now working in administration at a major
          hospital.
        </template>
      </success-story>
    </div>
  </section>

  <landing-how-it-works class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>
      Personalised job support for people who've been
      <span class="yellow-underline-h2">left behind</span>
    </template>

    <template #body>
      Beam's dedicated caseworkers provide specialist job support to people who
      have been locked out of the job market - including single parents, care
      leavers, domestic abuse victims and people with physical or mental health
      conditions.
    </template>
  </landing-how-it-works>

  <!-- Community -->
  <landing-quotes-section
    pink-photo="/images/hana-pink.jpg"
    member-name="Ibrahim"
    :top-quote="{
      quoterName: 'Laurent Cousot',
      quoterPhoto:
        'https://dl.airtable.com/.attachmentThumbnails/a3482a05334e4d81ef913fd5953cbc90/eeaf5274?ts=1654693355&amp;userId=usroquGODABkybAYT&amp;cs=029cfe2ba9322fd0',
      quoteText: 'Best of luck Ibrahim! I am sure you will reach your goals!',
    }"
  >
    <template #title
      >A supportive
      <span class="yellow-underline-h2">online community</span></template
    >

    <template #subtitle>
      <div class="mt-4 text-left text-sm sm:text-lg">
        Through Beam's platform, job-seekers can follow each other's progress
        and motivate one another to reach their goals.
      </div>
      <div class="mt-4 text-left text-sm sm:text-lg">
        They are also spurred on by members of the public who send
        confidence-boosting messages and offers of work experience.
      </div>
    </template>

    <template #member-quote>
      “Beam has given me a second chance to get back into work and motivated me
      to achieve my goals”
    </template>
  </landing-quotes-section>

  <!-- Member Hub -->
  <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>
      Pioneering technology for partners and service users
    </template>

    <template #body>
      <div>
        Beam's smart, easy-to-use Member Hub helps job-seekers apply for jobs at
        amazing employers, connect with their supporters and keep track of their
        progress - all from the touch of a smartphone. Beam's partners also get
        real-time reporting on the people they've referred.
      </div>
    </template>

    <template #image>
      <img
        class="inline-block sm:w-96"
        :src="`/images/member-hub.png`"
        alt="Beam Member Hub"
      />
    </template>
  </landing-left-text>

  <landing-testimonials :testimonials="testimonials"></landing-testimonials>

  <!-- Contact -->
  <contact-section
    :csrf-token="csrfToken"
    submission-page="unemployed"
    class="mt-16 lg:mt-32"
    title="I support job-seekers"
  >
    <template #subtitle>See how Beam can support your organisation </template>
  </contact-section>

  <landing-bottom-section></landing-bottom-section>
</template>

<script>
export default {
  props: {
    awards: { type: Array },
    csrfToken: { type: String },
    testimonials: { type: Array },
  },
}
</script>
