<template>
  <landing-header>
    <template #pill> Beam for Prison leavers </template>

    <template #title>
      <div>
        <span class="yellow-underline-h1">Reduce reoffending</span> by over 90%
        with Beam's community-powered model
      </div>
    </template>

    <template #body>
      <div>
        We support prison leavers into new jobs and homes. We give each person a
        dedicated caseworker and connect them with a supportive online community
        who provide funding and mentorship. Finally, we match them with
        forward-thinking employers and landlords.
      </div>
      <div class="mt-8">
        Beam's award-winning service is commissioned by central government and
        dozens of local authorities to level the playing field for returning
        citizens and reduce reoffending.
      </div>
    </template>

    <template #photo>
      <img :src="`/images/tony.jpg`" alt="Tony" class="md:rounded-sm" />
    </template>

    <template #caption>
      Tony worked with Beam to become an electrician
    </template>
  </landing-header>

  <!-- Contact -->
  <contact-section
    :csrf-token="csrfToken"
    submission-page="prison-leavers"
    class="mt-16 lg:mt-32"
    title="I work in the criminal justice sector"
  >
    <template #subtitle>
      See how Beam can help you reduce reoffending
    </template>
  </contact-section>

  <!-- Awards -->
  <section class="mt-16 sm:mt-24">
    <h2 class="beam-h2">Beam is proud to be...</h2>

    <ul class="mt-8 flex flex-wrap justify-center sm:mt-12 sm:justify-around">
      <team-partner
        class="w-1/3 sm:w-auto"
        v-for="award in awards"
        :key="award.id"
        :name="award.name"
        :show-name="true"
        :photo-path="award.photo"
      ></team-partner>
    </ul>
  </section>

  <!-- Bar Chart -->
  <section class="full-screen-width mt-16 bg-pale-grey-100 py-20 sm:mt-24">
    <div class="beam-container">
      <div class="bg-white px-5 py-10 text-center lg:px-16 lg:py-16">
        <h2 class="beam-h2 mx-auto max-w-xl">
          Prison leavers that use Beam are
          <span class="yellow-underline-h2">92% less likely to reoffend</span>
        </h2>

        <div class="mt-12 text-center">
          <img
            :src="`/images/prison-leavers-chart.jpg`"
            alt="Prison leavers that use Beam are 92% less likely to reoffend"
            class="mx-auto hidden max-w-2xl sm:block"
          />
          <img
            :src="`/images/prison-leavers-chart-mobile.jpg`"
            alt="Prison leavers that use Beam are 92% less likely to reoffend"
            class="sm:hidden"
          />
        </div>

        <div class="mt-4 hidden text-center font-medium sm:block">
          Reoffending rate
        </div>

        <div class="mt-8">
          Source:
          <a
            href="https://beamorg.s3.eu-west-2.amazonaws.com/uploads/beam_impact_report_+may_2021.pdf"
            target="_blank"
            class="text-sm underline sm:text-base"
            >McKinsey Impact Report</a
          >
        </div>
      </div>

      <success-story
        class="-mx-8"
        :campaign="{
          member_name: 'Guy',
          slug: 'guy-electrician-training-part-2',
        }"
        photo="/images/guy-blue.png"
        :link-to-more="false"
        text-after-name="spent most of his adult life in prison."
      >
        <template #title>Now he's thriving as an electrician. </template>

        <template #subtitle
          >Guy first used drugs aged 10. Growing up, he fell into a cycle of
          crime, hard drug use and homelessness. He then joined Beam and raised
          £3,875 for his electrical training from 283 members of the Beam
          community. Today, Guy has a career, home and true independence.
        </template>
      </success-story>
    </div>
  </section>

  <landing-how-it-works class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>How it works</template>

    <template #body>
      Beam achieves market-leading outcomes by removing financial barriers,
      empowering the community and using pioneering technology.
    </template>
  </landing-how-it-works>

  <!-- Nick campaign -->
  <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
    <template #title
      ><span class="yellow-underline-h2">Remove all barriers</span> to work and
      housing</template
    >

    <template #body>
      <div>
        Beam fundraises training, smartphones, transport and more, so prison
        leavers have everything they need to find a job and move into a home. By
        sharing donations among everyone using Beam, 100% of people reach their
        funding target within 3 weeks.
      </div>
    </template>

    <template #image>
      <div class="max-w-card sm:w-80 sm:max-w-full">
        <campaign-card :campaign="prisonLeaversCampaign"></campaign-card>
      </div>
    </template>
  </landing-left-text>

  <!-- Community -->
  <landing-quotes-section
    pink-photo="/images/artur-pink.jpg"
    member-name="Artur"
    :top-quote="{
      quoterName: 'Alain Portmann',
      quoterPhoto: '/images/alain-portmann.jpeg',
      quoteText: 'Artur may this journey spark fulfilment and success.',
    }"
    :bottom-quote="{
      quoterName: 'Anna Tankel',
      quoterPhoto: '/images/anna-tankel.png',
      quoteText:
        'Good luck, Artur! I\'m inspired by your determination, and I\'m sure you\'re going to do brilliantly!',
    }"
  >
    <template #title>
      Harness the <span class="yellow-underline-h2">power of community</span>
    </template>

    <template #subtitle>
      <div class="mt-4 text-left text-sm sm:text-xl">
        Prison leavers use Beam to share their personal stories, post inspiring
        updates, and build trust with the public. They also receive
        confidence-boosting messages and form connections with other prison
        leavers using Beam.
      </div>
    </template>

    <template #member-quote>
      “I feel like a really lucky person because this will change everything in
      my life.”
    </template>
  </landing-quotes-section>

  <!-- Member Hub -->
  <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
    <template #title>
      Empower prison leavers with
      <span class="yellow-underline-h2">digital skills</span></template
    >

    <template #body>
      <div>
        Beam's easy-to-use website helps prison leavers apply for jobs, connect
        with their supporters and keep track of their progress - all from the
        touch of a smartphone that Beam provides. Meanwhile, Beam's government
        partners get real-time reporting on people they've referred.
      </div>
    </template>

    <template #image>
      <img
        class="inline-block sm:w-96"
        :src="`/images/member-hub.png`"
        alt="Beam Member Hub"
      />
    </template>
  </landing-left-text>

  <section class="mt-16 sm:mt-24">
    <h2 class="beam-h2">What our customers say</h2>
    <landing-testimonials :testimonials="testimonials"></landing-testimonials>
  </section>

  <!-- Contact -->
  <contact-section
    :csrf-token="csrfToken"
    submission-page="prison-leavers"
    class="mt-16 lg:mt-32"
    title="I work in the criminal justice sector"
  >
    <template #subtitle>
      See how Beam can help you reduce reoffending
    </template>
  </contact-section>

  <landing-bottom-section
    group-descriptor="a prison leaver"
  ></landing-bottom-section>
</template>

<script>
export default {
  props: {
    prisonLeaversCampaign: {
      type: Object,
      required: true,
    },
    csrfToken: { type: String },
    awards: { type: Array },
    testimonials: { type: Array },
  },
}
</script>
