<template>
  <div
    class="flex rounded sm:justify-center"
    :class="{ 'mt-8': firstItem, 'sm:bg-pale-grey-100': evenItem }"
  >
    <!-- PHOTO -->
    <div
      class="hidden text-center sm:flex sm:w-1/3 sm:flex-col sm:items-center sm:justify-center sm:py-10"
      :class="photoOrder"
    >
      <img
        v-lazy="photo"
        :alt="photoAltText"
        class="inline-block rounded"
        :class="photoWidth"
      />
      <span
        class="mt-1 text-xs text-bluey-grey-100"
        :class="captionClass"
        v-if="photoCaption"
        >{{ photoCaption }}</span
      >
    </div>

    <!-- BLUE DOT -->
    <div class="relative order-2 mr-8 sm:mx-12 sm:flex sm:items-center">
      <!-- Blue bar -->
      <div
        class="center-absolute-x absolute z-0 border-l-2 border-yellow-100"
        :class="blueBarClasses"
      ></div>

      <!-- Top blue dot -->
      <div
        v-if="firstItem"
        class="center-absolute-x absolute top-0 h-3 w-3 rounded-full bg-azure-100"
      ></div>
      <!-- Blue dot -->
      <div
        class="relative h-8 w-8 shrink-0 rounded-full border-2 border-azure-100 bg-white text-white sm:h-10 sm:w-10"
      >
        <div
          class="flex h-full w-full items-center justify-center rounded-full border-2 border-white bg-azure-100 sm:border-4"
        >
          <icon name="check" class="inline-block h-5 w-5 text-white"></icon>
        </div>
      </div>
    </div>

    <!-- RIGHT -->
    <div
      class="order-3 sm:mb-0 sm:flex sm:w-1/3 sm:flex-col sm:justify-center"
      :class="textClasses"
    >
      <slot name="title"></slot>
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: { type: Number, default: null },
    lastItem: { type: Boolean, default: false },
    photo: { type: String },
    photoCaption: { type: String },
    captionClass: { type: String },
    photoAltText: { type: String },
    photoWidth: { type: String, default: "w-full" },
  },
  computed: {
    evenItem() {
      return this.index % 2 == 0
    },
    firstItem() {
      return this.index == 1
    },
    photoOrder() {
      if (this.evenItem) {
        return ["order-3"]
      } else {
        return ["order-1"]
      }
    },
    textClasses() {
      let result = []

      if (this.evenItem) {
        result = ["sm:order-1"]
      }

      if (this.lastItem) {
        result.push("mb-0")
      } else {
        result.push("mb-5")
      }

      return result
    },
    blueBarClasses() {
      if (this.lastItem) {
        return ["sm:top-0 sm:h-1/2"]
      } else {
        return ["h-full"]
      }
    },
  },
}
</script>
