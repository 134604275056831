<template>
  <vue-final-modal
    name="team-member-modal"
    @before-open="beforeOpen"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-5 max-w-md"
  >
    <div
      class="absolute right-0 top-0 mr-4 mt-2 cursor-pointer text-right text-xl text-azure-100"
      @click="$vfm.hide('team-member-modal')"
    >
      <icon name="x-mark" class="h-6 w-6"></icon>
    </div>

    <div class="flex">
      <div>
        <img class="rounded" width="80" :src="photo" />
      </div>

      <div class="ml-5">
        <div class="font-medium">{{ name }}</div>
        <div class="text-sm text-bluey-grey-100">{{ title }}</div>
        <a v-if="link" :href="link" class="blue-link text-sm">Impact Page</a>
      </div>
    </div>

    <div v-html="bio" class="markdown-wrapper mt-5 text-sm"></div>
  </vue-final-modal>
</template>

<script>
export default {
  data: function () {
    return {
      name: "",
      photo: "",
      title: "",
      bio: "",
      link: "",
      open: false,
    }
  },
  methods: {
    beforeOpen(event) {
      this.name = event.ref.params.value.name
      this.title = event.ref.params.value.title
      this.photo = event.ref.params.value.photo
      this.bio = event.ref.params.value.bio
      this.link = event.ref.params.value.link
    },
  },
}
</script>
