<template>
  <div
    class="absolute right-0 top-0 mr-4 mt-2 cursor-pointer text-right text-xl text-azure-100"
    @click="$vfm.hide(name)"
  >
    <icon name="x-mark" class="h-6 w-6 pt-1"></icon>
  </div>
</template>

<script>
export default {
  props: ["name"],
}
</script>
