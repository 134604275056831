<template>
  <button
    class="gtm-video-play relative mb-4 block flex w-full items-center justify-center rounded-md bg-cover bg-center focus:outline-none"
    :class="[height]"
    :style="`background-image: url('${thumbnailUrl}'); `"
    @click.prevent="showVideoModal"
  >
    <div
      class="absolute rounded-full border-4 border-yellow-100 bg-white p-5 py-4 font-medium"
    >
      <svg
        class="-mt-1 inline h-6"
        width="12"
        height="16"
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 6L-4.89399e-07 11.1962L-3.51373e-08 0.803847L9 6Z"
          fill="#071754"
        />
      </svg>
      <span v-if="buttonText" class="ml-2 inline">{{ buttonText }}</span>
    </div>
  </button>
</template>

<script>
import VideoMixin from "../vue-common/mixins/VideoMixin"

export default {
  props: {
    height: {
      type: String,
      default: "h-48",
    },
    buttonText: {
      type: String,
      default: "Watch the video",
    },
    thumbnailUrl: String,
  },
  mixins: [VideoMixin],
}
</script>
