<template>
  <section class="flex flex-col items-center">
    <div
      class="inline-block rounded bg-pale-grey-100 px-3 py-2 text-xs font-medium uppercase sm:text-base"
    >
      <slot name="title"></slot>
    </div>

    <div
      class="mx-auto mt-8 max-w-4xl text-center text-sm sm:text-4xl font-bold"
    >
      <slot name="body"></slot>
    </div>
  </section>
</template>
