<template>
  <section class="mt-8">
    <div class="mt-8 flex flex-col flex-wrap justify-center sm:flex-row">
      <testimonial
        v-for="testimonial in testimonials"
        :key="testimonial.id"
        :testimonial="testimonial"
        :drop-shadow="true"
        class="h-full"
      ></testimonial>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    testimonials: {
      type: Array,
      required: true,
    },
  },
}
</script>
