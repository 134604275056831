<template>
  <div
    class="relative"
    @click="hideMenu"
    @mouseover="showMenu"
    @mouseleave="hideMenu"
  >
    <slot name="link"></slot>

    <transition v-if="linksSlotProvided" name="slide-fade-bottom">
      <div v-show="this.menuOpen" class="desktop-menu">
        <slot name="links"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    linksSlotProvided() {
      return this.$slots.links
    },
  },
  data: function () {
    return {
      menuOpen: false,
    }
  },
  methods: {
    showMenu() {
      this.menuOpen = true
    },
    hideMenu() {
      this.menuOpen = false
    },
  },
}
</script>
