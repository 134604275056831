<template>
  <div>
    <header>
      <h1 class="beam-h1 mx-auto md:w-4/5">
        To date, Beam's supporters have changed the lives of
        {{ livesChangedCount.toLocaleString() }} people
      </h1>
    </header>

    <main class="">
      <section class="min-h-screen">
        <success-story
          v-for="campaign in storyCampaigns"
          :campaign="campaign"
          :quotes="campaign.story_quotes"
          :video-url="campaign.video_url"
          :key="campaign.id"
          :link-to-more="false"
        >
          <template #title
            >Now {{ campaign.subject_pronoun_possessive }} working as
            {{ campaign.an_occupation }}.</template
          >
          <template #subtitle
            >{{ campaign.member_name }} raised
            {{ campaign.formatted_target }} for
            {{ campaign.possessive_pronoun }} training from
            {{ campaign.supporters_count }} supporters.
            <span class="capitalize">{{
              campaign.subject_pronoun_possessive
            }}</span>
            now working full-time as {{ campaign.an_occupation }}!</template
          >
        </success-story>
      </section>

      <member-grid
        v-if="thumbnailCampaigns"
        :thumbnail-campaigns="thumbnailCampaigns"
        :funded-count="fundedCount"
        :total-payments="totalPayments"
        :total-users="totalUsers"
      ></member-grid>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    storyCampaigns: { type: Array },
    thumbnailCampaigns: { type: Array },
    fundedCount: { type: Number },
    livesChangedCount: { type: Number },
    totalPayments: { type: String },
    totalUsers: { type: Number },
  },
}
</script>
