<template>
  <div>
    <!-- Support Message -->
    <section class="mt-10">
      <label for="support-message" class="font-medium"
        >Message of support (optional)</label
      >
      <div class="mt-1 text-sm text-bluey-grey-100">
        While optional, encouragement is a great source of support.
      </div>
      <textarea
        @click="showAnonymousCheckbox = true"
        class="beam-text-input mt-5 h-20 w-full"
        v-model="supporterMessage"
        id="support-message"
      ></textarea>

      <transition name="slide-fade">
        <template v-if="showAnonymousCheckbox">
          <check-box
            checkbox-label="Show my name on the campaign page"
            :default-checked="true"
            ref="anonymousCheckbox"
          ></check-box>
        </template>
      </transition>
    </section>

    <!-- <section class='py-3 mt-6 text-sm text-center rounded bg-pale-grey-100'>10% of this donation will go towards keeping Beam going</section> -->

    <!-- Donate button -->
    <section class="align-center mt-6 flex justify-center text-center">
      <div
        v-if="errorMessage"
        class="mb-4 font-medium text-pink-100"
        v-html="errorMessage"
      ></div>

      <button
        @click="donateClicked"
        class="button-primary button inline-flex items-center justify-center px-12 py-3 sm:px-16"
      >
        <spinner-icon class="inline-flex h-5 w-5" v-show="submitting" />

        <icon
          v-show="!submitting"
          name="gift"
          class="inline-block h-5 w-5"
          :solid="true"
        ></icon>
        <span class="ml-2">Redeem {{ giftAmount }} Gift</span>
      </button>
    </section>
  </div>
</template>

<script>
export default {
  props: ["campaignId", "giftAmount"],
  data: function () {
    return {
      showAnonymousCheckbox: false,
      errorMessage: "",
      supporterMessage: "",
      submitting: false,
    }
  },
  methods: {
    donateClicked() {
      if (this.submitting) {
        return
      }

      this.submitting = true

      const anonymousCheckbox = this.$refs.anonymousCheckbox
      const anonymous = anonymousCheckbox ? !anonymousCheckbox.checked : false

      const opts = {
        redemption: {
          campaign_id: this.campaignId,
          support_message: this.supporterMessage,
          anonymous: anonymous,
          marketing_consent: true,
        },
      }

      const _this = this

      this.axios
        .post("/redemptions.json", opts)
        .then(function (response) {
          const data = response.data
          if (data.success) {
            location = data.redirect_url
          } else if (data.error_message) {
            _this.errorMessage = data.error_message
          }
        })
        .catch(function (error) {
          _this.errorMessage =
            "We're sorry, there was a problem. Please contact support."
        })
        .finally(function () {
          _this.submitting = false
        })
    },
  },
}
</script>
