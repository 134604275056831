<template>
  <section v-if="faqsPresent" class="mx-auto max-w-xl lg:max-w-4xl rounded-lg">
    <div class="mt-16 sm:mt-20">
      <h2 v-if="showTitle" class="beam-h2 mb-12 lg:mb-16 text-center">
        {{ title }}
      </h2>

      <faq-question
        :class="bgColor"
        v-for="faq in faqs"
        :key="faq.question"
        :question="faq.question"
        :answer="faq.answer"
      >
      </faq-question>
    </div>

    <div v-if="showLink" class="mt-16 text-center">
      <a
        href="https://help.beam.org"
        class="button button-secondary inline-block px-16 py-3"
        target="blank"
        >See more FAQs</a
      >
    </div>
  </section>
</template>

<script>
export default {
  props: {
    defaultFaqs: {
      type: Array,
      default: () => [],
    },
    pageType: { type: String, required: false },
    title: { type: String, default: "FAQs" },
    showTitle: { type: Boolean, default: true },
    showLink: { type: Boolean, default: false },
    bgColor: { type: String, default: "bg-pale-grey-100" },
  },
  computed: {
    faqsPresent() {
      return this.faqs.length > 0
    },
  },
  data() {
    return {
      faqs: this.defaultFaqs,
    }
  },
  created() {
    if (!this.pageType) return

    this.axios
      .get(`/airtable/faqs?page_type=${this.pageType}`)
      .then((response) => {
        this.faqs = response.data.faqs
      })
  },
}
</script>
