<template>
  <div class="bg-white p-5">
    <div class="flex items-center">
      <img class="mr-3 h-12 w-12 rounded-full" :src="message.member_photo" />
      <div class="font-medium">Message for {{ message.member_name }}</div>
    </div>

    <div class="mt-5">
      <slot></slot>
    </div>

    <div class="mt-5">From {{ message.from_name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
