<template>
  <div class="flex flex-wrap justify-center">
    <stories-campaign-thumb
      v-for="campaign in campaigns"
      :key="campaign.id"
      :campaign="campaign"
    >
    </stories-campaign-thumb>
  </div>
</template>

<script>
export default {
  props: {
    campaigns: { type: Array, required: true },
  },
}
</script>
