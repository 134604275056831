<template>
  <div class="mx-auto max-w-2xl">
    <form
      ref="form"
      action="/member_referrals"
      accept-charset="UTF-8"
      method="post"
    >
      <div class="stack-mt-12">
        <!-- Explicitly show / hide to remove extra padding caused by stack -->
        <member-form-field
          v-if="!form.referrer_email.hidden"
          :field="form.referrer_email"
          v-model="form.referrer_email.value"
        >
          forcerefresh
        </member-form-field>
        <!-- Hide partner select if already selected -->
        <member-form-field
          v-if="!partnerId"
          :field="form.partner_id"
          v-model="form.partner_id.value"
        >
          forcerefresh
        </member-form-field>

        <member-form-field
          :field="form.referral_location_id"
          v-model="form.referral_location_id.value"
        >
          forcerefresh
        </member-form-field>
        <div v-if="ineligibleLocation">
          <p>⚠️ Oh no! Sadly, Beam isn’t active in your area in the moment.</p>
          <br />
          <p>
            Interested in bringing Beam to your borough? Email us at
            <a href="mailto:partner@beam.org" class="blue-link"
              >partner@beam.org</a
            >
          </p>
          <br />
          <p>
            Want to add your client to Beam’s waiting list? Then complete the
            rest of this form and we’ll get in touch when we can help.
          </p>
        </div>
        <member-form-field
          :field="form.contract_id"
          v-model="form.contract_id.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.referrer_first_name"
          v-model="form.referrer_first_name.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.referrer_last_name"
          v-model="form.referrer_last_name.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.referrer_phone_number"
          v-model="form.referrer_phone_number.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.first_name"
          v-model="form.first_name.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.last_name"
          v-model="form.last_name.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field :field="form.email" v-model="form.email.value">
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.phone_number"
          v-model="form.phone_number.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.job_ready"
          v-model="form.job_ready.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.tenancy_ready"
          v-model="form.tenancy_ready.value"
        >
          forcerefresh
        </member-form-field>

        <template
          :key="question.id"
          v-for="question in form.eligibility_field_values"
        >
          <member-form-field :field="question" v-model="question.value">
            forcerefresh
          </member-form-field>

          <template
            v-if="question.value && question.value.toLowerCase().includes('no')"
          >
            <div>
              <div class="text-xl font-medium leading-5 text-navy-600">
                ⚠️ This might be a problem
              </div>
              <div class="member-form-sublabel">
                Want to add your client to Beam’s waiting list? Then complete
                the rest of this form and we’ll get in touch when we can help.
              </div>
            </div>
          </template>
        </template>

        <member-form-field
          v-for="customField in form.custom_field_values"
          :key="customField.id"
          :field="customField"
          v-model="customField.value"
        >
          forcerefresh
        </member-form-field>

        <member-form-field
          :field="form.can_access_internet"
          v-model="form.can_access_internet.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.referrer_concerns"
          v-model="form.referrer_concerns.value"
        >
          forcerefresh
        </member-form-field>
        <member-form-field
          :field="form.referrer_privacy_policy_opt_in"
          v-model="form.referrer_privacy_policy_opt_in.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberReferralConditions from "./MemberReferralConditions"
export default {
  mixins: [MemberFormShared, MemberReferralConditions],
  props: {
    partnerId: { type: String, required: false },
    eligibleLocations: { type: Array, default: () => [] },
    primePartners: { type: Array, default: () => [] },
    contracts: { type: Array, default: () => [] },
  },
  data() {
    return {
      tableName: "member_referral",
      ineligibleLocation: false,
    }
  },
  computed: {
    primePartnerSelected() {
      return this.primePartners.includes(this.form.partner_id.value)
    },
    selectedContract() {
      return this.contracts.find(
        (contract) => contract.id == this.form.contract_id.value
      )
    },
  },
  methods: {
    handlePartnerChanged() {
      if (this.primePartnerSelected) {
        this.ineligibleLocation = false
      }
    },
    hideContractDropdown() {
      this.form.contract_id.hidden = true
    },
    handleContractDropdown() {
      let contractsInLocation = this.contracts.filter((contract) => {
        return contract.referral_location_ids.includes(
          this.form.referral_location_id.value
        )
      })

      if (contractsInLocation.length == 0) {
        this.hideContractDropdown()
      } else if (contractsInLocation.length == 1) {
        this.form.contract_id.value = contractsInLocation[0].id
        this.hideContractDropdown()
      } else {
        // Show the drop-down
        this.form.contract_id.select_options = contractsInLocation.map(
          (contract) => {
            return {
              label: contract.display_name,
              value: contract.id,
            }
          }
        )
        this.form.contract_id.select_options.push({
          label: "Not sure",
          value: "0",
        })
        this.form.contract_id.hidden = false
      }
    },
  },
  watch: {
    "form.referral_location_id.value": function () {
      // Unset selected contract if the dropdown might be shown
      // If only 1 contract, it has automatically been assigned to the referral
      if (this.contracts.length > 1) {
        this.form.contract_id.value = null
      }

      if (this.primePartnerSelected) return

      this.handleContractDropdown()

      this.ineligibleLocation = !this.eligibleLocations.includes(
        this.form.referral_location_id.value
      )
    },
    "form.partner_id.value": {
      handler() {
        this.handlePartnerChanged()
      },
      immediate: true,
    },
    "form.contract_id.value": {
      handler() {
        if (!this.selectedContract) {
          this.form.custom_field_values = []
          this.form.eligibility_field_values = []
          return
        }

        this.form.custom_field_values = this.selectedContract.custom_questions

        this.form.eligibility_field_values =
          this.selectedContract.eligibility_questions
      },
      immediate: true,
    },
  },
}
</script>
