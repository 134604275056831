<template>
  <div class="mb-5 flex items-center justify-between">
    <div v-if="budgetItem.budget_item_url" class="text-sm sm:text-base">
      <a
        v-if="modalUrl"
        @click="openModal"
        href="javascript:;"
        class="blue-link gtm-click-budget-item"
      >
        {{ budgetItem.description }}
      </a>

      <a
        v-else
        :href="budgetItem.budget_item_url"
        class="blue-link gtm-click-budget-item"
        target="blank"
      >
        {{ budgetItem.description }}
      </a>
    </div>

    <div v-else class="text-sm sm:text-base">
      {{ budgetItem.description }}
    </div>

    <div class="ml-5 text-sm font-medium">
      £{{ budgetItem.amount.toLocaleString() }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    budgetItem: { type: Object },
  },
  computed: {
    modalUrl() {
      if (
        this.budgetItem.budget_item_url &&
        this.budgetItem.budget_item_url.includes("modal:")
      ) {
        return true
      }
      return false
    },
  },
  methods: {
    openModal() {
      const airtableName = this.budgetItem.budget_item_url.replace("modal:", "")
      this.$vfm.show("modal-budget-item", { airtableName: airtableName })
    },
  },
}
</script>
