<template>
  <div class="mt-10 sm:mt-12 lg:mt-16">
    <label class="font-medium" :for="emailType">{{ emailTitle }}</label>

    <div class="mt-1 text-sm text-bluey-grey-100">{{ emailSubtitle }}</div>

    <check-box
      v-on:checkbox-updated="onCheckboxUpdated"
      :checkbox-label="`Get emails on ${emailTitle.toLowerCase()}`"
      :default-checked="enabled"
    ></check-box>
  </div>
</template>

<script>
export default {
  props: {
    emailSettingId: { type: Number },
    emailType: { type: String },
    emailTitle: { type: String },
    emailSubtitle: { type: String },
    enabled: { type: Boolean },
  },
  methods: {
    onCheckboxUpdated(checkboxValue) {
      const data = { enabled: checkboxValue }
      const url = `/email_settings/${this.emailSettingId}`

      this.axios.put(url, data)
    },
  },
}
</script>
