<template>
  <div class="flex-1 bg-white p-5">
    <slot name="photo"></slot>

    <div class="mt-5">
      <div class="mt-2">“{{ quote }}”</div>
    </div>

    <slot name="quoter"></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    quote: String,
  },
}
</script>
