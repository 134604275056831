<template>
  <div class="mt-8">
    <campaign-card-small
      v-for="campaign in campaigns"
      :key="campaign.id"
      :member-name="campaign.member_name"
      :stage-display="campaign.stage_display"
      :slug="campaign.slug"
      :avatar-url="campaign.avatar_url"
      :is-withdrawn="campaign.is_withdrawn"
    ></campaign-card-small>

    <div class="mt-8 text-center" v-if="!finished">
      <button
        v-if="!loading"
        @click="fetchPage()"
        class="button button-secondary inline-block px-10 py-3"
      >
        See more
      </button>

      <button
        v-if="loading"
        class="button button-secondary inline-block px-10 py-3"
      >
        Loading...
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fundraiserId: {
      type: Number,
      required: false,
    },
  },
  data: function () {
    return {
      campaigns: [],
      page: 1,
      finished: false,
      loading: false,
    }
  },
  created() {
    this.fetchPage()
  },
  methods: {
    fetchPage() {
      if (this.finished) {
        return
      }

      this.loading = true

      const url = `/fundraisers/${this.fundraiserId}/impact_campaigns?page=${this.page}`

      this.axios.get(url).then((response) => {
        const data = response.data
        this.page += 1
        this.campaigns = this.campaigns.concat(data.campaigns)

        this.finished = data.finished
        this.loading = false
      })
    },
  },
}
</script>
