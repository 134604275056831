export default {
  data: function () {
    return {
      tipAmounts: [0, 5, 10, 15, 20, 30, 50],
    }
  },
  computed: {
    totalAmount() {
      // Returns a Float
      if (!this.selectedAmount) {
        return 0
      }

      return this.selectedAmount + parseFloat(this.beamAmount())
    },
    totalAmountDisplay() {
      return this.showCentsUnlessWhole(this.totalAmount)
    },
  },
  methods: {
    beamAmount(percent) {
      // Returns a String
      if (!this.selectedAmount) {
        return 0
      }

      if (!percent) {
        percent = this.beamDonationPercent
      }

      const amount = this.selectedAmount * (percent / 100)

      return this.showCentsUnlessWhole(amount)
    },
    showCentsUnlessWhole(amount) {
      // £2.00 => £2
      // £2.50 => £2.50
      if (amount.toString().includes(".")) {
        return amount.toFixed(2)
      } else {
        return amount
      }
    },
  },
}
