<template>
  <slot name="header"></slot>

  <member-form-field
    v-for="field in fields"
    :key="field.id"
    :field="field"
    v-model="field.value"
  >
    forcerefresh
  </member-form-field>
</template>

<script>
export default {
  props: {
    fields: { type: Object },
  },
}
</script>
