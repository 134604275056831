<template>
  <div id="positions" class="md:beam-container w-full flex justify-center">
    <div class="w-full m px-0">
      <div class="w-full flex mt-16 bg-pale-grey-100 p-8"></div>
      <div class="w-full">
        <div class="w-full flex justify-center">
          <h2 class="beam-h2 mb-8">
            Check out our {{ jobsCount }} {{ openRoles }}
          </h2>
        </div>
        <div
          class="w-full bg-white border rounded-none md:rounded-xl overflow-hidden"
        >
          <div class="w-full p-4">
            <div
              v-for="(roles, name) in departments"
              :key="name"
              class="p-4 sm:p-5 border-b border-gray-200 last:border-b-0"
            >
              <div class="flex flex-col sm:flex-row items-start">
                <div
                  class="font-medium text-lg sm:text-xl lg:text-2xl w-full sm:w-3/12 mb-4 sm:mb-0 mr-4"
                >
                  {{ name }}
                </div>
                <div class="flex-1">
                  <a
                    v-for="role in roles"
                    :key="role.id"
                    :href="role.url"
                    class="blue-link block text-base sm:text-lg mb-2 last:mb-0"
                    target="_blank"
                  >
                    {{ role.title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamTailorMixin from "./mixins/TeamTailorMixin"

export default {
  mixins: [TeamTailorMixin],
  data: function () {
    return {
      departments: {},
      jobsCount: 0,
    }
  },

  computed: {
    openRoles() {
      if (this.jobsCount == 1) {
        return "open role"
      } else {
        return "open roles"
      }
    },
  },

  created() {
    this.axios
      .get(this.teamTailorApiUrl, { headers: this.teamTailorHeaders })
      .then((response) => {
        response.data.data.forEach((job) => {
          this.jobsCount = this.jobsCount += 1
          let departmentName

          if (job.relationships.department.data) {
            let department = response.data.included.filter((dept) => {
              return dept.id == job.relationships.department.data.id
            })[0]

            departmentName = department["attributes"].name
          }

          if (!this.departments[departmentName]) {
            this.departments[departmentName] = []
          }

          this.departments[departmentName].push({
            title: job.attributes.title,
            id: job.id,
            url: job.links["careersite-job-url"],
          })
        })
      })
  },
  watch: {
    jobsCount() {
      const el = document.querySelector(".js-open-positions")
      el.textContent = this.jobsCount
      el.parentElement.classList.remove("hidden")
    },
  },
}
</script>
