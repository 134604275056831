<template>
  <vue-final-modal
    :name="name"
    @before-open="beforeOpen"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-5 max-w-md"
  >
    <modal-close-button :name="name"></modal-close-button>

    <div class="text-center">
      <div class="text-xl font-medium">Share {{ memberName }}'s Post</div>

      <!-- Facebook -->
      <div class="mt-8">
        <button
          @click="facebookShare(facebookShareUrl)"
          class="button js_ga_share inline-flex w-full items-center justify-center bg-facebook py-3 text-white"
          data-source="share_popup"
          data-action="Facebook"
        >
          <facebook-icon class="mr-2 inline-block h-4 w-4"></facebook-icon>
          <span>Share</span>
        </button>
      </div>

      <!-- Twitter -->
      <div class="mt-4">
        <a
          :href="twitterShareUrl"
          class="button js_ga_share inline-flex w-full items-center justify-center bg-twitter py-3 text-white"
          target="_blank"
          data-source="share_popup"
          data-action="Twitter"
        >
          <twitter-icon class="mr-2 inline-block h-4 w-4"></twitter-icon>
          <span>Twitter</span>
        </a>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import FacebookMixin from "../mixins/FacebookMixin"

export default {
  mixins: [FacebookMixin],
  data() {
    return {
      name: "modal-update-share",
      memberName: "",
      updateId: null,
      twitterShareUrl: "",
      facebookShareUrl: "",
      open: false,
    }
  },
  methods: {
    beforeOpen(event) {
      this.getFacebookSDK()

      this.memberName = event.ref.params.value.memberName
      this.updateId = event.ref.params.value.updateId

      this.axios
        .get(`/campaign_updates/${this.updateId}/share_urls`)
        .then((response) => {
          const data = response.data
          this.twitterShareUrl = data.twitter
          this.facebookShareUrl = data.facebook
        })
    },
  },
}
</script>
