<template>
  <vue-final-modal
    :name="name"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-5 max-w-md"
  >
    <!-- Close button -->
    <div
      class="absolute right-0 top-0 mr-4 mt-2 cursor-pointer text-right text-xl text-azure-100"
      @click="$vfm.hide(name)"
    >
      <icon name="x-mark" class="h-6 w-6"></icon>
    </div>

    <!-- Top icon -->
    <div
      class="button-tertiary mx-auto flex h-12 w-12 items-center justify-center rounded-full"
    >
      <icon name="gift" class="h-6 w-6"></icon>
    </div>

    <template v-if="showIntroText">
      <div class="mt-8">
        {{ gifterName }} has made a {{ giftAmount }} donation on your behalf so
        that you can support a homeless person into a stable job and home.
      </div>
      <div class="mt-8">
        You can choose to donate to one person's campaign or let Beam choose for
        you.
      </div>
      <div class="mt-8 text-center">
        <button
          @click="$vfm.hide(name)"
          class="button button-secondary mx-auto px-12 py-3"
        >
          Browse Campaigns
        </button>

        <div class="mt-6 text-center">or</div>

        <a
          @click="showCompleteForm = true"
          class="button button-primary mx-auto mt-6 inline-block px-12 py-3"
        >
          Let Beam choose
        </a>
      </div>
    </template>

    <transition name="slide-fade">
      <template v-if="showCompleteForm">
        <gift-redemption-page :gift-amount="giftAmount"> </gift-redemption-page>
      </template>
    </transition>

    <template v-if="zeroFunding">
      <div class="mt-8 text-center font-medium">
        All of our campaigns have been funded!
      </div>

      <div class="mt-8">
        Don't worry, we will allocate your gift to the next live campaign and
        let you know as soon as that happens.
      </div>
    </template>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    gifterName: { type: String },
    giftAmount: { type: String },
    zeroFunding: { type: Boolean },
  },
  computed: {
    showIntroText() {
      return !this.showCompleteForm && !this.zeroFunding
    },
  },
  data() {
    return {
      name: "modal-gift-redeem",
      showCompleteForm: false,
      open: true,
    }
  },
  mounted: function () {
    // Show itself on page load
    this.$vfm.show(this.name)
  },
}
</script>
