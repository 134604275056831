export default {
  methods: {
    handlePaymentRequest(paymentResponse) {
      this.paymentResponse = paymentResponse
      this.submitting = true

      // These should be available on paymentResponse
      // payerEmail
      // payerName
      //

      if (this.monthlyType) {
        this.postPayment({
          paymentMethodId: paymentResponse.paymentMethod.id,
          paymentMethodType: "payment-request",
        })
        return
      }

      this.axios
        .post(`/payment_intents`, {
          amount: this.totalAmountPence,
          description: "payment-request",
        })
        .then((intentResult) => {
          stripe
            .confirmCardPayment(
              intentResult.data.secret,
              {
                payment_method: paymentResponse.paymentMethod.id,
                setup_future_usage: "off_session",
              },
              { handleActions: false }
            )
            .then((confirmOneResult) => {
              // Hide the window - test this for failures
              paymentResponse.complete("success")

              if (confirmOneResult.error) {
                this.displayError(confirmOneResult.error.message)
              } else if (confirmOneResult.paymentIntent.status == "succeeded") {
                this.postToServer(
                  paymentResponse.paymentMethod.id,
                  confirmOneResult.paymentIntent.id
                )
              } else {
                stripe
                  .confirmCardPayment(intentResult.data.secret)
                  .then((confirmTwoResult) => {
                    if (confirmTwoResult.error) {
                      // Failed 3DS
                      this.displayError(confirmTwoResult.error.message)
                    } else {
                      this.postToServer(
                        paymentResponse.paymentMethod.id,
                        confirmTwoResult.paymentIntent.id
                      )
                    }
                  })
              }
            })
        })
        .catch((error) => {
          this.displayError(
            "There was an error taking your donation. Please try again or contact support"
          )
        })
    },
    postToServer(paymentMethodId, paymentIntentId) {
      this.postPayment({
        paymentMethodId: paymentMethodId,
        paymentIntentId: paymentIntentId,
        paymentMethodType: "payment-request",
      })
    },
  },
}
