export default {
  methods: {
    showField(id) {
      let form = this.thisForm

      switch (id) {
        case "incentive_amount":
          return this.fieldEquals(form.incentive_used, true)
        case "property_source":
          return (
            this.fieldContains(form.property_finder, "member") ||
            this.fieldContains(form.property_finder, "beam")
          )
        case "property_source_other":
          return this.fieldContains(form.property_source, "other")
        case "deposit_certificate":
          return this.fieldEquals(form.deposit_paid, true)
        default:
          return true
      }
    },
  },
}
