export default {
  methods: {
    showField(id) {
      let form = this.$parent.form ? this.$parent.form : this.form

      switch (id) {
        case "rent_guarantor_detail":
          return this.fieldEquals(form.rent_guarantor, true)
        default:
          return true
      }
    },
  },
}
