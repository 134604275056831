<template>
  <div
    class="col-span-12 flex flex-row items-center bg-white py-8 px-4 md:px-8 text-5xl md:col-span-6 gap-6"
  >
    <slot name="emoji"><div class="w-20">📈</div></slot>
    <div>
      <div
        class="items-left mt-1 flex flex-col place-content-start text-2xl font-bold lg:mt-0"
      >
        <number-counter-group
          :increment-amount="incrementAmount"
          :number="number"
          :is-currency="isCurrency"
          :class="{ 'saturate-0': number == 0 }"
        ></number-counter-group>
        <div class="text-2xl font-bold mt-4">
          <slot name="text">amount of things</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    incrementAmount: { type: Number, default: 3 },
    number: { type: Number },
    isCurrency: { type: Boolean, default: false },
  },
}
</script>
