export default {
  methods: {
    fetchHiddenNotices() {
      let hiddenNotices = JSON.parse(localStorage.getItem("hiddenNotices"))

      if (!hiddenNotices) {
        hiddenNotices = []
      }

      return hiddenNotices
    },
  },
}
