<template>
  <div>
    <label
      :for="id"
      class="block text-xl font-medium leading-5 text-navy-600"
      :class="{ hidden: !visible }"
    >
      <slot></slot>

      <span v-if="field.required" class="pl-2 text-pink-100">*</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    field: { type: Object },
    id: { type: String },
    visible: { type: Boolean, default: true },
  },
}
</script>
