<template>
  <div>
    <template v-if="ownProfile">
      <label class="relative block cursor-pointer">
        <img :src="avatarUrl" :class="imageClasses" class="hover-bounce" />
        <input
          type="file"
          class="hidden"
          ref="file"
          @change="handlePhotoUpload"
        />

        <div
          class="absolute bottom-0 right-0 m-1 flex h-8 w-8 items-center justify-center rounded-full bg-pale-grey-100"
        >
          <icon name="pencil-square" class="inline-block h-5 w-5"></icon>
        </div>
      </label>
    </template>

    <template v-else>
      <img :src="avatarUrl" :class="imageClasses" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    avatarUrl: { type: String },
    ownProfile: { type: Boolean },
    imageClasses: { type: String },
  },
  methods: {
    handlePhotoUpload() {
      let formData = new FormData()
      formData.append("user[avatar]", this.$refs.file.files[0])

      this.axios.patch("/users/current.json", formData).then((response) => {
        this.avatarUrl = response.data.user.thumb_avatar_url
      })
    },
  },
}
</script>
