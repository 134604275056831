export default {
  data() {
    return {
      minAmount: 2.5,
      emailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    }
  },
  methods: {
    clearErrorStates(fields) {
      // Remove error class
      fields.forEach(function (field) {
        const el = document.getElementById(field)
        if (!el) return
        el.classList.remove("beam-input-error")
      })
    },
    validateEmail() {
      if (!this.emailRegex.test(this.emailAddress)) {
        this.displayError("Please enter a valid email address", "email-address")
        return false
      }

      return true
    },
    validateRecipientDetails() {
      if (this.pdfType) {
        return true
      }

      if (!this.emailRegex.test(this.recipientEmail)) {
        this.displayError(
          "Please enter a valid recipient email",
          "recipient-email"
        )
        return false
      }

      if (!this.recipientFirstName) {
        this.displayError(
          "Please enter your recipient first name",
          "recipient-first-name"
        )
        return false
      }

      if (!this.recipientLastName) {
        this.displayError(
          "Please enter your recipient last name",
          "recipient-last-name"
        )
        return false
      }

      return true
    },
    validateAmount() {
      if (this.selectedAmount < this.minAmount) {
        this.displayError(
          "Sorry, Beam's minimum donation is £" + this.minAmount,
          "custom-amount"
        )
        return false
      }

      return true
    },
    validateGiftsCount() {
      if (this.emailType) {
        return true
      }

      if (this.giftsCount < 1) {
        this.displayError(
          "Please select the number of gifts you want to buy",
          "gifts-count"
        )
        return false
      }

      return true
    },
    validateFirstName() {
      if (!this.firstName) {
        this.displayError("Please enter your first name", "first-name")
        return false
      }

      return true
    },
    validateGiftAid() {
      if (this.giftAidChecked) {
        return this.$refs.giftAid.validate()
      }

      return true
    },
    looksLikeSpam() {
      if (
        (this.emailSpamRegex &&
          this.emailAddress
            .trim()
            .toLowerCase()
            .match(new RegExp(this.emailSpamRegex))) ||
        (this.nameSpamRegex &&
          this.fullName
            .trim()
            .toLowerCase()
            .match(new RegExp(this.nameSpamRegex)))
      ) {
        this.displayError(
          "Sorry, there was a problem doing that. Please contact support."
        )
        this.logEvent("spam-payment-attempt")
        return false
      }

      return true
    },
  },
}
