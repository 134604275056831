<template>
  <div class="mx-auto max-w-2xl">
    <form
      ref="form"
      action="/member_superchargers"
      accept-charset="UTF-8"
      method="post"
    >
      <div class="stack-mt-12">
        <member-form-field
          v-for="field in form"
          :key="field.id"
          :field="field"
          @update:modelValue="fieldChange(field)"
          v-model="field.value"
        >
          forcerefresh
        </member-form-field>
      </div>

      <div class="mt-8 pt-5">
        <member-form-errors
          v-if="formHasErrors"
          :error-fields="errorFields"
        ></member-form-errors>

        <div class="flex justify-end">
          <button
            @click.prevent="submit"
            type="submit"
            class="button button-primary inline-block px-16 py-3"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MemberFormShared from "./MemberFormShared"
import MemberSuperchargerFormConditions from "./MemberSuperchargerFormConditions"
export default {
  mixins: [MemberFormShared, MemberSuperchargerFormConditions],
  data() {
    return {
      tableName: "member_supercharger",
    }
  },
  methods: {
    fieldChange(field) {
      if (field.id === "member_id") {
        this.memberChange()
      }
    },
    memberChange() {
      this.updateMemberHelplink("live_vacancies")
      this.updateMemberHelplink("job_preferences")
    },
  },
  created() {
    this.memberChange()
  },
}
</script>
