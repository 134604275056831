<template>
  <div class="sticky top-0 z-10 flex justify-around bg-white py-1">
    <a
      href="/members/todo"
      class="w-1/4 flex-shrink flex-grow cursor-pointer pb-2 text-center"
    >
      <div class="mb-2" :class="{ 'font-medium': selectedTab == 'todo' }">
        To Do
      </div>
      <div class="relative mx-auto h-10 w-10">
        <icon
          name="check-circle"
          :solid="selectedTab == 'todo'"
          :counter-value="incompleteActionsCount"
        />
      </div>
    </a>
    <a
      :href="nearestJobLink"
      class="w-1/4 flex-shrink flex-grow cursor-pointer pb-2 text-center"
    >
      <div class="mb-2" :class="{ 'font-medium': selectedTab == 'jobs' }">
        Jobs
      </div>
      <div class="relative mx-auto h-10 w-10">
        <icon
          name="briefcase"
          :solid="selectedTab == 'jobs'"
          :counter-value="member.matched_job_applications_count"
        />
      </div>
    </a>
    <a
      href="/members/dashboard"
      class="w-1/4 flex-shrink flex-grow cursor-pointer pb-2 text-center"
    >
      <div class="mb-2" :class="{ 'font-medium': selectedTab == 'community' }">
        Activity
      </div>
      <div class="relative mx-auto h-10 w-10">
        <icon
          name="bell"
          :solid="selectedTab == 'community'"
          :counter-value="member.unread_notifications_count"
        />
      </div>
    </a>
    <a
      href="/members/support"
      class="w-1/4 flex-shrink flex-grow cursor-pointer pb-2 text-center"
      :class="{ 'border-azure-100 text-azure-100': selectedTab == 'support' }"
    >
      <div class="mb-2" :class="{ 'font-medium': selectedTab == 'support' }">
        Support
      </div>
      <div>
        <img
          class="mx-auto w-10 rounded-full"
          :src="caseworker.thumb_avatar_url"
          v-if="caseworker && caseworker.thumb_avatar_url"
        />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    caseworker: {
      type: Object,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    nearestJobLink: {
      type: String,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    incompleteActionsCount: {
      type: Number,
      required: true,
    },
  },
  methods: {},
}
</script>
