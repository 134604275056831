<template>
  <vue-final-modal
    :name="name"
    :lock-scroll="true"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-5 max-w-md"
  >
    <modal-close-button :name="name"></modal-close-button>

    <img :src="memberPhoto" class="mx-auto w-16 rounded" />

    <div class="mt-6 text-center text-xl font-medium">
      A message to you from {{ memberName }}
    </div>

    <div class="mt-8 whitespace-pre-line">{{ message }}</div>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    message: String,
    memberName: String,
    memberPhoto: String,
  },
  data: function () {
    return {
      name: "modal-thank-you-message",
      open: true,
    }
  },
  mounted: function () {
    this.$vfm.show(this.name)
  },
}
</script>
