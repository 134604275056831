<template>
  <vue-final-modal
    :name="name"
    @before-open="beforeOpen"
    :lock-scroll="true"
    v-model="open"
    classes="flex absolute justify-center items-center h-full w-full"
    content-class="flex-grow bg-white rounded-md relative p-5 max-w-xl"
  >
    <modal-close-button :name="name"></modal-close-button>

    <!-- Top icon -->
    <div
      class="mx-auto flex h-8 w-8 items-center justify-center rounded-full bg-pink-100 sm:h-12 sm:w-12"
    >
      <template v-if="gift">
        <icon name="gift" class="inline-block h-5 w-5 text-white"></icon>
      </template>

      <template v-if="!gift">
        <icon name="check" class="inline-block h-5 w-5 text-white"></icon>
      </template>
    </div>

    <!-- heading -->
    <div class="mt-4 text-center text-lg font-medium sm:mt-8 sm:text-2xl">
      {{ heading }}
    </div>

    <div class="mt-3 text-sm sm:mt-8 sm:text-lg" v-html="bodyStart"></div>

    <div class="mt-3 text-sm sm:text-lg">
      {{ bodyFinish }}
    </div>

    <template v-if="!gift">
      <div
        class="space-y-2 sm:space-y-0 mx-auto mt-8 flex flex-col items-center justify-center sm:mt-12 sm:flex-row sm:justify-around"
      >
        <button
          @click="facebookShare(facebookShareUrl)"
          class="button js_ga_share flex w-40 items-center justify-center bg-facebook py-2 text-sm text-white sm:w-32 sm:text-base"
          data-source="thankyou"
          data-action="Facebook"
        >
          <facebook-icon class="mr-2 inline-block h-4 w-4"></facebook-icon>
          <span>Share</span>
        </button>

        <a
          :href="twitterShareUrl"
          class="button js_ga_share flex w-40 items-center justify-center bg-twitter py-2 text-sm text-white sm:w-32 sm:text-base"
          data-source="thankyou"
          data-action="Twitter"
          target="_blank"
        >
          <twitter-icon class="mr-2 inline-block h-4 w-4"></twitter-icon>
          <span>Tweet</span>
        </a>

        <a
          :href="whatsappShareUrl"
          class="button js_ga_share flex w-40 items-center justify-center bg-whatsapp py-2 text-sm text-white sm:w-32 sm:text-base"
          data-source="thankyou"
          data-action="WhatsApp"
          target="_blank"
        >
          <whatsapp-icon class="mr-2 inline-block h-4 w-4"></whatsapp-icon>
          <span>WhatsApp</span>
        </a>

        <copy-button
          class="w-40 justify-center py-2 text-sm sm:w-32 sm:text-base"
          :url="copyShareUrl"
          source="thankyou"
        >
        </copy-button>
      </div>
    </template>

    <a
      v-if="gift"
      href="/gifts/new"
      class="button button-secondary mx-auto mt-12 block px-12 py-3 text-lg"
    >
      Give another gift
    </a>
  </vue-final-modal>
</template>

<script>
import FacebookMixin from "../mixins/FacebookMixin"

export default {
  mixins: [FacebookMixin],
  props: [
    "twitterShareUrl",
    "facebookShareUrl",
    "copyShareUrl",
    "whatsappShareUrl",
    "splitDonation",
  ],
  data() {
    return {
      name: "modal-thank-you",
      campaign: null,
      gift: null,
      fundraiser: null,
      subscription: null,
      open: false,
    }
  },
  methods: {
    beforeOpen(event) {
      this.campaign = event.ref.params.value.campaign
      this.gift = event.ref.params.value.gift
      this.fundraiser = event.ref.params.value.fundraiser
      this.subscription = event.ref.params.value.subscription
    },
  },
  computed: {
    heading() {
      if (this.fundraiser) {
        return `Thank you for giving to ${this.fundraiser.name}!`
      } else if (this.subscription) {
        return "Thank you!"
      } else if (this.gift) {
        return "Thank you for buying a Beam Gift Card!"
      } else {
        return "Thank you for your donation!"
      }
    },
    bodyStart() {
      if (this.subscription) {
        return `You're one of <span class='font-medium'>${this.subscription.subscriber_count.toLocaleString()}</span> monthly supporters helping people into employment and out of homelessness.`
      } else if (this.campaign) {
        return `Thank you for supporting <span class='font-medium'>${this.campaign.member_name}</span> ${this.campaign.to_become_or_move_text}. You'll be getting a personal message from ${this.campaign.object_pronoun} shortly.`
      } else if (this.gift) {
        if (this.gift.delivery_method == "pdf") {
          return "We have emailed you your PDF gift card to print and give."
        } else {
          return `We have emailed your gift donation to ${this.gift.recipient_first_name} at ${this.gift.recipient_email}.`
        }
      } else if (this.splitDonation) {
        return "You'll be getting a personal message from the people you supported shortly."
      } else {
        return "You'll be getting a personal message from the person you supported shortly."
      }
    },
    bodyFinish() {
      if (this.gift) {
        return ""
      } else if (this.subscription) {
        return "Each month you'll get an email introducing you to the person that your monthly donation supported."
      }

      return "In the meantime, please help spread the word. On average, sharing on Facebook brings in £20 of donations for free."
    },
  },
}
</script>
