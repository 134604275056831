<template>
  <section class="mt-8">
    <div class="">
      <textarea
        id="post-update-textarea"
        class="beam-text-input block w-full"
        placeholder="Post an update to your supporters..."
        @click="focussed = true"
        v-model.trim="updateText"
      >
      </textarea>

      <transition name="slide-fade">
        <div v-if="focussed" class="mt-2 flex items-baseline justify-end">
          <label>
            <span class="blue-link">
              <icon
                name="photo"
                class="inline-block h-5 w-5 pt-1"
                :solid="true"
              ></icon>
              {{ attachedText }}
            </span>

            <input type="file" @change="attachmentsChanged" class="hidden" />
          </label>

          <div class="ml-4">
            <button
              @click="postUpdate"
              class="button button-secondary px-6 py-2 text-sm"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    source: String,
    memberId: Number,
    fundraiserId: Number,
  },
  data() {
    return {
      updateText: "",
      updateAttachments: null,
      focussed: false,
      posting: false,
    }
  },
  computed: {
    buttonText() {
      return this.posting ? "Posting" : "Post"
    },
    formData() {
      var data = new FormData()
      data.append("text", this.updateText)
      if (this.source) data.append("source", this.source)
      if (this.memberId) data.append("member_id", this.memberId)
      if (this.fundraiserId) data.append("fundraiser_id", this.fundraiserId)

      if (this.updateAttachments) {
        this.updateAttachments.forEach((file) => {
          data.append("attachment[]", file)
        })
      }

      return data
    },
    attachedText() {
      if (!this.updateAttachments) {
        return "Attach Photo or Video"
      } else if (this.updateAttachments.length == 1) {
        return "File Attached!"
      } else {
        return this.updateAttachments.length + " Attachments"
      }
    },
    isTextBlank() {
      return this.updateText == null || this.updateText.length == 0
    },
    isAttachmentsBlank() {
      return (
        this.updateAttachments == null || this.updateAttachments.length == 0
      )
    },
  },
  methods: {
    attachmentsChanged(event) {
      this.updateAttachments = []

      for (var i = 0; i < event.target.files.length; i++) {
        this.updateAttachments.push(event.target.files[i])
      }
    },
    postUpdate() {
      if (this.isTextBlank && this.isAttachmentsBlank) {
        return
      }

      this.posting = true

      this.axios
        .post("/campaign_updates", this.formData)
        .then((response) => {
          if (response.data.success) {
            this.handleSuccess()
            this.$emit("update-posted")
          } else {
            this.$root.showFlash(
              "Sorry, there was an error posting your update.",
              "error"
            )
          }
        })
        .finally(() => {
          this.posting = false
        })
    },
    handleSuccess() {
      this.updateText = ""
      this.updateAttachments = null

      if (this.fundraiserId) {
        this.$root.showFlash("Thanks for your update!")
      } else {
        this.$root.showFlash(
          "Thank you for your update! We’ll turn the content into an update for your Beam profile. If you have questions about this (or anything else!) please contact your caseworker.",
          "success"
        )
      }
    },
  },
}
</script>
