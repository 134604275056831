<template>
  <div class="mt-5 flex items-center justify-start sm:mt-8">
    <!-- Like Button -->
    <button
      @click="toggleLike"
      class="button button-tertiary flex h-8 items-center px-4 py-1"
      title="Like"
    >
      <span class="mr-2 text-pink-100" v-if="mutableLiked">
        <icon name="heart" :solid="true" class="h-4 w-4"></icon>
      </span>

      <span class="mr-2 text-bluey-grey-100" v-if="!mutableLiked">
        <icon name="heart" :solid="true" class="h-4 w-4"></icon>
      </span>

      <span class="text-xs font-medium sm:text-sm" v-if="!mutableLiked">{{
        mutableLikeCount
      }}</span>
      <span
        class="text-xs font-medium text-pink-100 sm:text-sm"
        v-if="mutableLiked"
        >{{ mutableLikeCount }}</span
      >
    </button>

    <!-- Share Button -->
    <a
      @click="openShareModal"
      href="javascript:;"
      class="button button-tertiary ml-2 flex h-8 items-center px-4 py-1 sm:ml-3"
    >
      <icon
        name="share"
        :solid="true"
        class="mr-2 h-4 w-4 text-bluey-grey-100"
        title="Share"
      ></icon>
      <span class="text-xs font-medium sm:text-sm">Share</span>
    </a>

    <!-- Fund Button -->
    <a
      v-if="donateLink"
      :href="donateLink"
      class="button button-primary ml-2 flex h-8 items-center px-4 py-1 sm:ml-3"
    >
      <span class="text-xs font-medium text-white sm:text-sm">Donate</span>
    </a>
  </div>
</template>

<script>
import AnalyticsMixin from "./mixins/AnalyticsMixin"

export default {
  mixins: [AnalyticsMixin],
  props: {
    likedByUser: Boolean,
    likeCount: Number,
    donateLink: String,
    campaignId: Number,
  },
  data: function () {
    return {
      mutableLiked: this.likedByUser,
      mutableLikeCount: this.likeCount,
    }
  },
  methods: {
    openShareModal: function () {
      const opts = {
        memberName: this.$parent.memberName,
        updateId: this.$parent.update.id,
      }

      this.$vfm.show("modal-update-share", opts)
    },
    toggleLike: function () {
      if (this.mutableLiked) {
        this.unlike()
      } else {
        this.like()
      }
    },
    unlike: function () {
      this.mutableLiked = false
      this.mutableLikeCount -= 1

      const url = "/campaign_update_likes/" + this.$parent.update.id

      this.axios.delete(url)
    },
    like: function () {
      this.mutableLiked = true
      this.mutableLikeCount += 1

      const postOpts = {
        campaign_update_like: {
          campaign_update_id: this.$parent.update.id,
          source: gon.feed_source,
        },
      }

      const _this = this

      this.axios.post("/campaign_update_likes", postOpts).then(function () {
        _this.logEvent("update-like")
      })
    },
  },
}
</script>
