<template>
  <div class="sticky top-0 z-20">
    <slot
      :showActions="showActions"
      :showMemberHubLinks="showMemberHubLinks"
      :navDonateHref="navDonateHref"
    >
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    showActions: { type: Boolean },
    showMemberHubLinks: { type: Boolean },
    navDonateHref: { type: String },
  },
}
</script>
