<template>
  <div>
    <template v-if="showEditForm">
      <form
        class="mt-8"
        :action="`/subscriptions/${id}.json`"
        method="post"
        @submit.prevent="save"
      >
        <!-- Donation amount -->
        <label for="total-value" class="inline-block font-medium"
          >Donation amount</label
        >
        <div class="relative mt-5">
          <div class="center-absolute-y absolute pl-4 text-bluey-grey-100">
            £
          </div>
          <input
            type="number"
            class="beam-text-input w-full pl-8"
            v-model.number="selectedAmount"
            id="total-value"
          />
        </div>

        <!-- Tip -->
        <label for="service-fee" class="mt-8 inline-block font-medium"
          >Beam support costs</label
        >
        <div class="mt-5 flex items-center">
          <select
            class="beam-text-input w-full"
            id="service-fee"
            v-model="beamDonationPercent"
          >
            <tip-amount-option
              v-for="amount in tipAmounts"
              :key="amount"
              :amount="amount"
            >
            </tip-amount-option>
          </select>
          <icon
            name="chevron-down"
            class="fas pointer-events-none -ml-8 inline-block h-5 w-5 pt-1 text-azure-100"
          ></icon>
        </div>

        <label for="support-message" class="mt-8 inline-block font-medium"
          >Message of support (optional)</label
        >
        <input
          type="text"
          class="beam-text-input my-5 w-full"
          v-model.trim="supportMessage"
          id="support-message"
        />

        <button
          class="button button-secondary mt-6 w-full px-12 py-3 sm:w-auto"
        >
          Donate £{{ totalAmountDisplay }} monthly
        </button>
      </form>

      <h3 class="heading-level-4 mt-16 font-medium">
        Pause your monthly donation
      </h3>

      <div class="mt-4">
        If you would like to temporarily pause your monthly donation you can do
        so by clicking one of the buttons below. It will automatically resume
        after that period of time.
      </div>

      <form
        class="mt-8"
        :action="`/subscriptions/${id}/pause.json?pause_period=one_month`"
        method="post"
        @submit.prevent="togglePaused"
      >
        <button class="button button-tertiary w-full py-2 sm:w-56">
          Pause for one month
        </button>
      </form>

      <form
        class="mt-4"
        :action="`/subscriptions/${id}/pause.json?pause_period=three_months`"
        method="post"
        @submit.prevent="togglePaused"
      >
        <button class="button button-tertiary w-full py-2 sm:w-56">
          Pause for three months
        </button>
      </form>

      <form
        class="mt-4"
        :action="`/subscriptions/${id}/pause.json?pause_period=six_months`"
        method="post"
        @submit.prevent="togglePaused"
      >
        <button class="button button-tertiary w-full py-2 sm:w-56">
          Pause for six months
        </button>
      </form>

      <div class="mt-16 text-center text-sm">
        Please
        <a
          class="blue-link"
          href="mailto:hello@beam.org?subject=Monthly Donation"
          >contact support</a
        >
        if you would like to cancel your monthly donation.
      </div>
    </template>

    <template v-if="currentlyPaused">
      <div>
        Your monthly donation has been paused. It will resume on
        <span class="font-medium">{{ pausedUntilDisplay }}</span
        >.
      </div>

      <form
        class="mt-8"
        :action="`/subscriptions/${id}/unpause.json`"
        method="post"
        @submit.prevent="togglePaused"
      >
        <button class="button button-secondary w-full px-12 py-3 sm:w-auto">
          Unpause
        </button>
      </form>
    </template>

    <template v-if="showCreateSubscriptionButton">
      Make the biggest impact by giving a monthly donation.

      <div class="mt-5 text-center sm:mt-8 lg:text-left">
        <a
          href="/donate"
          class="button button-secondary inline-block w-full py-3 text-center sm:w-auto sm:px-12"
        >
          Give Monthly
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import CheckoutAmountsMixin from "../checkout/CheckoutAmountsMixin"

export default {
  mixins: [CheckoutAmountsMixin],
  props: {
    subscription: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      id: this.subscription.id,
      beamDonationPercent: this.subscription.beam_donation_percent,
      supportMessage: this.subscription.support_message,
      selectedAmount: this.subscription.initial_amount_for_account,
    }
  },
  computed: {
    pausedUntilDisplay() {
      return new Date(this.subscription.paused_until).toLocaleDateString(
        "en-GB",
        { year: "numeric", day: "numeric", month: "long" }
      )
    },
    currentlyPaused() {
      if (!this.subscription.paused_until) return false

      return new Date() < new Date(this.subscription.paused_until)
    },
    showCreateSubscriptionButton() {
      return !this.id
    },
    showEditForm() {
      return this.id && !this.currentlyPaused
    },
    postData() {
      return {
        subscription: {
          support_message: this.supportMessage,
          beam_donation_percent: this.beamDonationPercent,
          total_value: this.totalAmount,
        },
      }
    },
  },
  methods: {
    save(event) {
      this.$root.closeFlash()

      this.axios.patch(event.target.action, this.postData).then((response) => {
        const data = response.data

        if (data.success) {
          this.$root.showFlash(
            "Your monthly donation has been updated!",
            "notice"
          )
          this.$emit("subscription-updated", data.subscription)
        } else {
          this.$root.showFlash(data.error_message, "error")
        }
      })
    },
    togglePaused() {
      this.$root.closeFlash()

      this.axios.post(event.target.action, {}).then((response) => {
        const data = response.data

        if (data.success) {
          this.$emit("subscription-updated", data.subscription)

          let message = data.subscription.paused_until
            ? "Your monthly donation has been paused."
            : "Your monthly donation has been unpaused."

          this.$root.showFlash(message, "notice")

          this.$scrollTo(document.querySelector("#app"))
        } else {
          this.$root.showFlash(data.error_message, "error")
        }
      })
    },
  },
}
</script>
